import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface FavoriteIconProps {
    className?: string;
}

export const FavoriteIcon: FunctionComponent<FavoriteIconProps> = ({ className }) => {
    const { t } = useTranslation();

    return <svg className={className} width="145" height="102" viewBox="0 0 145 102" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4">
        <g filter="url(#filter0_f_4539_9607)">
        <ellipse cx="50.5737" cy="52.9332" rx="28.7846" ry="6.69047" fill="#FFEBE7"/>
        </g>
        <path d="M110.15 6.20532H56.0826C54.0708 6.20532 52.1414 7.00451 50.7188 8.42706C49.2963 9.84962 48.4971 11.779 48.4971 13.7908V75.4019H48.5898V97.0375C48.5897 97.8729 48.8037 98.6943 49.2114 99.4235C49.6191 100.153 50.2068 100.765 50.9185 101.203C51.6303 101.64 52.4422 101.888 53.2769 101.922C54.1116 101.957 54.9411 101.777 55.6864 101.399L80.6259 88.7566C81.3054 88.4159 82.055 88.2385 82.8151 88.2385C83.5753 88.2385 84.3249 88.4159 85.0044 88.7566L110.711 101.492C111.457 101.861 112.284 102.034 113.116 101.994C113.947 101.954 114.755 101.703 115.462 101.264C116.168 100.824 116.751 100.212 117.155 99.4838C117.559 98.7561 117.771 97.9372 117.77 97.1049L117.702 75.4019V13.7698C117.697 11.7674 116.9 9.84852 115.485 8.43148C114.07 7.01443 112.153 6.21421 110.15 6.20532Z" fill="url(#paint0_linear_4539_9607)"/>
        <path d="M144.053 26.1589C144.054 27.7987 143.46 29.3831 142.379 30.6168C141.299 31.8505 139.807 32.6492 138.182 32.864C137.884 32.9019 137.585 32.9213 137.286 32.9222H114.788C114.3 32.9212 113.813 32.881 113.332 32.8021C111.264 32.4452 109.401 31.3361 108.102 29.6882C106.802 28.0402 106.159 25.9699 106.294 23.8758C106.43 21.7817 107.335 19.8118 108.836 18.3452C110.337 16.8786 112.328 16.019 114.424 15.9319C115.511 13.5723 117.335 11.6301 119.623 10.3982C121.91 9.16628 124.535 8.71151 127.104 9.10248C129.672 9.49344 132.043 10.7089 133.86 12.5656C135.677 14.4223 136.841 16.8195 137.176 19.3955H137.278C138.167 19.3941 139.048 19.568 139.87 19.9072C140.692 20.2465 141.439 20.7444 142.068 21.3726C142.697 22.0008 143.196 22.7469 143.537 23.5681C143.877 24.3894 144.053 25.2698 144.053 26.1589Z" fill="url(#paint1_linear_4539_9607)"/>
        <path d="M29.6553 12.9125C29.6553 14.2649 30.1925 15.5618 31.1487 16.518C32.1049 17.4743 33.4019 18.0115 34.7542 18.0115H51.6826C53.3505 18.0026 54.9491 17.3429 56.1378 16.1728C57.3265 15.0028 58.0113 13.4148 58.0465 11.7472C58.0816 10.0796 57.4643 8.46422 56.3259 7.24511C55.1876 6.026 53.6182 5.29953 51.9521 5.22046C51.1304 3.45055 49.7568 1.99492 48.0374 1.07212C46.318 0.149306 44.3457 -0.190896 42.4166 0.102583C40.4874 0.396062 38.7055 1.30738 37.3383 2.69974C35.9712 4.0921 35.0925 5.89035 34.8343 7.82455H34.7578C33.4068 7.82358 32.1106 8.35886 31.1539 9.31284C30.1972 10.2668 29.6582 11.5615 29.6553 12.9125Z" fill="url(#paint2_linear_4539_9607)"/>
        <g filter="url(#filter1_f_4539_9607)">
        <ellipse cx="52.1637" cy="29.4937" rx="16.2252" ry="3.47684" fill="#FF7C86" fillOpacity="0.39"/>
        </g>
        <path d="M85.1214 18.2834L91.1223 30.4413C91.3003 30.8017 91.5633 31.1136 91.8885 31.3499C92.2136 31.5863 92.5914 31.7402 92.9892 31.7983L106.403 33.7326C106.857 33.8021 107.283 33.9964 107.633 34.2938C107.984 34.5911 108.245 34.9799 108.387 35.4168C108.529 35.8536 108.548 36.3214 108.44 36.7681C108.332 37.2148 108.103 37.6228 107.777 37.9467L98.0841 47.4075C97.7959 47.688 97.5804 48.0346 97.4563 48.4172C97.3322 48.7997 97.3032 49.2068 97.3719 49.6031L99.6644 62.962C99.743 63.4188 99.6922 63.8884 99.5177 64.3177C99.3431 64.747 99.0519 65.1189 98.6769 65.3913C98.3019 65.6637 97.8582 65.8256 97.3959 65.8588C96.9337 65.8919 96.4714 65.795 96.0613 65.579L84.0636 59.2578C83.7074 59.071 83.3111 58.9734 82.9089 58.9734C82.5067 58.9734 82.1105 59.071 81.7542 59.2578L69.7565 65.579C69.3466 65.7939 68.8848 65.8898 68.4232 65.8561C67.9617 65.8223 67.5187 65.6602 67.1444 65.3881C66.7701 65.1159 66.4794 64.7445 66.305 64.3158C66.1306 63.8871 66.0796 63.4182 66.1576 62.962L68.4459 49.6031C68.5146 49.2068 68.4857 48.7997 68.3616 48.4172C68.2375 48.0346 68.022 47.688 67.7337 47.4075L58.0411 37.9467C57.7152 37.6228 57.4856 37.2148 57.3778 36.7681C57.2701 36.3214 57.2884 35.8536 57.4308 35.4168C57.5732 34.9799 57.8341 34.5911 58.1844 34.2938C58.5348 33.9964 58.9607 33.8021 59.4149 33.7326L72.8286 31.7856C73.2264 31.7275 73.6042 31.5737 73.9294 31.3373C74.2546 31.1009 74.5175 30.7891 74.6955 30.4286L80.6965 18.2708C80.9053 17.8631 81.2229 17.5211 81.614 17.2827C82.0052 17.0443 82.4547 16.9189 82.9128 16.9202C83.3709 16.9215 83.8196 17.0495 84.2094 17.2901C84.5992 17.5307 84.9149 17.8745 85.1214 18.2834Z" fill="url(#paint3_linear_4539_9607)"/>
        <path d="M79.5416 15.8121C79.2967 14.8516 80.1835 13.9861 81.1378 14.2543C82.0921 14.5224 82.3982 15.7231 81.6888 16.4155C80.9794 17.1079 79.7865 16.7727 79.5416 15.8121Z" fill="#FFBEBD"/>
        <path d="M20.0484 15.8121C19.8035 14.8516 20.6903 13.9861 21.6446 14.2543C22.5989 14.5224 22.9051 15.7231 22.1957 16.4155C21.4863 17.1079 20.2934 16.7727 20.0484 15.8121Z" fill="#FFBEBD"/>
        <path d="M9.02034 5.41058C9.28577 4.31102 10.8498 4.31102 11.1152 5.41058C11.21 5.80351 11.5168 6.11031 11.9098 6.20516C13.0093 6.47058 13.0093 8.03458 11.9098 8.3C11.5168 8.39485 11.21 8.70165 11.1152 9.09458C10.8498 10.1941 9.28577 10.1941 9.02034 9.09458C8.92549 8.70165 8.6187 8.39485 8.22576 8.3C7.12621 8.03458 7.12621 6.47058 8.22576 6.20516C8.6187 6.11031 8.92549 5.80351 9.02034 5.41058Z" fill="#FFBEBD"/>
        <path d="M52.7265 51.7713C52.7265 52.0813 52.4751 52.3327 52.1651 52.3327C51.8551 52.3327 51.6037 52.0813 51.6037 51.7713C51.6037 51.4613 51.8551 51.2099 52.1651 51.2099C52.4751 51.2099 52.7265 51.4613 52.7265 51.7713Z" stroke="#FFBEBD" strokeWidth="2.48287"/>
        <path d="M78.4178 41.9462C78.6832 40.8467 80.2472 40.8467 80.5126 41.9462C80.6075 42.3392 80.9143 42.646 81.3072 42.7408C82.4068 43.0062 82.4068 44.5702 81.3072 44.8356C80.9143 44.9305 80.6075 45.2373 80.5126 45.6302C80.2472 46.7298 78.6832 46.7298 78.4178 45.6302C78.323 45.2373 78.0162 44.9305 77.6232 44.8356C76.5237 44.5702 76.5237 43.0062 77.6232 42.7408C78.0162 42.646 78.323 42.3392 78.4178 41.9462Z" fill="#FFBEBD"/>
        <circle cx="81.2667" cy="31.1683" r="1.24143" stroke="#FC9999" strokeWidth="1.12274"/>
        </g>
        <defs>
        <filter id="filter0_f_4539_9607" x="0.0160675" y="24.4697" width="101.115" height="56.9268" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_4539_9607"/>
        </filter>
        <filter id="filter1_f_4539_9607" x="15.2479" y="5.32628" width="73.8313" height="48.3347" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="10.3453" result="effect1_foregroundBlur_4539_9607"/>
        </filter>
        <linearGradient id="paint0_linear_4539_9607" x1="54.9215" y1="-5.21166" x2="79.5341" y2="107.303" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9999"/>
        <stop offset="1" stopColor="#FFE7DA"/>
        </linearGradient>
        <linearGradient id="paint1_linear_4539_9607" x1="136.884" y1="43.2541" x2="118.093" y2="8.96606" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBEBD"/>
        <stop offset="1" stopColor="#FFF6F5"/>
        </linearGradient>
        <linearGradient id="paint2_linear_4539_9607" x1="52.6598" y1="25.7794" x2="38.5296" y2="0.00394675" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBEBD"/>
        <stop offset="1" stopColor="#FFF6F5"/>
        </linearGradient>
        <linearGradient id="paint3_linear_4539_9607" x1="99.7506" y1="65.9892" x2="15.3645" y2="-19.276" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFDEAC"/>
        <stop offset="0.70369" stopColor="#FF426F"/>
        </linearGradient>
        </defs>
    </svg>    
}
