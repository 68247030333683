const getOrigin = (linkDomain: 'base' | 'challenges') => {
  const isDevelop = process.env.NODE_ENV === 'development';

  const currentHostname = window.location.hostname;
  const isYourSubdomain = currentHostname.startsWith('your.');
  const isOnChallengesSubdomain = currentHostname.includes('challenges.');

  let result = '';

  // Если ссылка на челленджи
  if (linkDomain === 'challenges') {
    result =
      !isOnChallengesSubdomain || isDevelop
        ? `https://${isYourSubdomain ? 'your.' : ''}challenges.s10.run`
        : '';
    // Ссылка на основной
  } else {
    result =
      isOnChallengesSubdomain || isDevelop
        ? `https://${isYourSubdomain ? 'your.' : ''}s10.run`
        : '';
  }

  return result;
};

const baseOrigin = getOrigin('base');
const challengesOrigin = getOrigin('challenges');

export const links = {
  origin: baseOrigin,
  challengesOrigin: challengesOrigin,
  profileLink: `${baseOrigin}/profile`,
  noInProtocolLink:
    'https://answers.s10.run/t/menya-net-v-protokole-v-chem-prichina-ne-najden-v-protokole/18',
  shopLink: 'https://shop.s10.run',
  ratingLink: `${challengesOrigin}/rating`,
  schoolLink: 'https://school.s10.run',
  telegramChatLink: 'https://t.me/S10_Challenges',
  cannyLink: 'https://s10.canny.io',
  answersLink: `${baseOrigin}/tag?q=s10challenges_%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0`,
  telegramStroLink: 'https://t.me/strofilov',
  payment: `${baseOrigin}/money`,
  premium: `${baseOrigin}/premium`,
  appealsAdministration: `${baseOrigin}/t5/misc/appeals`,
};
