import React, { FunctionComponent, Suspense, useMemo } from "react";
import { Lang } from "/src/domain/shared/User";
import { StudentInfo } from "/src/domain/dashboard/DashboardData";
import { ChartAnchor, chartEntryData } from "/src/domain/charts/ChartsData";
const Chart4NearPaces = React.lazy(() => import('/src/ui/charts/charts/Chart4NearPaces'));
const Chart4AvgPace = React.lazy(() => import('/src/ui/charts/charts/Chart4AvgPace'));
const Chart4Cost = React.lazy(() => import('/src/ui/charts/charts/Chart4Cost'));
const Chart4CostHist = React.lazy(() => import('/src/ui/charts/charts/Chart4CostHist'));
const Chart4Cadence = React.lazy(() => import('/src/ui/charts/charts/Chart4Cadence'));
const Chart4PerWeek = React.lazy(() => import('/src/ui/charts/charts/Chart4PerWeek'));
const Chart4PerMonth = React.lazy(() => import('/src/ui/charts/charts/Chart4PerMonth'));
const Chart4Flb = React.lazy(() => import('/src/ui/charts/charts/Chart4Flb'));
const Chart4Words = React.lazy(() => import('/src/ui/charts/charts/Chart4Words'));

import { Box, CircularProgress } from "@mui/material";
import { BlockHeader } from "../BlockHeader";
import { makeStyles } from "@mui/styles";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { random as _random } from "lodash";

interface ChartProps {
    studentInfo: StudentInfo;
    headerClassName: string;
}

const STRO_ID = "7439904";

export const Chart: FunctionComponent<ChartProps> = ({
    headerClassName,
    studentInfo
}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const isRuLang = i18n.language === Lang.RU || i18n.language === Lang.UK;

    const studentId = studentInfo.isPremium ? studentInfo.id : STRO_ID;

    const dashboardChart = useMemo(() => {
        if (studentInfo.dashboardChart) 
            return studentInfo.dashboardChart;

        const charts = Object.keys(chartEntryData) as Array<ChartAnchor>;
        const chartIndex = _random(charts.length - 1);
        
        return charts[chartIndex];
    }, [])
    
    return <Box>
            <BlockHeader
                title={ t(String(chartEntryData[dashboardChart])) }
                linkText={ t('moreCharts') }
                link={`/charts?${studentId}`}
                className={headerClassName}
            />
            {(dashboardChart === ChartAnchor.CHART_NEAR_PACE_7 || dashboardChart === ChartAnchor.CHART_NEAR_PACE_30) && <Suspense fallback={<CircularProgressStub className={classes.chart}/>}>
                <Chart4NearPaces
                    isRu={isRuLang}
                    studentId={studentId}
                />
            </Suspense>}
            {dashboardChart === ChartAnchor.CHART_AVG_PACE && <Suspense fallback={<CircularProgressStub className={cs(classes.chart, classes.chart4AvgPace)}/>}>
                <Chart4AvgPace
                    isRu={isRuLang}
                    studentId={studentId}
                    isFullScreen={false}
                    className={cs(classes.chart, classes.chart4AvgPace)}
                />
            </Suspense>}
            {dashboardChart === ChartAnchor.CHART_COST && <Suspense fallback={<CircularProgressStub className={classes.chart}/>}>
                <Chart4Cost
                    isRu={isRuLang}
                    studentId={studentId}
                    isFullScreen={false}
                    className={classes.chart}
                />
            </Suspense>}
            {dashboardChart === ChartAnchor.CHART_COST_HIST && <Suspense fallback={<CircularProgressStub className={classes.chart}/>}>
                <Chart4CostHist
                    isRu={isRuLang}
                    studentId={studentId}
                    isFullScreen={false}
                    className={classes.chart}
                />
            </Suspense>}
            {dashboardChart === ChartAnchor.CHART_CADENCE && <Suspense fallback={<CircularProgressStub className={classes.chart}/>}>
                <Chart4Cadence
                    isRu={isRuLang}
                    studentId={studentId}
                    isFullScreen={false}
                    className={classes.chart}
                />
            </Suspense>}
            {dashboardChart === ChartAnchor.CHART_PER_WEEK && <Suspense fallback={<CircularProgressStub className={classes.chart}/>}>
                <Chart4PerWeek
                    isRu={isRuLang}
                    studentId={studentId}
                    isFullScreen={false}
                    className={classes.chart}
                />
            </Suspense>}
            {dashboardChart === ChartAnchor.CHART_PER_MONTH && <Suspense fallback={<CircularProgressStub className={classes.chart}/>}>
                <Chart4PerMonth
                    isRu={isRuLang}
                    studentId={studentId}
                    isFullScreen={false}
                    className={classes.chart}
                />
            </Suspense>}
            {dashboardChart === ChartAnchor.CHART_FLB && <Suspense fallback={<CircularProgressStub className={classes.chart}/>}>
                <Chart4Flb
                    isRu={isRuLang}
                    studentId={studentId}
                    isFullScreen={false}
                    className={classes.chart}
                />
            </Suspense>}
            {dashboardChart === ChartAnchor.CHART_WORDS && <Suspense fallback={<CircularProgressStub/>}>
                <Chart4Words
                    isRu={isRuLang}
                    studentId={studentId}
                    className={classes.chart}
                />
            </Suspense>}
    </Box>
}


interface CircularProgressStubProps {
    className?: string;
}

const CircularProgressStub: FunctionComponent<CircularProgressStubProps> = ({ className }) => {
    return <Box className={className} sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }}>
        <CircularProgress size={"5rem"}/>
    </Box>
}

const useStyles = makeStyles(muiTheme => ({
    header: {

    },
    chart: {
        height: "35rem",
        [muiTheme.breakpoints.down("md")]: {
            fontSize: "0.8rem",
            margin: 0
        }
    },
    chart4AvgPace: {
        [muiTheme.breakpoints.down("md")]: {
            height: "100vh",
            minHeight: "40rem",
            maxHeight: "50rem"
        }
    }
}));