import React, { FunctionComponent } from "react";
import { Dialog, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import cs from "classnames";
import { useTranslation } from "react-i18next"

interface FeedbackDialogProps {
    isOpen: boolean;
    hide: () => void,
    className?: string;
}

export const FeedbackDialog: FunctionComponent<FeedbackDialogProps> = ({ 
    isOpen, 
    hide, 
    className,
    children 
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    return (
        <Dialog
            open={isOpen}
            onClick={hide}
        >
            <Paper onClick={(e) => e.stopPropagation()} className={classes.paper}>
                <div className={cs(classes.contentContainer, className)}>
                    { children }
                </div>
            </Paper>
        </Dialog>
    )
}

const useStyles = makeStyles({
    paper: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        padding: "1.5rem"
    },
    contentContainer: {
        maxWidth: "1100px",
        margin: "0 auto"
    }
})
