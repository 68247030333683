import axios from "axios";
import {convertObjectToFormData, postParameters} from "/src/domain/shared/convertObjectToFormData";


export const sendSkipForStat = async (studentId: string, trackId: string, status: boolean): Promise<boolean> => {
    try {
        const response = await axios.post(`/student?${studentId}`, convertObjectToFormData({
            trackId,
            skipforstat: status ? 1 : 0
        }), postParameters);

        return response.data.skipforstat;
    } catch (e) {
        console.log('sendSkipForStat:', e);
        return false;
    }
}