import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface MoreTrainersProps {
    className?: string;
}

export const MoreTrainers: FunctionComponent<MoreTrainersProps> = ({ className }) => {
    const { t } = useTranslation();

    return <svg className={className} width="45" height="53" viewBox="0 0 45 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.5578 13.4211C26.4005 13.3534 26.2665 13.2411 26.1723 13.0981C26.0782 12.9551 26.028 12.7876 26.0281 12.6164C26.027 12.4941 26.051 12.3728 26.0987 12.2601C26.1464 12.1475 26.2168 12.0458 26.3054 11.9615C26.3595 11.9099 26.4113 11.8591 26.4501 11.8182C26.7244 11.5294 26.9664 11.2115 27.1719 10.8703C27.7122 9.96856 27.9801 8.92976 27.9431 7.87921C27.9062 6.82866 27.566 5.81125 26.9637 4.94973C26.3613 4.08821 25.5225 3.41941 24.5485 3.02401C23.5745 2.62861 22.5069 2.52352 21.4745 2.72141C20.4225 2.90563 19.4502 3.40215 18.6841 4.14638C17.918 4.89061 17.3935 5.84813 17.1789 6.89442C16.9806 7.79533 17.0143 8.73187 17.277 9.61616C17.5397 10.5005 18.0227 11.3035 18.6807 11.9501C18.77 12.0347 18.8415 12.1363 18.891 12.249C18.9404 12.3616 18.9669 12.483 18.9689 12.606V12.6106C18.9704 12.7832 18.921 12.9525 18.8268 13.0971C18.7326 13.2418 18.5977 13.3554 18.4392 13.4237C16.2691 14.3227 14.5385 16.0375 13.6199 18.1993C12.9741 19.6798 12.6501 21.2806 12.6695 22.8957V24.0595C12.6695 24.3091 12.7186 24.5562 12.8142 24.7868C12.9097 25.0174 13.0497 25.2269 13.2262 25.4033C13.4027 25.5798 13.6122 25.7197 13.8428 25.8152C14.0734 25.9106 14.3206 25.9597 14.5702 25.9597H30.4313C30.9352 25.9596 31.4184 25.7594 31.7747 25.4031C32.131 25.0468 32.3311 24.5636 32.3311 24.0597V22.8842C32.3504 21.2697 32.0259 19.6696 31.3791 18.1902C30.4588 16.0303 28.7276 14.3178 26.5578 13.4211V13.4211Z" fill="#FF7770"/>
        <path d="M44.0467 15.5659C43.1265 13.406 41.3955 11.6936 39.2258 10.7968C39.0686 10.7291 38.9346 10.6168 38.8405 10.4738C38.7464 10.3308 38.6962 10.1633 38.6962 9.99212C38.6951 9.86976 38.7191 9.74848 38.7669 9.63581C38.8146 9.52313 38.885 9.42146 38.9736 9.33711C39.0277 9.28549 39.0796 9.23476 39.1182 9.1938C39.3926 8.9052 39.6346 8.5875 39.84 8.24635C40.3804 7.34467 40.6483 6.30586 40.6114 5.25529C40.5745 4.20471 40.2344 3.18726 39.6321 2.32572C39.0297 1.46417 38.1909 0.795348 37.2169 0.399952C36.2429 0.00455597 35.1752 -0.100514 34.1428 0.0974202C33.0907 0.281581 32.1184 0.778071 31.3523 1.52232C30.5862 2.26656 30.0618 3.22411 29.8472 4.27044C29.6489 5.17134 29.6826 6.10786 29.9453 6.99216C30.2079 7.87645 30.6909 8.67953 31.3489 9.32607C31.4382 9.41068 31.5097 9.51229 31.5592 9.62493C31.6087 9.73757 31.6351 9.85896 31.637 9.98197V9.98667C31.6387 10.1593 31.5893 10.3286 31.495 10.4733C31.4008 10.618 31.2659 10.7316 31.1073 10.7998C30.2406 11.1549 29.436 11.6456 28.7236 12.2536C30.7224 13.4387 32.2943 15.2267 33.2137 17.3608C33.981 19.1003 34.3666 20.984 34.3448 22.8851V23.3359H43.0989C43.6027 23.3359 44.086 23.1357 44.4423 22.7795C44.7986 22.4232 44.9988 21.94 44.9988 21.4361V20.2599C45.0181 18.6454 44.6936 17.0452 44.0467 15.5659V15.5659Z" fill="#FF7770"/>
        <path d="M16.2784 12.2531C15.5646 11.6436 14.758 11.152 13.8892 10.7968C13.7319 10.7291 13.598 10.6168 13.5038 10.4738C13.4097 10.3308 13.3595 10.1633 13.3595 9.99213C13.3584 9.86975 13.3825 9.74846 13.4303 9.63579C13.478 9.52311 13.5484 9.42145 13.6371 9.33712C13.691 9.2855 13.7429 9.23477 13.7817 9.19381C14.056 8.90517 14.2979 8.58748 14.5033 8.24637C15.0437 7.34469 15.3117 6.30589 15.2748 5.25531C15.2379 4.20472 14.8978 3.18727 14.2954 2.32571C13.6931 1.46416 12.8543 0.795331 11.8803 0.399937C10.9062 0.00454374 9.83859 -0.100516 8.80617 0.0974341C7.75409 0.281629 6.78176 0.77813 6.01565 1.52237C5.24954 2.2666 4.72509 3.22414 4.51051 4.27045C4.3122 5.17136 4.34597 6.10787 4.60864 6.99216C4.87131 7.87644 5.35428 8.67953 6.01225 9.32608C6.10157 9.41068 6.17308 9.51229 6.22256 9.62493C6.27203 9.73757 6.29849 9.85897 6.30036 9.98198V9.98669C6.30199 10.1593 6.25259 10.3286 6.15836 10.4733C6.06413 10.6179 5.92926 10.7316 5.77069 10.7998C3.60062 11.6988 1.87009 13.4136 0.951393 15.5753C0.305535 17.0558 -0.0184752 18.6566 0.000813283 20.2718V21.4361C0.000803486 21.6856 0.049937 21.9326 0.145408 22.1631C0.24088 22.3936 0.380819 22.6031 0.557235 22.7795C0.733651 22.9559 0.943089 23.0958 1.17359 23.1913C1.40409 23.2868 1.65114 23.3359 1.90063 23.3359H10.6555V22.8963C10.6337 20.9953 11.0185 19.1116 11.784 17.3714C12.7026 15.2326 14.2763 13.4405 16.2784 12.2531V12.2531Z" fill="#FF7770"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.2881 36.0406C24.4359 35.9899 24.5959 35.9866 24.7457 36.0312C24.8955 36.0759 25.0276 36.1662 25.1236 36.2896L30.3736 43.0396C30.476 43.1713 30.5316 43.3333 30.5316 43.5001C30.5316 43.6669 30.476 43.829 30.3736 43.9606L25.1236 50.7106C25.0276 50.8339 24.8955 50.9241 24.7458 50.9686C24.596 51.0132 24.4361 51.0099 24.2883 50.9592C24.1406 50.9085 24.0123 50.8129 23.9215 50.6858C23.8307 50.5586 23.7819 50.4063 23.7818 50.2501V47.2576C19.7476 47.3416 17.8096 48.1074 16.8361 48.9399C15.9106 49.7311 15.7411 50.6619 15.5648 51.6369L15.5191 51.8881C15.4854 52.0679 15.3872 52.2292 15.243 52.3416C15.0987 52.4541 14.9183 52.5099 14.7358 52.4987C14.5532 52.4874 14.381 52.4099 14.2517 52.2806C14.1223 52.1513 14.0446 51.9792 14.0333 51.7966C13.9043 49.7356 14.0978 46.7491 15.5536 44.2509C16.9666 41.8261 19.5106 39.9616 23.7818 39.7666V36.7501C23.7818 36.5938 23.8305 36.4415 23.9213 36.3143C24.0121 36.1871 24.1403 36.0914 24.2881 36.0406V36.0406Z" fill="#004AAD"/>
    </svg>
}
