import React, { FunctionComponent } from "react";
import { Box, SxProps } from "@mui/material";
const regBanner = require("../../assets/blank_banner_with_runners.svg");

interface BlankBannerProps {
    text: string;
    link: string;
    className?: string;
    sx?: SxProps;
}

export const BlankBanner: FunctionComponent<BlankBannerProps> = ({ 
    text,
    link,
    className,
    sx
}) => {

    return <Box 
        component="a" 
        href={link}
        sx={{ 
            position: "relative",
            display: "block",
            ...sx
        }}
        className={className} 
    >
        <Box component={"img"} src={regBanner} sx={{ width: "100%" }}/>
        <Box sx={{ 
            position: "absolute", 
            left: "27%",
            right: "3%",
            top: "50%",
            transform: "translateY(-50%)",
            fontSize: {
                xs: "1.5rem",
                sm: "3rem",
                md: "4.5rem"
            },
            textAlign: "center",
            color: "primary.contrastText"
        }}>
            { text }
        </Box>
    </Box>
}
