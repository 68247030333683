import React, { FunctionComponent } from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import cs from "classnames";
import { ClickIcon } from "./icons/ClickIcon";
import { FavoriteIcon } from "./icons/FavoriteIcon";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from "react-i18next";

interface PremiumBannerProps {
    type: BannerType;
    className?: string;
    onClick?: () => void; 
}

export enum BannerType {
    PRO = 1,
    DIPLOMA
}

export const PremiumBanner: FunctionComponent<PremiumBannerProps> = ({
    type = BannerType.PRO,
    className,
    onClick
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return <ButtonBase 
        className={cs(classes.bannerContainer, className)}
        href="/premium"
        onClick={onClick}
    >
        { type === BannerType.PRO && <ClickIcon className={classes.clickIcon}/> }
        { type === BannerType.DIPLOMA && <FavoriteIcon className={classes.favoriteIcon}/> }
        <Box className={classes.main}>
            <Typography className={classes.title} gutterBottom>
                { type === BannerType.PRO && "S10 Pro" }
                { type === BannerType.DIPLOMA && t('individualDiploma') }
            </Typography>
            <Typography className={classes.description}>
                { type === BannerType.PRO && t('premiumAds') }
                { type === BannerType.DIPLOMA && t('premiumAdditionalDiploma') }
            </Typography>
        </Box>
        <ArrowForwardIosIcon/>
    </ButtonBase>
}

const useStyles = makeStyles(muiTheme => ({
    bannerContainer: {
        background: "linear-gradient(290.77deg, #EBCC74 -37.5%, #FF7D74 62.31%)",
        borderRadius: "6px",
        display: "flex",
        justifyContent: "space-between",
        textAlign: "start",
        color: muiTheme.palette.primary.contrastText,
        position: "relative",
        padding: "0 2.5rem",
        [muiTheme.breakpoints.down("sm")]: {
            padding: "0 1rem"
        }
    },
    clickIcon: {
        position: "absolute",
        right: "4rem",
        top: "3.5rem",
        [muiTheme.breakpoints.down("sm")]: {
            right: "2.5rem",
            top: "2.5rem"
        }
    },
    favoriteIcon: {
        position: "absolute",
        right: "3.5rem",
        top: "2rem",
        [muiTheme.breakpoints.down("sm")]: {
            right: "2rem",
        }
    },
    main: {
        margin: "3rem 2rem",
        [muiTheme.breakpoints.down("sm")]: {
            margin: "2.5rem 1rem"
        }
    },
    title: {
        fontWeight: 700,
        fontSize: "1.4rem",
    },
    description: {
        fontSize: "1.1rem",
        [muiTheme.breakpoints.down("sm")]: {
            fontSize: ".9rem"
        },
    }
}))

