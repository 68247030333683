import React, { FunctionComponent } from 'react';
import { DialogContent, DialogTitle, Box } from '@mui/material';
import { CustomDialog } from '/src/ui/shared/banners/common/CustomDialog';
import { BannerHead } from '/src/ui/shared/banners/common/BannerHead';
import { useTranslation } from 'react-i18next';
const postGuideImg = require('../../../../assets/premium_post_guide.png');

interface SavingPostInstructionsDialogProps {
  open: boolean;
  onClose: () => void;
}

export const SavingPostInstructionsDialog: FunctionComponent<
  SavingPostInstructionsDialogProps
> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <CustomDialog open={open} onClose={onClose} fullWidth>
      <BannerHead onClose={onClose} />
      <DialogTitle sx={{ textAlign: 'center' }}>{t('headerValid')}</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Box
          component="img"
          src={postGuideImg}
          sx={{ width: '100%', maxWidth: '15rem' }}
        />
      </DialogContent>
    </CustomDialog>
  );
};
