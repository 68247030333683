import React, { FunctionComponent, useState, useRef, useEffect, useCallback } from "react";
import { Difficulty, TrainerEvaluation, TrainingTask, TrainingType, MessageType, Report, ReportParameters } from "/src/domain/studentTasks/StudentTasksData";
import { User } from "/src/domain/shared/User";
import { TimerType } from "/src/ui/studentTasks/studentTasks/TaskView"
import { ReportDialog } from "./ReportDialog";
import { RichTextEditor, EditorMode } from "./RichTextEditor";
import { AppCheckbox } from "/src/ui/shared/checkbox";
import { TagsField } from "/src/ui/studentTasks/studentTasks/TagsField";
import { ReportButton } from "/src/ui/studentTasks/studentTasks/sendReport/ReportButton";
import NumberFormat from 'react-number-format';
import {
    LinearProgress,
    Radio,
    FormControl,
    FormControlLabel,
    RadioGroup,
    TextField,
    Select,
    MenuItem,
    InputLabel
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { difficultyText } from "/src/domain/studentTasks/difficultyInfo";
import { useTranslation } from "react-i18next";
import { checkValidTime } from "/src/domain/shared/checkValidTime"
import { isString as _isString } from "lodash";
import { Editor as TinyMCEEditor } from 'tinymce';
import { get as _get } from "lodash";

interface SendReportProps {
    studentId: string;
    currentUser: User;
    isExpanded: boolean;
    hide: () => void;
    setReportSent: () => void;
    isTimeShowTimer: boolean;
    isTimeLimitWarning: boolean;
    timer: TimerType;
    task: TrainingTask;
    reportParameters?: ReportParameters;
    setReportParameters: (reportParameters: ReportParameters) => void;
    isReportSent?: boolean;
    sendTaskReport: (report: Report, reportParameters: ReportParameters, task: TrainingTask) => Promise<Boolean>;
    
    getTags: (studentId: string, tag?: string) => Promise<string[]>;
}


export const SendReport: FunctionComponent<SendReportProps> = ({ 
    studentId,
    currentUser,
    isExpanded, 
    hide,
    setReportSent,
    isTimeShowTimer,
    isTimeLimitWarning,
    timer,
    task,
    reportParameters,
    setReportParameters,
    isReportSent,
    sendTaskReport,
    getTags
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const editorRef = useRef<TinyMCEEditor | null>(null);
    const [difficulty, setDifficulty] = useState<string>(String(reportParameters?.difficulty || Difficulty.FINE));
    const [comment, setComment] = useState<string>(String(reportParameters?.comment || ''));
    const [placeage, setPlaceage] = useState<string>(String(reportParameters?.placeage || ''));
    const [placeabs, setPlaceabs] = useState<string>(String(reportParameters?.placeabs || ''));
    const [personalBest, setPersonalBest] = useState(Boolean(reportParameters?.personalBest || 0));
    const [time, setTime] = useState(reportParameters?.time || '');
    const [timeError, setTimeError] = useState(false);
    const [trainerEvaluation, setTrainerEvaluation] = useState<TrainerEvaluation>(reportParameters?.trainerEvaluation || TrainerEvaluation.FIVE);
    const [reportErrorMessage, setReportErrorMessage] = useState<MessageType>(null)
    const [tags, setTags] = useState(reportParameters?.tags || []);
    const [isReportProgress, setReportProgress] = useState(false);

    const handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = '';
    }, []);

    useEffect(() => {
        if (!isExpanded) {
            return window.removeEventListener('beforeunload', handleBeforeUnload);
        }
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isExpanded])

    if (!timer) return null;

    const isReportBig = task.type === TrainingType.COMPETITION;
    
    const isPremiumReport = Boolean(reportParameters?.html || currentUser.isPremium);

    const handleSubmit: (e: any) => void = async (e) => {
        e.preventDefault();
        
        const pk = new Date(task.date).getTime() / 1000 - (180 * 60);

        if (isPremiumReport && !editorRef.current?.getContent()) {
            return alert(t('reportRequirement'));
        }

        const resiltValue = isPremiumReport ? (editorRef.current?.getContent() || "") : comment;
        const isHtml = isPremiumReport ? 1 : 0;

        const report: Report = {
            pk,
            sem: Number(difficulty),
            value: resiltValue,
            html: isHtml,
            tags: JSON.stringify(tags)
        }

        const newReportParameters: ReportParameters = {
            difficulty: Number(difficulty),
            comment: resiltValue,
            html: isHtml,
            tags
        }

        if (reportParameters?.ts)
            newReportParameters.ts = reportParameters?.ts;

            
        if (isReportBig) {
            report.bigreport = 1;
            report.t = time;
            report.placeage = Number(placeage);
            report.placeabs = Number(placeabs);
            report.lr = personalBest ? 1 : 0;
            report.ot = Number(trainerEvaluation);

            newReportParameters.time = time;
            newReportParameters.placeage = Number(placeage);
            newReportParameters.placeabs = Number(placeabs);
            newReportParameters.personalBest = personalBest ? 1 : 0;
            newReportParameters.trainerEvaluation = Number(trainerEvaluation);
        }

        setReportProgress(true);
        const isCreateTask = await sendTaskReport(report, newReportParameters, task);
        setReportProgress(false);
        if (!isCreateTask) return setReportErrorMessage('serverError');
        
        setReportSent()
        setReportParameters(newReportParameters);
        hide();
    }

    const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const time = e.target.value;
        const isValidTime = checkValidTime(time);        
        setTime(time);
        setTimeError(!isValidTime);
    }
    
    const handleDialog = () => {
        hide();
        setReportErrorMessage(null);
    }

    const closeDialogHandler = () => {
        if (confirm(t('dataLossFromDialog'))) {
            handleDialog();
        }
    }
    
    return (
        <ReportDialog 
            open={isExpanded} 
            onClose={closeDialogHandler}
            isPremiumReport={isPremiumReport}
        >
            <form onSubmit={handleSubmit} className={classes.form}>
                <RadioGroup 
                    name="sem" 
                    row 
                    value={difficulty}
                    onChange={(e) => setDifficulty(e.target.value)}
                >
                    <FormControlLabel
                        value={String(Difficulty.EASУ)}
                        className={classes.controlLabel}
                        control={<Radio color={"primary"}/>}
                        label={ String(t(difficultyText[Difficulty.EASУ])) }
                    />
                    <FormControlLabel
                        value={String(Difficulty.FINE)}
                        className={classes.controlLabel}
                        control={<Radio color={"primary"}/>}
                        label={ String(t(difficultyText[Difficulty.FINE])) }
                    />  
                    <FormControlLabel
                        value={String(Difficulty.HARD_DONE)}
                        className={classes.controlLabel}
                        control={<Radio color={"primary"}/>}
                        label={ String(t(difficultyText[Difficulty.HARD_DONE])) }
                    />
                    <FormControlLabel
                        value={String(Difficulty.HARD_NOT_DONE)}
                        className={classes.controlLabel}
                        control={<Radio color={"primary"}/>}
                        label={ String(t(difficultyText[Difficulty.HARD_NOT_DONE])) }
                    />
                    <FormControlLabel
                        value={String(Difficulty.INJURY)}
                        className={classes.controlLabel}
                        control={<Radio color={"primary"}/>}
                        label={ String(t(difficultyText[Difficulty.INJURY])) }
                    />
                </RadioGroup>
                <TagsField
                    studentId={studentId}
                    tags={tags}
                    setTags={setTags}
                    getTags={getTags}
                />
                { isReportBig && <>
                    <AppCheckbox
                        name="lr"
                        label={ t('personalRecord') }
                        checked={personalBest}
                        onChange={() => setPersonalBest(!personalBest)}
                        className={classes.checkbox}
                        primary
                    />
                    <TextField
                        label={ t('placeInAgeGroup') }
                        name="placeage"
                        type="number"
                        fullWidth
                        value={placeage}
                        onChange={(e) => setPlaceage(e.target.value)}
                        className={classes.field}
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="standard"
                    />
                    <TextField
                        label={ t('placeInAbsoluteСategory') }
                        name="placeabs"
                        type="number"
                        fullWidth 
                        value={placeabs}
                        onChange={(e) => setPlaceabs(e.target.value)}
                        className={classes.field}
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="standard"
                    />
                    <NumberFormat
                        value={time} 
                        onChange={handleTimeChange}
                        mask="_" 
                        id="time"
                        label={ t('officialTime') }
                        name="t"
                        format="##:##:##"
                        fullWidth
                        required
                        className={classes.field}
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ inputMode: 'numeric' }}
                        error={timeError}
                        helperText={ timeError && t('invalidValue') }
                        customInput={TextField}

                    />
                    <FormControl variant="standard" fullWidth className={classes.field}>
                        <InputLabel id="ot-label">{ t('trainerEvaluation') }</InputLabel>
                        <Select
                            name="ot"
                            labelId="ot-label"
                            label={ t('trainerEvaluation') }
                            required
                            value={trainerEvaluation}
                            onChange={(e) => setTrainerEvaluation(Number(e.target.value))}
                        >
                                <MenuItem value={TrainerEvaluation.ONE}>{TrainerEvaluation.ONE}</MenuItem>
                                <MenuItem value={TrainerEvaluation.TWO}>{TrainerEvaluation.TWO}</MenuItem>
                                <MenuItem value={TrainerEvaluation.THREE}>{TrainerEvaluation.THREE}</MenuItem>
                                <MenuItem value={TrainerEvaluation.FOUR}>{TrainerEvaluation.FOUR}</MenuItem>
                                <MenuItem value={TrainerEvaluation.FIVE}>{TrainerEvaluation.FIVE}</MenuItem>
                        </Select>
                    </FormControl>
                </>}
                {isPremiumReport ? <>
                    <div className={classes.richEditorContainer}>
                        <RichTextEditor
                            type={EditorMode.REPORT}
                            imageSaveId={task.date}
                            editorRef={editorRef}
                            initialValue={reportParameters?.comment || ""}
                        />
                    </div>
                </>
                : <TextField
                    name="value"
                    id="standard-multiline-static"
                    label={t('comment')}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className={classes.field}
                    multiline
                    variant="standard"
                    fullWidth
                    required
                    rows={4}
                />}
                {_isString(reportErrorMessage) ?
                    <div className={classes.errorMessage}>
                        { t(reportErrorMessage) }
                    </div>
                    : <div className={classes.createBtnBlock}>
                        {isReportProgress ? <LinearProgress />
                        : <ReportButton
                            timer={timer}
                            isTimeShowTimer={isTimeShowTimer}
                            isTimeLimitWarning={isTimeLimitWarning}
                            isReportSent={isReportSent}
                            disabled={timeError}
                            type="submit"
                        />}
                    </div>
                }   
            </form>
        </ReportDialog>
    )
}

const useStyles = makeStyles(muiTheme => ({
    form: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    },
    field: {
        marginTop: "0.75rem"
    },
    richEditorContainer: {
        minHeight: "25rem",
        margin: "1rem 0",
        overflow: "auto",
        flexGrow: 1
    },
    controlLabel: {
        margin: 0,
        fontSize: "0.9rem",
        marginRight: "0.5rem"
    },
    createBtnBlock: {
        paddingTop: "0.75rem",
        minHeight: "2rem"
    },
    checkbox: {
        marginTop: "1rem",
        width: "100%"
    },
    errorMessage: {
        marginTop: "0.75rem",
        fontWeight: 500       
    }
}))