import React, {FunctionComponent} from "react";
import makeStyles from '@mui/styles/makeStyles';
import cs from "classnames";

type IconProps = {
    className?: string
}

export const UncheckedIcon: FunctionComponent<IconProps> = ({className}) => {
    const classes = useStyles();

    return (
        <svg className={cs(classes.icon, className)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 11V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H14" stroke="black"
                  strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M13 21L5 21C3.9 21 3 20.1 3 19L3 5C3 3.9 3.9 3 5 3L19 3C20.1 3 21 3.9 21 5L21 14" stroke="black"
                  strokeWidth="2" strokeMiterlimit="10"/>
        </svg>
    );
}

const useStyles = makeStyles({
    icon: {
        width: "1.5rem",
        height: "1.5rem"
    }
});