import { createTheme, Theme } from "@mui/material/styles";
import { theme } from "/src/assets/theme";

export default createTheme({
    palette: {
        primary: {
            main: theme.primaryColor,
            contrastText: theme.WHITE_COLOR,
            light: theme.lightPrimaryColor
        },
        secondary: {
            main: theme.secondaryColor,
            contrastText: theme.WHITE_COLOR,
            light: theme.lightSecondaryColor
        },
    },
});

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}