import { RatingSeasonData } from "/src/domain/challenges/ChallengeRatingData";
import axios from "axios";
import { links } from "/src/domain/shared/links";

export const getRatings = async (season: number = 0, showAll: boolean = false): Promise<RatingSeasonData | null> => {
    try {
        const response = await axios.get(`${links.challengesOrigin}/api/challenges/rating-v4`, {
            params: {
                season,
                showAll: showAll ? 1 : 0
            }
        });

        const rating: RatingSeasonData = response.data.season;
        return rating;
    } catch (e) {
        console.log("getRatings: ", e);
        return null;
    }
}