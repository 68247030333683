import React, { FunctionComponent, useState } from "react";
import { NotificationsProvider } from "/src/contexts/NotificationsContext";
import { Box } from "@mui/material";
import { NotificationButton } from "./NotificationButton";
import { ListOfNotifications } from "./ListOfNotifications";
import { useTranslation } from "react-i18next";

interface NotificationsProps {
    isDialog?: boolean;
    className?: string;
}

export const Notifications: FunctionComponent<NotificationsProps> = ({
    isDialog,
    className
}) => {
    const { t } = useTranslation();
    const [expandedNotifications, expandNotifications] = useState(false);

    return <NotificationsProvider>
        <Box className={className}>
            <NotificationButton
                expandNotifications={() => expandNotifications(!expandedNotifications)}
            />
            <ListOfNotifications
                expandedNotifications={expandedNotifications}
                expandNotifications={() => expandNotifications(!expandedNotifications)}
                isDialog={isDialog}
            />
        </Box>
    </NotificationsProvider>
}
