import React, { FunctionComponent, useState, useContext } from 'react';
import { PostContext, PostContextProps } from '/src/contexts/PostContext';
import { IconButton, Alert, Snackbar, Slide, SlideProps } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import { useTranslation } from 'react-i18next';

interface RepostPostButtonProps {
  currentUserId: string;
  postId: string;
  isRepostAvailable?: boolean;
  isRepostedPost?: boolean;
  isRepostPreview?: boolean;
}

export const RepostPostButton: FunctionComponent<RepostPostButtonProps> = ({
  currentUserId,
  postId,
  isRepostAvailable,
  isRepostedPost,
  isRepostPreview,
}) => {
  const { t } = useTranslation();
  const [isLoadingRepostButton, setIsLoadingRepostButton] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isUpdatedRepost, setIsUpdatedRepost] = useState(false);

  const { addRepostPost, repostedPostDeletedStateUpdate, checkRepostedPosts } =
    useContext(PostContext) as PostContextProps;

  const handleClickRepostButton = async () => {
    setIsLoadingRepostButton(true);
    const isUpdated = await checkRepostedPosts(postId);
    setIsUpdatedRepost(isUpdated);

    const isAdded = await addRepostPost(postId);
    if (isAdded) {
      setIsOpenAlert((v) => !v);
    } else {
      alert('serverError');
    }

    setIsLoadingRepostButton(false);
  };

  const handleClickUnrepostButton = async () => {
    if (!currentUserId || !repostedPostDeletedStateUpdate) return;

    if (!confirm(t('confirmPostDeletion'))) {
      return;
    }

    setIsLoadingRepostButton(true);
    const isDeleted = await repostedPostDeletedStateUpdate(
      currentUserId,
      postId,
      isRepostPreview
    );
    setIsLoadingRepostButton(false);

    if (!isDeleted) {
      alert(t('serverError'));
    }
  };

  return (
    <>
      {isRepostAvailable && !isRepostedPost && (
        <IconButton
          onClick={handleClickRepostButton}
          disabled={isLoadingRepostButton}
          sx={{
            stroke: 'rgba(0, 0, 0, 0.54)',
            color: 'transparent',
            strokeWidth: '0.1rem',
            transform: 'scaleX(-1)',
          }}
        >
          <ReplyIcon />
        </IconButton>
      )}
      {isRepostedPost && (
        <IconButton
          onClick={handleClickUnrepostButton}
          disabled={isLoadingRepostButton}
          sx={{
            stroke: 'transparent',
            color: 'secondary.main',
            strokeWidth: '0.1rem',
            transform: 'scaleX(-1)',
          }}
        >
          <ReplyIcon />
        </IconButton>
      )}
      <Snackbar
        open={isOpenAlert}
        sx={{
          top: {
            xs: '3rem',
          },
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        TransitionComponent={TransitionDown}
      >
        <Alert
          severity="success"
          onClose={() => setIsOpenAlert((v) => !v)}
          sx={{
            '& .MuiAlert-message': {
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          {isUpdatedRepost ? t('repostUpdated') : t('repostAdded')}
        </Alert>
      </Snackbar>
    </>
  );
};

type TransitionProps = Omit<SlideProps, 'direction'>;
const TransitionDown: FunctionComponent<TransitionProps> = (props) => {
  return <Slide {...props} direction="down" />;
};
