import React, { FunctionComponent } from "react";
import { UpcomingRaceProvider } from "/src/contexts/UpcomingRaceContext";
import { UpcomingRacesView } from "/src/ui/shared/upcomingRaces/UpcomingRacesView";

export interface UpcomingRacesContainerProps {
    startReg?: number;
    organization?: string;
    withHeader?: boolean;
    className?: string;
}

export const UpcomingRacesContainer: FunctionComponent<UpcomingRacesContainerProps> = ({ 
    startReg, 
    organization, 
    withHeader, 
    className 
}) => {
    return (
        <UpcomingRaceProvider>
            <UpcomingRacesView
                startReg={startReg}
                organization={organization}
                withHeader={withHeader}
                className={className}
            />
        </UpcomingRaceProvider>
    )
}
