import React, { FunctionComponent } from 'react';
import { Box, SxProps } from '@mui/material';
import { Difficulty } from '/src/domain/studentTasks/StudentTasksData';
import {
  difficultyText,
  difficultyColors,
} from '/src/domain/studentTasks/difficultyInfo';
import { useTranslation } from 'react-i18next';

interface ReportDifficultyProps {
  difficulty: Difficulty;
  sx?: SxProps;
}

export const ReportDifficulty: FunctionComponent<ReportDifficultyProps> = ({
  difficulty,
  sx
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        mt: '0.5rem',
        fontWeight: 500,
        color: difficultyColors[difficulty],
        ...sx
      }}
    >
      {t(difficultyText[difficulty])}
    </Box>
  );
};
