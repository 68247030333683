import React, { FunctionComponent } from 'react';
import { PostData } from '/src/domain/studentTasks/StudentTasksData';
import {
  Box,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  SxProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomDialog } from '../common/CustomDialog';
import { PostContentView } from '/src/ui/studentTasks/studentTasks/posts/PostContentView';

export interface PostBannerTemplateProps {
  open: boolean;
  onClose: () => void;
  link1: LinkType;
  link2: LinkType;
  post: PostData;
  addDomainToImageSrc?: boolean;
}

type LinkType = {
  text: string;
  href: string;
};

export const PostBannerTemplate: FunctionComponent<PostBannerTemplateProps> = ({
  open,
  onClose,
  link1,
  link2,
  post,
  addDomainToImageSrc,
}) => {
  return (
    <CustomDialog open={open} onClose={onClose}>
      <Box
        sx={{
          m: '1.5rem 1.5rem 0',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ fontSize: '2rem', opacity: 0.6 }} />
        </IconButton>
      </Box>
      <DialogContent sx={{ pt: 0 }}>
        <PostContentView
          sx={{ mt: 0 }}
          {...post}
          addDomainToImageSrc={addDomainToImageSrc}
        />
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'space-between', p: '0.5rem 1.5rem 1.75rem' }}
      >
        <Button size="large" sx={buttonSx} variant="outlined" href={link1.href}>
          {link1.text}
        </Button>
        <Button
          size="large"
          sx={buttonSx}
          variant="contained"
          href={link2.href}
        >
          {link2.text}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

const buttonSx: SxProps = {
  width: '47%',
  textTransform: 'none',
  textAlign: 'center',
};
