import React, {FunctionComponent, useContext, useState} from "react";
import {PageConfig, PageData} from "/src/domain/shared/Page";
import {User} from "/src/domain/shared/User";

export type updatePageData<T> = (pageData: T) => void

export type updateCurrentUser<T> = (currentUser: T) => void

export type PageContextProps = {
    pageData: PageData | null,
    currentUser?: User,
    pageConfig: PageConfig | null,
    setInitialPageData: (pageData: PageData, pageConfig: PageConfig, user?: User) => void,
    getPageData: () => PageData | null,
    getPageConfig: () => PageConfig | null,
    setPageData: updatePageData<PageData>,
    setCurrentUser: updateCurrentUser<User>
}

export type PageProviderProps = {
    initialPageData: PageData,
    initialCurrentUser?: User,
    initialPageConfig: PageConfig,
}

export const PageProvider: FunctionComponent<PageProviderProps> = ({
                                                                       children,
                                                                       initialCurrentUser,
                                                                       initialPageConfig,
                                                                       initialPageData
                                                                   }) => {
    const [pageData, setPageData] = useState<PageData | null>(initialPageData);
    const [currentUser, setCurrentUser] = useState<User | undefined>(initialCurrentUser);
    const [pageConfig, setPageConfig] = useState<PageConfig | null>(initialPageConfig);

    const setInitialPageData = (pageData: PageData, pageConfig: PageConfig, user?: User) => {
        setPageData(pageData);
        setPageConfig(pageConfig);
        if (user)
            setCurrentUser(user);
    };

    const getPageData = (): PageData | null => {
        return pageData;
    };

    const getPageConfig = (): PageConfig | null => {
        return pageConfig;
    };

    return <PageContext.Provider
        value={{
            pageData,
            currentUser,
            pageConfig,
            setInitialPageData,
            getPageData,
            getPageConfig,
            setPageData,
            setCurrentUser,
        }}>{children}</PageContext.Provider>;
};

export const PageContext = React.createContext<PageContextProps | undefined>(undefined);

export function usePageContext(): PageContextProps {
    return useContext(PageContext) as PageContextProps;
}
