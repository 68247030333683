import { TrainingTask, PostData, PostProps } from "/src/domain/studentTasks/StudentTasksData";
import { User } from "/src/domain/shared/User";
import { TrainerBaseInfo } from "/src/domain/shared/TrainerBaseInfo";
import { StudentInfo } from "/src/domain/charts/ChartsData";

export interface DashboardData {
    studentInfo?: StudentInfo;
    trainerInfo?: TrainerBaseInfo;
    trainers?: TrainerInfo[];
    tasks?: TrainingTask[];
    feed?: TrainingTask[];
}

export type FeedData = (TrainingTask | PostProps)[];

export { TrainerBaseInfo }

export type TrainerInfo =  Pick<TrainerBaseInfo, 
    "id" | "firstName" | "lastName" | 
    "avatarUrl" | "avgRating" | "isRatingHidden" | "isFinishedS10School"
>

export { StudentInfo };

export interface OldTaskData {
    0: TrainingTask;
    selecttyp: number;
    oninterval: OldTaskInterval
}

export enum OldTaskInterval {
    YEAR1 = "-1 year",
    MONTH6 = "-6 month",
    MONTH3 = "-3 month",
    MONTH1 = "-1 month",
    WEEK2 = "-2 week"
}

export enum UserRoleOnPage {
    STUDENT_OWN_PAGE,
    READONLY_STUDENT_PAGE,

    TRAINER_OWN_PAGE,
    READONLY_TRAINER_PAGE,

    UNREGISTERED
}

interface ArgsGetRoleOnPage {
    currentUser?: User;
    studentInfo?: StudentInfo;
    trainerInfo?: TrainerBaseInfo;
}

export const getRoleOnPage = ({
    currentUser,
    studentInfo,
    trainerInfo
}: ArgsGetRoleOnPage): UserRoleOnPage => {
    if (studentInfo && currentUser?.id == studentInfo?.id) {
        return UserRoleOnPage.STUDENT_OWN_PAGE;
    } else if (studentInfo && currentUser?.id != studentInfo?.id) {
        return UserRoleOnPage.READONLY_STUDENT_PAGE;
    }

    if (trainerInfo && currentUser?.id == trainerInfo?.id) {
        return UserRoleOnPage.TRAINER_OWN_PAGE;
    } else if (trainerInfo && currentUser?.id != trainerInfo?.id) {
        return UserRoleOnPage.READONLY_TRAINER_PAGE;
    }

    return UserRoleOnPage.UNREGISTERED;;
}