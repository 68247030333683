import React, { FunctionComponent, useEffect, useState, useCallback } from "react";
import { Autocomplete, TextField, IconButton, SxProps } from "@mui/material";
import { StudentBaseInfo } from "/src/domain/shared/StudentBaseInfo";
import { useTranslation } from "react-i18next";
import { userSearch } from "/src/domain/shared/requests/userSearch";
import { trimName } from "/src/domain/shared/trimName";
import { links } from "/src/domain/shared/links";
import { debounce as _debounce, isObject as _isObject, filter as _filter } from "lodash";

const INTERVAL_BETWEEM_REQUESTS = 500;

interface UserSearchProps {
    sx?: SxProps,
    className?: string;
}

export const UserSearch: FunctionComponent<UserSearchProps> = ({
    sx,
    className
}) => {
    const { t } = useTranslation();
    const [users, setUsers] = useState<StudentBaseInfo[]>([]);
    const [user, setUser] = useState<StudentBaseInfo | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const debounceSearch = useCallback(_debounce(
        (name?: string) => setUsersState(name),

        INTERVAL_BETWEEM_REQUESTS
    ), [])

    const handleAutocompleteChange = (event: any, newValue: StudentBaseInfo | null) => {
        setUser(newValue || null);
        
        if (newValue) {
            window.open(links.origin + `/profile?${newValue.id}`);
        }
    }

    const handleAutocompleteInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        debounceSearch(e.target.value);    
    }

    const setUsersState = async (name?: string) => {
        setLoading(true);

        const users = await userSearch(name);
        const clearedUsers = _filter(users, u => _isObject(u));
        setUsers(clearedUsers);

        setLoading(false);
    }

    useEffect(() => {
        setUsersState();
    }, [])

    return <Autocomplete
        sx={ sx }
        className={className}
        size="small"
        loading={loading}
        loadingText={ t('loading') }
        options={users}
        onChange={handleAutocompleteChange}
        getOptionLabel={getOptionLabel}
        noOptionsText={ t('noResults') }
        renderInput={(params) => <TextField
            {...params}
            label={ t('findFriends') }
            onChange={handleAutocompleteInputChange}
        />}
    />
}

const getOptionLabel = (user: StudentBaseInfo) => {
    return `${trimName(user.firstName, user.lastName)} (id${user.id})`
}