import React, {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
  useState,
  useMemo,
} from 'react';
import { CurrentAudioPostData } from '/src/domain/post/PostData';
import { PostContext, PostContextProps } from '/src/contexts/PostContext';
import {
  Collapse,
  Box,
  IconButton,
  FormControl,
  Select,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
} from '@mui/material';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import { PremiumAdBanner } from '/src/ui/shared/banners/PremiumAdBanner';
import { theme as themeColor } from '/src/assets/theme';
import { useTranslation } from 'react-i18next';
import { isNumber as _isNumber } from 'lodash';
import dayjs from 'dayjs';
import { links } from '/src/domain/shared/links';
import Cookies from 'js-cookie';

const LISTEN_INTERVAL = 500;
const MARK_SAVING_INTERVAL = 5000;
let intervalCount = 0;

const MIN_AUDIO_TRACKS_FOR_SKIP_CONTROLS = 2;

const ADVERTISING_START_TIME_SEC = 600;

interface PostAudioPlayerProps {
  isPlaylistMode?: boolean;
}

const IS_PLAYER_EXPANDED = 'isPlayerExpanded';
const PLAYBACK_RATE = 'playbackRate';

const saveToLocalStorage = (key: string, value: boolean | string) => {
  if (value === true) {
    localStorage.setItem(key, 'true');
  } else if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

const getFromLocalStorage = (key: string) => {
  const storedValue = localStorage.getItem(key);
  return storedValue;
};

const getExpanded = () => {
  return !!getFromLocalStorage(IS_PLAYER_EXPANDED) || false;
};

const getPlaybackRate = () => {
  const playbackRate = Number(getFromLocalStorage(PLAYBACK_RATE));
  if (playbackRate) {
    return playbackRate;
  }
  return 1;
};

const checkCookie = (key: string) => {
  return Cookies.get(key) === '1';
};

export const PostAudioPlayer: FunctionComponent<PostAudioPlayerProps> = ({
  isPlaylistMode,
}) => {
  const { t } = useTranslation();
  const playerRef = useRef<AudioPlayer>(null);
  const [isExpanded, setExpanded] = useState(() => getExpanded());
  const [playbackRate, setPlaybackRate] = useState(() => getPlaybackRate());

  const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
  const [isPayed, setIsPayed] = useState(true);
  const [isTrainer, setIsTrainer] = useState(true);

  const {
    currentAudioPost,
    setCurrentAudioPostState,
    saveAudioMark,
    setPlaylistState,
    playlist,
    isParticipantInCommercialChallenge,
  } = useContext(PostContext) as PostContextProps;

  const handlePlaybackRateChange = (value: number) => {
    const audio = playerRef.current?.audio.current;
    if (audio) {
      audio.playbackRate = value;
      setPlaybackRate(value);
      saveToLocalStorage(PLAYBACK_RATE, String(value));
    }
  };

  useEffect(() => {
    handlePlaybackRateChange(playbackRate);
  }, [currentAudioPost]);

  useEffect(() => {
    setCurrentAudioPostState();
    setPlaylistState();
    setIsPayed(checkCookie('ispayed'));
    setIsTrainer(checkCookie('istrainer'));
  }, []);

  useLayoutEffect(() => {
    if (currentAudioPost?.isBugged) {
      playerRef.current?.audio.current?.play();
    } else {
      playerRef.current?.audio.current?.pause();
    }
  }, [currentAudioPost]);

  const handlePlayClick = (isBugged: boolean) => {
    if (!currentAudioPost || currentAudioPost.isBugged === isBugged) {
      return;
    }

    const newCurrentAudioPodcast: CurrentAudioPostData = {
      ...currentAudioPost,
      isBugged,
    };

    setCurrentAudioPostState(newCurrentAudioPodcast);
  };

  const saveMark = (isFull: boolean) => {
    if (
      !currentAudioPost ||
      !playerRef.current?.audio.current ||
      playerRef.current?.audio.current?.paused
    )
      return;

    const currentTime = isFull
      ? 0
      : playerRef.current.audio.current.currentTime;

    saveAudioMark(currentAudioPost.postid, currentTime, isFull);
  };

  const updatePostProgressBar = () => {
    const postProgressBar =
      currentAudioPost?.postProgressBar?.curtainRef.current;
    const player = playerRef.current?.audio.current;
    if (postProgressBar && player) {
      postProgressBar.style.left = `${
        (player.currentTime / player.duration) * 100
      }%`;
    }
  };

  const advertisePremium = () => {
    if (isPayed || isTrainer || isParticipantInCommercialChallenge) {
      return;
    }

    const player = playerRef.current?.audio.current;
    if (
      !player?.currentTime ||
      player.currentTime <= ADVERTISING_START_TIME_SEC
    ) {
      return;
    }

    player.currentTime = 0;
    player.pause();

    const postProgressBar =
      currentAudioPost?.postProgressBar?.curtainRef.current;
    if (postProgressBar) {
      postProgressBar.style.left = '0';
    }

    setOpenPremiumDialog(true);
  };

  const handleListenIntervalTrigger = () => {
    intervalCount += LISTEN_INTERVAL;
    updatePostProgressBar();
    if (intervalCount % MARK_SAVING_INTERVAL === 0) {
      saveMark(false);
    }
    advertisePremium();
  };

  const handlePlaybackEnd = () => {
    saveMark(true);
    isPlaylistMode && handleNextOrPrevClick(true);
  };

  const handleNextOrPrevClick = (next: boolean) => {
    if (!playlist || !currentAudioPost) {
      return;
    }

    const currentIndex = playlist.ids.indexOf(currentAudioPost.postid);

    if (currentIndex === -1) {
      return;
    }

    let newIndex = currentIndex + (next ? 1 : -1);
    if (newIndex < 0) {
      newIndex = playlist.ids.length - 1;
    } else if (newIndex >= playlist.ids.length) {
      newIndex = 0;
    }

    const newCurrentPost = playlist.posts[playlist.ids[newIndex]];
    if (!newCurrentPost?.audiosec || !newCurrentPost?.audiosrc) {
      return;
    }

    const newCurrentAudioPost: CurrentAudioPostData = {
      ...newCurrentPost,
      audiosec: newCurrentPost.audiosec,
      audiosrc: newCurrentPost.audiosrc,
      audiomarksec: 0,
      isBugged: true,
      postProgressBar: currentAudioPost.postProgressBar,
    };

    setCurrentAudioPostState(newCurrentAudioPost);
  };

  const src = useMemo(() => {
    if (!currentAudioPost) {
      return undefined;
    }

    return (
      `${links.origin}${currentAudioPost?.audiosrc}` +
      getSourceEnding(currentAudioPost?.audiomarksec)
    );
  }, [currentAudioPost?.audiosrc, currentAudioPost?.audiosec]);

  if (!src) return null;

  const handleExpandedButtonToggle = (value: boolean) => {
    setExpanded(value);
    saveToLocalStorage(IS_PLAYER_EXPANDED, value);
  };

  const showSkipControls =
    playlist?.ids &&
    playlist?.ids.length >= MIN_AUDIO_TRACKS_FOR_SKIP_CONTROLS &&
    isPlaylistMode;

  return (
    <>
      {Boolean(currentAudioPost) && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            height: isExpanded ? 'auto' : 0,
            zIndex: 999,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: '0.4rem',
              bottom: {
                xs: 0,
                sm: '0.5rem',
              },
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
          >
            {!isPlaylistMode && isExpanded && (
              <IconButton
                title={t('playlist')}
                LinkComponent={'a'}
                href="/playlist"
                size="small"
              >
                <QueueMusicIcon />
              </IconButton>
            )}
            <IconButton
              onClick={() => handleExpandedButtonToggle(!isExpanded)}
              size="small"
              title={isExpanded ? t('hide') : t('show')}
              sx={
                isExpanded
                  ? undefined
                  : { 
                      transform: 'scale(1.5)', 
                      mr: '0.5rem', 
                      mb: '0.5rem', 
                      background: themeColor.greyColor,
                      opacity: 0.8,
                      '&:hover': {
                        background: themeColor.greyColor,
                        opacity: 0.7,
                      }
                  }
              }
            >
              {isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <MusicNoteIcon
                  color={currentAudioPost?.isBugged ? 'primary' : 'inherit'}
                />
              )}
            </IconButton>
          </Box>
          <Collapse in={isExpanded}>
            <Box
              preload="metadata"
              component={AudioPlayer}
              autoPlayAfterSrcChange={false}
              src={src}
              onPlay={() => handlePlayClick(true)}
              onPause={() => handlePlayClick(false)}
              ref={playerRef}
              showSkipControls={showSkipControls}
              customVolumeControls={[]}
              onClickNext={() => handleNextOrPrevClick(true)}
              onClickPrevious={() => handleNextOrPrevClick(false)}
              onEnded={handlePlaybackEnd}
              onListen={handleListenIntervalTrigger}
              listenInterval={LISTEN_INTERVAL}
              footer={
                <PlayerFooter
                  playbackRate={playbackRate}
                  handlePlaybackRateChange={handlePlaybackRateChange}
                />
              }
            />
          </Collapse>
        </Box>
      )}
      <PremiumAdBanner
        open={openPremiumDialog}
        onClose={() => setOpenPremiumDialog(false)}
      />
    </>
  );
};

interface PlayerFooterProps {
  playbackRate: number;
  handlePlaybackRateChange: (value: number) => void;
}

const PlayerFooter: FunctionComponent<PlayerFooterProps> = ({
  playbackRate,
  handlePlaybackRateChange,
}) => {
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    const playbackRate = Number(event.target.value);
    playbackRate && handlePlaybackRateChange(playbackRate);
  };
  return (
    <Box>
      <FormControl sx={{ minWidth: '7rem' }} size="small">
        <InputLabel
          id="speed-select"
          sx={{
            '&.Mui-focused': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
          }}
        >
          {t('speed')}
        </InputLabel>
        <Select
          labelId="speed-select"
          value={String(playbackRate)}
          label={t('speed')}
          onChange={handleChange}
          sx={{
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                borderColor: 'rgba(0, 0, 0, 0.23)',
                borderWidth: '1px',
              },
          }}
        >
          <MenuItem value={0.5}>0.5</MenuItem>
          <MenuItem value={0.75}>0.75</MenuItem>
          <MenuItem value={1}>1.0</MenuItem>
          <MenuItem value={1.25}>1.25</MenuItem>
          <MenuItem value={1.5}>1.5</MenuItem>
          <MenuItem value={1.75}>1.75</MenuItem>
          <MenuItem value={2}>2.0</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const getSourceEnding = (audiomarksec?: number) => {
  const sec = audiomarksec ? `#t=${audiomarksec}` : '';

  return `?${dayjs().unix()}${sec}`;
};
