import React, { FunctionComponent, useContext, useEffect } from 'react';
import {
  RatingContext,
  RatingContextProps,
} from '/src/contexts/RatingContext';
import {
  UserProvider,
  UserContext,
  UserContextProps,
} from '/src/contexts/shared/UserContext';
import { RatingSeasonData } from '/src/domain/challenges/ChallengeRatingData';
import { Box } from '@mui/material';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Button,
  IconButton,
  SxProps,
} from '@mui/material';
import { CustomDialog } from '../common/CustomDialog';
import { Avatar, AvatarSize } from '/src/ui/shared/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { links } from '/src/domain/shared/links';
import { isObject as _isObject } from 'lodash';

export interface ChooseCoachBannerProps {
  open: boolean;
  onClose: () => void;
}

export const ChooseCoachBanner: FunctionComponent<ChooseCoachBannerProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { requestRatingSeasonData, ratingSeasonData } = useContext(
    RatingContext,
  ) as RatingContextProps;

  useEffect(() => {
    requestRatingSeasonData(0);
  }, []);

  const rating = _isObject(ratingSeasonData) ? ratingSeasonData : null;

  if (!rating) return null;

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          pb: '0.5rem',
        }}
      >
        <Box sx={{ m: '0 3rem' }}>{t('chooseTrainer')}</Box>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '1.5rem',
          }}
        >
          <CloseIcon sx={{ fontSize: '2rem', opacity: 0.6 }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center' }}>
          {t('chooseTrainerAdDescription')}
        </DialogContentText>
        <AvatarsView rating={rating} />
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          p: '2.25rem 1.5rem 1.75rem',
        }}
      >
        <Button
          size="large"
          sx={{
            textTransform: 'none',
          }}
          variant="contained"
          fullWidth
          href={`${links.origin}/trainerlist`}
        >
          {t('choose')}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

interface AvatarsViewProps {
  rating: RatingSeasonData;
}

const AvatarsView: FunctionComponent<AvatarsViewProps> = ({ rating }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        m: '2.5rem 1rem 0.5rem',
      }}
    >
      <UserProvider userId={rating.ratingTrainers[1]?.id || null}>
        <AvatarItem
          sx={{
            transform: 'translateX(0.75rem)',
          }}
        />
      </UserProvider>
      <UserProvider userId={rating.ratingTrainers[0]?.id || null}>
        <AvatarItem
          sx={{
            transform: 'scale(1.2) translateY(-0.4rem)',
            zIndex: 1,
            border: '1.5px solid white',
          }}
        />
      </UserProvider>
      <UserProvider userId={rating.ratingTrainers[2]?.id || null}>
        <AvatarItem
          sx={{
            transform: 'translateX(-0.75rem)',
          }}
        />
      </UserProvider>
    </Box>
  );
};

interface AvatarItemProps {
  sx?: SxProps;
}

const AvatarItem: FunctionComponent<AvatarItemProps> = ({ sx }) => {
  const { user } = useContext(UserContext) as UserContextProps;
  return (
    <Avatar
      size={AvatarSize.MEDIUM}
      avatarUrl={user?.avatarUrl || ''}
      sx={sx}
    />
  );
};

