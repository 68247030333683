import React, { FunctionComponent } from "react";
import { Box, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";

interface HtmlStyleContainerProps {
    sx?: SxProps;
    className?: string;
}

export const htmlFontSize = {
    h1: "1.17em",
    h2: "1em"
}

export const htmlStyles: SxProps = {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    "& img": {
        maxWidth: "100%",
        height: "auto"
    },
    "& h1": {
        fontSize: htmlFontSize.h1,
    },
    "& h2": {
        fontSize: htmlFontSize.h2,
    }
}

export const HtmlStyleContainer: FunctionComponent<HtmlStyleContainerProps> = ({
    sx,
    className,
    children
}) => {
    const { t } = useTranslation();

    return <Box 
        className={className}
        sx={{
            ...htmlStyles,
            ...sx
        }}
    >
        { children }
    </Box>
}

