import axios from "axios";
import { links } from "/src/domain/shared/links";

export const getUserPrivacy = async (userId: string): Promise<boolean> => {
    try {
        const response = await axios.get(`${links.origin}/apiweb/social.php?action=openprofile&id=${userId}`);
    
        return !response.data.open;
    } catch (e) {
        console.error("getUserPrivacy:",e);
        return true;
    }
}