import { ChallengeFormula } from '/src/domain/challenges/ChallengeProtocolData';
import { sec2hms } from '/src/utils/time-utils';
import * as Humanize from 'humanize-plus';

export const getValueLabel = (challengeType: ChallengeFormula): string => {
  switch (challengeType) {
    case ChallengeFormula.TEAM_SIMPLE:
    case ChallengeFormula.TEAM_RELAY:
      return 'time';
    case ChallengeFormula.TEAM_MAX_DISTANCE:
      return 'distance_km';
    case ChallengeFormula.TEAM_STEPPER:
      return 'stepsLabel';
    case ChallengeFormula.BANANA:
      return 'bananaResult';
    case ChallengeFormula.TEAM_HOUR_RUN:
      return 'distanceHour';
  }
  return '';
};

export const formatRecordResult = (
  challengeType: ChallengeFormula,
  result: any,
): string => {
  switch (challengeType) {
    case ChallengeFormula.TEAM_SIMPLE:
    case ChallengeFormula.TEAM_RELAY:
    case ChallengeFormula.TEAM_NOSTRA_RUN:
      return sec2hms(result);
    case ChallengeFormula.TEAM_MAX_DISTANCE:
    case ChallengeFormula.TEAM_HOUR_RUN:
      return result;
    case ChallengeFormula.BANANA:
      return String(result).substr(0, 8);
    case ChallengeFormula.TEAM_STEPPER:
    case ChallengeFormula.STEPPER:
      return Humanize.compactInteger(result, 1);
  }
  return result;
};

export const formatRecordResultExtended = (
  challengeType: ChallengeFormula,
  result: any,
): string => {
  switch (challengeType) {
    case ChallengeFormula.TEAM_STEPPER:
    case ChallengeFormula.STEPPER:
      return Humanize.formatNumber(result);
  }
  return formatRecordResult(challengeType, result);
};
