import React, { FunctionComponent, useState } from "react";
import { Track } from "/src/domain/studentTasks/StudentTasksData";
import makeStyles from '@mui/styles/makeStyles';
import { theme } from "/src/assets/theme";
import { useTranslation } from "react-i18next"
import { isNumber as _isNumber } from "lodash";

interface KmForecastProps {
    readOnly: boolean;
    forecast?: number;
    tracks?: Track[];
}

export const KmForecast: FunctionComponent<KmForecastProps> = ({ 
    readOnly,
    forecast, 
    tracks 
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [forecastState, setForecast] = useState(forecast)

    const isActive = !tracks?.length && !readOnly;

    if (!isActive && !forecast) return null

    const forecastString = !tracks?.length ? `${ t('forecast') }: ${forecast} ${ t('km') }` 
        :  `${forecast} ${ t('km') } ${ t('forecast') }`;

    return (
        <div className={classes.forecastContainer}>
            { forecastString }
        </div>
    );
}

const useStyles = makeStyles({
    forecastContainer: {
        display: "inline-block",
        minWidth: "10rem",
        padding: "0.25rem 0.5rem",
        backgroundColor: theme.primarySurfaceColor,
        borderRadius: "5px"
    }
})
