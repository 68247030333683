import React, { FunctionComponent, useContext } from "react";
import { DashboardContext, DashboardContextProps } from "/src/contexts/DashboardContext"
import { User } from "/src/domain/shared/User";
import { TrainingTask } from "/src/domain/studentTasks/StudentTasksData";
import { StudentInfo } from "/src/domain/dashboard/DashboardData";
import { TaskView } from "/src/ui/studentTasks/studentTasks/TaskView";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TasksProps {
    studentInfo?: StudentInfo,
    tasks: TrainingTask[];
    trainerId?: string;
    currentUser?: User;
    className?: string;
    isReadOnly: boolean;
    isFeed?: boolean;
}

export const Tasks: FunctionComponent<TasksProps> = ({
    studentInfo, 
    tasks,
    trainerId,
    currentUser,
    className,
    isReadOnly,
}) => {
    const { t } = useTranslation();

    const { 
        sendTaskReport, 
        currentDate,
        getTags,
    } = useContext(DashboardContext) as DashboardContextProps;

    return <Box className={className}>
        {tasks.map((task) => {
            return <TaskView
                    key={task.id}
                    task={task}
                    studentInfo={studentInfo}
                    readOnly={isReadOnly}
                    isTribunalUser={false}
                    currentDate={currentDate}
                    trainerId={trainerId}
                    currentUser={currentUser}
                    sendTaskReport={sendTaskReport}
                    getTags={getTags}
                />
        })}
    </Box>
}

