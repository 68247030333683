import React, { FunctionComponent } from "react";
import cs from "classnames";
import { makeStyles } from "@mui/styles";

type TelegramIconProps = {
    className?: string,
};

export const TelegramIcon: FunctionComponent<TelegramIconProps> = ({className}) => {
    const classes = useStyles();

    return (
        <svg className={cs(classes.icon, className)} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.3 10.9992C21.3 16.6878 16.6885 21.2992 11 21.2992C5.31142 21.2992 0.699951 16.6878 0.699951 10.9992C0.699951 5.31069 5.31142 0.699219 11 0.699219C16.6885 0.699219 21.3 5.31069 21.3 10.9992Z"
                stroke="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.3536 13.5803C10.9377 13.9854 10.5254 14.3838 10.1169 14.7853C9.97409 14.926 9.81353 15.0037 9.60857 14.9929C9.46865 14.9855 9.39041 14.9241 9.34745 14.7913C9.03449 13.8201 8.71697 12.8512 8.40569 11.8789C8.37545 11.7846 8.33081 11.7388 8.23529 11.7093C7.49801 11.4841 6.76217 11.2537 6.02753 11.0202C5.91449 10.9842 5.80049 10.9372 5.70281 10.8707C5.55185 10.7685 5.52977 10.6017 5.66513 10.4817C5.79017 10.371 5.93945 10.2745 6.09449 10.2131C6.99569 9.85597 7.90169 9.51037 8.80625 9.16165C11.1019 8.27533 13.3975 7.38973 15.6933 6.50341C16.1299 6.33469 16.4654 6.57229 16.42 7.04437C16.391 7.34629 16.3127 7.64341 16.2496 7.94149C15.7511 10.2952 15.2522 12.6491 14.7511 15.0023C14.632 15.561 14.2396 15.7069 13.7798 15.3678C13.0096 14.8002 12.2402 14.2307 11.4705 13.6621C11.4338 13.6355 11.3956 13.6098 11.3536 13.5803ZM9.62489 14.295C9.63353 14.2926 9.64217 14.2902 9.65081 14.2881C9.65561 14.266 9.66257 14.2444 9.66449 14.2225C9.71393 13.689 9.76625 13.1557 9.80921 12.6213C9.81881 12.5022 9.85961 12.4177 9.94913 12.3376C10.6591 11.7028 11.3647 11.0639 12.0717 10.4265C12.8543 9.72061 13.6372 9.01549 14.417 8.30677C14.4652 8.26309 14.4854 8.18869 14.5185 8.12821C14.4451 8.12029 14.3663 8.09077 14.2996 8.10805C14.2087 8.13157 14.1225 8.18437 14.0414 8.23549C12.3141 9.32341 10.5883 10.4133 8.85977 11.499C8.76209 11.5605 8.74385 11.6125 8.78105 11.7198C8.90849 12.0858 9.02441 12.4552 9.14465 12.8238C9.30473 13.3144 9.46481 13.8047 9.62489 14.295Z"
                  fill="white"/>
        </svg>
    );
}

const useStyles = makeStyles({
    icon: {
        width: "1.5rem",
        height: "1.5rem",
    }
});