import React, { FunctionComponent } from "react";
import { TrainingType } from "/src/domain/studentTasks/StudentTasksData";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next"
import { trainingText, trainingColors } from "/src/domain/studentTasks/trainingInfo";

interface TrainingTypeProps {
    type: TrainingType;
}

export const TrainingTypeView: FunctionComponent<TrainingTypeProps> = ({ type }) => {
    const { t } = useTranslation()
    const classes = useStyles({type})

    if (!type) return null;
    return (
        <div className={classes.taskType}>
            { t(trainingText[type])}
        </div>
    )
}

interface TrainingTypeProps {
    type: TrainingType;
}

const useStyles = makeStyles({
    taskType: (props: TrainingTypeProps) => ({
        fontSize: "1.1rem",
        fontWeight: 500,
        marginTop: "0.5rem",
        position: "relative",
        textAlign: "end",
        color: trainingColors[props.type],
        "&::before": {
            content: "''",
            display: "inline-block",
            position: "relative",
            left: "-1rem",
            bottom: "0.15rem",
            height: "0.5rem",
            width: "0.5rem",
            borderRadius: "50%",
            backgroundColor: trainingColors[props.type]
        }
    })
})
