import React, { FunctionComponent, useState } from 'react';
import { ParticipantRating, RatingSeasonData } from '/src/domain/challenges/ChallengeRatingData';
import { getRatings } from "/src/domain/shared/requests/getRatings";
import { get as _get } from 'lodash';

export interface RatingContextProps {
    ratingDataAll: ParticipantRating[] | RatingResponseData;
    requestRatingDataAll: (id: number) => void;
    ratingSeasonData: RatingSeasonData | RatingResponseData;
    requestRatingSeasonData: (id?: number, showAll?: boolean) => void;
    participantsProgress: boolean;
    seasonProgress: boolean;
}

type RatingResponseData = null | undefined | string

export const RatingContext = React.createContext<RatingContextProps | undefined>(undefined)

export const RatingProvider: FunctionComponent = ({ children }) => {
    const [ ratingDataAll, setRatingDataAll ] = useState<ParticipantRating[] | RatingResponseData>(null)
    const [ ratingSeasonData, setRatingSeasonData ] = useState<RatingSeasonData | RatingResponseData>(null)
    const [ participantsProgress, setParticipantsProgress ] = useState(false)
    const [ seasonProgress, setSeasonProgress ] = useState(false)

    
    const requestRatingDataAll: (id: number) => void = async (id) => {
        setParticipantsProgress(true)
        const rating = await getRatings(id, true);

        if (rating?.ratingParticipants) {
            setRatingDataAll(rating?.ratingParticipants);
        } else {
            setRatingDataAll('serverError');
        }

        setParticipantsProgress(false)
    }


    const requestRatingSeasonData: (id?: number, showAll?: boolean) => void = async (id, showAll = false) => {
        setSeasonProgress(true)
            const rating = await getRatings(id, showAll);
            
            setRatingDataAll(null);

            if (rating) {
                setRatingSeasonData(rating);
            } else {
                setRatingSeasonData('serverError');
            }
        setSeasonProgress(false)
        
    }

    return (
        <RatingContext.Provider
            value={{
                ratingDataAll,
                requestRatingDataAll,
                ratingSeasonData,
                requestRatingSeasonData,
                participantsProgress,
                seasonProgress
            }}>{children}</RatingContext.Provider>
    )
}


// showAll testData

// const showAll: Participant[] = [
//     {
//         id: '324234231',
//         firstName: "Виктор",
//         lastName: "Вершинин",
//         place: 1,
//         rating: 2519,
//         movement: Movement.UP
//     },
//     {
//         id: '324234232',
//         firstName: "Виктор",
//         lastName: "Вершинин",
//         place: 1,
//         rating: 2519,
//         movement: Movement.UP
//     },
//     {
//         id: '324234233',
//         firstName: "Виктор",
//         lastName: "Вершинин",
//         place: 1,
//         rating: 2519,
//         movement: Movement.DOWN
//     },
//     {
//         id: '324234234',
//         firstName: "Виктор",
//         lastName: "Вершинин",
//         place: 1,
//         rating: 2519,
//         movement: Movement.IN_PLACE
//     },
//     {
//         id: '324234235',
//         firstName: "Виктор",
//         lastName: "Вершинин",
//         place: 1,
//         rating: 2519,
//         movement: Movement.UP
//     },
//     {
//         id: '324234236',
//         firstName: "Виктор",
//         lastName: "Вершинин",
//         place: 1,
//         rating: 2519,
//         movement: Movement.UP
//     },
//     {
//         id: '324234237',
//         firstName: "Виктор",
//         lastName: "Вершинин",
//         place: 1,
//         rating: 2519,
//         movement: Movement.UP
//     },
//     {
//         id: '324234238',
//         firstName: "Виктор",
//         lastName: "Вершинин",
//         place: 1,
//         rating: 2519,
//         movement: Movement.DOWN
//     },
//     {
//         id: '324234239',
//         firstName: "Виктор",
//         lastName: "Вершинин",
//         place: 1,
//         rating: 2519,
//         movement: Movement.IN_PLACE
//     },
//     {
//         id: '324234241',
//         firstName: "Виктор",
//         lastName: "Вершинин",
//         place: 1,
//         rating: 2519,
//         movement: Movement.UP
//     }
// ]


// season testData

// const seasonData: RatingSeasonData = {
//     ratingParticipants: [
//         {
//             id: '324234231',
//             firstName: "Виктор",
//             lastName: "Вершинин",
//             place: 5,
//             rating: 2519,
//             movement: Movement.UP
//         },
//         {
//             id: '324234232',
//             firstName: "Виктор",
//             lastName: "Вершинин",
//             place: 1,
//             rating: 2519,
//             movement: Movement.UP
//         },
//         {
//             id: '324234233',
//             firstName: "Виктор",
//             lastName: "Вершинин",
//             place: 1,
//             rating: 2519,
//             movement: Movement.DOWN
//         },
//         {
//             id: '324234234',
//             firstName: "Виктор",
//             lastName: "Вершинин",
//             place: 1,
//             rating: 2519,
//             movement: Movement.IN_PLACE
//         },
//         {
//             id: '324234235',
//             firstName: "Виктор",
//             lastName: "Вершинин",
//             place: 1,
//             rating: 2519,
//             movement: Movement.UP
//         },
//         {
//             id: '324234236',
//             firstName: "Виктор",
//             lastName: "Вершинин",
//             place: 1,
//             rating: 2519,
//             movement: Movement.UP
//         },
//         {
//             id: '324234237',
//             firstName: "Виктор",
//             lastName: "Вершинин",
//             place: 1,
//             rating: 2519,
//             movement: Movement.UP
//         },
//         {
//             id: '324234238',
//             firstName: "Виктор",
//             lastName: "Вершинин",
//             place: 1,
//             rating: 2519,
//             movement: Movement.DOWN
//         },
//         {
//             id: '324234239',
//             firstName: "Виктор",
//             lastName: "Вершинин",
//             place: 1,
//             rating: 2519,
//             movement: Movement.IN_PLACE
//         },
//         {
//             id: '324234241',
//             firstName: "Виктор",
//             lastName: "Вершинин",
//             place: 1,
//             rating: 2519,
//             movement: Movement.UP
//         }
//     ],
//     ratingTrainers: [
//         {
//             id: "86464671",
//             firstName: "Наталия",
//             lastName: "Тумковская",
//             place: 1,
//             averagePlaceInRating: 43,
//             studentsInRating: 8,
//             movement: Movement.DOWN,
//         },
//         {
//             id: "86464672",
//             firstName: "Наталия",
//             lastName: "Тумковская",
//             place: 1,
//             averagePlaceInRating: 43,
//             studentsInRating: 8,
//             movement: Movement.UP,
//         },
//         {
//             id: "86464673",
//             firstName: "Наталия",
//             lastName: "Тумковская",
//             place: 1,
//             averagePlaceInRating: 43,
//             studentsInRating: 8,
//             movement: Movement.DOWN,
//         },
//         {
//             id: "86464674",
//             firstName: "Наталия",
//             lastName: "Тумковская",
//             place: 1,
//             averagePlaceInRating: 43,
//             studentsInRating: 8,
//             movement: Movement.DOWN,
//         },
//         {
//             id: "86464675",
//             firstName: "Наталия",
//             lastName: "Тумковская",
//             place: 1,
//             averagePlaceInRating: 43,
//             studentsInRating: 8,
//             movement: Movement.DOWN,
//         },
//         {
//             id: "86464676",
//             firstName: "Наталия",
//             lastName: "Тумковская",
//             place: 1,
//             averagePlaceInRating: 43,
//             studentsInRating: 8,
//             movement: Movement.DOWN,
//         }
//     ],
// }