import React, { FunctionComponent } from 'react';
import { Dialog } from '@mui/material';

interface CustomDialogProps {
  open: boolean;
  onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
  fullWidth?: boolean;
}

export const CustomDialog: FunctionComponent<CustomDialogProps> = ({
  open,
  onClose,
  fullWidth,
  children,
}) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
        },
      }}
      fullWidth={fullWidth}
    >
      {children}
    </Dialog>
  );
};
