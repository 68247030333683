import React, { FunctionComponent, useEffect, useState } from 'react';
import { User, TelegramConnectInfo } from "/src/domain/shared/User";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "/src/domain/shared/requests/getUserInfo";
import { changePageLanguage } from "/src/domain/shared/changePageLanguage";
import dayjs from "dayjs";
import axios from "axios";
import { links } from "/src/domain/shared/links";


export interface CurrentUserContextProps {
    currentUser?: User;
    currentDate: string;
    setCurrentUserState: () => Promise<User | undefined>;
    telegramConnectInfo?: TelegramConnectInfo | null;
    requestTelegramConnectInfo: () => Promise<void>;
}

export const CurrentUserContext = React.createContext<CurrentUserContextProps | undefined>(undefined);

interface CurrentUserProviderProps {
    defaultUser?: User | null;
}

const getCurrentDate = () => dayjs.tz(dayjs(), "Europe/Moscow").format("YYYY-MM-DD HH:mm:ss");

const getInitCurrentUser = (defaultUser?: User | null) => {
    if (defaultUser === null) {
        return undefined;
    } 

    return defaultUser;
}

export const CurrentUserProvider: FunctionComponent<CurrentUserProviderProps> = ({
    defaultUser,
    children
}) => {
    const { t } = useTranslation();

    const [currentUser, setCurrentUser] = useState<User | undefined>(() => getInitCurrentUser(defaultUser));
    const [currentDate, setCurrentDate] = useState<string>(getCurrentDate);
    const [telegramConnectInfo, setTelegramConnectInfo] = useState<TelegramConnectInfo | null>();

    const setCurrentUserState = async () => {
        const receivedUser = await getUserInfo();

        if (!receivedUser) return;

        setCurrentUser(receivedUser);

        return receivedUser;
    }

    useEffect(() => {
        if (defaultUser === undefined) {
            setCurrentUserState();
        };
    }, [])

    const setPageLanguage = () => {
        if (!currentUser?.lang) return;

        changePageLanguage(currentUser.lang);
    }

    const setCurrentDateState = () => {
        if (!currentUser?.nowmsk) return;

        const date = currentUser?.nowmsk ? dayjs.unix(currentUser.nowmsk) : dayjs();
        const currentDate = date.tz("Europe/Moscow").format("YYYY-MM-DD HH:mm:ss");
        setCurrentDate(currentDate);
    }

    const requestTelegramConnectInfo = async () => {
        try {
            const response = await axios.get(links.origin + "/apiweb/student/self/tlgrmpin/")
            setTelegramConnectInfo(response.data || null);
        } catch (e) {
            setTelegramConnectInfo(null);
            console.log('requestTelegramConnectInfo', e);
        }
    }

    useEffect(() => {
        setPageLanguage();
        setCurrentDateState();
    }, [currentUser])

    return (
        <CurrentUserContext.Provider
            value={{
                currentUser,
                currentDate,
                setCurrentUserState,
                telegramConnectInfo,
                requestTelegramConnectInfo,
            }}>{children}</CurrentUserContext.Provider>
    )
}
