import React, { FunctionComponent, useState } from "react";
import { Box, ButtonBase } from "@mui/material";
import { HtmlStyleContainer } from "/src/ui/shared/HtmlStyleContainer";
import parse from "html-react-parser";
import { getHtmlPreview } from "/src/domain/studentTasks/getHtmlPreview";
import { isString as _isString } from "lodash";
import { useTranslation } from "react-i18next";

interface ReportCommentProps {
    isHtml: boolean;
    comment: string;
    className: string;
}

export const ReportComment: FunctionComponent<ReportCommentProps> = ({
    isHtml,
    comment,
    className
}) => {
    const { t } = useTranslation();


    return <HtmlStyleContainer className={className}>
        <HtmlComment comment={comment}/>
    </HtmlStyleContainer>
}

interface HtmlCommentProps {
    comment: string;
}

const HtmlComment: FunctionComponent<HtmlCommentProps> = ({ comment }) => {
    const [isPreview, setIsPreview] = useState<boolean>(true);

    const text = !isPreview && parse(comment);
    const previewResult = isPreview && getHtmlPreview(comment);

    return <Box>
        { text && text } 
        { previewResult && <>
            { previewResult.result }
            { previewResult.isPreview && <ShowMoreButton onClick={() => setIsPreview(!isPreview)}/> }
        </> }
    </Box>
}

interface ShowMoreButtonProps {
    onClick: () => void;
}

const ShowMoreButton: FunctionComponent<ShowMoreButtonProps> = ({ onClick }) => {
    const { t } = useTranslation();

    return <ButtonBase 
        onClick={onClick}
        sx={{
            color: "primary.main",
            textDecoration: "underline",
            fontSize: "inherit",
            display: "block",
            marginTop: "0.75rem"
        }}
    >
        { t('readMore') }
    </ButtonBase>
}
