import React, { FunctionComponent, useState } from 'react';
import {
  Club,
  ClubSettings,
  ClubMemberStat,
  ClubStat,
  ClubFastestSegments,
  ClubToSend,
  ClubMember,
  ClubMembershipFeeData,
  PaymentInfo,
  PaymentType,
} from '/src/domain/club/ClubData';
import {
  SearchClubBy,
  getClub,
  getDefaultTrainerClub,
  getClubSettings,
  getClubs,
  getClubMemberIds,
  updateUserClub,
  getClubMembers,
  getClubMembershipFeeData,
  getPaymentsInfo,
  disableAutoPayment,
} from '/src/domain/shared/requests/clubsRequests';
import axios from 'axios';
import { links } from '/src/domain/shared/links';
import {
  shuffle as _shuffle,
  isNumber as _isNumber,
  isString as _isString,
} from 'lodash';
import { convertObjectToFormData } from '/src/domain/shared/convertObjectToFormData';
import { parse } from 'query-string';
import Cookies from 'js-cookie';
export { SearchClubBy };

export interface ClubContextProps {
  club?: Club | null;
  setClub: React.Dispatch<React.SetStateAction<Club | null | undefined>>;
  requestClub: (value: string, searchBy?: SearchClubBy ) => Promise<void>;
  defaultTrainerClub?: Club | null;
  requestDefaultTrainerClub: (defaultTrainerId: string) => Promise<void>;
  clubSettings?: ClubSettings | null;
  requestClubSettings: (clubId: string, isKey?: boolean) => Promise<void>;
  userClub?: Club | null;
  setUserClub: React.Dispatch<React.SetStateAction<Club | null | undefined>>;
  requestUserClub: (userId: string) => Promise<void>;
  pageOwnerClub?: Club | null;
  requestPageOwnerClub: (userId: string) => Promise<void>;
  userClubUpdateRequest: (
    userId: string,
    clubId: string | null,
  ) => Promise<boolean>;
  clubs?: Club[] | null;
  requestClubs: () => Promise<void>;
  clubMemberIds?: string[] | null;
  requestClubMemberIds: (clubId: string) => Promise<void>;
  clubMembers?: ClubMember[] | null;
  requestClubMembers: (clubId: string) => Promise<void>;
  clubMemberStats?: ClubMemberStat[] | null;
  requestClubMemberStats: (clubId: string, limit?: number) => Promise<void>;
  clubStat?: ClubStat | null;
  requestClubStat: (clubId: string) => Promise<void>;
  clubFastestSegments?: ClubFastestSegments | null;
  requestClubFastestSegments: (
    clubId: string,
    isShowAll?: boolean,
  ) => Promise<void>;
  updateClub: (
    clubId: string,
    club: ClubToSend,
    logoImg: File | null,
    themeImg: File | null,
  ) => Promise<boolean>;
  saveBanForFastestResult: (value: {
    clubId: string;
    stravauser: string;
    distance: string;
    activityId: string;
  }) => Promise<boolean>;
  clubMembershipFeeData?: ClubMembershipFeeData | null;
  requestClubMembershipFeeData: (userId?: string) => Promise<void>;
  requestToDisableAutoPayment: (paymentType?: PaymentType) => Promise<void>;
  paymentsInfo?: PaymentInfo[] | null;
  requestPaymentsInfo: () => Promise<void>;
}

const CLUBS_REQUEST_URL = `${links.origin}/apiweb/clubs.php`;
// const CLUBS_ADMIN_URL = `http://localhost:3000/clubs`;
const CLUBS_ADMIN_URL = `https://s10-dashboard.routeme.life/api/clubs`;

export const ClubContext = React.createContext<ClubContextProps | undefined>(
  undefined,
);

export const ClubProvider: FunctionComponent = ({ children }) => {
  const [club, setClub] = useState<Club | null>();
  const [defaultTrainerClub, setDefaultTrainerClub] = useState<Club | null>();
  const [clubSettings, setClubSettings] = useState<ClubSettings | null>();
  const [userClub, setUserClub] = useState<Club | null>();
  const [pageOwnerClub, setPageOwnerClub] = useState<Club | null>();
  const [clubs, setClubs] = useState<Club[] | null>();
  const [clubMemberIds, setClubMemberIds] = useState<string[] | null>();
  const [clubMembers, setClubMembers] = useState<ClubMember[] | null>();
  const [clubMemberStats, setClubMemberStats] = useState<
    ClubMemberStat[] | null
  >();
  const [clubStat, setClubStat] = useState<ClubStat | null>();
  const [clubFastestSegments, setClubFastestSegments] =
    useState<ClubFastestSegments | null>();
  const [clubMembershipFeeData, setClubMembershipFeeData] =
    useState<ClubMembershipFeeData | null>();
  const [paymentsInfo, setPaymentsInfo] = useState<PaymentInfo[] | null>();

  const requestClub = async (clubId: string, searchBy?: SearchClubBy) => {
    const clubResponse = await getClub(clubId, searchBy);
    setClub(clubResponse);
  };

  const requestDefaultTrainerClub = async (defaultTrainerId: string) => {
    const clubResponse = await getDefaultTrainerClub(defaultTrainerId);
    setDefaultTrainerClub(clubResponse);
  };

  const requestClubSettings = async (clubId: string, isKey?: boolean) => {
    const clubSettingsResponse = await getClubSettings(clubId);
    setClubSettings(clubSettingsResponse);
  };

  const requestUserClub = async (userId: string) => {
    const userClubResponse = await getClubs(userId);
    setUserClub(userClubResponse);
  };

  const requestPageOwnerClub = async (userId: string) => {
    const userClubResponse = await getClubs(userId);
    setPageOwnerClub(userClubResponse);
  };

  const userClubUpdateRequest = async (
    userId: string,
    clubId: string | null,
  ) => {
    const isUpdated = await updateUserClub(userId, clubId);
    return isUpdated;
  };

  const requestClubs = async () => {
    const clubsResponse = await getClubs();
    setClubs(clubsResponse);
  };

  const requestClubMemberIds = async (clubId: string) => {
    const clubMemberIds = await getClubMemberIds(clubId);
    setClubMemberIds(clubMemberIds);
  };

  const requestClubMembers = async (clubId: string) => {
    const clubMembers = await getClubMembers(clubId);
    setClubMembers(clubMembers);
  };

  const requestClubMemberStats = async (clubId: string, limit?: number) => {
    try {
      const response = await axios.get(CLUBS_REQUEST_URL, {
        params: {
          action: 'bymonth',
          club_id: clubId,
          limit,
        },
      });
      setClubMemberStats(response.data.bymonth || null);
    } catch (e) {
      console.log('requestClubMemberStats', e);
      setClubMemberStats(null);
    }
  };

  const requestClubStat = async (clubId: string) => {
    try {
      const response = await axios.get(CLUBS_REQUEST_URL, {
        params: {
          action: 'stat',
          club_id: clubId,
        },
      });
      setClubStat(response.data || null);
    } catch (e) {
      console.log('requestClubStat', e);
      setClubStat(null);
    }
  };

  const requestClubFastestSegments = async (
    clubId: string,
    isShowAllResults?: boolean,
  ) => {
    try {
      const response = await axios.get(CLUBS_REQUEST_URL, {
        params: {
          action: 'fastest',
          club_id: clubId,
          all: isShowAllResults ? 1 : 0,
        },
      });
      setClubFastestSegments(response.data.fastest || null);
    } catch (e) {
      console.log('requestClubFastestSegments', e);
      setClubFastestSegments(null);
    }
  };

  const updateClub = async (
    clubId: string,
    club: ClubToSend,
    logoImg: File | null,
    themeImg: File | null,
  ) => {
    const formData = convertObjectToFormData(club);
    logoImg && formData.append('logoImg', logoImg);
    themeImg && formData.append('themeImg', themeImg);
    if (club.membershipCost) {
      formData.append('membershipFeeRub', String(club.membershipCost.rub));
      formData.append('membershipFeeEur', String(club.membershipCost.eur));
    }

    try {
      const resp = await axios.put(`${CLUBS_ADMIN_URL}/${clubId}`, formData, {
        headers: {
          'S10-apptoken': Cookies.get('apptoken'),
        },
      });

      requestClub(clubId);
      return true;
    } catch (e) {
      console.log('updateClub', e);
      return false;
    }
  };

  const saveBanForFastestResult: (value: {
    clubId: string;
    stravauser: string;
    distance: string;
    activityId: string;
  }) => Promise<boolean> = async ({
    clubId,
    stravauser,
    distance,
    activityId,
  }) => {
    try {
      await axios.get(`${links.origin}/apiweb/clubs.php`, {
        params: {
          action: 'fastbestskip',
          club_id: clubId,
          stravauser,
          distance,
          activityId,
        },
      });

      return true;
    } catch (e) {
      console.log('saveBanForFastestResult', e);
      return false;
    }
  };

  const requestClubMembershipFeeData = async (userId?: string) => {
    const clubMembershipFeeDataResponse = await getClubMembershipFeeData(
      userId,
    );
    setClubMembershipFeeData(clubMembershipFeeDataResponse);
  };

  const requestToDisableAutoPayment = async (paymentType?: PaymentType) => {
    const clubMembershipFeeDataResponse = await disableAutoPayment(paymentType);
    setClubMembershipFeeData(clubMembershipFeeDataResponse);
  };

  const requestPaymentsInfo = async () => {
    const paymentsInfo = await getPaymentsInfo();
    setPaymentsInfo(paymentsInfo);
  };

  return (
    <ClubContext.Provider
      value={{
        club,
        setClub,
        requestClub,
        defaultTrainerClub,
        requestDefaultTrainerClub,
        clubSettings,
        requestClubSettings,
        userClub,
        setUserClub,
        requestUserClub,
        pageOwnerClub,
        requestPageOwnerClub,
        userClubUpdateRequest,
        clubs,
        requestClubs,
        clubMemberIds,
        requestClubMemberIds,
        clubMembers,
        requestClubMembers,
        clubMemberStats,
        requestClubMemberStats,
        clubStat,
        requestClubStat,
        clubFastestSegments,
        requestClubFastestSegments,
        updateClub,
        saveBanForFastestResult,
        clubMembershipFeeData,
        requestClubMembershipFeeData,
        requestToDisableAutoPayment,
        paymentsInfo,
        requestPaymentsInfo,
      }}
    >
      {children}
    </ClubContext.Provider>
  );
};

export const getClubKey = () => {
  const query = parse(location.search);
  return _isString(query.id) ? query.id : null;
};
export enum PageSize {
  MOBILE,
  TABLET,
  PC,
}
