import React, { FunctionComponent } from "react";
import cs from "classnames";
import { makeStyles } from "@mui/styles";

type InstagramIconProps = {
    className?: string,
};

export const InstagramIcon: FunctionComponent<InstagramIconProps> = ({className}) => {
    const classes = useStyles();

    return (
        <svg className={cs(classes.icon, className)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9024 14.2439C13.0878 14.2439 14.0488 13.2829 14.0488 12.0975C14.0488 10.9121 13.0878 9.95117 11.9024 9.95117C10.7171 9.95117 9.7561 10.9121 9.7561 12.0975C9.7561 13.2829 10.7171 14.2439 11.9024 14.2439Z" fill="white"/>
            <path d="M14.5853 6.83008H9.2194C8.43891 6.83008 7.75599 7.07398 7.31696 7.513C6.87794 7.95203 6.63403 8.63496 6.63403 9.41544V14.7813C6.63403 15.5618 6.87794 16.2447 7.36574 16.7325C7.85355 17.1715 8.48769 17.4154 9.26818 17.4154H14.5853C15.3657 17.4154 16.0487 17.1715 16.4877 16.7325C16.9755 16.2935 17.2194 15.6106 17.2194 14.8301V9.46422C17.2194 8.68374 16.9755 8.04959 16.5365 7.56179C16.0487 7.07398 15.4145 6.83008 14.5853 6.83008ZM11.9023 15.4154C10.0487 15.4154 8.58525 13.9032 8.58525 12.0984C8.58525 10.2447 10.0974 8.7813 11.9023 8.7813C13.7072 8.7813 15.2682 10.2447 15.2682 12.0984C15.2682 13.952 13.756 15.4154 11.9023 15.4154ZM15.3657 9.41544C14.9267 9.41544 14.5853 9.07398 14.5853 8.63496C14.5853 8.19593 14.9267 7.85447 15.3657 7.85447C15.8048 7.85447 16.1462 8.19593 16.1462 8.63496C16.1462 9.07398 15.8048 9.41544 15.3657 9.41544Z" fill="white"/>
            <path d="M12 0.5C18.3572 0.5 23.5 5.64281 23.5 12C23.5 18.3572 18.3572 23.5 12 23.5C5.64281 23.5 0.5 18.3572 0.5 12C0.5 5.64281 5.64281 0.5 12 0.5Z" stroke="white"/>
        </svg>
    );
}

const useStyles = makeStyles({
    icon: {
        width: "1.5rem",
        height: "1.5rem",
    }
});

