import React from 'react';
import { TimerType } from '/src/ui/studentTasks/studentTasks/TaskView';
import dayjs from 'dayjs';
import { isNumber as _isNumber } from 'lodash';

// The timer starts if there are more (seconds)
const timerStartLimitation = 10;
// The timer stops if less than or equal (seconds)
const timerStopLimitation = 0;
// timer step (seconds)
const timerStep = 1;

interface startReportTimerProps {
  isDiary?: boolean;
  isBigReport: boolean;
  readOnly: boolean;
  isExternalTask: boolean;
  setTimer: React.Dispatch<React.SetStateAction<TimerType>>;
  taskDate: string;
  currentDate: string;
  hasReport: boolean;
}

export const startReportTimer = ({
  isDiary,
  isBigReport,
  readOnly,
  isExternalTask,
  setTimer,
  taskDate,
  currentDate,
  hasReport,
}: startReportTimerProps): number | void => {
  if (
    // обычный отчёт в журнале
    (!isBigReport && isDiary) ||
    // только чтение
    readOnly ||
    // чужая задача
    isExternalTask
  )
    return;

  const dayHasNotСome = dayjs(taskDate).isAfter(currentDate);

  if (isBigReport) {
    const isTimerOn = !dayHasNotСome || hasReport;
    return setTimer(isTimerOn);
  }

  if (dayHasNotСome) return;

  const secondsLeft: number = dayjs(taskDate)
    .add(2, 'day')
    .diff(dayjs(currentDate), 'second');

  if (secondsLeft <= timerStartLimitation) return;

  const intervalId = window.setInterval(() => {
    setTimer((timer) => {
      if (!_isNumber(timer)) {
        return secondsLeft;
      }

      if (timer <= timerStopLimitation) {
        clearInterval(intervalId);
        return false;
      }
      return timer - timerStep;
    });
  }, 1000);
  return intervalId;
};
