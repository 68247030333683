import React, { FunctionComponent } from 'react';
import { IconsProps } from './iconsProps';

export const Person: FunctionComponent<IconsProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 60 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="23" y="13" width="14" height="17" fill="#FFFEDA" />
      <path
        d="M34.25 20H44.75V17.4H34.25V20ZM34.25 14.8H44.75V12.2H34.25V14.8ZM49.9344 31.0174L44.6187 24.1599C44.2469 23.6616 43.7875 23.2772 43.2406 23.0068C42.6937 22.7364 42.1031 22.6008 41.4687 22.5999H29V9.59999C29 8.885 29.2572 8.2727 29.7717 7.7631C30.2862 7.2535 30.904 6.99914 31.625 7H47.375C48.0969 7 48.7151 7.2548 49.2296 7.7644C49.7441 8.274 50.0009 8.88586 50 9.59999V30.3999C50 30.5083 49.9943 30.6114 49.9829 30.7093C49.9716 30.8073 49.9554 30.91 49.9344 31.0174ZM31.625 32.9999C30.9031 32.9999 30.2854 32.7451 29.7717 32.2355C29.2581 31.7259 29.0009 31.114 29 30.3999V25.1999H41.4687C41.6875 25.1999 41.8901 25.2489 42.0764 25.3468C42.2628 25.4448 42.4212 25.58 42.5516 25.7524L48.0641 32.9024C47.9547 32.9457 47.8401 32.973 47.7202 32.9843C47.6003 32.9956 47.4852 33.0008 47.375 32.9999H31.625Z"
        fill="url(#paint0_linear_7644_3161)"
      />
      <path
        d="M28.3854 21.0484V21.1082C28.3854 21.8128 28.3854 22.1659 28.2158 22.4543C28.0462 22.7427 27.7373 22.9139 27.1212 23.2572L26.4715 23.6177C26.9189 22.1037 27.0688 20.4766 27.1237 19.0855L27.1319 18.9044L27.1335 18.8618C27.6669 19.047 27.9667 19.1854 28.1535 19.4443C28.3854 19.7663 28.3854 20.1939 28.3854 21.0484ZM12 21.0484V21.1082C12 21.8128 12 22.1659 12.1696 22.4543C12.3392 22.7427 12.648 22.9139 13.2641 23.2572L13.9146 23.6177C13.4665 22.1037 13.3166 20.4766 13.2617 19.0855L13.2535 18.9044L13.2527 18.8618C12.7185 19.047 12.4186 19.1854 12.2319 19.4443C12 19.7663 12 20.1948 12 21.0484Z"
        fill="url(#paint1_linear_7644_3161)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7787 16.2845C22.5933 16.0902 21.3939 15.9951 20.1927 16.0002C18.732 16.0002 17.5277 16.1288 16.6068 16.2845C15.6736 16.4418 15.2075 16.5204 14.8175 17.0005C14.4284 17.4806 14.4488 17.9992 14.4898 19.0364C14.6315 22.5986 15.4 27.0488 19.5783 27.4421V30.3374H18.4067C18.2174 30.3375 18.034 30.4032 17.8876 30.5233C17.7412 30.6433 17.641 30.8104 17.6038 30.9961L17.4482 31.7711H15.2771C15.1142 31.7711 14.9579 31.8358 14.8426 31.9511C14.7274 32.0663 14.6627 32.2226 14.6627 32.3855C14.6627 32.5485 14.7274 32.7048 14.8426 32.82C14.9579 32.9353 15.1142 33 15.2771 33H25.1083C25.2713 33 25.4276 32.9353 25.5428 32.82C25.658 32.7048 25.7228 32.5485 25.7228 32.3855C25.7228 32.2226 25.658 32.0663 25.5428 31.9511C25.4276 31.8358 25.2713 31.7711 25.1083 31.7711H22.9373L22.7816 30.9961C22.7445 30.8104 22.6442 30.6433 22.4979 30.5233C22.3515 30.4032 22.1681 30.3375 21.9787 30.3374H20.8072V27.4421C24.9854 27.0488 25.7547 22.5994 25.8957 19.0364C25.9366 17.9992 25.9579 17.4798 25.5679 17.0005C25.178 16.5204 24.7118 16.4418 23.7787 16.2845ZM20.8375 19.1191C20.9498 19.1657 21.0458 19.2444 21.1133 19.3455C21.1808 19.4465 21.2168 19.5654 21.2168 19.6869V22.964C21.2168 23.1269 21.1521 23.2832 21.0368 23.3985C20.9216 23.5137 20.7653 23.5784 20.6024 23.5784C20.4394 23.5784 20.2831 23.5137 20.1679 23.3985C20.0526 23.2832 19.9879 23.1269 19.9879 22.964V21.1698L19.8077 21.35C19.7514 21.4104 19.6836 21.4588 19.6082 21.4924C19.5328 21.526 19.4515 21.544 19.369 21.5455C19.2865 21.5469 19.2045 21.5318 19.128 21.5009C19.0515 21.47 18.982 21.424 18.9237 21.3656C18.8653 21.3073 18.8193 21.2378 18.7884 21.1613C18.7575 21.0847 18.7423 21.0028 18.7438 20.9203C18.7452 20.8378 18.7633 20.7564 18.7969 20.681C18.8305 20.6057 18.8789 20.5378 18.9392 20.4816L20.1682 19.2527C20.254 19.1668 20.3634 19.1082 20.4826 19.0844C20.6017 19.0607 20.7252 19.0727 20.8375 19.1191Z"
        fill="url(#paint2_linear_7644_3161)"
      />
      <circle cx="30" cy="31" r="9" fill="#FFFEDA" />
      <path
        d="M30 21C28.6868 21 27.3864 21.2587 26.1732 21.7612C24.9599 22.2638 23.8575 23.0003 22.9289 23.9289C21.0536 25.8043 20 28.3478 20 31C20 33.6522 21.0536 36.1957 22.9289 38.0711C23.8575 38.9997 24.9599 39.7362 26.1732 40.2388C27.3864 40.7413 28.6868 41 30 41C32.6522 41 35.1957 39.9464 37.0711 38.0711C38.9464 36.1957 40 33.6522 40 31C40 29.6868 39.7413 28.3864 39.2388 27.1732C38.7362 25.9599 37.9997 24.8575 37.0711 23.9289C36.1425 23.0003 35.0401 22.2638 33.8268 21.7612C32.6136 21.2587 31.3132 21 30 21ZM30 27.39C31.6135 28.4383 33.4958 28.9974 35.42 29C36.2 29 36.95 28.91 37.67 28.74C37.88 29.45 38 30.21 38 31C38 35.41 34.41 39 30 39C27 39 24.39 37.34 23 34.89L24.75 33V32C24.75 31.6685 24.8817 31.3505 25.1161 31.1161C25.3505 30.8817 25.6685 30.75 26 30.75C26.3315 30.75 26.6495 30.8817 26.8839 31.1161C27.1183 31.3505 27.25 31.6685 27.25 32V33H30M34 30.75C33.6685 30.75 33.3505 30.8817 33.1161 31.1161C32.8817 31.3505 32.75 31.6685 32.75 32C32.75 32.3315 32.8817 32.6495 33.1161 32.8839C33.3505 33.1183 33.6685 33.25 34 33.25C34.3315 33.25 34.6495 33.1183 34.8839 32.8839C35.1183 32.6495 35.25 32.3315 35.25 32C35.25 31.6685 35.1183 31.3505 34.8839 31.1161C34.6495 30.8817 34.3315 30.75 34 30.75Z"
        fill="url(#paint3_linear_7644_3161)"
      />
      <path
        d="M46.125 28.3754C45.9668 27.7336 45.0516 27.7336 44.8934 28.3754C44.8376 28.6018 44.6596 28.7787 44.4326 28.8324C43.7945 28.9833 43.7945 29.8914 44.4326 30.0423C44.6596 30.0959 44.8376 30.2729 44.8934 30.4993C45.0516 31.1411 45.9668 31.1411 46.125 30.4993C46.1808 30.2729 46.3588 30.0959 46.5857 30.0423C47.2239 29.8914 47.2239 28.9833 46.5857 28.8324C46.3588 28.7787 46.1808 28.6018 46.125 28.3754Z"
        fill="url(#paint4_linear_7644_3161)"
      />
      <path
        d="M47.7685 36.2064C47.6104 35.5646 46.6951 35.5646 46.5369 36.2064C46.4811 36.4328 46.3031 36.6098 46.0762 36.6634C45.4381 36.8143 45.4381 37.7225 46.0762 37.8733C46.3031 37.927 46.4811 38.104 46.5369 38.3304C46.6951 38.9721 47.6104 38.9721 47.7685 38.3304C47.8243 38.104 48.0024 37.927 48.2293 37.8733C48.8674 37.7225 48.8674 36.8143 48.2293 36.6634C48.0024 36.6098 47.8243 36.4328 47.7685 36.2064Z"
        fill="url(#paint5_linear_7644_3161)"
      />
      <path
        d="M13.251 5.49841C13.0928 4.85663 12.1776 4.85663 12.0194 5.49841C11.9636 5.72481 11.7855 5.90179 11.5586 5.95544C10.9205 6.10631 10.9205 7.01446 11.5586 7.16533C11.7855 7.21898 11.9636 7.39596 12.0194 7.62236C12.1776 8.26414 13.0928 8.26414 13.251 7.62236C13.3068 7.39596 13.4848 7.21898 13.7117 7.16533C14.3498 7.01446 14.3498 6.10631 13.7117 5.95544C13.4848 5.90179 13.3068 5.72481 13.251 5.49841Z"
        fill="url(#paint6_linear_7644_3161)"
      />
      <path
        d="M47.9504 11.6836C48.4238 11.3455 48.3445 10.6191 47.8089 10.3867C47.2813 10.1577 46.705 10.581 46.7673 11.1516C46.8295 11.7222 47.4842 12.0166 47.9504 11.6836Z"
        fill="url(#paint7_linear_7644_3161)"
      />
      <path
        d="M39.5314 35.0508C38.9662 34.9104 38.4575 35.4222 38.616 35.9718C38.7744 36.5211 39.4807 36.6963 39.8874 36.2871C40.2941 35.8779 40.0964 35.1911 39.5314 35.0508Z"
        fill="url(#paint8_linear_7644_3161)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7644_3161"
          x1="39.5"
          y1="7"
          x2="39.5"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF06D" />
          <stop offset="1" stopColor="#FFFEDA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7644_3161"
          x1="20.1927"
          y1="18.8618"
          x2="20.1927"
          y2="23.6177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7D6" />
          <stop offset="1" stopColor="#F6EC94" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7644_3161"
          x1="20.1928"
          y1="16"
          x2="28.5"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBF91" />
          <stop offset="0.578125" stopColor="#FFF8BE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7644_3161"
          x1="30"
          y1="21"
          x2="30"
          y2="41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC8B" />
          <stop offset="1" stopColor="#FFF2E9" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7644_3161"
          x1="42.9415"
          y1="30.8339"
          x2="47.3543"
          y2="22.2457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE3C1" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_7644_3161"
          x1="44.585"
          y1="38.6649"
          x2="48.9978"
          y2="30.0767"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE3C1" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_7644_3161"
          x1="10.0674"
          y1="7.95692"
          x2="14.4803"
          y2="-0.631274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE3C1" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_7644_3161"
          x1="46.8158"
          y1="10.0927"
          x2="50.5971"
          y2="11.6199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE3C1" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_7644_3161"
          x1="39.0242"
          y1="36.9264"
          x2="37.8015"
          y2="33.1039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE3C1" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
