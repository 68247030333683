import React, { FunctionComponent, useContext } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { theme } from "/src/assets/theme";
import { TelegramIcon } from "/src/ui/shared/footer/TelegramIcon";
import { FacebookIcon } from "/src/ui/shared/footer/FacebookIcon";
import { InstagramIcon } from "/src/ui/shared/footer/InstagramIcon";
import { YoutubeIcon } from "/src/ui/shared/footer/YoutubeIcon";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import { PageContext, PageContextProps } from "/src/contexts/Page";
import { links } from "/src/domain/shared/links";

export const Footer: FunctionComponent<any> = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { pageConfig } = useContext(PageContext) as PageContextProps;
    if (!pageConfig?.footer) return null;

    return (
        <footer className={classes.footer}>
            <div className={classes.linkContainer}>
                <a className={classes.link} href={"https://answers.s10.run/t/kak-zaregistrirovatsya-na-platforme/299"}>{t('instruction')}</a>
                {/* <a className={classes.link} href={"https://s10.canny.io/"}>{t('suggestChanges')}</a> */}
                {/* <a className={classes.link}
                    href={"https://stro.run/?utm_source=s10&utm_medium=service&utm_campaign=footer"}>{t('blogStro')}</a> */}
                {/* <a className={classes.link}
                    href={"https://anchor.fm/stro?utm_source=s10&utm_medium=service&utm_campaign=footer"}>{t('listenPodcast')}</a> */}
                <br />
                <a className={classes.link} href={`${links.origin}/challenges#schedule`}>{t('challengesSchedule')}</a>
                <a className={cs(classes.link, classes.underline)}
                    href={`${links.origin}/school?utm_source=s10&utm_medium=service&utm_campaign=footer`}>{t('trainersSchool')}</a>
                <br />
                <a className={classes.link}
                    href={"https://shop.s10.run/?utm_source=s10&utm_medium=service&utm_campaign=footer"}>{t('shopCollection')}</a>
            </div>
            <div>
                <div className={classes.social}>
                    <a className={classes.imageLink} href={"https://t.me/s10_challenges"}><TelegramIcon /></a>
                    <a className={classes.imageLink} href={"https://www.facebook.com/s10.run/"}><FacebookIcon /></a>
                    <a className={classes.imageLink} href={"https://www.instagram.com/s10run/"}><InstagramIcon /></a>
                    <a className={classes.imageLink}
                        href={"https://www.youtube.com/c/S10run/featured"}><YoutubeIcon /></a>
                </div>
                <div className={classes.copyright}>All rights reserved © 2010 — {(new Date()).getFullYear()} S10.run
                </div>
            </div>
        </footer>
    );
}

const useStyles = makeStyles((muiTheme) => ({
    footer: {
        [muiTheme.breakpoints.up("sm")]: {
            padding: "3rem 5rem 1.5rem",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
        },
        padding: "2rem 1rem 1rem",
        marginTop: "2rem",
        background: theme.primaryColor,
        width: "100%",
        boxSizing: "border-box"
    },
    social: {
        display: "flex",
        justifyContent: "flex-end",
    },
    copyright: {
        color: theme.WHITE_COLOR,
        opacity: 0.4,
        textAlign: "right",
        padding: "0.5rem 0",
        fontSize: "0.85rem",
    },
    imageLink: {
        textDecoration: "none",
        "&:hover": { textDecoration: "none" },
        display: "block",
        marginLeft: "0.5rem"
    },
    linkContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "1.5rem",
        [muiTheme.breakpoints.up("sm")]: {
            marginBottom: 0
        }
    },
    link: {
        color: theme.WHITE_COLOR,
        textDecoration: "none",
        "&:hover": { textDecoration: "none" },
        display: "block",
        "&:not(:last-child)": { marginBottom: "0.75rem" },
        [muiTheme.breakpoints.up("sm")]: {
            "&:last-child": { marginBottom: "0.5rem" }
        },
        fontSize: "0.9rem"
    },
    underline: {
        textDecoration: "underline",
    }
}));