import React, {
  FunctionComponent,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  CurrentAudioPostProps,
  CurrentAudioPostData,
} from '/src/domain/post/PostData';
import { PostContext, PostContextProps } from '/src/contexts/PostContext';
import { Box, IconButton, ClickAwayListener, Tooltip } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
const audioTrackIcon = require('../../../../assets/icons/audio_track.svg');

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

import LoadingButton from '@mui/lab/LoadingButton';

import { useTranslation } from 'react-i18next';
import { sec2hms } from '/src/utils/time-utils';
import { theme } from '/src/assets/theme';

export const AudioView: FunctionComponent<CurrentAudioPostProps> = (props) => {
  const { t } = useTranslation();
  const progressBarCurtainRef = useRef<HTMLDivElement | null>(null);
  const [isLoadingPlaylist, setIsLoadingPlaylist] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const {
    currentAudioPost,
    setCurrentAudioPostState,
    playlist,
    updatePlaylistState,
  } = useContext(PostContext) as PostContextProps;

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleAudioClick = () => {
    const isCurrentAudio = currentAudioPost?.postid === props.postid;
    if (isCurrentAudio) {
      const newCurrentAudioPost: CurrentAudioPostData | null =
        currentAudioPost
          ? {
              ...currentAudioPost,
              isBugged: !currentAudioPost.isBugged,
            }
          : null;
      setCurrentAudioPostState(newCurrentAudioPost);
    } else {
      setCurrentAudioPostState({
        ...props,
        isBugged: true,
        postProgressBar: currentAudioPost?.postProgressBar
      });
    }
  };

  const changePostProgressBar = () => {
    if (
      currentAudioPost?.postid !== props.postid ||
      currentAudioPost.postProgressBar?.postid === props.postid
    ) {
      return false;
    }

    if (currentAudioPost?.postProgressBar?.curtainRef.current) {
      currentAudioPost.postProgressBar.curtainRef.current.style.left = '0';
    }

    const newCurrentAudioPost = { ...currentAudioPost };
    newCurrentAudioPost.postProgressBar = {
      postid: props.postid,
      curtainRef: progressBarCurtainRef,
    };
    setCurrentAudioPostState(newCurrentAudioPost);

    return true;

  };

  useEffect(() => {
    changePostProgressBar();
  }, [currentAudioPost]);

  const isTrackInPlaylist = playlist?.ids.includes(props.postid);

  const handlePlaylistButtonClick = async () => {
    setIsLoadingPlaylist(true);
    await updatePlaylistState(props.postid);
    setIsLoadingPlaylist(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.25rem 0.5rem',
        marginTop: '0.75rem',
        border: `1px solid ${theme.greyColor}`,
        borderRadius: '5px',
      }}
    >
      <IconButton onClick={() => handleAudioClick()}>
        {props.postid == currentAudioPost?.postid &&
        currentAudioPost.isBugged ? (
          <PauseIcon />
        ) : (
          <PlayArrowIcon />
        )}
      </IconButton>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box sx={{ flexGrow: 1 }}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={String(t('playerRewindHint'))}
          >
            <Box
              onClick={handleTooltipOpen}
              sx={{
                backgroundImage: `url(${audioTrackIcon})`,
                height: '2rem',
                marginRight: '0.5rem',
                backgroundRepeat: 'repeat-x',
                backgroundPositionY: 'center',
                backgroundSize: 'contain',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  opacity: 0.5,
                  backgroundColor: theme.lightPrimaryColor,
                }}
                ref={progressBarCurtainRef}
              ></Box>
            </Box>
          </Tooltip>
        </Box>
      </ClickAwayListener>
      <Box component="span">{sec2hms(props.audiosec)}</Box>
      {playlist?.ids && (
        <LoadingButton
          onClick={handlePlaylistButtonClick}
          loading={isLoadingPlaylist}
        >
          {isTrackInPlaylist ? <PlaylistRemoveIcon /> : <PlaylistAddIcon />}
        </LoadingButton>
      )}
    </Box>
  );
};
