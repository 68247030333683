import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import { PremiumFeaturesBanner } from "/src/ui/shared/premiumBanners/PremiumFeaturesBanner";
import { PostViewMode } from "/src/domain/studentTasks/StudentTasksData";
import { Box, Dialog } from "@mui/material";
import { theme } from "/src/assets/theme";
import { PostEditor } from "./PostEditor";
import { Editor as TinyMCEEditor } from 'tinymce';
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

interface PostButtonProps {
    getPostId: () => Promise<string | null>;
    createPost: (postId: string, date: string, text: string, viewMode: PostViewMode, audio: File | null, tags: string[]) => Promise<boolean>;
    currentDate: string;
    isPremium: boolean;
    currentUserId?: string;
}

export const PostButton: FunctionComponent<PostButtonProps> = ({
    getPostId,
    createPost,
    currentDate,
    isPremium,
    currentUserId
}) => {
    const { t } = useTranslation();

    const editorRef = useRef<TinyMCEEditor | null>(null);

    const [openEditor, setOpenEditor] = useState(false);
    const [postId, setPostId] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [openPremiumBanner, setOpenPremiumBanner] = useState(false);
    const [viewMode, setViewMode] = React.useState(PostViewMode.REGULAR);
    const [tags, setTags] = useState<string[]>([]);

    const [newAudioFile, setNewAudioFile] = useState<File | null>(null);

    const setComponentStates = async () => {
        const postId = await getPostId();
        setPostId(postId);
        
        if (postId)
            setLoading(false);
    }

    useEffect(() => {
        if (!openEditor) return;
        setComponentStates();

        return () => {
            setPostId(null);
        }
    }, [openEditor])

    const createNewPost = async () => {
        const text = editorRef.current?.getContent();
        if (!postId || !text) {
            alert(t('postRequirement'));
            return false;
        }
        setLoading(true);


        const postDate = dayjs(currentDate).format("YYYY-MM-DD");

        const isSent = await createPost(postId, postDate, text, viewMode, newAudioFile, tags);

        setLoading(false);

        if (!isSent) {
            alert(t('serverError'));
            return false;
        }
        
        setOpenEditor(false);
        setPostId(null);

        return true;        
    }   

    const handlePostButtonClick = () => {
        if (isPremium) {
            setOpenEditor(!openEditor);
        } else {
            setOpenPremiumBanner(!openPremiumBanner);
        }
    }

    return <>
        <Box
            onClick={handlePostButtonClick}
            sx={{
                m: "1rem 0",
                p: "0.6rem 0.875rem",
                cursor: "pointer",
                borderRadius: "4px",
                color: theme.darkColor,
                opacity: 0.8,
                border: `1px solid ${theme.greyTextColor}`,
                "&:hover": {
                    borderColor: theme.darkColor
                }
            }}
        >
            { t('postRequirement') }
        </Box>
        <Dialog PaperComponent={React.Fragment} open={openPremiumBanner} onClose={() => setOpenPremiumBanner(!openPremiumBanner)}>
            <PremiumFeaturesBanner/>
        </Dialog>
        <PostEditor
            open={openEditor}
            onClose={() => setOpenEditor(!openEditor)}
            postId={postId}
            editorRef={editorRef}
            savePost={createNewPost}
            loading={loading}
            viewMode={viewMode}
            setViewMode={setViewMode}
            currentUserId={currentUserId}
            newAudioFile={newAudioFile}
            setNewAudioFile={setNewAudioFile}
            tags={tags}
            setTags={setTags}
        />
    </>
}
