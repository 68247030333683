import { Protocol, ChallengeFormula } from "/src/domain/challenges/ChallengeProtocolData";
import axios from "axios";
import { links } from "/src/domain/shared/links";

export interface ProtocolData {
    challenge: ChallengeInfo;
    protocols: Protocol[];
}

export interface ChallengeInfo {
    challenge_key: string;
    type: ChallengeFormula;
    id: number;
    max_team_participants: number;
    name: string;
}




export const getProtocol = async (challengeKey?: string): Promise<ProtocolData | null> => {
    try {
        const response = await axios.get(`${links.challengesOrigin}/api/protocol/get-web`, {
            withCredentials: true,
            params: {
                id: challengeKey
            }
        });
        const protocols = response.data.protocols;
        const challenge = response.data.challenge;
        
        return {
            protocols,
            challenge
        };
    } catch (e) {
        console.log("getProtocol: ", e);
        return null;
    }
}
