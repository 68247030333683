import React, { FunctionComponent, useState, memo, useContext } from 'react';
import { User } from '/src/domain/shared/User';
import { CurrentUserProvider } from '/src/contexts/shared/CurrentUserContext';
import {
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
} from '@mui/material';
import { PremiumAdBanner } from './PremiumAdBanner';
import { RegistrationBanner } from './RegistrationBanner';
import { BannerTemplate } from './common/BannerTemplate';
import { TelegramConnectionBanner } from './TelegramConnectionBanner';
import { ChooseCoachBannerContainer } from './chooseCoachBanner/ChooseCoachBannerContainer';
import { PostBannerContainer } from './postBanner/PostBannerContainer';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { links } from '/src/domain/shared/links';
import { random as _random } from 'lodash';
import { TestUids, TestMode } from '/src/domain/shared/checkUserForTestee';

interface BannersViewProps {
  currentUser?: User;
  addDomainToImageSrc?: boolean;
}

const AD_POST_ID = '1042';

export const BannersView: FunctionComponent<BannersViewProps> = memo(
  ({ currentUser, addDomainToImageSrc }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(true);
    const [cookiesData, setCookiesData] = useState({
      islogin: checkCookie('islogin'),
      istelegram: checkCookie('istelegram'),
      isprofileok: checkCookie('isprofileok'),
      isresults1ok: checkCookie('isresults1ok'),
      iscompetitionok: checkCookie('iscompetitionok'),
      ispayed: checkCookie('ispayed'),
      istrained: checkCookie('istrained'),
    });

    const handleSwitchChange = (value: keyof typeof cookiesData) => {
      const newCookiesData = { ...cookiesData };
      newCookiesData[value] = !newCookiesData[value];
      setCookiesData(newCookiesData);
    };

    const handleClose = () => {
      setIsOpen(false);
    };

    if (checkCookie('istrainer')) {
      return null;
    }

    let bannerEl: JSX.Element | null = null;

    if (!cookiesData.islogin) {
      bannerEl = <RegistrationBanner open={isOpen} onClose={handleClose} />;
    } else if (!currentUser) {
      return null;
    } else if (!cookiesData.istelegram) {
      bannerEl = (
        <CurrentUserProvider defaultUser={null}>
          <TelegramConnectionBanner open={isOpen} onClose={handleClose} />
        </CurrentUserProvider>
      );
    } else if (!cookiesData.isprofileok) {
      bannerEl = (
        <BannerTemplate
          open={isOpen}
          onClose={handleClose}
          title={t('fillOutProfile')}
          text={t('fillOutProfileAdDescriptions')}
          link={{
            content: t('goToProfile'),
            href: `${links.origin}/profile?${currentUser.id}`,
          }}
        />
      );
    } else if (!cookiesData.isresults1ok) {
      bannerEl = (
        <BannerTemplate
          open={isOpen}
          onClose={handleClose}
          title={t('fillInTableOfResults')}
          text={t('fillInTableOfResultsAdDescription')}
          link={{
            content: t('fill'),
            href: `${links.origin}/profile?${currentUser.id}`,
          }}
        />
      );
    } else if (!cookiesData.iscompetitionok) {
      bannerEl = (
        <BannerTemplate
          open={isOpen}
          onClose={handleClose}
          title={t('fillInTableOfPlans')}
          text={t('fillInTableOfPlansAdDescription')}
          link={{
            content: t('fill'),
            href: `${links.origin}/profile?${currentUser.id}`,
          }}
        />
      );
    } else if (!cookiesData.ispayed) {
      const minRandomValue = cookiesData.istrained ? 1 : 0;
      const bannerIndex = _random(minRandomValue, 2);

      if (bannerIndex === 0) {
        bannerEl = (
          <ChooseCoachBannerContainer open={isOpen} onClose={handleClose} />
        );
      } else if (bannerIndex === 1) {
        bannerEl = <PremiumAdBanner open={isOpen} onClose={handleClose} />;
      } else {
        bannerEl = (
          <PostBannerContainer
            open={isOpen}
            onClose={handleClose}
            postId={AD_POST_ID}
            currentUser={currentUser}
            addDomainToImageSrc={addDomainToImageSrc}
            link1={{
              text: t('orderLabel'),
              href: 'https://market.yandex.ru/product--kartiloks-por/1738665797?cpa=1&clid=617&utm_source=market_partner&utm_campaign=pharma_drreddys&utm_term=cartilox&utm_content= s10_banner&uniqueId=880796&erid=2VtzqxYzknd',
            }}
            link2={{
              text: t('readMore'),
              href: 'https://cartilox.ru/?utm_source=s10site&utm_medium=sitemain&utm_campaign=bloggers&utm_content=banner&utm_term=s10run&erid=2VtzqxYzknd',
            }}
          />
        );
      }
    }

    return (
      <>
        {bannerEl}
        {TestUids.check(TestMode.PAID_WITH_TRAINER_AND_STRO, currentUser?.id) && (
          <FormGroup sx={{ m: '1.5rem' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={cookiesData.islogin}
                  onClick={() => handleSwitchChange('islogin')}
                />
              }
              label="islogin"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={cookiesData.istelegram}
                  onClick={() => handleSwitchChange('istelegram')}
                />
              }
              label="istelegram"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={cookiesData.isprofileok}
                  onClick={() => handleSwitchChange('isprofileok')}
                />
              }
              label="isprofileok"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={cookiesData.isresults1ok}
                  onClick={() => handleSwitchChange('isresults1ok')}
                />
              }
              label="isresults1ok"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={cookiesData.iscompetitionok}
                  onClick={() => handleSwitchChange('iscompetitionok')}
                />
              }
              label="iscompetitionok"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={cookiesData.ispayed}
                  onClick={() => handleSwitchChange('ispayed')}
                />
              }
              label="ispayed"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={cookiesData.istrained}
                  onClick={() => handleSwitchChange('istrained')}
                />
              }
              label="istrained"
            />
            <Button
              variant="contained"
              onClick={() => setIsOpen(true)}
              sx={{ mt: '1rem' }}
            >
              Start dialog
            </Button>
          </FormGroup>
        )}
      </>
    );
  },
);

const checkCookie = (key: string) => {
  return Cookies.get(key) === '1';
};
