import { Comment, PreviewCommentsInfo } from "/src/domain/studentTasks/StudentTasksData";
import { ActivityNotificationType } from "/src/domain/notifications/NotificationsData";
import { links } from "/src/domain/shared/links";
import axios from "axios";
import { convertObjectToFormData } from "/src/domain/shared/convertObjectToFormData";
import { pickBy as _pickBy, identity as _identity } from "lodash";

interface AddCommentProps {
    entityId: string, 
    text: string, 
    parentCommentId?: number;
    replyToId?: string;
    type: ActivityNotificationType;
}

export const addComment = async (props: AddCommentProps): Promise<Comment | null> => {
    try {

        const params = {
            action: 'comment_add',
            id: props.entityId,
            parentCommentId: props.parentCommentId,
            replyToId: props.replyToId,
            text: props.text,
            idtyp: props.type
        }

        const pureParams = _pickBy(params, _identity)

        const response = await axios.post(links.origin + `/apiweb/social.php`, convertObjectToFormData(pureParams))

        return response.data.newcomment;
    } catch (e) {
        console.log("addComment:", e); 
        return null;
    }
}

export const deleteComment = async (entityId: string, commentId: number, type: ActivityNotificationType): Promise<boolean> => {
    try {
        const response = await axios.post(links.origin + `/apiweb/social.php`, convertObjectToFormData({
            action: 'comment_del',
            id: entityId,
            commentid: commentId,
            idtyp: type
        }));

        return response.data.deleted === "ok";
    } catch (e) {
        console.log("deleteComment:", e); 
        return false;
    }
}


export const getListOfComments = async (entityId: string, type: ActivityNotificationType): Promise<Comment[]> => {
    try {
        const response = await axios.get(links.origin + `/apiweb/social.php`, {
            params: {
                action: "comment_list",
                id: entityId,
                idtyp: type
            }
        })

        return response.data.list;
    } catch (e) {
        console.log("getListOfComments:", e);
        return [];
    }
}


export const getCommentsPreview = async (entityId: string, type: ActivityNotificationType): Promise<PreviewCommentsInfo | null> => {
    try {
        const response = await axios.get(links.origin + `/apiweb/social.php`, {
            params: {
                action: "comment_short",
                id: entityId,
                idtyp: type
            }
        })

        return response.data;
    } catch (e) {
        console.log("getCommentsPreview:", e);
        return null
    }
}
