import { StudentBaseInfo } from "/src/domain/shared/StudentBaseInfo";
import { links } from "/src/domain/shared/links";
import axios from "axios";

export const userSearch = async (name?: string): Promise<StudentBaseInfo[]> => {
    try {
        const response = await axios.get(links.origin + "/apiweb/social.php", {
            params: {
                action: "search_user",
                q: name
            }
        })

        return response.data.list;
    } catch (e) {
        console.log("userSearch:", e);
        return [];
    }
}