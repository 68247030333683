import { StudentBaseInfo } from "/src/domain/shared/StudentBaseInfo";

export enum NotificationType {
    LIKE_ACTIVITY = "likeActivity",
    ACTIVITY_COMMENT = "activityComment",
    REPLY_TO_ACTIVITY_COMMENT = "replyToActivityComment",
    MENTIONED_IN_ACTIVITY_COMMENT = "mentionedInActivityComment",
    COMMENT_ON_FOLLOWED_ACTIVITY = "commentOnFollowedActivity",
    STARTED_FOLLOWING = "startedFollowing"
}

export interface Notif<NotificationType> {
    type: NotificationType;
    studentInfo: StudentBaseInfo;
    date: string;
}

export interface ActivityNotif<NotificationType> extends Notif<NotificationType> {
    activityId: string;
    etyp?: ActivityNotificationType;
    eid?: string;
}

export interface ActivityLikeNotif extends ActivityNotif<NotificationType.LIKE_ACTIVITY> {
}

interface ExtensForActivityCommentNotif<NotificationType> extends  ActivityNotif<NotificationType> {
    replyToStudent?: StudentBaseInfo;
    text?: string;
}

export interface ActivityCommentNotif extends ExtensForActivityCommentNotif<NotificationType.ACTIVITY_COMMENT> {
}

export interface ReplyToActivityCommentNotif extends ExtensForActivityCommentNotif<NotificationType.REPLY_TO_ACTIVITY_COMMENT> {
    replyToStudent: StudentBaseInfo;
}

export interface CommentOnFollowedActivityNotif extends ExtensForActivityCommentNotif<NotificationType.COMMENT_ON_FOLLOWED_ACTIVITY> {
}

export interface MentionedInActivityCommentNotif extends ExtensForActivityCommentNotif<NotificationType.MENTIONED_IN_ACTIVITY_COMMENT> {
}

export type FollowNotif = Notif<NotificationType.STARTED_FOLLOWING>

export type NotificationItemType = ActivityLikeNotif | ActivityCommentNotif 
    | ReplyToActivityCommentNotif | MentionedInActivityCommentNotif | CommentOnFollowedActivityNotif
    | FollowNotif;

export enum ActivityNotificationType {
    ACTIVITY = "activity",
    BLOG = "blog"
}