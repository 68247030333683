export class Theme {
    // colors
    primaryColor: string = "#004AAD";
    primaryColor70: string = "#5d84b0";
    lightPrimaryColor: string = "#f4f4ff";
    primarySurfaceColor: string = "#E9E9FF";
    onPrimaryColor: string = "#AFAFE1";
    primaryCurrentUserColor: string = "#DEE7FF";
    primaryDarkColor: string = '#034186';

    secondaryColor: string = "#FF6259";
    secondaryColor70: string = "#ff908a";
    lightSecondaryColor: string = "#FFDFDD";
    onSecondaryColor: string = "#FFBFBB";

    greyColor: string = "#EBEBEB";
    darkGreyColor: string = "#3F3F3F";
    charcoalGreyColor: string = "#252525";
    greyTextColor: string = "#AAAAAA";
    greyBorderColor: string = "#F2F2F2";

    trackInProtocol: string = "#D9FFDD";
    
    greenColor: string = "#46794B";
    lightGreenColor: string = "#64C504";
    greenSurfaceColor: string = "#D9FFDD";

    purpleColor: string = "#7534FF";
    lightPurpleColor: string = "#ccccff";
    orangeColor: string = "#FF7A00";
    blueColor: string = "#004F94";
    creamColor: string = "#FFFCE0";
    brightYellow: string = "#FFF175";

    redErrorColor: string = "#f44336";

    darkColor: string = "#2B3642";
    icyWhiteColor: string = "#FAFAFF"
    // defaults
    WHITE_COLOR: string = "#FFFFFF";
}

export const theme = new Theme();