import { PageData } from "/src/domain/shared/Page";
import { StudentBaseInfo } from "/src/domain/shared/StudentBaseInfo";

export interface ChartsData extends PageData {
    studentInfo: StudentInfo;
}

export interface StudentInfo extends StudentBaseInfo {
    dashboardChart?: ChartAnchor;
}

export enum ChartAnchor {
    NONE = '',
    CHART_NEAR_PACE_7 = 'chart4nearpace7',
    CHART_NEAR_PACE_30 = 'chart4nearpace30',
    CHART_AVG_PACE = 'chart4avgpace',
    CHART_COST = 'chart4cost',
    CHART_COST_HIST = 'chart4costhist',
    CHART_CADENCE = 'chart4cadence',
    CHART_PER_WEEK = 'chart4perweek',
    CHART_PER_MONTH = 'chart4permonth',
    CHART_FLB = 'chart4flb',
    CHART_WORDS = 'chart4words'
}

export enum ChartGroupAnchor {
    SPEED_1 = 'speed1',
    SPEED = 'speed',
    COST = 'cost',
    CADENCE = 'cadence',
    PER_WEEK = 'kmperweek',
    PER_MONTH = 'kmpermonth',
    FLB = 'flb',
    WORDS = 'words'
}

export type ChartEntryData = {
    [key in ChartAnchor]?: string;
}

export const chartEntryData: ChartEntryData = {
    [ChartAnchor.CHART_NEAR_PACE_7]: 'distanceAtCombatPace',
    [ChartAnchor.CHART_AVG_PACE]: 'velocityVsVolume',
    [ChartAnchor.CHART_COST]: 'pulseCostVersusPace',
    [ChartAnchor.CHART_COST_HIST]: 'changeMinPulseCost',
    [ChartAnchor.CHART_CADENCE]: 'cadence',
    [ChartAnchor.CHART_PER_WEEK]: 'kmPerWeek',
    [ChartAnchor.CHART_PER_MONTH]: 'kmPerMonth',
    [ChartAnchor.CHART_FLB]: 'loadingShapeBalance',
    [ChartAnchor.CHART_WORDS]: 'wordCloudDescription',
}

