import React, { FunctionComponent } from "react";
import cs from "classnames";
import { makeStyles } from "@mui/styles";

type FacebookIconProps = {
    className?: string,
};

export const FacebookIcon: FunctionComponent<FacebookIconProps> = ({className}) => {
    const classes = useStyles();

    return (
        <svg className={cs(classes.icon, className)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 0.5C18.3572 0.5 23.5 5.64281 23.5 12C23.5 18.3572 18.3572 23.5 12 23.5C5.64281 23.5 0.5 18.3572 0.5 12C0.5 5.64281 5.64281 0.5 12 0.5Z"
                stroke="white"/>
            <path
                d="M15 6.08136V7.97288H13.9145C13.0658 7.97288 12.8882 8.4 12.8882 9.01017V10.3729H14.9211L14.6645 12.4881H12.8882V18H10.7763V12.4881H9V10.3729H10.7763V8.80678C10.7763 6.99661 11.8421 6 13.4211 6C14.1711 6 14.8224 6.06102 15 6.08136Z"
                fill="white"/>
        </svg>
    );
}

const useStyles = makeStyles({
    icon: {
        width: "1.5rem",
        height: "1.5rem",
    }
});

