import React, { FunctionComponent } from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CrownIcon, CrownIconSize } from "/src/ui/shared/CrownIcon";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import cs from "classnames";
import { links } from "/src/domain/shared/links";
import { useTranslation } from "react-i18next";


interface UpgradeToPremiumBannerProps {
    className?: string;
    onClick?: () => void; 
}

export const PremiumFeaturesBanner: FunctionComponent<UpgradeToPremiumBannerProps> = ({
    className,
    onClick
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return <ButtonBase className={cs(classes.bannerContainer, className)} 
        href={`${links.origin}/premium`}
        onClick={onClick}
    >
        <CrownIcon size={CrownIconSize.LARGE}/>
        <Box className={classes.main}>
            <Typography gutterBottom>
                { t('becomePremium') + "." }
            </Typography>
            <Typography fontSize={".75rem"}>
                { t('premiumFeaturesAds') }
            </Typography>
        </Box>
        <ArrowForwardIosIcon/>
    </ButtonBase>
}

const useStyles = makeStyles(muiTheme => ({
    bannerContainer: {
        background: "linear-gradient(283.45deg, #4399FF 3.24%, #004AAD 64.96%)",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 2.5rem",
        color: muiTheme.palette.primary.contrastText,
        [muiTheme.breakpoints.down("sm")]: {
            padding: "0 1rem"
        }
    },
    main: {
        margin: "2.5rem 2rem",
        [muiTheme.breakpoints.down("sm")]: {
            margin: "1.5rem 1rem"
        }
    }
}))
