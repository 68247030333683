import React, {FunctionComponent} from "react";
import {Star, StarSize} from "/src/ui/shared/Star";
import {makeStyles} from "@mui/styles";
import cs from "classnames";

type RatingStarsProps = {
    value: number;
    total?: number;
    starSize?: StarSize;
    className?: string;
}

const DEFAULT_STARS_TOTAL = 10;

export const RatingStars: FunctionComponent<RatingStarsProps> = (props) => {
    const {
        value,
        total = DEFAULT_STARS_TOTAL,
        starSize = StarSize.NORMAL,
        className
    } = props;
    const classes = useStyles(props);
    const stars = new Array(total).fill(0);

    return (
        <div className={cs(classes.container, className)}>
            {stars.map((_, index) => (
                <Star className={classes.star} key={`start_${index}`} size={starSize} isActive={index < value}/>))}
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center"
    },
    star: (props: RatingStarsProps) => ({
        marginRight: props.starSize === StarSize.NORMAL ? "0.3rem" : "0.2rem",
    })
});