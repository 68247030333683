import React, { FunctionComponent, useState, useEffect } from 'react';
import { getUserPrivacy } from "/src/domain/shared/requests/getUserPrivacy";

export interface ProfilePrivacyContextProps {
    isProfilePrivate: boolean;
}


export const ProfilePrivacyContext = React.createContext<ProfilePrivacyContextProps | undefined>(undefined);

interface ProfilePrivacyProviderProps {
    userId?: string;
    isReadOnly: boolean;
}

export const ProfilePrivacyProvider: FunctionComponent<ProfilePrivacyProviderProps> = ({ 
    isReadOnly,
    userId,  
    children 
}) => {
    const [isProfilePrivate, setProfilePrivacy] = useState(true);

    const setProfileIsOpenState = async () => {
        if (!isReadOnly || !userId) {
            return setProfilePrivacy(false);
        }

        const isProfilePrivate = await getUserPrivacy(userId);
        setProfilePrivacy(isProfilePrivate);
    }

    useEffect(() => {
        setProfileIsOpenState();
    }, [])

    return (
        <ProfilePrivacyContext.Provider 
            value={{
                isProfilePrivate
            }}>{children}</ProfilePrivacyContext.Provider>
    )
}
