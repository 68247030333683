import React, { FunctionComponent, useEffect, useContext } from "react";
import { UpcomingRaceContext, UpcomingRaceContextProps } from "/src/contexts/UpcomingRaceContext";
import { UpcomingRaceView } from "/src/ui/shared/upcomingRaces/UpcomingRaceView";
import { Tabs, Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import cs from "classnames";
import { useTranslation } from "react-i18next";


type UpcomingRacesViewProps = {
    startReg?: number,
    organization?: string;
    withHeader?: boolean,
    className?: string;
};

const SCROLL_BUTTON_COLLOR = "rgba(255, 255, 255, 0.8)"

export const UpcomingRacesView: FunctionComponent<UpcomingRacesViewProps> = ({ 
    startReg, 
    organization,
    withHeader = true,
    className
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { getUpcomingRaces, racesData } = useContext(UpcomingRaceContext) as UpcomingRaceContextProps;

    useEffect(() => {
        getUpcomingRaces(startReg, organization)
    }, [])

    if (!racesData?.length) return null;

    return (
        <div className={cs(classes.container, className)}>
            {withHeader && <h3 className={classes.header}>
                {t('upcomingRaces')}
            </h3>}
            <Tabs 
                variant="scrollable" 
                allowScrollButtonsMobile
                visibleScrollbar
                value={false}
                className={classes.racesContainer}
            >
                {racesData.map((r, i) => (<Box className={classes.raceContainer} key={i}>
                    <UpcomingRaceView raceData={r} />
                </Box>))}
            </Tabs>
        </div>
    );
};

const useStyles = makeStyles((muiTheme) => ({
    container: {
        marginTop: "1rem",
    },
    racesContainer: {
        position: "relative",
        "& .MuiTabScrollButton-root": {
            opacity: 1,
            width: "3rem",
            height: "3rem",
            position: "absolute",
            zIndex: 5,
            top: "45%",
            borderRadius: "50%",
            "&:first-child": {
                background: SCROLL_BUTTON_COLLOR,
                left: 0,
            },
            "&:last-child": {
                background: SCROLL_BUTTON_COLLOR,
                right: 0
            }
        },
        "& .MuiSvgIcon-root": {
            fontSize: "2.5rem",
            color: muiTheme.palette.primary.main
        },
        "& .MuiTabs-scroller": {
            whiteSpace: "initial",
            paddingBottom: "1rem"
        },
        "& .MuiTabs-scrollButtons.Mui-disabled": {
            opacity: 0.2
        }
    },
    raceContainer: {
        display: "flex",
        paddingRight: "1.5rem",
        "&:first-child": {
            marginLeft: "1.5rem"
        }
    },
    header: {
        fontWeight: 500,
        fontSize: "1.25rem",
        display: "flex",
        alignItems: "flex-start",
        marginBottom: "1rem",
        marginLeft: "1.5rem"
    }
}));