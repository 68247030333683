import React, { FunctionComponent } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Advizer } from './icons/Advizer';
import { useTranslation } from 'react-i18next';

interface BannerHeadProps {
  onClose: () => void;
}

export const BannerHead: FunctionComponent<BannerHeadProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        m: '1.5rem 1.5rem 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Advizer sx={{ width: '2rem', height: '2rem' }} />
      <IconButton onClick={onClose}>
        <CloseIcon sx={{ fontSize: '2rem', opacity: 0.6 }} />
      </IconButton>
    </Box>
  );
};
