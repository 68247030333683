import React, { FunctionComponent } from "react";
import { DialogContent } from "@mui/material";
import { FeedbackDialog } from "/src/ui/shared/FeedbackDialog";
import { DialogListWrapper } from "/src/ui/studentTasks/studentTasks/actions/DialogListWrapper";

interface ReportDialogProps {
    open: boolean;
    onClose: () => void;
    isPremiumReport: boolean;
}

export const ReportDialog: FunctionComponent<ReportDialogProps> = ({ 
    open,
    onClose,
    isPremiumReport,
    children
}) => {
    return <>
        {isPremiumReport ? <DialogListWrapper 
            open={open} 
            onClose={onClose} 
            disableEnforceFocus
            PaperProps={{
                sx: { height: "100%" }
            }}
        >
            <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
                { children }
            </DialogContent>
        </DialogListWrapper>
        : <FeedbackDialog
            isOpen={open} 
            hide={onClose}
        >
            { children }
        </FeedbackDialog>}
    </>
}
