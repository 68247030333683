import React, { FunctionComponent } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { ResultCardProps, BoardType } from "./ResultCard"
import { Avatar } from "./Avatar";
import { Logo } from "./Logo";
import {ChallengeProtocolPedestal} from "/src/ui/shared/challengeProtocol/ChallengeProtocolPedestal";

type PedestalProps = {
    board: ResultCardProps
};

export const Pedestal: FunctionComponent<PedestalProps> = ({board}) => {
    const classes = useStyles();

    return (
        <div className={classes.pedestal}>
            <div className={classes.firstPlace}>
                {board.type === BoardType.SINGLE && <Avatar url={board.records[0]?.imgUrl}/>}
                {board.type === BoardType.CLUB && <Logo url={board.records[0]?.imgUrl}/>}
            </div>
            <div className={classes.secondPlace}>
                {board.type === BoardType.SINGLE && <Avatar url={board.records[1]?.imgUrl}/>}
                {board.type === BoardType.CLUB && <Logo url={board.records[1]?.imgUrl}/>}
            </div>
            <div className={classes.thirdPlace}>
                {board.type === BoardType.SINGLE && <Avatar url={board.records[2]?.imgUrl}/>}
                {board.type === BoardType.CLUB && <Logo url={board.records[2]?.imgUrl}/>}
            </div>
            <ChallengeProtocolPedestal/>
        </div>
    );
}

const useStyles = makeStyles({
    pedestal: {
        position: 'relative',
        height: '13.5rem',
        bottom: '-1.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        background: `no-repeat url(${require('../../../assets/icons/challengeProtocolStars.svg')})`,
        backgroundPosition: 'cneter',
        backgroundSize: 'cover'
    },
    firstPlace: {
        position: 'absolute',
        bottom: '7.5rem'
    },
    secondPlace: {
        position: 'absolute',
        bottom: '6rem',
        transform: 'translate(-100%, 0)'
    },
    thirdPlace: {
        position: 'absolute',
        bottom: '4.5rem',
        transform: 'translate(95%, 0)'
    }
});