import React, { FunctionComponent } from "react";
import { Lang } from "/src/domain/shared/User";
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import "./editor-skin.css";
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.css';
import { useTranslation } from "react-i18next";
import { links } from "/src/domain/shared/links";
import axios from "axios";
import * as DOMPurify from "dompurify";
import { htmlFontSize } from "/src/ui/shared/HtmlStyleContainer";

interface RichTextEditorProps {
    imageSaveId: string;
    initialValue: string;
    editorRef: React.MutableRefObject<TinyMCEEditor | null>;
    type: EditorMode;
}

export enum EditorMode {
    POST,
    REPORT
}

export const RichTextEditor: FunctionComponent<RichTextEditorProps> = ({
    imageSaveId,
    initialValue,
    editorRef,
    type
}) => {
    const { t, i18n } = useTranslation();
    const tinyLang = i18n.language === Lang.RU || i18n.language === Lang.UK ? Lang.RU : undefined;

    return <Editor
            apiKey={String(process.env.TINYMCE_API_KEY)}
            onInit={(evt, editor) => {
                editorRef.current = editor;
            }}
            initialValue={initialValue}
            init={{
                skin: false,
                content_style: contentCss.toString() + '\n' + contentUiCss.toString() + '\n' + styles,
                plugins: 'quickbars autolink link image imagetools lists code table codesample media',
                file_picker_types: 'image',
                images_file_types: 'jpg,jpeg,png',
                quickbars_insert_toolbar: false,
                quickbars_selection_toolbar: "bold italic link forecolor backcolor blockquote",
                images_upload_handler: async (blobInfo: BlobInfo, progress: (percent: number) => void) => {
                    const result = await image_upload_handler(type, blobInfo, progress, imageSaveId);
                    if (!result) {
                        alert(t('fileNotSupported'));
                        return "";
                    }

                    return result;
                },
                image_dimensions: false,
                object_resizing: false,
                media_dimensions: false,
                iframe_template_callback: (data: any) =>
                    `<iframe title="${data.title}" style="width: 100%; height: auto; aspect-ratio: 16/9; border: none;" src="${data.source}"></iframe>`,
                
                // setup: function (editor) {
                //     editor.on('init', function(args) {
                //         editor = args.target;
                //         editor.on('NodeChange', function(e) {
                //             if (e && e.element.nodeName.toLowerCase() == 'img') {
                //                 let width = (e.element as HTMLImageElement).width;
                //                 let height = (e.element as HTMLImageElement).height;
                //                 if (width > MAX_IMAGE_WIDTH) {
                //                     height = height / (width / MAX_IMAGE_WIDTH);
                //                     width = MAX_IMAGE_WIDTH;
                //                 }
                //                 editor.dom.setAttribs(e.element, {'width': width, 'height': height});
                //             }
                //         });
                //     });
                // },
                paste_data_images: false,
                menubar: false,
                toolbar: 'h1 h2 bold italic strikethrough image media link forecolor backcolor align bullist numlist',
                height: "100%",
                language: tinyLang,
                // for develop
                // document_base_url: links.origin + '/',
                relative_urls: false,
                paste_preprocess: function(plugin, args) {
                    args.content = DOMPurify.sanitize(args.content, {
                        FORBID_TAGS: ['div', 'h3', 'h4', 'h5', 'h6'],
                        FORBID_ATTR: ['class', 'id'],
                        ALLOWED_URI_REGEXP: /^(https?):\/\/[^\s/$.?#].[^\s]*$/i,
                    });
                },
            }}
        />
}

const styles = `
    img {max-width: 100%; height: auto;}
    h1 {font-size: ${htmlFontSize.h1};}
    h2 {font-size: ${htmlFontSize.h2};}
`

interface BlobInfo {
    id: () => string;
    name: () => string;
    filename: () => string;
    blob: () => Blob;
    base64: () => string;
    blobUri: () => string;
    uri: () => string | undefined;
}



async function image_upload_handler (type: EditorMode, blobInfo: BlobInfo, progress: (percent: number) => void, id: string) {
    try {
        const formData = new FormData();
        formData.append("logo", blobInfo.blob());

        const requestType = type === EditorMode.POST ? "post" : undefined;
        const response = await axios.post(`${links.origin}/tinyfileadd.php?d=${id}`, formData, {
            params: {
                d: id,
                t: requestType
            }
        });

        return response.data.location;
    } catch (e) {
        return false;
    }

}