import React, { FunctionComponent } from 'react';
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomDialog } from './CustomDialog';
import { BannerHead } from './BannerHead';
import { CustomDialogTitle } from './CustomDialogTitle';

interface BannerTemplateProps {
  open: boolean;
  onClose: () => void;
  title: string;
  text: string;
  link: {
    content: string | JSX.Element;
    href: string;
  };
}

export const BannerTemplate: FunctionComponent<BannerTemplateProps> = ({
  open,
  onClose,
  title,
  text,
  link,
}) => {
  const { t } = useTranslation();

  return (
    <CustomDialog open={open} onClose={onClose}>
      <BannerHead onClose={onClose} />
      <CustomDialogTitle>{title}</CustomDialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          p: '2.25rem 1.5rem 1.75rem',
        }}
      >
        <Button
          size="large"
          fullWidth
          sx={{
            textTransform: 'none',
          }}
          variant="contained"
          href={link.href}
        >
          {link.content}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};
