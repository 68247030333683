import React, { FunctionComponent, useContext, useState, useEffect } from "react";
import { DashboardContext, DashboardContextProps } from "/src/contexts/DashboardContext"
import { User } from "/src/domain/shared/User";
import { TaskRoute } from "/src/domain/studentTasks/StudentTasksData";
import { StudentInfo, FeedData, TrainerBaseInfo } from "/src/domain/dashboard/DashboardData";
import { TaskView } from "/src/ui/studentTasks/studentTasks/TaskView";
import { PostView } from "/src/ui/studentTasks/studentTasks/posts/PostView";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TestMode, TestUids } from "/src/domain/shared/checkUserForTestee";

interface TasksProps {
    studentInfo?: StudentInfo,
    trainerInfo?: TrainerBaseInfo,
    tasks: FeedData;
    trainerId?: string;
    currentUser?: User;
    className?: string;
    isReadOnly: boolean;
    isFeed?: boolean;
}

const NUMBER_OF_PREVIEW_TASKS = 5;

export const Tasks: FunctionComponent<TasksProps> = ({
    studentInfo, 
    trainerInfo,
    tasks,
    trainerId,
    currentUser,
    className,
    isReadOnly,
    isFeed,
}) => {
    const { t } = useTranslation();
    const [isDisplayAllFeed, setDisplayAllFeed] = useState(true);

    const postEndObserver = React.useRef<IntersectionObserver | null>(null);

    const { 
        sendTaskReport, 
        currentDate,
        getTags,
        sendViewedPost,
    } = useContext(DashboardContext) as DashboardContextProps;

    useEffect(() => {
        setDisplayAllFeed(tasks.length <= NUMBER_OF_PREVIEW_TASKS);
    }, [tasks])

    useEffect(() => {
        if (!isFeed) return;

        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    const id = (entry.target as HTMLElement).dataset.id;
    
                    if (id === undefined) {
                        return;
                    }

                    if (entry.isIntersecting) {
                        const isNotSent = !sentViewedPosts.includes(id);
                        isNotSent && sendViewedPost(id);
                        isNotSent && sentViewedPosts.push(id);
                    }
                });
            }
        );
        postEndObserver.current = observer;
    }, [])

    const feed = isDisplayAllFeed ? tasks : tasks.slice(0, NUMBER_OF_PREVIEW_TASKS);

    const showMoreButtonHandler = () => {
        if (studentInfo || trainerInfo) {
            return setDisplayAllFeed(!isDisplayAllFeed);
        }

        window.location.href = '/login';
    }

    return <Box className={className}>
        {feed.map((obj) => {
            return <React.Fragment 
                key={obj.type === "post" ? obj.postid 
                    : obj.id + String(obj.studentInfo?.id) + String(obj.tracks?.[0].id)}
            >
                { TestUids.check(TestMode.STRO, currentUser?.id) && obj.category && <Box 
                    sx={{ 
                        textAlign: "center",
                        fontSize: "1.25rem"
                    }}
                >
                    { obj.category }
                </Box> }
                {obj.type === "post" ? <>{
                    isFeed && TestUids.customCheck([TestUids.nataSBDClubAdmin, TestUids.clubSBDManager], obj.userid) ? 
                    null
                    : <>
                        <PostView
                            {...obj}
                            currentDate={currentDate}
                            isReadOnly={isReadOnly}
                            isPreview
                            currentUserId={currentUser?.id}
                            isRepostAvailable={Boolean(trainerInfo && trainerInfo?.id === currentUser?.id)}
                            isRepostedPost={false}
                        />
                        {isFeed && <PostEndDiv
                            postId={obj.postid}
                            observer={postEndObserver.current}
                        />}
                    </>
                }</>
                : <TaskView
                    task={obj}
                    studentInfo={studentInfo}
                    readOnly={isReadOnly}
                    isTribunalUser={false}
                    currentDate={currentDate}
                    trainerId={trainerId}
                    currentUser={currentUser}
                    sendTaskReport={sendTaskReport}
                    getTags={getTags}
                />}
            </React.Fragment>
        })}
        {!isDisplayAllFeed && <Button onClick={showMoreButtonHandler} fullWidth variant="outlined" sx={{ mt: "0.5rem" }}>
                { t('showMore') }
        </Button>}
    </Box>
}

interface PostEndDivProps {
    observer: IntersectionObserver | null;
    postId: string;
}

const PostEndDiv: FunctionComponent<PostEndDivProps> = ({
    observer,
    postId,
}) => {
    const [ref, setRef] = React.useState<HTMLElement | null>(null);

    React.useEffect(() => {
        if (ref) {
            observer?.observe(ref);
        }
        return () => {
            if (ref) {
                observer?.unobserve(ref);
            }
        };
    }, [observer, ref]);

    return <Box data-id={postId} ref={setRef} />
}

const sentViewedPosts: string[] = [];
