import React, { FunctionComponent } from "react";
import { makeStyles } from "@mui/styles";
import cs from 'classnames';

type TeamLogoProps = {
    teamLogoUrl: string,
    size?: TeamLogoSize,
    className?: string,
}

export enum TeamLogoSize {
    SMALL = "small",
    NORMAL = "normal",
}

export const TeamLogo: FunctionComponent<TeamLogoProps> = ({teamLogoUrl, size = TeamLogoSize.NORMAL, className}) => {
    const classes = useStyles({teamLogoUrl, size});

    return <div role="img" className={cs(classes.teamLogo, className)}/>;
};

const useStyles = makeStyles({
    teamLogo: (props: TeamLogoProps) => ({
        width: props.size === TeamLogoSize.NORMAL ? '6rem' : '3rem',
        height: '100%',
        maxHeight: props.size === TeamLogoSize.NORMAL ? '2.25rem' : '1.5rem',
        backgroundImage: 'url(' + props.teamLogoUrl + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundSize: 'contain',
    })
});