import React, { FunctionComponent } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { HeaderProps } from "/src/ui/shared/header/HeaderProps";
import { MobileHeader } from "/src/ui/shared/header/MobileHeader";
import { DesktopHeader } from "/src/ui/shared/header/DesktopHeader";


export const Header: FunctionComponent<HeaderProps> = ({currentUser, teamLogo}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    return matches
        ? <MobileHeader currentUser={currentUser} teamLogo={teamLogo}/>
        : <DesktopHeader currentUser={currentUser} teamLogo={teamLogo}/>;
};