import { SubscriptionItem, SubscriptionValue } from "/src/domain/subscriptionData/SubscriptionData";
import { StudentBaseInfo } from "/src/domain/shared/StudentBaseInfo";
import { links } from "/src/domain/shared/links";
import axios from "axios";

export const getListOfSubscriptions = async (id?: string): Promise<SubscriptionItem[]> => {
    try {
        const response = await axios.get(links.origin + "/apiweb/social.php?action=subscribe_list", {
            params: {
                id
            }
        });
        
        if (!response.data.subscribed) {
            return [];
        }
        return response.data.subscribed;
    } catch (e) {
        console.log("getListOfSubscriptions:", e);
        return [];
    }
}

export const getListOfSubscribers = async (id?: string): Promise<SubscriptionItem[]> => {
    try {
        const response = await axios.get(links.origin + "/apiweb/social.php?action=subscribe_follow", {
            params: {
                id
            }
        });
        
        if (!response.data.follow) {
            return [];
        }
        return response.data.follow;
    } catch (e) {
        console.log("getListOfSubscribers:", e);
        return [];
    }
}

export const getSubscribe = async (id: string): Promise<SubscriptionValue> => {
    try {
        const response = await axios.get(links.origin + `/apiweb/social.php?action=subscribe_check&id=${id}`);
        
        return response.data.isSubscribed;
    } catch (e) {
        console.log("getSubscribe:", e);
        return false;
    }
}

export const addSubscribe = async (id: string): Promise<SubscriptionValue> => {
    try {
        const response = await axios.post(links.origin + `/apiweb/social.php?action=subscribe_add&id=${id}`);
        
        return response.data.isSubscribed
    } catch (e) {
        console.log("addSubscribe:", e);
        return false;
    }
}

export const deleteSubscribe = async (id: string): Promise<SubscriptionValue> => {
    try {
        const response = await axios.post(links.origin + `/apiweb/social.php?action=subscribe_delete&id=${id}`);
        
        return response.data.isSubscribed
    } catch (e) {
        console.log("deleteSubscribe:", e);
        return false;
    }
}

export const getStudentInfo = async (id: string): Promise<StudentBaseInfo | null> => {
    try {
        const response = await axios.post(links.origin + `/apiweb/social.php?action=studentInfo&id=${id}`);

        return response.data.studentInfo;
    } catch (e) {
        console.log("getStudentInfo:", e);
        return null;
    }
}