export const convertObjectToFormData = (object: Object): FormData => {
    const formData = new FormData();
    (Object.keys(object) as Array<keyof typeof object>).forEach((key) => {
        formData.append(key, String(object[key]));
    })
    return formData;
}

export const postParameters = {
    withCredentials: true,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
}