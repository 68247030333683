import axios from "axios";
import { links } from "/src/domain/shared/links";

export const getTags = async (studentId?: string, tag?: string): Promise<string[]> => {
    const tagParameter = tag || null;

    try {
        const response = await axios.get(`${links.origin}/tags_api`, {
            params: {
                id: studentId,
                tag: tagParameter
            }
        });
    
        const tags: string[] = response.data;
        return tags;
    } catch (e) {
        console.log("getTags: ", e);
        return [];
    }
}