import React, { Children, FunctionComponent } from "react";
import { Dialog, PaperProps, AppBar, Toolbar, IconButton, 
    useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

interface DialogListWrapperProps {
    open: boolean;
    onClose: () => void;
    fullWidth?: boolean;
    disableEnforceFocus?: boolean;
    PaperProps?: Partial<PaperProps<"div", {}>> | undefined;
}

export const DialogListWrapper: FunctionComponent<DialogListWrapperProps> = ({ 
    open,
    onClose,
    fullWidth,
    disableEnforceFocus,
    PaperProps,
    children
}) => {
    const { t } = useTranslation();

    const downSmMediaQuery = useMediaQuery(useTheme().breakpoints.down("sm"));

    return <Dialog
        fullScreen={downSmMediaQuery}
        fullWidth={fullWidth}
        disableEnforceFocus={disableEnforceFocus}
        open={open} 
        onClose={onClose} 
        sx={{ zIndex: 1100 }}
        PaperProps={PaperProps}
        scroll="paper"
    >
        <AppBar sx={{ position: "relative" }}>
            <Toolbar sx={{
                display: "flex",
                justifyContent: "flex-end"
            }}>
                <IconButton onClick={onClose}>
                    <CloseIcon sx={{ color: "primary.contrastText" }}/>
                </IconButton>
            </Toolbar>
        </AppBar>
        { children }
    </Dialog>

}
