import React, {FunctionComponent, useMemo, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import Carousel from 'react-material-ui-carousel';

import {ResultCard, ResultCardProps, BoardType} from "./ResultCard";
import {Pedestal} from "./Pedestal";
import {theme} from "/src/assets/theme";

export interface LeaderBoardProps {
    boards: ResultCardProps[];
}

export const LeaderBoard: FunctionComponent<LeaderBoardProps> = ({
    boards
}) => {
    const classes = useStyles()
    const [activeBoardIndex, setActiveBoardIndex] = useState(0);

    const activeBoard = boards[activeBoardIndex];

    if (!boards.length) return null;
    
    return (
        <>
            {activeBoard.type !== BoardType.TEAM && 
                <Pedestal board={activeBoard}/>}
            <Carousel
                animation="slide"
                timeout={500}
                interval={5000}
                autoPlay={true}
                indicators={boards.length !== 1}
                navButtonsAlwaysInvisible={boards.length === 1}
                className={classes.carousel}
                onChange={setActiveBoardIndex}
                indicatorContainerProps={{
                    style: {
                        margin: 0,
                        paddingTop: '0.5rem',
                    }
                }}
                indicatorIconButtonProps={{
                    style: {
                        backgroundColor: theme.primaryColor,
                        color: theme.WHITE_COLOR,
                        margin: '0 0.2rem',
                        transform: 'scale(0.5)'
                    }
                }}
                activeIndicatorIconButtonProps={{
                    style: {
                        color: theme.primaryColor
                    }
                }}
            >
                {
                    boards.map(board => (
                        <ResultCard
                            key={board.title}
                            {...board}
                        />))
                }
            </Carousel>
        </>
    )
}

  

const useStyles = makeStyles(theme => ({
    carousel: {
        marginBottom: '1rem',
        margin: '0 auto',
        maxWidth: '30rem',
        [theme.breakpoints.down('sm')]: {
            background: `no-repeat url(${require('../../../assets/icons/challengeProtocolAbstractLeaderBoard.svg')})`,
            backgroundSize: 'cover',
        }
    }
}));