import { some as _some } from "lodash";

export enum TestMode {
    STRO,
    STRO_TRAINER,
    DUTY_TRAINER,
    NATA,
    PAID_WITH_TRAINER,
    PAID,
    TRAINER,
    PAID_WITH_TRAINER_AND_STRO,
    TOTAL_DEV,
    TOTAL_DEV_WITH_STRO
}

export class TestUids {
    static readonly stro = "7439904";
    static readonly stroTrainer = "1000004669";
    static readonly dutyTrainer = "15765419"
    static readonly nata = "69208447";
    static readonly paidWithTrainer = "95101180";
    static readonly paid = "1000000243";
    static readonly trainer = "1000004464";
    static readonly antoninaVitalievna = "1000007890";
    static readonly veraDervoedova = "1000009676";
    static readonly andreiCebanenco = "1000006305";
    static readonly evgenyDenisovCoach = "1000009665";
    static readonly evgenyDenisov = "1000006801";
    static readonly dmitryPoznyshevCoach = "1000006345";
    static readonly vladimirGrezovCoach = "20324385";
    static readonly vladimirGrezov = "1000002041";
    static readonly alexeySidorenkoCoach = "1000006322";
    static readonly nataSBDClubAdmin = "89744810";
    static readonly nikitaVolodarskiy = "1000005438";
    static readonly clubSBDManager = "1000011110";
    static readonly ilyaTokachirov = "1000011882";
    static readonly paidWithTrainerAndStro = [TestUids.paidWithTrainer, TestUids.stro];
    static readonly totalDev = [TestUids.paidWithTrainer, TestUids.paid, TestUids.trainer];
    static readonly totalDevWithStro = [...TestUids.totalDev, TestUids.stro];

    static check(mode: TestMode, userId?: string) {
        switch (mode) {
            case TestMode.STRO:
                return userId == TestUids.stro;
            case TestMode.STRO_TRAINER:
                return userId == TestUids.stroTrainer;
            case TestMode.DUTY_TRAINER:
                return userId == TestUids.dutyTrainer;
            case TestMode.NATA:
                return userId == TestUids.nata;
            case TestMode.PAID_WITH_TRAINER:
                return userId == TestUids.paidWithTrainer;
            case TestMode.PAID:
                return userId == TestUids.paid;
            case TestMode.TRAINER:
                return userId == TestUids.trainer;
            case TestMode.PAID_WITH_TRAINER_AND_STRO:
                return _some(TestUids.paidWithTrainerAndStro, u => u == userId);
            case TestMode.TOTAL_DEV:
                return _some(TestUids.totalDev, u => u == userId);
            case TestMode.TOTAL_DEV_WITH_STRO:
                return _some(TestUids.totalDevWithStro, u => u == userId);
            default:
                return false;
        }
    }

    static customCheck(ruleUids: string[], userId?: string) {
        return _some(ruleUids, u => u == userId);
    }
}