import React from "react"
import parse from "html-react-parser";
import { isArray as _isArray, isString as _isString } from "lodash";
export const MAX_TEXT_LENGTH = 140;

export interface HtmlData {
    isPreview: boolean;
    result: string | JSX.Element | (JSX.Element | string)[];
}

interface DraftHtmlData {
    isPreview: boolean;
    result: (JSX.Element | string)[];
    count: number;
}

export const getHtmlPreview = (value: string, lengthLimit = MAX_TEXT_LENGTH): HtmlData => {
    const parsedHtml = parse(value);
    const resultData: DraftHtmlData = {
        isPreview: false,
        result: [],
        count: 0
    }
    return truncateJSX(parsedHtml, resultData, lengthLimit);;
}

type NodeType = JSX.Element | string | (JSX.Element | string)[];

const truncateJSX = (arr: NodeType, resultData: DraftHtmlData, lengthLimit: number): HtmlData => {
    const recursiveTraversal = (node: NodeType, lengthLimit: number): NodeType => {
        if (Array.isArray(node)) {
            let truncatedNodes = [];
            for (let i = 0; i < node.length; i++) {
                if (resultData.count < lengthLimit) {
                    const child = recursiveTraversal(node[i], lengthLimit);
                    truncatedNodes.push(child);
                } else {
                    break;
                }
            }
            return truncatedNodes as NodeType;
        } else if (node && typeof node === 'object' && 'props' in node) {
            const truncatedChildren = React.Children.map(node.props.children, child =>{
                if (resultData.count < lengthLimit) {
                    return recursiveTraversal(child, lengthLimit)
                }
            });
            return React.cloneElement(node, undefined, truncatedChildren);
        } else if (_isString(node)) {
            if (resultData.count + node.length < lengthLimit) {
                resultData.count += node.length;
                return node;
            } else {
                const newNode = node.slice(0, lengthLimit - resultData.count) + '...';
                resultData.count += newNode.length;
                resultData.isPreview = true;

                return newNode;
            }
        }
        return node;
    }
    try {
        const newNode = recursiveTraversal(arr, lengthLimit);
        return {
            ...resultData,
            result: newNode
        };
    } catch (e) {
        return resultData;
    }
}
