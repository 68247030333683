import React, { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import HamburgerMenu from "react-hamburger-menu";
import { TeamLogo, TeamLogoSize } from "/src/ui/shared/TeamLogo";
import { LogoIcon } from "/src/ui/shared/header/Logo";
import { CrownIcon } from "/src/ui/shared/header/CrownIcon";
import { Notifications } from "/src/ui/shared/header/notifications/Notifications";
import { makeStyles } from "@mui/styles";
import { theme } from "/src/assets/theme";
import { HeaderProps } from "/src/ui/shared/header/HeaderProps";
import { UserRole } from "/src/domain/shared/User";
import { PageContext, PageContextProps } from "/src/contexts/Page";
import { links } from "/src/domain/shared/links";

export const MobileHeader: FunctionComponent<HeaderProps> = ({ currentUser, teamLogo }) => {
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();
    const classes = useMobileStyles({ isOpen });
    const { pageConfig } = useContext(PageContext) as PageContextProps;

    const handleClick = () => {
        setOpen(!isOpen);
    };

    return (
        <header className={classes.header}>
            <div className={classes.logoContainer}>
                {!pageConfig?.isWhiteLabel && <><HamburgerMenu
                    isOpen={isOpen}
                    menuClicked={handleClick}
                    width={24}
                    height={24}
                    strokeWidth={3}
                    rotate={0}
                    color='white'
                    borderRadius={0}
                    animationDuration={0.3}
                />
                    <a href={`${links.origin}/`} className={classes.logoLink}>
                        <LogoIcon className={classes.logo} />
                    </a></>}
                {Boolean(teamLogo?.length) && Boolean(currentUser) &&
                    <TeamLogo teamLogoUrl={teamLogo as string}
                        size={TeamLogoSize.NORMAL}
                        className={classes.teamLogo}
                    />}
                {Boolean(currentUser) && <Notifications
                        isDialog
                        className={classes.icon}
                    />}
            </div>
            {!Boolean(currentUser) &&
                <div className={classes.login}>
                    <a href={pageConfig?.authUrl || `${links.origin}/login`} className={classes.link}>{t('login')}</a>
                </div>}
            {!pageConfig?.isWhiteLabel &&
                <div className={classes.linkContainer}>
                    {Boolean(currentUser?.currentTrainerId) &&
                        <a href={`${links.origin}/profile?${currentUser?.currentTrainerId}`}
                            className={classes.link}>{t('myTrainer')}</a>}
                    {(!Boolean(currentUser?.currentTrainerId) && currentUser?.role === UserRole.STUDENT) &&
                        <a href={`${links.origin}/trainerlist`} className={classes.link}>{t('chooseTrainer')}</a>}
                    {Boolean(currentUser) && currentUser?.role === UserRole.STUDENT &&
                        <a href={`${links.origin}/student?${currentUser?.id}&v2=1`} className={classes.link}>{t('workouts')}</a>}
                    {Boolean(currentUser) && currentUser?.role === UserRole.TRAINER &&
                        <a href={`${links.origin}/students`} className={classes.link}>{t('students')}</a>}
                    {!Boolean(currentUser?.isPremium) &&
                        <a href={`${links.origin}/premium`} className={classes.link}><CrownIcon
                            className={classes.icon} />{t('premium')}</a>}
                    <div className={classes.divider} />
                    <a href={`${links.challengesOrigin}/`} className={classes.link}>{t('challenges')}</a>
                    <a href={`${links.challengesOrigin}/newrace`} className={classes.link}>{t('newChallenge')}</a>
                    <a href={`${links.challengesOrigin}/rating`} className={classes.link}>{t('rating')}</a>
                    <a href={links.answersLink} className={classes.link}>{t('faq')}</a>
                    {Boolean(currentUser) && <>
                        <div className={classes.divider} />
                        <a href={`${links.origin}/money`} className={classes.link}>{t('money')}</a>
                        <a href={`${links.origin}/profile?${currentUser?.id}`} className={classes.link}>{t('myProfile')}</a>
                        <a href={`${links.origin}/logout`} className={classes.link}>{t('logout')}</a>
                    </>}
                </div>}
        </header>
    );
}

type HeaderStyleProps = {
    isOpen: boolean
}

const useMobileStyles = makeStyles({
    header: (props: HeaderStyleProps) => ({
        backgroundColor: theme.primaryColor,
        height: props.isOpen ? "100vh" : "3rem",
        position: props.isOpen ? "fixed" : "relative",
        width: "100%",
        transition: "height 0.2s ease-out",
        zIndex: 100,
        overflowY: "hidden",
        top: 0,
    }),
    logo: {
        height: '100%',
        width: '15rem',
        flex: 1,
    },
    logoLink: {
        height: '2rem'
    },
    teamLogo: {
        height: '2rem',
        width: '15rem'
    },
    logoContainer: {
        position: "absolute",
        top: 0,
        height: "100%",
        maxHeight: "2rem",
        width: "100%",
        padding: "0.65rem 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '&>div:first-child': {
            position: "absolute!important",
            left: "1rem",
            top: "0.65rem",
        }
    },
    linkContainer: {
        position: "relative",
        top: "3rem",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column"
    },
    link: {
        color: theme.WHITE_COLOR,
        textDecoration: "none",
        "&:hover": { textDecoration: "none" },
        display: "flex",
        alignItems: "center",
        padding: "0.75rem 1.25rem",
    },
    divider: {
        height: "1px",
        width: "100%",
        background: theme.WHITE_COLOR,
        opacity: 0.5
    },
    icon: {
        marginRight: "0.5rem"
    },
    login: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: '3rem',
        display: 'flex',
        alignItems: 'center'
    }
});