import React, {FunctionComponent} from "react";
import {Grid, Link} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

export interface ResultCardRowProps {
    place?: number;
    label: string;
    value: string | number | Date; 
    imgUrl?: string;
    link?: string;
}

export const ResultCardRow: FunctionComponent<ResultCardRowProps> = ({
    place,
    label,
    value,
    link
}) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.row}>
            <Grid item xs={2} container alignItems="center">
                {place}
            </Grid>
            <Grid 
                component={Link} 
                sx={{ color: "inherit" }} 
                underline={link ? "hover" : "none"}
                href={link}
                item xs={6} 
                container 
                alignItems="center"
            >
                {label}
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
                {value}
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles({
    row: {
        padding: '0.2rem 0',
        '&>div': {
            height: '2.4rem',
            overflowY: 'auto'
        }
    }
});