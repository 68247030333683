import React, { FunctionComponent, useState, useEffect } from "react";
import { StudentInfoForLike } from "/src/domain/studentTasks/StudentTasksData";
import { ActivityNotificationType } from "/src/domain/notifications/NotificationsData";
import { List, ListItemButton, ListItemAvatar, ListItemText,
    Avatar, Typography, DialogContent } from "@mui/material";
import { DialogListWrapper } from "./DialogListWrapper";
import { theme } from "/src/assets/theme";
import { DialogProgress } from "./DialogProgress";
import { getListOfLikes } from "/src/domain/shared/requests/likeRequests";
import { trimName } from "/src/domain/shared/trimName";
import { useTranslation } from "react-i18next";

interface LikesDialogProps {
    entityId: string;
    open: boolean;
    onClose: () => void;
    type: ActivityNotificationType;
}

export const LikesDialog: FunctionComponent<LikesDialogProps> = ({
    entityId,
    open,
    onClose,
    type
}) => {
    const { t } = useTranslation();
    const [listOfLikes, setListOfLikes] = useState<StudentInfoForLike[]>([]);
    const [progress, setProgress] = useState<boolean>(false);

    const setListOfLikesState = async () => {
        setProgress(true);

        const listOfLikes = await getListOfLikes(entityId, type);
        setListOfLikes(listOfLikes);

        setProgress(false);
    }

    useEffect(() => {
        if (!open) return;

        setListOfLikesState();
    }, [open])

    return <DialogListWrapper open={open} onClose={onClose} fullWidth>
        <DialogContent sx={{ p: 0 }}>
            {progress ? <DialogProgress />
                : <List>
                    {listOfLikes.map((i) => <LikeItem key={i.id} studentInfo={i}/>)}
                </List>
            }
        </DialogContent>
    </DialogListWrapper>
}

interface LikeItemProps {
    studentInfo: StudentInfoForLike;
}

const LikeItem: FunctionComponent<LikeItemProps> = ({
    studentInfo
}) => {

    return <ListItemButton 
        component="a" 
        href={`/profile?${studentInfo?.id}`} 
        disabled={!studentInfo}
        disableRipple
        sx={{
            "&:not(:last-child):after": {
                content: "''",
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                height: "0.1rem",
                backgroundColor: theme.darkColor,
                opacity: 0.12
            }
        }}
    >
        <ListItemAvatar>
            <Avatar
                src={studentInfo?.avatarUrl}
            />
        </ListItemAvatar>
        <ListItemText 
            primary={<Typography sx={{ display: "flex", justifyContent: "space-between" }}>
                {trimName(studentInfo.firstName, studentInfo.lastName)} 
                <span> ({ studentInfo.likeCost })</span>
            </Typography>}
        />
    </ListItemButton>
}