import {Page, PageConfig, PageData} from "/src/domain/shared/Page";
import {User} from "/src/domain/shared/User";
import React, {FunctionComponent} from "react";
import ReactDOM from "react-dom";
import App from "/src/ui/shared/App";

// @ts-ignore
export function mountComponentToContainer(page: Page, pageData: PageData, currentUser?: User, pageConfig: PageConfig, Component: FunctionComponent<any>) {
    const container = findContainer("root");
    if (!container) return;

    ReactDOM.unmountComponentAtNode(container);
    ReactDOM.render(
        <App pageData={pageData} currentUser={currentUser} pageConfig={pageConfig}>
            <Component/>
        </App>, container);
}

export function findContainer(id: string) {
    const container = document.getElementById(id);
    if (!container) {
        console.error(`No container found for mounting. Please, add container with #${id} to DOM`);
        return null;
    } else {
        return container;
    }
}