import React, { FunctionComponent, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { PostSearch } from './PostSearch';
import { TagSearch } from './TagSearch';
import { useTranslation } from 'react-i18next';

interface ContentSearchProps {
  getTags: (tag?: string) => Promise<string[]>;
  className?: string;
}

enum ViewMode {
  POSTS,
  TAG,
}

export const ContentSearch: FunctionComponent<ContentSearchProps> = ({
  getTags,
  className,
}) => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.POSTS);

  return (
    <Box className={className}>
      <Tabs
        value={viewMode}
        onChange={(e, value) => setViewMode(value)}
        variant="fullWidth"
        sx={{ mb: '1rem' }}
      >
        <Tab label={t('posts')} value={ViewMode.POSTS} />
        <Tab label={t('tags')} value={ViewMode.TAG} />
      </Tabs>
      {viewMode === ViewMode.POSTS && <PostSearch />}
      {viewMode === ViewMode.TAG && <TagSearch getTags={getTags} />}
    </Box>
  );
};
