import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { NotificationItemType, NotificationType } from "/src/domain/notifications/NotificationsData";
import { links } from "/src/domain/shared/links";
import axios from 'axios'

export interface NotificationsContextProps {
    numberOfUnreadNotifications: number | null;

    listOfNotifications: NotificationItemType[];
    getListOfNotifications: (last15: boolean | null) => void;
}


export const NotificationsContext = React.createContext<NotificationsContextProps | undefined>(undefined);

const FUTURE_DATE = '9999-01-01';

export const NotificationsProvider: FunctionComponent = ({ children }) => {
    const [numberOfUnreadNotifications, setNumberOfUnreadNotifications] = useState<number | null>(null);
    const [listOfNotifications, setListOfNotifications] = useState<NotificationItemType[]>([]);
    const componentMounted = useRef(true);

    const getNumberOfUnreadNotifications = async () => {
        try {
            const response = await axios.get(`${links.origin}/apiweb/notify.php?action=unread`);
    
            if (!componentMounted.current) {
                return
            }
            setNumberOfUnreadNotifications(response.data.unread);
        } catch (e) {
            console.log("getListOfNotifications:", e);
        }
    }
    
    useEffect(() => {
        getNumberOfUnreadNotifications();

        return () => {
            componentMounted.current = false;
        }
    }, [])
    
    const getListOfNotifications = async (last15: boolean | null) => {
        try {
            const response = await axios.get(`${links.origin}/apiweb/notify.php`, {
                params: {
                    action: 'list',
                    from: last15 && FUTURE_DATE
                }
            });
            
            setListOfNotifications(response.data.notify || []);

            // clear the counter of unread notifications
            !last15 && setNumberOfUnreadNotifications(null);
        } catch (e) {
            console.log("getListOfNotifications:", e);
        }
    }

    return (
        <NotificationsContext.Provider 
            value={{
                numberOfUnreadNotifications,

                listOfNotifications,
                getListOfNotifications
            }}>{children}</NotificationsContext.Provider>
    )
}

// TODO mock data
// const listOfNotificationsMock: NotificationItemType[] = [
//     {
//         type: NotificationType.LIKE_ACTIVITY,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         }
//     },
//     {
//         type: NotificationType.ACTIVITY_COMMENT,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         text: 'ACTIVITY COMMENT',
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         }
//     },
//     {
//         type: NotificationType.ACTIVITY_COMMENT,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         text: 'ACTIVITY COMMENT',
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//         replyToStudent: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//     },
//     {
//         type: NotificationType.REPLY_TO_ACTIVITY_COMMENT,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         text: 'REPLY TO ACTIVITY COMMENT',
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//         replyToStudent: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//     },
//     {
//         type: NotificationType.STARTED_FOLLOWING,
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         }
//     },
//     {
//         type: NotificationType.LIKE_ACTIVITY,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         }
//     },
//     {
//         type: NotificationType.ACTIVITY_COMMENT,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         }
//     },
//     {
//         type: NotificationType.ACTIVITY_COMMENT,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//         replyToStudent: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//     },
//     {
//         type: NotificationType.REPLY_TO_ACTIVITY_COMMENT,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//         replyToStudent: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//     },
//     {
//         type: NotificationType.STARTED_FOLLOWING,
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         }
//     },
//     {
//         type: NotificationType.LIKE_ACTIVITY,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         }
//     },
//     {
//         type: NotificationType.ACTIVITY_COMMENT,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         }
//     },
//     {
//         type: NotificationType.ACTIVITY_COMMENT,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//         replyToStudent: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//     },
//     {
//         type: NotificationType.REPLY_TO_ACTIVITY_COMMENT,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//         replyToStudent: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//     },
//     {
//         type: NotificationType.COMMENT_ON_FOLLOWED_ACTIVITY,
//         activityId: '321234341',
//         date: "2023-02-16 20:01:16",
//         text: "COMMENT ON FOLLOWED ACTIVITY",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//         replyToStudent: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         },
//     },
//     {
//         type: NotificationType.STARTED_FOLLOWING,
//         date: "2023-02-16 20:01:16",
//         studentInfo: {
//             id: "1233212",
//             firstName: "firstName",
//             lastName: "lastName",
//             avatarUrl: "https://s10.run",
//             isPremium: true
//         }
//     },
// ];