import amplitude from "amplitude-js";
import { User } from "/src/domain/shared/User";

export const initAnalytics = (userId?: string) => {
  amplitude.getInstance().init(String(process.env.AMPLITUDE_API_KEY), userId);
};

export const sendEvent = (
  eventType: EventType,
  eventProperties: EventProperties
) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};

export enum EventType {
  ACTIVITY_PREMIUM_BANNER = "Activity: clicked on a premium banner",
  ACTIVITY_PREMIUM_UPLOAD = "Activity: want to upload premium photos",
  STUDENT_PREMIUM_TRACKS = "Student: wants to watch premium tracks",
  STUDENT_PREMIUM_SEARCH = "Student: premium search",
  STUDENT_PREMIUM_DIARY = "Student: premium view diary",
  STUDENT_PREMIUM_BANNER = "Student: clicked on a premium banner",
  SUPPORT_COMMUNITY = "Support: clicked on the community chat",
  SUPPORT_PREMIUM = "Support: wants premium support",
  PROFILE_PREMIUM_NFT = "Profile: wants premium nft",
  CHALLENGE_VIEW_STARTREG = "Challenge: view startreg",
  CHALLENGE_ENTRY = "Challenge: entry",
  CHALLENGE_CLICK_PREDICTIONS = "Challenge: click predictions",
  CHALLENGE_VIEW_TEAM = "Challenge: view team page",
  CHALLENGE_JOIN_TEAM = "Challenge: join team",
  CHALLENGE_VIEW_PROTOCOL = "Challenge: view protocol",
  CHALLENGE_AUDIT = "Challenge: audit",
  CHALLENGE_VIEW_RATING = "Challenge: view rating",
  CHALLENGE_CHANGE_SEASON = "Challenge: change rating season",
  CHALLENGE_VIEW_STARTLIST = "Challenge: view startlist",
}

interface EventProperties {
  user?: User;
}
