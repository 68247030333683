import React, { FunctionComponent } from "react";
import { Box, Typography, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import cs from "classnames";
import { theme } from "/src/assets/theme"

interface BlockHeaderProps {
    title: string;
    linkText?: string;
    link?: string;
    className?: string;
}

export const BlockHeader: FunctionComponent<BlockHeaderProps> = ({
    title,
    linkText,
    link,
    className
}) => {
    const classes = useStyles();

    const handleHeaderClick = () => {
        if (link) {
            window.location.href = link;
        }
    }

    return <Box onClick={handleHeaderClick} className={cs(classes.container, className)}>
        <Typography variant="h3" className={classes.header} sx={{ cursor: link ? "pointer" : "initial" }}>
            { title }
        </Typography>
        {linkText && <Link href={link} className={classes.link}>
            { linkText }
        </Link>}
    </Box>
}

const useStyles = makeStyles(muiTheme => ({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    header: {
        fontSize: "1.25rem",
        fontWeight: 700,
        color: muiTheme.palette.primary.main,
        marginRight: "0.5rem",
        "&::first-letter": {
            textTransform: "uppercase"
        }
    },
    link: {
        color: theme.greyTextColor,
        textAlign: "right"
    }
}));