import React, { FunctionComponent } from "react";
import { Box, SxProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import cs from "classnames";
import { theme } from "/src/assets/theme";

const crownIcon = require("/src/assets/icons/crown.svg");

type AvatarProps = {
  avatarUrl: string;
  size?: AvatarSize;
  className?: string;
  sx?: SxProps;
  premium?: boolean;
};

export enum AvatarSize {
  SMALL = "2rem",
  PROTOCOL = "2.5rem",
  MEDIUM = "4rem",
  NORMAL = "6rem",
  LARGE = "8rem",
}

export const Avatar: FunctionComponent<AvatarProps> = ({
  avatarUrl,
  size = AvatarSize.NORMAL,
  className,
  sx,
  premium,
}) => {
  const classes = useStyles({ avatarUrl, size });

  return (
    <Box sx={sx} role="img" className={cs(classes.avatar, className)}>
      {premium && (
        <div className={classes.badgeContainer}>
          <img src={crownIcon} className={classes.crown} />
          Premium
        </div>
      )}
    </Box>
  );
};

const DEFAULT_SERVER_AVATAR_URL = "/i/profile.png";

const getServerAvatarUrl = (url?: string) => {
  if (!url) return "";

  if (url.includes(DEFAULT_SERVER_AVATAR_URL)) return "";

  return url;
};

const useStyles = makeStyles({
  avatar: (props: AvatarProps) => ({
    height: props.size,
    width: props.size,
    backgroundImage:
      "url(" +
      getServerAvatarUrl(props.avatarUrl) +
      "), url(" +
      require("/src/assets/icons/defaultAvatar.svg") +
      ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: "50%",
    position: "relative",
    flexShrink: 0,
  }),
  badgeContainer: {
    background: theme.primaryColor,
    borderRadius: "10px",
    padding: "0.15rem 0.35rem",
    color: "white",
    position: "absolute",
    bottom: "-0.4rem",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    alignItems: "center",
    fontSize: "0.6rem",
    fontWeight: 300,
  },
  crown: {
    width: "0.65rem",
    height: "0.65rem",
    marginRight: "0.35rem",
  },
});
