import React, { FunctionComponent } from "react";
import { IconsProps } from "./iconsProps"

export const Idea: FunctionComponent<IconsProps> = ({ className }) => {

    return <svg className={className} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M55.7419 19.9226C55.7419 8.87742 45.1097 0 32 0C18.8903 0 8.25806 8.87742 8.25806 19.9226C8.25806 22.8129 8.98064 25.6 10.3226 28.1806H10.2193L19.6129 41.2903V47.071C19.6129 52.6452 25.3935 57.0839 32 57.0839C38.6064 57.0839 44.3871 52.5419 44.3871 47.071V41.2903L53.6774 28.1806H53.5742C54.9161 25.6 55.7419 22.8129 55.7419 19.9226Z" fill="#D1D800"/>
    <path d="M32.2064 57.0839C38.8129 57.0839 44.3871 52.5419 44.3871 47.071V41.2903L53.6774 28.1806H53.5742C54.9161 25.7032 55.6387 22.9161 55.6387 20.0258C55.7419 8.87742 45.2129 0 32.1032 0" fill="#D1D800"/>
    <path d="M23.7419 52.6453V60.9033C23.7419 62.5549 24.9807 64.0001 26.7355 64.0001H37.3678C39.0194 64.0001 40.3613 62.6582 40.3613 60.9033V52.6453H23.7419Z" fill="#FF8E8E"/>
    <path d="M37.2645 64.0001C38.9161 64.0001 40.2581 62.6582 40.2581 60.9033V52.6453H23.7419" fill="#FF8E8E"/>
    <path d="M40.2581 55.2258C40.2581 55.5355 40.0516 55.742 39.7419 55.742H24.2581C23.9484 55.742 23.7419 55.5355 23.7419 55.2258C23.7419 54.9162 23.9484 54.7097 24.2581 54.7097H39.7419C40.0516 54.7097 40.2581 54.9162 40.2581 55.2258Z" fill="#FFB39C"/>
    <path d="M40.2581 57.2903C40.2581 57.6 40.0516 57.8064 39.7419 57.8064H24.2581C23.9484 57.8064 23.7419 57.6 23.7419 57.2903C23.7419 56.9806 23.9484 56.7742 24.2581 56.7742H39.7419C40.0516 56.7742 40.2581 56.9806 40.2581 57.2903Z" fill="#FFB39C"/>
    <path d="M40.2581 59.355C40.2581 59.6647 40.0516 59.8711 39.7419 59.8711H24.2581C23.9484 59.8711 23.7419 59.6647 23.7419 59.355C23.7419 59.0453 23.9484 58.8389 24.2581 58.8389H39.7419C40.0516 58.8389 40.2581 59.0453 40.2581 59.355Z" fill="#FFB39C"/>
    <path d="M32 26.3225C27.9742 26.3225 24.1548 27.8709 21.2645 30.7612L23.0194 32.5161C25.3936 30.1419 28.5936 28.7999 32 28.7999C35.4065 28.7999 38.6065 30.1419 40.9807 32.5161L42.7355 30.7612C39.8452 27.8709 36.0258 26.3225 32 26.3225Z" fill="#E7EB6A"/>
    <path d="M33.0323 21.6773C33.0323 22.2967 32.6194 22.7096 32 22.7096C31.3806 22.7096 30.9677 22.2967 30.9677 21.6773V11.3548C30.9677 10.7354 31.3806 10.3225 32 10.3225C32.6194 10.3225 33.0323 10.7354 33.0323 11.3548V21.6773Z" fill="#E7EB6A"/>
    <path d="M39.1226 23.0193C38.9161 23.6386 38.1936 23.9483 37.5742 23.7418C36.9549 23.5354 36.6452 22.8128 36.8516 22.1934L40.4645 12.6967C40.671 12.0773 41.3936 11.7676 42.0129 11.9741C42.6323 12.1805 42.942 12.9031 42.7355 13.5225L39.1226 23.0193Z" fill="#E7EB6A"/>
    <path d="M44.1807 26.6321C43.7677 27.1482 42.9419 27.1482 42.529 26.7353C42.0129 26.3224 42.0129 25.4966 42.4258 25.0837L49.1355 17.445C49.5484 16.9289 50.3742 16.9289 50.7871 17.3418C51.3032 17.7547 51.3032 18.5805 50.8903 18.9934L44.1807 26.6321Z" fill="#E7EB6A"/>
    <path d="M24.8774 23.0193C25.0839 23.6386 25.8065 23.9483 26.4258 23.7418C27.0452 23.5354 27.3549 22.8128 27.1484 22.1934L23.5355 12.6967C23.329 12.0773 22.6065 11.7676 21.9871 11.9741C21.3678 12.1805 21.0581 12.9031 21.2645 13.5225L24.8774 23.0193Z" fill="#E7EB6A"/>
    <path d="M19.8194 26.6321C20.2323 27.1482 21.0581 27.1482 21.471 26.7353C21.9871 26.3224 21.9871 25.4966 21.5742 25.0837L14.8645 17.445C14.4516 16.9289 13.6258 16.9289 13.2129 17.3418C12.6968 17.7547 12.6968 18.5805 13.1097 18.9934L19.8194 26.6321Z" fill="#E7EB6A"/>
    <path d="M32 35.4065C33.0832 35.4065 33.9613 34.5284 33.9613 33.4452C33.9613 32.362 33.0832 31.4839 32 31.4839C30.9168 31.4839 30.0387 32.362 30.0387 33.4452C30.0387 34.5284 30.9168 35.4065 32 35.4065Z" fill="#E7EB6A"/>
    </svg>
    
}
