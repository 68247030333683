import React, { FunctionComponent, useState, useEffect } from "react";
import { PreviewCommentsInfo, Comment } from "/src/domain/studentTasks/StudentTasksData"
import { Box, Typography, ButtonBase,
    List, ListItem } from "@mui/material";
import { getCommentElement } from "./CommentsDialog";
import { theme } from "/src/assets/theme";
import { useTranslation } from "react-i18next";
import { trimName } from "/src/domain/shared/trimName";

interface CommentsPreviewProps {
    commentsPreview: PreviewCommentsInfo;
    onClick: () => void;
    currentUserId?: string;
}

export const CommentsPreview: FunctionComponent<CommentsPreviewProps> = ({
    commentsPreview,
    onClick,
    currentUserId
}) => {
    const { t } = useTranslation();

    return <Box sx={{
        mt: commentsPreview.comments?.length ? 0 : "0.5rem"
    }}>
        {Boolean(commentsPreview.comments?.length) && <List>
            {commentsPreview?.comments.map((c) => <CommentPreviewItem key={c.id} comment={c} />)}
        </List>}
        {Boolean(currentUserId) && <ButtonBase 
            onClick={onClick} 
            sx={{ 
                color: theme.greyTextColor,
                fontSize: "inherit"
            }}
        >
            { t('addComment') } ({ commentsPreview?.numberOfComments })
        </ButtonBase>}
    </Box>
}

interface CommentPreviewItemProps {
    comment: Comment;
}

const CommentPreviewItem: FunctionComponent<CommentPreviewItemProps> = ({ comment }) => {
 
    const handleNameClick = () => {
        window.location.href = `/profile?${comment.studentInfo?.id}`
    }

    return <ListItem key={comment.id} disablePadding sx={{ display: "block", mb: "0.35rem" }}>
            <Typography onClick={handleNameClick} sx={{ cursor: "pointer", fontWeight: 500 }}>
                { trimName(comment.studentInfo.firstName, comment.studentInfo.lastName) } 
            </Typography> 
            { getCommentElement(comment.text, true) }
    </ListItem>
}