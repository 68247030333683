import React, { FunctionComponent } from "react";
import { IconsProps } from "./iconsProps";

export const Favorite: FunctionComponent<IconsProps> = ({ className }) => {

    return <svg className={className} viewBox="15 0 45 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_4539_10009)">
    <ellipse cx="38.2864" cy="37.2329" rx="16.2073" ry="3.7671" fill="#FFEBE7"/>
    </g>
    <path d="M38.4976 40.1945C49.597 40.1945 58.5949 31.1966 58.5949 20.0972C58.5949 8.99784 49.597 0 38.4976 0C27.3982 0 18.4004 8.99784 18.4004 20.0972C18.4004 31.1966 27.3982 40.1945 38.4976 40.1945Z" fill="url(#paint0_linear_4539_10009)"/>
    <path d="M48.4636 2.62793H28.2268C27.4738 2.62793 26.7517 2.92705 26.2193 3.45949C25.6868 3.99194 25.3877 4.71408 25.3877 5.46707V28.5272H25.4224V36.625C25.4224 36.9377 25.5025 37.2452 25.6551 37.5181C25.8077 37.791 26.0276 38.0203 26.294 38.184C26.5604 38.3477 26.8643 38.4404 27.1767 38.4533C27.4891 38.4662 27.7996 38.3988 28.0786 38.2575L37.413 33.5256C37.6673 33.3981 37.9479 33.3317 38.2324 33.3317C38.5169 33.3317 38.7975 33.3981 39.0518 33.5256L48.6734 38.2922C48.9525 38.4306 49.2623 38.4953 49.5735 38.4803C49.8847 38.4654 50.1869 38.3712 50.4515 38.2068C50.7161 38.0423 50.9343 37.8131 51.0855 37.5407C51.2366 37.2683 51.3158 36.9618 51.3153 36.6503L51.2901 28.5272V5.45918C51.288 4.70974 50.9897 3.99152 50.4602 3.46115C49.9307 2.93077 49.213 2.63126 48.4636 2.62793Z" fill="url(#paint1_linear_4539_10009)"/>
    <path d="M61.1537 10.0962C61.1544 10.7099 60.9318 11.3029 60.5275 11.7647C60.1232 12.2264 59.5647 12.5254 58.9563 12.6058C58.845 12.6199 58.733 12.6272 58.6209 12.6276H50.2006C50.0179 12.6272 49.8356 12.6121 49.6553 12.5826C48.8813 12.449 48.1841 12.0339 47.6978 11.4171C47.2115 10.8003 46.9705 10.0254 47.0213 9.24163C47.0721 8.45783 47.411 7.72051 47.9727 7.17159C48.5345 6.62267 49.2795 6.30095 50.0643 6.26835C50.471 5.38519 51.1539 4.65824 52.0099 4.19716C52.866 3.73607 53.8487 3.56586 54.81 3.71219C55.7712 3.85852 56.6588 4.31346 57.3388 5.00839C58.0189 5.70332 58.4545 6.60055 58.58 7.56473H58.6182C58.951 7.56419 59.2806 7.62927 59.5882 7.75625C59.8958 7.88322 60.1753 8.0696 60.4108 8.30472C60.6463 8.53984 60.8332 8.81908 60.9606 9.12647C61.0881 9.43387 61.1537 9.76338 61.1537 10.0962Z" fill="url(#paint2_linear_4539_10009)"/>
    <path d="M18.3359 5.13863C18.3359 5.64479 18.537 6.13021 18.8949 6.48811C19.2528 6.84601 19.7382 7.04708 20.2444 7.04708H26.5804C27.2047 7.04378 27.803 6.79686 28.2479 6.35891C28.6929 5.92097 28.9492 5.32662 28.9623 4.70247C28.9755 4.07832 28.7444 3.4737 28.3184 3.0174C27.8923 2.5611 27.3049 2.2892 26.6813 2.2596C26.3738 1.59715 25.8596 1.05233 25.2161 0.706941C24.5726 0.361547 23.8344 0.234215 23.1123 0.344059C22.3902 0.453904 21.7233 0.794998 21.2116 1.31614C20.6999 1.83727 20.371 2.51033 20.2744 3.23427H20.2457C19.7401 3.23391 19.2549 3.43426 18.8968 3.79132C18.5388 4.14838 18.337 4.63295 18.3359 5.13863Z" fill="url(#paint3_linear_4539_10009)"/>
    <g filter="url(#filter1_f_4539_10009)">
    <ellipse cx="39.1826" cy="24.0353" rx="9.1357" ry="1.95765" fill="#FF7C86" fillOpacity="0.39"/>
    </g>
    <path d="M39.0964 7.14892L41.3425 11.6994C41.4091 11.8343 41.5075 11.951 41.6292 12.0395C41.751 12.128 41.8923 12.1856 42.0412 12.2073L47.0618 12.9313C47.2318 12.9573 47.3912 13.0301 47.5223 13.1413C47.6534 13.2526 47.7511 13.3982 47.8044 13.5617C47.8577 13.7252 47.8646 13.9003 47.8242 14.0675C47.7839 14.2347 47.698 14.3874 47.576 14.5086L43.9482 18.0496C43.8403 18.1546 43.7596 18.2843 43.7132 18.4275C43.6667 18.5707 43.6559 18.7231 43.6816 18.8714L44.5397 23.8714C44.5691 24.0424 44.5501 24.2182 44.4847 24.3789C44.4194 24.5396 44.3104 24.6787 44.1701 24.7807C44.0297 24.8826 43.8636 24.9432 43.6906 24.9557C43.5176 24.9681 43.3446 24.9318 43.1911 24.8509L38.7005 22.485C38.5672 22.4151 38.4189 22.3786 38.2683 22.3786C38.1178 22.3786 37.9695 22.4151 37.8362 22.485L33.3456 24.8509C33.1922 24.9314 33.0193 24.9673 32.8466 24.9547C32.6738 24.942 32.508 24.8813 32.3679 24.7795C32.2278 24.6776 32.119 24.5386 32.0537 24.3781C31.9885 24.2177 31.9694 24.0422 31.9986 23.8714L32.855 18.8714C32.8808 18.7231 32.8699 18.5707 32.8235 18.4275C32.777 18.2843 32.6964 18.1546 32.5885 18.0496L28.9607 14.5086C28.8387 14.3874 28.7528 14.2347 28.7124 14.0675C28.6721 13.9003 28.679 13.7252 28.7323 13.5617C28.7856 13.3982 28.8832 13.2526 29.0143 13.1413C29.1455 13.0301 29.3049 12.9573 29.4749 12.9313L34.4954 12.2026C34.6443 12.1808 34.7857 12.1233 34.9074 12.0348C35.0291 11.9463 35.1275 11.8296 35.1942 11.6947L37.4403 7.14418C37.5184 6.99158 37.6373 6.86359 37.7837 6.77437C37.9301 6.68515 38.0983 6.63819 38.2698 6.63868C38.4412 6.63917 38.6092 6.68709 38.7551 6.77715C38.901 6.8672 39.0191 6.99587 39.0964 7.14892Z" fill="url(#paint4_linear_4539_10009)"/>
    <path d="M16.6475 29.5393C16.6472 30.1505 16.8691 30.741 17.2718 31.2007C17.6746 31.6604 18.2308 31.958 18.8367 32.038C18.9469 32.0533 19.0581 32.0611 19.1693 32.0612H27.5529C27.7356 32.0609 27.9179 32.0459 28.0982 32.0162C28.8732 31.8872 29.5728 31.4748 30.061 30.8592C30.5492 30.2435 30.7914 29.4684 30.7404 28.6843C30.6895 27.9002 30.3491 27.163 29.7853 26.6157C29.2215 26.0684 28.4745 25.75 27.6892 25.7224C27.2837 24.8435 26.6035 24.1203 25.7512 23.6616C24.8989 23.2029 23.9205 23.0336 22.9637 23.1793C22.0068 23.3249 21.1231 23.7776 20.4459 24.4692C19.7687 25.1607 19.3345 26.0536 19.2089 27.0133H19.1693C18.8378 27.0133 18.5096 27.0787 18.2033 27.2057C17.8971 27.3327 17.6189 27.5188 17.3847 27.7534C17.1504 27.988 16.9648 28.2665 16.8383 28.573C16.7118 28.8794 16.6469 29.2078 16.6475 29.5393Z" fill="url(#paint5_linear_4539_10009)"/>
    <path d="M54.5971 16.3315C54.4592 15.7906 54.9585 15.3033 55.4958 15.4543C56.0331 15.6053 56.2055 16.2814 55.8061 16.6712C55.4066 17.0611 54.735 16.8723 54.5971 16.3315Z" fill="#FFBEBD"/>
    <path d="M21.1 16.3315C20.9621 15.7906 21.4614 15.3033 21.9987 15.4543C22.5361 15.6053 22.7084 16.2814 22.309 16.6712C21.9096 17.0611 21.2379 16.8723 21.1 16.3315Z" fill="#FFBEBD"/>
    <path d="M14.8905 10.4753C15.04 9.85624 15.9206 9.85624 16.07 10.4753C16.1234 10.6966 16.2962 10.8693 16.5174 10.9227C17.1365 11.0722 17.1365 11.9528 16.5174 12.1022C16.2962 12.1557 16.1234 12.3284 16.07 12.5496C15.9206 13.1688 15.04 13.1688 14.8905 12.5496C14.8371 12.3284 14.6644 12.1557 14.4431 12.1022C13.824 11.9528 13.824 11.0722 14.4431 10.9227C14.6644 10.8693 14.8371 10.6966 14.8905 10.4753Z" fill="#FFBEBD"/>
    <path d="M38.9577 36.5786C38.9577 36.4535 39.059 36.3522 39.184 36.3522C39.309 36.3522 39.4104 36.4535 39.4104 36.5786C39.4104 36.7036 39.309 36.8049 39.184 36.8049C39.059 36.8049 38.9577 36.7036 38.9577 36.5786Z" stroke="#FFBEBD" strokeWidth="2.48287"/>
    <path d="M53.9638 31.0466C54.1132 30.4275 54.9938 30.4275 55.1433 31.0466C55.1967 31.2679 55.3694 31.4406 55.5907 31.494C56.2098 31.6435 56.2098 32.5241 55.5907 32.6735C55.3694 32.7269 55.1967 32.8997 55.1433 33.1209C54.9938 33.74 54.1132 33.74 53.9638 33.1209C53.9103 32.8997 53.7376 32.7269 53.5164 32.6735C52.8973 32.5241 52.8973 31.6435 53.5164 31.494C53.7376 31.4406 53.9103 31.2679 53.9638 31.0466Z" fill="#FFBEBD"/>
    <defs>
    <filter id="filter0_f_4539_10009" x="0.306107" y="11.6928" width="75.961" height="51.0802" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_4539_10009"/>
    </filter>
    <filter id="filter1_f_4539_10009" x="9.35631" y="1.38707" width="59.6526" height="45.2967" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="10.3453" result="effect1_foregroundBlur_4539_10009"/>
    </filter>
    <linearGradient id="paint0_linear_4539_10009" x1="38.3427" y1="-6.54415" x2="38.7544" y2="64.0343" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE1E1" stopOpacity="0"/>
    <stop offset="1" stopColor="#FFE1E0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_4539_10009" x1="27.7923" y1="-1.64528" x2="37.0044" y2="40.4673" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FF9999"/>
    <stop offset="1" stopColor="#FFE7DA"/>
    </linearGradient>
    <linearGradient id="paint2_linear_4539_10009" x1="58.4705" y1="16.4946" x2="51.4375" y2="3.66113" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFBEBD"/>
    <stop offset="1" stopColor="#FFF6F5"/>
    </linearGradient>
    <linearGradient id="paint3_linear_4539_10009" x1="26.9462" y1="9.95452" x2="21.6575" y2="0.307141" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFBEBD"/>
    <stop offset="1" stopColor="#FFF6F5"/>
    </linearGradient>
    <linearGradient id="paint4_linear_4539_10009" x1="44.5719" y1="25.0045" x2="12.9875" y2="-6.90899" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFDEAC"/>
    <stop offset="0.70369" stopColor="#FF426F"/>
    </linearGradient>
    <linearGradient id="paint5_linear_4539_10009" x1="28.0714" y1="35.9137" x2="21.0684" y2="23.1227" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFBEBD"/>
    <stop offset="1" stopColor="#FFF6F5"/>
    </linearGradient>
    </defs>
    </svg>
    
}
