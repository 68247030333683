import { Lang } from "src/domain/shared/User";
import i18n from "i18next";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/lv";
import "dayjs/locale/en";
import "dayjs/locale/uk";


export const changePageLanguage = (lang: Lang) => {
    dayjs.locale(lang);
    i18n.changeLanguage(lang, (e, t) => {
        if (e) return console.log('something went wrong loading', e);
    })
}

