import { links } from '/src/domain/shared/links';
import {
  Club,
  ClubSettings,
  ClubMember,
  ClubMembershipFeeData,
  PaymentInfo,
  PaymentType,
} from '/src/domain/club/ClubData';
import dayjs from 'dayjs';

import axios from 'axios';

type GetClubs = {
  (): Promise<Club[]>;
  (userId: string): Promise<Club | null>;
};

export const getClubs: GetClubs = async (userId?: string) => {
  try {
    const response = await axios.get(links.challengesOrigin + '/api/clubs/get', {
      params: {
        id: userId,
      },
    });

    return response.data;
  } catch (e) {
    console.log('getClubs:', e);
    if (userId === undefined) {
      return [];
    }
    return null;
  }
};

export enum SearchClubBy {
  ID = 'club_id',
  KEY = 'club_key',
  ALIAS = 'alias',
}

export const getClub = async (value: string, searchBy = SearchClubBy.ID): Promise<Club | null> => {
  try {
    const response = await axios.get(links.challengesOrigin + '/api/clubs/get', {
      params: {
        [searchBy]: value,
      },
    });

    return response.data;
  } catch (e) {
    console.log('getClub:', e);
    return null;
  }
};

export const getDefaultTrainerClub = async (defaultTrainerId: string): Promise<Club | null> => {
  try {
    const response = await axios.get(links.challengesOrigin + '/api/clubs/get', {
      params: {
        default_trainer_id: defaultTrainerId,
      },
    });

    return response.data;
  } catch (e) {
    console.log('getDefaultTrainerClub:', e);
    return null;
  }
};

export const getClubSettings = async (clubId: string): Promise<ClubSettings | null> => {
  try {
    const response = await axios.get(links.challengesOrigin + '/api/clubs/get', {
      params: {
        club_id: clubId,
        settings: true,
      },
    });

    return response.data;
  } catch (e) {
    console.log('getClubSettings:', e);
    return null;
  }
};

export const updateUserClub = async (userId: string, clubId: string | null) => {
  try {
    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('club_id', String(clubId));
    const response = await axios.post(
      links.challengesOrigin + '/api/clubs/update',
      formData,
    );

    if (response.data.ok) {
      return true;
    }

    return false;
  } catch (e) {
    console.log('updateUserClub:', e);
    return false;
  }
};

export const getClubMemberIds = async (
  clubId: string,
): Promise<string[] | null> => {
  try {
    const response = await axios.get(
      links.challengesOrigin + '/api/clubs/participants',
      {
        params: {
          club_id: clubId,
        },
      },
    );

    return response.data;
  } catch (e) {
    console.log('getClubMemberIds:', e);
    return null;
  }
};

export const getClubMembers = async (
  clubId: string,
): Promise<ClubMember[] | null> => {
  try {
    const response = await axios.get(
      links.challengesOrigin + '/api/clubs/users',
      {
        params: {
          club_id: clubId,
        },
      },
    );

    return response.data;
  } catch (e) {
    console.log('getClubMembers:', e);
    return null;
  }
};

export const getClubMembershipFeeData = async (
  userId?: string,
): Promise<ClubMembershipFeeData | null> => {
  try {
    const response = await axios.get(links.origin + '/apiweb/clubmoney.php', {
      params: {
        action: 'info',
        id: userId,
      },
    });

    return response.data || null;
  } catch (e) {
    console.log('getClubMembershipFeeData:', e);
    return null;
  }
};

export const getPayLink = (
  clubId: string,
  isRecurrent?: boolean,
  currency?: 'rub' | 'eur',
) => {
  let payLink = `${
    links.origin
  }/apiweb/clubmoney.php?action=pay&clubid=${clubId}&ts=${dayjs().unix()}`;
  payLink += isRecurrent ? '&recur=1' : '';
  payLink += currency ? `&currency=${currency}` : '';
  return payLink;
};

export const getPaymentsInfo = async (): Promise<PaymentInfo[] | null> => {
  try {
    const response = await axios.get(links.origin + '/apiweb/clubmoney.php', {
      params: {
        action: 'payments',
      },
    });

    return response.data.payments || null;
  } catch (e) {
    console.log('getPaymentsInfo:', e);
    return null;
  }
};

export const disableAutoPayment =
  async (paymentType?: PaymentType): Promise<ClubMembershipFeeData | null> => {
    try {
      const response = await axios.get(links.origin + '/apiweb/clubmoney.php', {
        params: {
          action: 'unsubscribe',
          paymentType: paymentType,
        },
      });

      return response.data || null;
    } catch (e) {
      console.log('disableAutoPayment:', e);
      return null;
    }
  };
