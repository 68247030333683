import React, { FunctionComponent } from "react";
import { TrainerInfo } from "/src/domain/dashboard/DashboardData";
import { Box } from "@mui/material";
import { TrainerEntryView } from "./TrainerEntryView"
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

interface TrainersProps {
    trainers: TrainerInfo[];
}

export const TrainersView: FunctionComponent<TrainersProps> = ({
    trainers
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return <Box className={classes.container}>
        {trainers.map((trainer => 
            <TrainerEntryView
                key={trainer.id}
                trainer={trainer}
            />
        ))}
        <TrainerEntryView/>
    </Box>
}

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "auto"
    }
})