import React, { FunctionComponent } from 'react';
import { IconsProps } from './iconsProps';

export const Ghost: FunctionComponent<IconsProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 60 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7644_3084)">
        <g filter="url(#filter0_d_7644_3084)">
          <path
            d="M37.9006 24.0483C37.9021 21.9527 37.4906 19.8773 36.6896 17.9407C35.8886 16.0042 34.7138 14.2445 33.2324 12.7623C31.7509 11.28 29.9919 10.1043 28.0558 9.3022C26.1197 8.50014 24.0445 8.08751 21.9489 8.08789C19.8535 8.08789 17.7786 8.5008 15.8428 9.30303C13.9071 10.1053 12.1484 11.2811 10.6672 12.7633C9.18609 14.2455 8.01155 16.0051 7.21072 17.9414C6.4099 19.8777 5.99849 21.9529 6 24.0483C5.99962 26.143 6.41187 28.2172 7.21321 30.1526C8.01454 32.0879 9.18927 33.8464 10.6703 35.3277C12.1513 36.809 13.9096 37.9841 15.8448 38.7857C17.78 39.5874 19.8542 40 21.9489 40H48.8817L43.2843 35.4164C41.6013 34.0382 40.2454 32.3036 39.3144 30.3376C38.3834 28.3717 37.9005 26.2236 37.9006 24.0483Z"
            fill="url(#paint0_linear_7644_3084)"
          />
        </g>
        <g filter="url(#filter1_d_7644_3084)">
          <path
            d="M37.9006 24.0483C37.9021 21.9527 37.4906 19.8773 36.6896 17.9407C35.8886 16.0042 34.7138 14.2445 33.2324 12.7623C31.7509 11.28 29.9919 10.1043 28.0558 9.3022C26.1197 8.50014 24.0445 8.08751 21.9489 8.08789C19.8535 8.08789 17.7786 8.5008 15.8428 9.30303C13.9071 10.1053 12.1484 11.2811 10.6672 12.7633C9.18609 14.2455 8.01155 16.0051 7.21072 17.9414C6.4099 19.8777 5.99849 21.9529 6 24.0483C5.99962 26.143 6.41187 28.2172 7.21321 30.1526C8.01454 32.0879 9.18927 33.8464 10.6703 35.3277C12.1513 36.809 13.9096 37.9841 15.8448 38.7857C17.78 39.5874 19.8542 40 21.9489 40H48.8817L43.2843 35.4164C41.6013 34.0382 40.2454 32.3036 39.3144 30.3376C38.3834 28.3717 37.9005 26.2236 37.9006 24.0483Z"
            fill="url(#paint1_linear_7644_3084)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9077 24.9346C16.9077 27.2665 18.6406 29.1569 20.7782 29.1569C22.9158 29.1569 24.6487 27.2665 24.6487 24.9346H16.9077Z"
          fill="#84A5FA"
        />
        <path
          d="M15.4995 23.878C16.4712 23.878 17.2589 23.0903 17.2589 22.1187C17.2589 21.147 16.4712 20.3594 15.4995 20.3594C14.5279 20.3594 13.7402 21.147 13.7402 22.1187C13.7402 23.0903 14.5279 23.878 15.4995 23.878Z"
          fill="url(#paint2_linear_7644_3084)"
        />
        <path
          d="M26.0557 23.878C27.0273 23.878 27.815 23.0903 27.815 22.1187C27.815 21.147 27.0273 20.3594 26.0557 20.3594C25.0841 20.3594 24.2964 21.147 24.2964 22.1187C24.2964 23.0903 25.0841 23.878 26.0557 23.878Z"
          fill="url(#paint3_linear_7644_3084)"
        />
        <g clipPath="url(#clip1_7644_3084)">
          <path
            d="M53.9697 8.45106C53.9697 7.00568 53.3951 5.61821 52.3732 4.59699C51.3509 3.57471 49.9644 3.00028 48.5187 3C47.0735 3 45.6861 3.57492 44.6642 4.59699C43.6423 5.61821 43.0676 7.00568 43.0676 8.45106C43.0676 9.89644 43.6423 11.2839 44.6642 12.3051C45.6861 13.3272 47.0735 13.9021 48.5187 13.9021C49.9638 13.9021 51.3513 13.3272 52.3732 12.3051C53.3951 11.2839 53.9697 9.89645 53.9697 8.45106Z"
            fill="url(#paint4_linear_7644_3084)"
          />
          <path
            d="M45.5348 8.39327C47.1241 7.70099 48.1837 7.24454 48.7135 7.02403C50.2278 6.39435 50.5421 6.28499 50.7474 6.28128C50.7925 6.28056 50.893 6.29172 50.9586 6.34474C51.0131 6.38945 51.0285 6.44992 51.0361 6.49238C51.0429 6.5348 51.0523 6.63147 51.0446 6.70693C50.9629 7.56888 50.6077 9.66056 50.4271 10.626C50.3513 11.0345 50.2006 11.1714 50.0549 11.1848C49.7381 11.2139 49.4979 10.9756 49.1913 10.7747C48.7118 10.4602 48.4409 10.2644 47.975 9.95756C47.4367 9.6029 47.7859 9.40793 48.0926 9.08939C48.1726 9.006 49.5677 7.73735 49.5941 7.62229C49.5976 7.60789 49.601 7.55423 49.5686 7.52596C49.5371 7.49759 49.4902 7.5073 49.4562 7.51497C49.4076 7.52587 48.6419 8.03248 47.1565 9.03471C46.9393 9.1841 46.7426 9.25692 46.5654 9.25309C46.3712 9.24892 45.9964 9.14305 45.7179 9.05259C45.3772 8.94161 45.1055 8.88293 45.1294 8.69444C45.1413 8.59632 45.2767 8.4959 45.5348 8.39327Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7644_3084"
          x="-5"
          y="-6.91211"
          width="64.8818"
          height="53.9121"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="5.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.322361 0 0 0 0 0.578492 0 0 0 0 0.879167 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7644_3084"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7644_3084"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_7644_3084"
          x="-2"
          y="-1.91211"
          width="58.8818"
          height="47.9121"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.322361 0 0 0 0 0.578492 0 0 0 0 0.879167 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7644_3084"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7644_3084"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7644_3084"
          x1="9.74784"
          y1="26.113"
          x2="43.0804"
          y2="41.5314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAFAFF" />
          <stop offset="1" stopColor="#93BEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7644_3084"
          x1="9.74784"
          y1="26.113"
          x2="43.0804"
          y2="41.5314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAFAFF" />
          <stop offset="1" stopColor="#93BEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7644_3084"
          x1="15.4995"
          y1="20.3594"
          x2="15.4995"
          y2="23.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C8AF6" />
          <stop offset="1" stopColor="#A9D1FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7644_3084"
          x1="26.0557"
          y1="20.3594"
          x2="26.0557"
          y2="23.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C8AF6" />
          <stop offset="1" stopColor="#A9D1FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7644_3084"
          x1="42.9947"
          y1="12.6425"
          x2="47.1545"
          y2="-0.0768328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5CBFF" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <clipPath id="clip0_7644_3084">
          <rect width="60" height="43" fill="white" />
        </clipPath>
        <clipPath id="clip1_7644_3084">
          <rect
            width="10.9021"
            height="10.9021"
            fill="white"
            transform="translate(43.0674 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
