import React, { FunctionComponent, useContext } from "react";
import { PageContext, PageContextProps } from "/src/contexts/Page";
import { DashboardProvider, DashboardContext, DashboardContextProps } from "/src/contexts/DashboardContext";
import { PostProvider } from "/src/contexts/PostContext";
import { CurrentUserProvider } from "/src/contexts/shared/CurrentUserContext";
import { UserProvider } from "/src/contexts/shared/UserContext";
import { ProfilePrivacyProvider } from "/src/contexts/shared/ProfilePrivacyContext";
import { SubscriptionsProvider } from "/src/contexts/SubscriptionsContext";
import { DashboardData, OldTaskInterval, UserRoleOnPage } from "/src/domain/dashboard/DashboardData";
import { User } from "/src/domain/shared/User";
import { ThemeProvider, StyledEngineProvider, Box, CircularProgress, GlobalStyles } from "@mui/material";
import { ThemeProvider as ThemeProviderForStyles, makeStyles } from "@mui/styles";
import { Header } from "/src/ui/shared/header";
import { Footer } from "/src/ui/shared/footer";
import { BlockHeader } from "./BlockHeader";
import { UpcomingRacesContainer } from "/src/ui/shared/upcomingRaces";
// import { QuestBanner } from "./questBanner/QuestBanner";
import { UserSearch } from "./UserSearch";
import { ControlPanel, ControlPanelType } from "./controlPanel/ControlPanel";
import { LeaderBoard } from "/src/ui/challengeProtocol/leaderBoard/LeaderBoard";
import { LeaderBoardContainer } from "/src/ui/challengeProtocol/leaderBoard/LeaderBoardContainer";
import { CurrentUserResults } from "/src/ui/challengeProtocol/currentUserResults/CurrentUserResults";
import { CurrentUserResultsContainer } from "/src/ui/challengeProtocol/currentUserResults/CurrentUserResultsContainer";
import { Tasks } from "./tasks/Tasks";
import { ContentSearch } from "./contentSearch/ContentSearch"
import { Posts } from "./Posts"
import { RepostedPostsView } from "/src/ui/trainerProfile/posts/RepostedPostsView";
import { Chart } from "./chart/Chart";
import { TrainersBlock } from "./trainers/TrainersBlock";
import { PremiumBanner, BannerType } from  "/src/ui/shared/premiumBanners/PremiumBanner";
import { BlankBanner } from "./BlankBanner";
import { PostAudioPlayer } from "/src/ui/studentTasks/studentTasks/posts/PostAudioPlayer";
import { BannersView } from "/src/ui/shared/banners/BannersView";
import theme from "/src/ui/shared/styledTheme";
import { theme as themeColor } from '/src/assets/theme';
import { useTranslation } from "react-i18next";
import { isNull as _isNull } from "lodash";
import { links } from "/src/domain/shared/links";
import dayjs from "dayjs";
import { DurationUnitType } from 'dayjs/plugin/duration';
import cs from "classnames";

export const DashboardApp: FunctionComponent = () => {
    const {pageData, currentUser} = useContext(PageContext) as PageContextProps;


    if (pageData === null) return null;
    const dashboard = pageData as DashboardData;

    return <StyledEngineProvider injectFirst>
        <GlobalStyles
            styles={{
                body: {
                    backgroundColor: themeColor.WHITE_COLOR,
                },
            }}
        />
        <ThemeProvider theme={theme}>
            <ThemeProviderForStyles theme={theme}>
                <CurrentUserProvider defaultUser={currentUser || null}>
                    <UserProvider userId={null}>
                        <PostProvider>
                            <SubscriptionsProvider>
                                <DashboardProvider>
                                    <ProfilePrivacyProvider userId={dashboard.studentInfo?.id} isReadOnly={false}>
                                        <App 
                                            currentUser={currentUser}
                                            dashboard={dashboard}
                                        />
                                    </ProfilePrivacyProvider>
                                </DashboardProvider>
                            </SubscriptionsProvider>
                        </PostProvider>
                    </UserProvider>
                </CurrentUserProvider>
            </ThemeProviderForStyles>
        </ThemeProvider>
    </StyledEngineProvider>
}

interface AppProps {
    currentUser?: User;
    dashboard: DashboardData;
}

const App: FunctionComponent<AppProps> = ({
    currentUser,
    dashboard,
}) => {
    const classes= useStyles();
    const { t } = useTranslation();

    const { 
        club,
        isParticipationInQuest, 
        protocolData, 
        isUserInQuestProtocol,
        seasonBoards, 
        userRatings, 
        currentDate,

        feed,
        isFeedLoading,
        oldTaskData,

        userRoleOnPage,
        getTags,
    } = useContext(DashboardContext) as DashboardContextProps;
    
    const showPremiumBanners = userRoleOnPage === UserRoleOnPage.STUDENT_OWN_PAGE && !currentUser?.isPremium;
    const showTrainerBlock = (userRoleOnPage === UserRoleOnPage.STUDENT_OWN_PAGE && !currentUser?.currentTrainerId) || userRoleOnPage === UserRoleOnPage.UNREGISTERED;
    const showRepostsBlock = userRoleOnPage === UserRoleOnPage.TRAINER_OWN_PAGE || userRoleOnPage === UserRoleOnPage.READONLY_TRAINER_PAGE;

    const pageOwner = dashboard.studentInfo || dashboard.trainerInfo;
    const showControlPanel = userRoleOnPage === UserRoleOnPage.STUDENT_OWN_PAGE || userRoleOnPage === UserRoleOnPage.TRAINER_OWN_PAGE;

    return <>
        <Header currentUser={currentUser} teamLogo={club?.logoUrl}/>
        <Box className={classes.contentContainer}>
            <UpcomingRacesContainer withHeader={false}/>
            {/* {!_isNull(isParticipationInQuest) && !_isNull(isUserInQuestProtocol) && <QuestBanner
                isQuestParticipant={isParticipationInQuest}
                isUserInQuestProtocol={isUserInQuestProtocol}
                currentDate={currentDate}
                className={classes.banner}
            />} */}
            {userRoleOnPage === UserRoleOnPage.UNREGISTERED && <BlankBanner link="/login" text={ t('register') } className={classes.banner}/>}
            {showControlPanel && <ControlPanel 
                type={ControlPanelType.MAIN}
                currentUser={currentUser}
                club={club}
            />}
            <UserSearch className={classes.header}/>
            {Boolean(seasonBoards.length) && <>
                <BlockHeader
                    title={ t('rating') }
                    linkText={ t('openRating') }
                    link={links.ratingLink}
                    className={classes.header}
                />
                <LeaderBoard boards={seasonBoards}/>
            </>}
            {userRoleOnPage === UserRoleOnPage.STUDENT_OWN_PAGE && userRatings && <CurrentUserResults
                userResults={userRatings}
            />}
            {showTrainerBlock && dashboard.trainers && <TrainersBlock trainers={dashboard.trainers}/>}
            {showPremiumBanners && <PremiumBanner type={BannerType.PRO} className={classes.header}/> }
            {(userRoleOnPage === UserRoleOnPage.STUDENT_OWN_PAGE || 
            userRoleOnPage === UserRoleOnPage.READONLY_STUDENT_PAGE )&& 
            !!dashboard.tasks?.length && <>
                <BlockHeader
                    title={ t('trainings') }
                    linkText={ t('goToTrainings') }
                    link={`/student?${dashboard.studentInfo?.id}&v2=1`}
                    className={classes.header}
                />
                <Tasks
                    studentInfo={dashboard.studentInfo}
                    tasks={dashboard.tasks}
                    trainerId={currentUser?.currentTrainerId}
                    currentUser={currentUser}
                    className={classes.header}
                    isReadOnly={userRoleOnPage !== UserRoleOnPage.STUDENT_OWN_PAGE}
                />
            </>}
            {dashboard.studentInfo && <Chart
                headerClassName={classes.header}
                studentInfo={dashboard.studentInfo}
            />}
            <BlockHeader
                title={ t('search') }
                className={classes.header}
            />
            <ContentSearch getTags={(tag?: string) => getTags(undefined, tag)} className={classes.header}/>
            {pageOwner && <>
                <BlockHeader
                    title={ t('posts') }
                    className={classes.header}
                    linkText={ t('morePosts') }
                    link={`/posts/${pageOwner.id}/`}
                />
                <Posts 
                    className={classes.header} 
                    postOwner={pageOwner}
                    ownerTrainerId={dashboard.trainerInfo?.id}
                />
            </>}
            {pageOwner && showRepostsBlock && <>
                <BlockHeader
                    title={ t('reposts') }
                    className={classes.header}
                    linkText={ t('morePosts') }
                    link={`/posts/${pageOwner.id}/reposts`}
                />
                <RepostedPostsView 
                    repostOwner={{
                        id: pageOwner.id,
                        firstName: pageOwner.firstName,
                        lastName: pageOwner.lastName,
                        isPremium: true,
                        avatarUrl: pageOwner.avatarUrl
                    }}
                    className={classes.header}
                />
            </>}
            {userRoleOnPage === UserRoleOnPage.STUDENT_OWN_PAGE && oldTaskData && <>
                <BlockHeader
                    title={ getOldTaskHeader(oldTaskData.oninterval) }
                    className={classes.header}
                />
                <Tasks
                    studentInfo={dashboard.studentInfo}
                    tasks={[oldTaskData[0]]}
                    trainerId={currentUser?.currentTrainerId}
                    currentUser={currentUser}
                    className={classes.header}
                    isReadOnly={!(userRoleOnPage === UserRoleOnPage.STUDENT_OWN_PAGE)}
                />
            </>}
            {(Boolean(feed.length) || isFeedLoading) && <>
                <BlockHeader
                    title={ t('friendsFeed') }
                    className={classes.header}
                />
                {isFeedLoading ? <Box sx={{ 
                    m: "2rem 1.5rem", 
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center"
                }}>
                    <CircularProgress/>
                </Box>
                : <Tasks
                    studentInfo={dashboard.studentInfo}
                    trainerInfo={dashboard.trainerInfo}
                    tasks={feed}
                    trainerId={currentUser?.currentTrainerId}
                    currentUser={currentUser}
                    className={classes.header}
                    isReadOnly={true}
                    isFeed
                />}
            </>}
            {userRoleOnPage === UserRoleOnPage.TRAINER_OWN_PAGE && <BlankBanner link="/students" text={ t('goToStudents') } className={cs(classes.banner, classes.studensBanner)}/>}
            {protocolData && Boolean(protocolData.protocols.length) && <>
                <BlockHeader
                    title={ t('protocol') }
                    linkText={ t('challengesSchedule') }
                    link={"/challenges"}
                    className={classes.header}
                />
                <LeaderBoardContainer 
                    challengeType={protocolData.challenge.type}
                    protocols={protocolData.protocols}
                />
                {userRoleOnPage === UserRoleOnPage.STUDENT_OWN_PAGE && <CurrentUserResultsContainer
                    challengeType={protocolData.challenge.type}
                    protocols={protocolData.protocols}
                    currentUser={currentUser}
                />}
            </>}
            {/* {showPremiumBanners && <PremiumBanner type={BannerType.DIPLOMA} className={classes.header}/>} */}
            {showControlPanel && <ControlPanel 
                type={ControlPanelType.BOTTOM}
                currentUser={currentUser}
                club={club}
            />}
        </Box>
        <Footer/>
        <PostAudioPlayer/>
        <BannersView currentUser={currentUser}/>
    </>
}

const CONTAINER_MAX_WIDTH = 1100;

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: `${CONTAINER_MAX_WIDTH}px`,
            margin: "0 auto"
        },
    },
    header: {
        margin: "1.5rem 1.5rem 0",
        [theme.breakpoints.up(CONTAINER_MAX_WIDTH)]: {
            margin: "1.5rem 0"
        }
    },
    banner: {
        margin: "0.5rem 1.5rem 0",
        [theme.breakpoints.up(CONTAINER_MAX_WIDTH)]: {
            margin: "0.5rem 0"
        }
    },
    studensBanner: {
        marginTop: "1.5rem"
    }
}));

const getOldTaskHeader = (value: OldTaskInterval) => {
    let durationParams: [number, DurationUnitType];

    if (value === OldTaskInterval.YEAR1) {
        durationParams = [-1, 'years'];
    } else if (value === OldTaskInterval.MONTH6) {
        durationParams = [-6, 'months'];
    } else if (value === OldTaskInterval.MONTH3) {
        durationParams = [-3, 'months'];
    } else if (value === OldTaskInterval.MONTH1) {
        durationParams = [-1, 'months'];
    } else {
        durationParams = [-2, 'weeks'];
    }

    return dayjs.duration(...durationParams).humanize(true);
}