import React, { FunctionComponent, useState, useEffect } from "react";
import {
    ChallengeFormula,
    instanceOfDsqRecord,
    instanceOfSingleRaceRecord,
    instanceOfTeamRecord,
    Protocol,
    SingleRaceRecord,
    TeamRaceRecord
} from "/src/domain/challenges/ChallengeProtocolData";
import {User} from "/src/domain/shared/User";
import {trimName} from "/src/domain/shared/trimName";
import {useTranslation} from "react-i18next";
import {CurrentUserResults, UserResultData} from "./CurrentUserResults";
import {getResultLabel, getResultValue} from "/src/ui/challengeProtocol/leaderBoard/LeaderBoardContainer";

interface CurrentUserResultsContainerProps {
    protocols: Protocol[];
    currentUser?: User;
    challengeType: ChallengeFormula;
}

export const CurrentUserResultsContainer: FunctionComponent<CurrentUserResultsContainerProps> = ({
    protocols,
    currentUser,
    challengeType
}) => {
    const { t } = useTranslation();
    const [userResults, setUserResults] = useState<UserResultData[] | null>(null)

    if (!currentUser) return null;

    useEffect(() => {
        const userResultsDraft: UserResultData[] = []

        protocols.forEach(protocol => {
            const record: TeamRaceRecord | SingleRaceRecord | undefined = protocol.records.find(r => {
                if (instanceOfDsqRecord(r)) return;

                if (instanceOfTeamRecord(r)) {
                    return r.records.find(tr => tr.participant.id == currentUser?.id);
                }
                if (instanceOfSingleRaceRecord(r)) {
                    return r.participant.id == currentUser?.id;
                }
            }) as TeamRaceRecord | SingleRaceRecord | undefined;

            const result = record && getResultValue(record, challengeType);

            if (record && result) {
                const userResult: UserResultData = {
                    title: protocol.title,
                    place: record.place,
                    label: instanceOfTeamRecord(record) ? record.name : trimName(currentUser?.firstName, currentUser?.lastName),
                    result: result,
                    resultLabel: t(getResultLabel(record, challengeType))
                }

                userResultsDraft.push(userResult);
            }
        })

        if (userResultsDraft.length === 0)
            return setUserResults(null);

        setUserResults(userResultsDraft);

    }, [protocols])

    if (!userResults) return null;

    return <CurrentUserResults userResults={userResults}/>
}