import React, { FunctionComponent, useMemo, useContext } from "react";
import { PostContext, PostContextProps } from '/src/contexts/PostContext';
import { Club } from "/src/domain/club/ClubData";
import { ControlItem, ControlItemProps } from "./ControlItem";
import { User, UserRole } from "/src/domain/shared/User";
import { defaultPageConfig } from "/src/domain/shared/Page";
import { UserContext, UserContextProps } from "/src/contexts/shared/UserContext";
import { Grid } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles";
import { theme } from "/src/assets/theme"
import { Shop } from "./icons/Shop"
import { Protocols } from "./icons/Protocols";
import { Speaker } from "./icons/Speaker"
import { Envelope } from "./icons/Envelope"
import { Hammer } from "./icons/Hammer"
import { House } from "./icons/House"
import { Feedback } from "./icons/Feedback";
import { Favorite } from "./icons/Favorite"
import { PurpleFavorite } from "./icons/PurpleFavorite"
import { Search } from "./icons/Search"
import { Ghost } from "./icons/Ghost"
import { Idea } from "./icons/Idea"
import { Person } from "./icons/Person"

import { links } from "/src/domain/shared/links";
import { useTranslation } from "react-i18next";

interface ControlPanelProps {
    type: ControlPanelType;
    club?: Club | null;
    currentUser?: User;
}

export const ControlPanel: FunctionComponent<ControlPanelProps> = ({
    type,
    club,
    currentUser
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { newAudioPostsCount } = useContext(PostContext) as PostContextProps;

    const { tribunalIds } = useContext(UserContext) as UserContextProps;

    const controlItemsList = getList(type, currentUser);
    const controlPanelViewProps = useMemo(() => (
        controlItemsList.map((key) => {
            return getControlItemViewProps({
                controlItemKey: key, 
                currentUser,
                tribunalIds,
                newAudioPostsCount,
                club,
            });
        })
    ), [type, currentUser, tribunalIds, newAudioPostsCount, club]);
    return <Grid className={classes.controlContainer} container spacing={2}>
        {controlPanelViewProps.map(f => {
            return f && <ControlItem
                key={f.label}
                {...f}
                label={ t(f.label) }
            />
        })}
    </Grid>
}

const useStyles = makeStyles(muiTheme => ({
    controlContainer: {
        marginTop: "0rem",
        padding: "1rem 1.5rem 0"
    }
}));

const getList = (type: ControlPanelType, currentUser?: User): ControlItemType[] => {
    if (type === ControlPanelType.BOTTOM) {
        return panelBottom;
    }

    if (currentUser?.role === UserRole.TRAINER) {
        return trainerPanelHead;
    }

    return panelHead;
}

export enum ControlPanelType {
    MAIN,
    BOTTOM
}


enum ControlItemType {
    // SHOP,
    ALL_PROTOCOLS,
    PODCASTS,
    SUPPORT,
    FIND_CLUB,
    CHECK_PAY,
    WRITE_TRAINER,
    GO_SCHOOL,
    COMPETITION,
    RUNNING_CHAT,
    IDEA,
    ANSWER,
    QUESTION,
    PROFILE,
    APPEALS_ADMINISTRATION
}

const panelHead = [
    ControlItemType.PODCASTS, ControlItemType.RUNNING_CHAT, ControlItemType.FIND_CLUB, ControlItemType.PROFILE, 
    ControlItemType.ALL_PROTOCOLS, ControlItemType.COMPETITION, ControlItemType.WRITE_TRAINER, ControlItemType.CHECK_PAY,
];

const trainerPanelHead = [
    ControlItemType.PODCASTS, ControlItemType.RUNNING_CHAT, ControlItemType.GO_SCHOOL, ControlItemType.PROFILE,  
]

const panelBottom = [ControlItemType.IDEA, ControlItemType.RUNNING_CHAT, ControlItemType.QUESTION, ControlItemType.PROFILE]

type ControlPanelMapData = {
    [key in ControlItemType]: ControlItemProps;
}

const color = {
    coral: "#FFEEEE",
    sky: "#EBF6FF",
    softPurple: "#E2E1FF",
    lightGreen: '#EBFFF5',
    lightCyan: '#DEFFFF',
}

const UTM_LABEL = "?utm_source=s10&utm_medium=dashboard&utm_campaign=bigicon"

const controlPanelMap: ControlPanelMapData = {
    // [ControlItemType.SHOP]: {
    //     label: 'buyClothes',
    //     link: links.shopLink + UTM_LABEL,
    //     Icon: Shop,
    //     color: color.coral
    // },
    [ControlItemType.ALL_PROTOCOLS]: {
        label: 'allProtocols',
        link: links.origin + '/tag?q=s10protocol2024',
        Icon: Protocols,
        color: color.coral,
    },
    [ControlItemType.PODCASTS]: {
        label: 'podcasts',
        link: '/audios',
        Icon: Speaker,
        color: color.lightCyan
    },
    [ControlItemType.SUPPORT]: {
        label: 'writeToSupport',
        link: defaultPageConfig.appealLink!,
        Icon: Envelope,
        color: color.sky
    },
    [ControlItemType.FIND_CLUB]: {
        label: 'findClub',
        link: links.ratingLink,
        Icon: House,
        color: color.softPurple
    },
    [ControlItemType.CHECK_PAY]: {
        label: 'checkPayment',
        link: "/money",
        Icon: Feedback,
        color: color.coral
    },
    [ControlItemType.WRITE_TRAINER]: {
        label: 'writeToTrainer',
        Icon: Search,
        color: color.lightGreen
    },
    [ControlItemType.GO_SCHOOL]: {
        label: 'goToSchool',
        link: links.schoolLink,
        Icon: Favorite,
        color: color.coral
    },
    [ControlItemType.COMPETITION]: {
        label: 'runYourRace',
        link: "/teambuilding" + UTM_LABEL,
        Icon: PurpleFavorite,
        color: color.softPurple
    },
    [ControlItemType.RUNNING_CHAT]: {
        label: 'runningChat',
        link: links.telegramChatLink,
        Icon: Ghost,
        color: color.sky
    },
    [ControlItemType.IDEA]: {
        label: 'suggestIdea',
        link: links.cannyLink,
        Icon: Idea,
        color: color.coral
    },
    [ControlItemType.ANSWER]: {
        label: 'findAnswer',
        link: links.answersLink,
        Icon: Envelope,
        color: color.sky
    },
    [ControlItemType.QUESTION]: {
        label: 'askQuestion',
        link: links.telegramStroLink,
        Icon: House,
        color: color.softPurple
    },
    [ControlItemType.PROFILE]: {
        label: 'myProfile',
        link: "/profile",
        Icon: Person,
        color: theme.creamColor
    },
    [ControlItemType.APPEALS_ADMINISTRATION]: {
        label: 'appeals',
        link: links.appealsAdministration,
        Icon: Hammer,
        color: color.coral
    }
}

interface GetControlItemViewPropsArgs {
    controlItemKey: ControlItemType;
    currentUser?: User;
    tribunalIds?: string[];
    newAudioPostsCount?: number;
    club?: Club | null;
}

const getControlItemViewProps = ({controlItemKey, currentUser, tribunalIds, newAudioPostsCount, club}: GetControlItemViewPropsArgs): ControlItemProps | null => {
    const haveTrainer = Boolean(currentUser?.currentTrainerId);
    const isStudent = currentUser?.role === UserRole.STUDENT;

    switch (controlItemKey) {
        case ControlItemType.PODCASTS: 
            return {...controlPanelMap[ControlItemType.PODCASTS], badge: newAudioPostsCount};
        case ControlItemType.WRITE_TRAINER:
            if (!currentUser) {
                return controlPanelMap[ControlItemType.WRITE_TRAINER];
            }

            if (!isStudent) {
                return null;
            } 
            
            if (haveTrainer) {
                controlPanelMap[ControlItemType.WRITE_TRAINER].link = `/chat2?${currentUser.id}`;
            } else {
                controlPanelMap[ControlItemType.WRITE_TRAINER].Icon = Search;
                controlPanelMap[ControlItemType.WRITE_TRAINER].label = "findTrainer";
                controlPanelMap[ControlItemType.WRITE_TRAINER].link = "/trainerlist";
            }
            return controlPanelMap[ControlItemType.WRITE_TRAINER];
        
        case ControlItemType.ALL_PROTOCOLS:
            return currentUser && tribunalIds?.includes(currentUser.id) ? 
                controlPanelMap[ControlItemType.APPEALS_ADMINISTRATION] 
                : controlPanelMap[ControlItemType.ALL_PROTOCOLS];
        
        case ControlItemType.FIND_CLUB: 
            if (club) {
                controlPanelMap[ControlItemType.FIND_CLUB].label = 'myClub';
                controlPanelMap[ControlItemType.FIND_CLUB].link = `${links.origin}/club?id=${club.clubKey}`;
                return controlPanelMap[ControlItemType.FIND_CLUB];
            }
    }

    return controlPanelMap[controlItemKey];
}

