import React, { FunctionComponent } from 'react';
import { IconsProps } from './iconsProps';

export const Speaker: FunctionComponent<IconsProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 60 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7644_3056)">
        <path
          d="M18 0.999999C13.0294 0.999999 9 4.80558 9 9.5C9 14.1944 13.0294 18 18 18C22.9706 18 27 14.1944 27 9.5C27 4.80558 22.9706 1 18 0.999999Z"
          fill="url(#paint0_linear_7644_3056)"
        />
        <path
          d="M41 41C45.9706 41 50 37.4183 50 33C50 28.5817 45.9706 25 41 25C36.0294 25 32 28.5817 32 33C32 37.4183 36.0294 41 41 41Z"
          fill="url(#paint1_linear_7644_3056)"
        />
        <g filter="url(#filter0_f_7644_3056)">
          <ellipse
            cx="25.1868"
            cy="18.2936"
            rx="10.3114"
            ry="1.95225"
            fill="#C4C1FE"
          />
        </g>
        <g filter="url(#filter1_f_7644_3056)">
          <ellipse
            cx="42.6774"
            cy="18.1485"
            rx="4.69884"
            ry="1.95225"
            fill="#40DFF0"
            fillOpacity="0.6"
          />
        </g>
        <path
          d="M10.5948 29.468C10.7207 28.8904 11.5444 28.8904 11.6703 29.468C11.7141 29.6693 11.8668 29.8292 12.0658 29.8825L12.0955 29.8904C12.6894 30.0493 12.6894 30.8921 12.0955 31.051L12.0658 31.0589C11.8668 31.1121 11.7141 31.2721 11.6703 31.4734C11.5444 32.0509 10.7207 32.0509 10.5948 31.4734C10.551 31.2721 10.3984 31.1121 10.1993 31.0589L10.1697 31.051C9.5757 30.8921 9.5757 30.0493 10.1697 29.8904L10.1993 29.8825C10.3984 29.8292 10.551 29.6693 10.5948 29.468Z"
          fill="url(#paint2_linear_7644_3056)"
        />
        <path
          d="M39.1378 2.46798C39.2637 1.89041 40.0874 1.89041 40.2132 2.46798C40.2571 2.66929 40.4097 2.82924 40.6088 2.88247L40.6384 2.8904C41.2324 3.04926 41.2324 3.8921 40.6384 4.05095L40.6088 4.05888C40.4097 4.11211 40.2571 4.27206 40.2132 4.47337C40.0874 5.05094 39.2637 5.05094 39.1378 4.47337C39.094 4.27206 38.9413 4.11211 38.7423 4.05888L38.7126 4.05095C38.1187 3.8921 38.1187 3.04926 38.7126 2.8904L38.7423 2.88247C38.9413 2.82924 39.094 2.66929 39.1378 2.46798Z"
          fill="url(#paint3_linear_7644_3056)"
        />
        <path
          d="M46.2139 16.2258C46.0897 15.6861 46.5404 15.1987 47.0243 15.3494C47.5074 15.4998 47.6613 16.1733 47.3016 16.5623C46.9418 16.9513 46.3378 16.7645 46.2139 16.2258Z"
          fill="url(#paint4_linear_7644_3056)"
        />
        <path
          d="M18.5211 35.9059C19.0078 35.7692 19.4444 36.2662 19.308 36.8015C19.1712 37.3379 18.5618 37.5104 18.2117 37.1118C17.8615 36.7132 18.0335 36.0429 18.5211 35.9059Z"
          fill="url(#paint5_linear_7644_3056)"
        />
        <path
          d="M26.8351 27.6268C29.8272 27.6268 32.2527 25.2012 32.2527 22.2091C32.2527 19.2171 29.8272 16.7915 26.8351 16.7915C23.843 16.7915 21.4175 19.2171 21.4175 22.2091C21.4175 25.2012 23.843 27.6268 26.8351 27.6268Z"
          fill="url(#paint6_linear_7644_3056)"
        />
        <path
          d="M35.49 31.0942C33.2146 29.9294 30.262 28.9813 26.8353 28.9813C23.4087 28.9813 20.4561 29.9294 18.1807 31.0942C17.5198 31.4346 16.9662 31.9514 16.5813 32.5874C16.1963 33.2234 15.9952 33.9536 16.0001 34.6969V35.7534C16.0001 37.2432 17.2191 38.4622 18.7089 38.4622H34.9618C36.4516 38.4622 37.6706 37.2432 37.6706 35.7534V34.6969C37.6706 33.18 36.8444 31.7849 35.49 31.0942ZM41.8828 12.5253C41.765 12.3726 41.6158 12.247 41.4454 12.1568C41.275 12.0666 41.0872 12.014 40.8947 12.0024C40.7022 11.9909 40.5095 12.0207 40.3295 12.0898C40.1495 12.159 39.9864 12.2659 39.8512 12.4034C39.3772 12.8774 39.323 13.6359 39.7293 14.1641C41.314 16.1957 43.3049 21.2477 39.7293 25.8256C39.323 26.3538 39.3772 27.1122 39.8512 27.5863C40.4336 28.1687 41.3817 28.101 41.8828 27.4644C43.9144 24.8504 46.4201 18.3628 41.8828 12.5253ZM38.0905 16.575C37.9853 16.3952 37.8405 16.2419 37.6672 16.1266C37.4938 16.0114 37.2964 15.9372 37.09 15.9097C36.8836 15.8823 36.6737 15.9024 36.4762 15.9683C36.2787 16.0343 36.0989 16.1445 35.9505 16.2905C35.5036 16.7375 35.4629 17.4282 35.7744 17.9835C36.113 18.5795 36.7767 20.2725 35.7609 22.0332C35.4494 22.575 35.5036 23.2522 35.9505 23.6856C36.5871 24.3222 37.6435 24.1596 38.0905 23.3876C39.6616 20.6924 38.876 17.9429 38.0905 16.575Z"
          fill="url(#paint7_linear_7644_3056)"
        />
        <path
          d="M25 10.6593L21.3865 10.6593L20.5534 14.3321L19.72 7.58354L18.887 16L18.0535 6L17.2205 15.9562L16.3871 6.84014L15.554 15.344L14.7206 9.06601L13.8876 14.4941L13.0541 10.6907L10 10.6907"
          stroke="url(#paint8_linear_7644_3056)"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_7644_3056"
          x="-7.01032"
          y="-5.5445"
          width="64.3942"
          height="47.6759"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="10.9429"
            result="effect1_foregroundBlur_7644_3056"
          />
        </filter>
        <filter
          id="filter1_f_7644_3056"
          x="16.0927"
          y="-5.68952"
          width="53.1691"
          height="47.6759"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="10.9429"
            result="effect1_foregroundBlur_7644_3056"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7644_3056"
          x1="18.0694"
          y1="20.7678"
          x2="17.9049"
          y2="-9.08301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.9999" stopColor="#49DEF2" stopOpacity="0.989583" />
          <stop offset="1" stopColor="#9DB3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7644_3056"
          x1="40.9306"
          y1="22.395"
          x2="41.0763"
          y2="50.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.9999" stopColor="#49DEF2" stopOpacity="0.989583" />
          <stop offset="1" stopColor="#9DB3FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7644_3056"
          x1="13.3452"
          y1="31.832"
          x2="8.74755"
          y2="23.9224"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49DEF2" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7644_3056"
          x1="41.8881"
          y1="4.83203"
          x2="37.2905"
          y2="-3.07761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49DEF2" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7644_3056"
          x1="47.8631"
          y1="15.7374"
          x2="44.4513"
          y2="14.736"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49DEF2" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_7644_3056"
          x1="18.9555"
          y1="37.735"
          x2="20.2697"
          y2="34.1031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49DEF2" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_7644_3056"
          x1="31.0009"
          y1="27.6992"
          x2="18.3595"
          y2="23.5649"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49DEF2" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_7644_3056"
          x1="41.1454"
          y1="38.639"
          x2="8.46351"
          y2="27.1557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49DEF2" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_7644_3056"
          x1="11.733"
          y1="5.93317"
          x2="27.3475"
          y2="13.5931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2E2E" />
          <stop offset="1" stopColor="#F7F7FF" />
        </linearGradient>
        <clipPath id="clip0_7644_3056">
          <rect width="60" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
