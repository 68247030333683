import React, { FunctionComponent } from "react";
import { UserResultData } from "/src/ui/challengeProtocol/currentUserResults/CurrentUserResults"
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";

interface CurrentUserResultRowData {
    userResult: UserResultData
}

const CurrentUserResultRow: FunctionComponent<CurrentUserResultRowData> = ({ userResult }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    return <>
        <Grid container className={classes.resultsHeader}>
            <Grid item xs={6}
                container
                alignItems="center"
                className={classes.resultsHeaderCategory}>{userResult.title}</Grid>
            <Grid item xs={6}
                container
                justifyContent="flex-end"
                alignItems="center"
                className={classes.resultsHeaderParameter}>{userResult.resultLabel}</Grid>
        </Grid>
        <div className={classes.results}>
            <Grid container>
                <Grid item xs={2}>
                    {userResult.place}
                </Grid>
                <Grid item xs={6}>
                    {userResult.label}
                </Grid>
                <Grid item container justifyContent="flex-end" xs={4}>
                    {userResult.result}
                </Grid>
            </Grid>
        </div>
    </>;
}

const useStyles = makeStyles(muiTheme => ({
    resultsHeader: {
        backgroundColor: muiTheme.palette.secondary.main,
        color: muiTheme.palette.secondary.contrastText,
        fontSize: '0.9rem',
        fontWeight: 600,
    },
    resultsHeaderCategory: {
        padding: '0.5rem 1.5rem'
    },
    resultsHeaderParameter: {
        background: `url(${require('../../../assets/icons/challengeProtocolCurrentUserCardRect.svg')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '0.5rem 1.5rem',
    },
    results: {
        padding: '1rem 1.5rem',
        fontSize: '0.9rem',
        lineHeight: '1.5rem',
    }
}))

export default CurrentUserResultRow