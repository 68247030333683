import React, { FunctionComponent } from "react";
import { ReportParameters, Difficulty, TaskRoute } from "/src/domain/studentTasks/StudentTasksData";
import makeStyles from '@mui/styles/makeStyles';
import { ReportComment } from "./ReportComment";
import { difficultyText, difficultyColors } from "/src/domain/studentTasks/difficultyInfo";
import { TagsView } from "/src/ui/studentTasks/studentTasks/TagsView";
import { ReportDifficulty } from "/src/ui/studentTasks/studentTasks/ReportDifficulty";
import Check from "@mui/icons-material/Check";
import { theme } from "/src/assets/theme";
import { useTranslation } from "react-i18next";
import smoothscroll from "smoothscroll-polyfill";

interface ReportParametersViewProps {
    studentId?: string;
    reportParameters: ReportParameters;
}


export const ReportParametersView: FunctionComponent<ReportParametersViewProps> = ({ 
    studentId,
    reportParameters,
}) => {
    const { t } = useTranslation();
    const classes = useStyles({ difficulty: reportParameters?.difficulty });

    smoothscroll.polyfill();

    return (
        <div className={classes.reportParametersContainer}>
            <ReportDifficulty difficulty={reportParameters.difficulty}/>
            <ReportComment
                isHtml={Boolean(reportParameters.html)}
                comment={reportParameters.comment}
                className={classes.parameter}
            />
            { (reportParameters.placeabs || reportParameters.placeage || reportParameters.time
               || reportParameters.personalBest || reportParameters.trainerEvaluation) &&
                <div className={classes.reportBigParameters}>
                    {reportParameters.placeabs && <div className={classes.parameter}>{ t('placeInAbsoluteСategory') }: { reportParameters.placeabs }</div>}
                    {reportParameters.placeage && <div className={classes.parameter}>{ t('placeInAgeGroup') }: { reportParameters.placeage }</div>}
                    {reportParameters.time && <div className={classes.parameter}>{ t('officialTime') }: { reportParameters.time }</div>}
                    {Boolean(reportParameters.personalBest) && <div className={classes.parameter}>{ t('personalRecord') }: <Check className={classes.checkPersonalBest}/></div>}
                    {reportParameters.trainerEvaluation && <div className={classes.parameter}>{ t('trainerEvaluation') }: { reportParameters.trainerEvaluation }</div>}    
                </div>
            }
            {!!reportParameters.tags?.length && <TagsView tags={reportParameters.tags}/>}
        </div>
    )
}

interface ReportStylesProps {
    difficulty: Difficulty;
}

const useStyles = makeStyles({
    reportParametersContainer: (props: ReportStylesProps) => ({
        marginBottom: "0.75rem",
        padding: "0.25rem 0.75rem 0.75rem",
        backgroundColor: theme.lightSecondaryColor,
        border: `1px solid ${difficultyColors[props.difficulty]}`,
        borderRadius: "5px",
        width: "95%",
        boxSizing: "border-box"
    }),
    reportBigParameters: {
        marginTop: "0.5rem",
        borderTop: "1px solid black"
    },
    parameter: {
        marginTop: "0.5rem",
        overflow: "auto",

    },
    checkPersonalBest: {
        fontSize: "1.25rem",
        position: "relative",
        top: "0.3rem",
        left: "0.3rem"
    }
})