import { PageData } from "/src/domain/shared/Page";
import { ChallengeStage } from "/src/domain/challenges/ChallengeStage";
import { UpcomingRaceData } from "/src/domain/challenges/UpcomingRace";
import { Track } from "./ChallengeAuditData";
import { ClubRating } from "./ChallengeRatingData";


export interface ChallengeProtocolData extends PageData {
  readonly upcomingRaces: UpcomingRaceData[];
  readonly challenge: Challenge;
  readonly protocols: Protocol[];
  readonly pointTables: PointTable[];
  readonly ippodrom: IppodromItem[];
  readonly systemMessage: string;
  readonly goalInfo?: GoalInfo;
  readonly clubsRating?: ClubRating[];
}

export enum ChallengeFormula {
  SIMPLE = "simple",
  MAX_DISTANCE = "max_distance",
  MAX_DISTANCE_SIMPLE = "max_distance_simple",
  STAIRS = "stairs",
  INVARIABLE_PULSE = "invariable_pulse",
  INVARIABLE_PACE = "invariable_pace",
  UNDERGROUND = "underground",
  PB_FREE_DISTANCE = "pb_free_distance",
  DIRECTION = "direction",
  MIN_DISTANCE = "min_distance",
  NOSTRA_RUN = "nostra_run",
  GHOST_RUN = "ghost_run",
  HIGHLAND = "highland",
  SIMPLE_CLASSIC = "simple_classic",
  YEAR_PLAN = "year_plan",
  NEGATIVE = "negative",
  IPPODROM = "ippodrom",
  STEPPER = "stepper",
  VELO_WITH_POINTS = "velo_with_points",
  CARS_MAX_DISTANCE = "cars_max_distance",

  //team
  TEAM_SIMPLE = "team_simple",
  TEAM_RELAY = "team_relay",
  BANANA = "banana",
  NEIGHBORHOODS = "neighborhoods",
  TEAM_MAX_DISTANCE = "team_max_distance",
  TEAM_STEPPER = "team_stepper",
  TEAM_NOSTRA_RUN = "team_nostra_run",
  TEAM_HOUR_RUN = "team_hour_run",
}

export interface Challenge {
  readonly name: string;
  readonly stage: ChallengeStage;
  readonly formula: ChallengeFormula;
  readonly challengeKey: string;
  readonly runWindow: RunWindow;
  readonly isProtocolVisible: boolean;
  readonly diplomas?: string[];
  readonly isDiplomaFree: boolean;
  readonly minTeamParticipants: number;
  readonly maxTeamParticipants: number;
  readonly startreg: number;
}

export interface RunWindow {
  readonly startDate: string;
  readonly stopDate: string;
}

export interface Protocol {
  readonly title: string;
  readonly records: ProtocolRecord[];
}

export interface ProtocolRecord {
  readonly place?: number;
  readonly type: RecordType;
}

export enum RecordType {
  SINGLE_RACE = "single",
  TEAM_RACE = "team",
  DSQ_RECORD = "dsq",
}

export interface SingleRaceRecord extends ProtocolRecord {
  readonly trackId: string;
  readonly participant: Participant;
  readonly time?: number;
  readonly absolute?: number;
  readonly parameters: Parameters[];
  readonly comment?: string;
  readonly data?: any;
}

export interface MaxDistanceRaceData {
  tracks: Track[];
}

export interface DsqRecord extends SingleRaceRecord {
  readonly moving_time?: number;
  readonly elapsed_time?: number;
}

export interface Participant {
  readonly id: string;
  readonly isPremium: boolean;
  readonly city?: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly avatarUrl?: string;
  readonly specialCat?: string;
  readonly coach?: string;
  readonly team?: string;
  readonly clubLogoUrl?: string;
  readonly clubName?: string;
  readonly clubId?: string;
  readonly clubKey?: string;
  readonly coachId?: string;
}

export type parameter = number | string | Date;

export interface Parameters {
  readonly index: number;
  readonly value: parameter;
  readonly label: string;
}

export const isTeamProtocol = (challenge: Challenge): boolean => {
  return challenge.maxTeamParticipants > 1;
};

export interface PointTable {
  readonly name: string;
  readonly title?: string;
  readonly records: MarPointTableRecord[] | LocationDistanceTableRecord[];
}

export interface PointTableRecord {
  readonly result: number;
}

export interface LocationDistanceTableRecord extends PointTableRecord {
  readonly location: string;
}

export interface MarPointTableRecord extends PointTableRecord {
  readonly participant: Participant;
}

export interface TeamRaceRecord extends ProtocolRecord {
  readonly result?: number | TeamRaceResult;
  readonly name: string;
  readonly metadata: TeamRecordMetadata;
  readonly records: SingleRaceRecord[];
}

export interface TeamRaceResult {
  index: number;
  value: number;
}

export interface TeamRecordMetadata {
  penalty?: number;
  absolute?: number;
  prediction?: number;
  steps?: StepsInfo;
  googleConnected?: GoogleConnectedInfo;
}

export interface StepsInfo {
  [key: string]: number;
}

export interface GoogleConnectedInfo {
  [key: string]: boolean;
}

export function instanceOfSingleRaceRecord(
  record: ProtocolRecord
): record is SingleRaceRecord {
  return record.type === RecordType.SINGLE_RACE;
}

export function instanceOfDsqRecord(
  record: ProtocolRecord
): record is DsqRecord {
  return record.type === RecordType.DSQ_RECORD;
}

export function instanceOfTeamRecord(
  record: ProtocolRecord
): record is TeamRaceRecord {
  return record.type === RecordType.TEAM_RACE;
}

export interface IppodromItem {
  participant: Participant;
  place: number;
  ownScore: number;
  capitanScore: number;
  teamScore: number;
  totalScore: number;
}

export interface GoalInfo {
  goalKm: number;
  completedKm: number;
  percentage: number;
}

export { ClubRating };

export interface ClubTabFilterItem {
  id: string;
  name: string;
}