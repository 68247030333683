import React, { FunctionComponent } from 'react';
import { Typography, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PostsStubProps {
  sx?: SxProps;
}

export const PostsStub: FunctionComponent<PostsStubProps> = ({ sx }) => {
  const { t } = useTranslation();

  return (
    <Typography variant={'h6'} sx={{ fontWeight: 'normal', ...sx }}>
      {t('noPosts')}
    </Typography>
  );
};
