import React, { FunctionComponent } from 'react';
import { IconsProps } from './iconsProps';

export const PurpleFavorite: FunctionComponent<IconsProps> = ({
  className,
}) => {
  return (
    <svg
      className={className}
      viewBox="0 0 61 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7644_3158)">
        <g filter="url(#filter0_f_7644_3158)">
          <ellipse
            cx="30.2874"
            cy="39.2329"
            rx="16.2073"
            ry="3.7671"
            fill="#FFEBE7"
          />
        </g>
        <path
          d="M30.498 1.99986C19.3986 1.99986 10.4007 10.9977 10.4007 22.0971C10.4007 33.1965 19.3986 42.1943 30.498 42.1943C41.5974 42.1943 50.5952 33.1965 50.5952 22.0971C50.5952 10.9977 41.5974 1.99987 30.498 1.99986Z"
          fill="url(#paint0_linear_7644_3158)"
        />
        <path
          d="M40.4641 4.62793H20.2273C19.4743 4.62793 18.7522 4.92705 18.2197 5.45949C17.6873 5.99194 17.3882 6.71408 17.3882 7.46707V30.5272H17.4229V38.625C17.4229 38.9377 17.503 39.2452 17.6556 39.5181C17.8081 39.791 18.0281 40.0203 18.2945 40.184C18.5609 40.3477 18.8648 40.4404 19.1772 40.4533C19.4896 40.4662 19.8001 40.3988 20.0791 40.2575L29.4135 35.5256C29.6678 35.3981 29.9484 35.3317 30.2329 35.3317C30.5174 35.3317 30.798 35.3981 31.0523 35.5256L40.6739 40.2922C40.953 40.4306 41.2628 40.4953 41.574 40.4803C41.8852 40.4654 42.1874 40.3712 42.452 40.2068C42.7166 40.0423 42.9348 39.8131 43.086 39.5407C43.2371 39.2683 43.3163 38.9618 43.3158 38.6503L43.2906 30.5272V7.45918C43.2885 6.70974 42.9902 5.99152 42.4607 5.46115C41.9312 4.93077 41.2135 4.63126 40.4641 4.62793Z"
          fill="url(#paint1_linear_7644_3158)"
        />
        <path
          d="M53.1542 12.0962C53.1549 12.7099 52.9323 13.3029 52.528 13.7647C52.1236 14.2264 51.5652 14.5254 50.9568 14.6058C50.8455 14.6199 50.7335 14.6272 50.6214 14.6276H42.2011C42.0184 14.6272 41.8361 14.6121 41.6558 14.5826C40.8818 14.449 40.1845 14.0339 39.6983 13.4171C39.212 12.8003 38.971 12.0254 39.0218 11.2416C39.0726 10.4578 39.4114 9.72051 39.9732 9.17159C40.535 8.62267 41.28 8.30095 42.0647 8.26835C42.4715 7.38519 43.1544 6.65824 44.0104 6.19716C44.8664 5.73607 45.8492 5.56586 46.8104 5.71219C47.7717 5.85852 48.6593 6.31346 49.3393 7.00839C50.0194 7.70332 50.455 8.60055 50.5805 9.56473H50.6187C50.9515 9.56419 51.2811 9.62927 51.5887 9.75625C51.8963 9.88322 52.1758 10.0696 52.4113 10.3047C52.6468 10.5398 52.8336 10.8191 52.9611 11.1265C53.0886 11.4339 53.1542 11.7634 53.1542 12.0962Z"
          fill="url(#paint2_linear_7644_3158)"
        />
        <path
          d="M10.3364 7.13863C10.3364 7.64479 10.5375 8.13021 10.8954 8.48811C11.2533 8.84601 11.7387 9.04708 12.2449 9.04708H18.5809C19.2052 9.04378 19.8035 8.79686 20.2484 8.35891C20.6933 7.92097 20.9497 7.32662 20.9628 6.70247C20.976 6.07832 20.7449 5.4737 20.3188 5.0174C19.8928 4.5611 19.3054 4.2892 18.6818 4.2596C18.3742 3.59715 17.8601 3.05233 17.2166 2.70694C16.573 2.36155 15.8348 2.23421 15.1128 2.34406C14.3907 2.4539 13.7238 2.795 13.2121 3.31614C12.7004 3.83727 12.3715 4.51033 12.2749 5.23427H12.2462C11.7406 5.23391 11.2554 5.43426 10.8973 5.79132C10.5392 6.14838 10.3375 6.63295 10.3364 7.13863Z"
          fill="url(#paint3_linear_7644_3158)"
        />
        <g filter="url(#filter1_f_7644_3158)">
          <ellipse
            cx="31.1831"
            cy="26.0353"
            rx="9.1357"
            ry="1.95765"
            fill="#FF7C86"
            fillOpacity="0.39"
          />
        </g>
        <path
          d="M22.1281 14.76L27.1048 13.7682C27.2524 13.7388 27.3906 13.6739 27.5076 13.5792C27.6245 13.4846 27.7167 13.3629 27.7763 13.2247L29.775 8.56262C29.8441 8.40515 29.9556 8.26997 30.0971 8.17213C30.2385 8.07429 30.4043 8.01763 30.5761 8.00846C30.7478 7.99929 30.9187 8.03797 31.0698 8.1202C31.2208 8.20242 31.3461 8.32497 31.4316 8.47418L33.9131 12.8948C33.9866 13.0262 34.091 13.1377 34.2173 13.2196C34.3436 13.3016 34.4879 13.3515 34.6379 13.365L39.6896 13.8303C39.8623 13.8461 40.0272 13.91 40.1655 14.0147C40.3038 14.1194 40.4101 14.2607 40.4722 14.4227C40.5343 14.5846 40.5499 14.7607 40.5171 14.9311C40.4843 15.1014 40.4045 15.2591 40.2867 15.3865L36.8391 19.1117C36.7371 19.2224 36.6634 19.3561 36.6244 19.5016C36.5855 19.647 36.5824 19.7997 36.6154 19.9466L37.7385 24.8965C37.7764 25.0655 37.7664 25.2417 37.7095 25.4053C37.6526 25.5689 37.5511 25.7134 37.4164 25.8223C37.2817 25.9313 37.1193 26.0004 36.9474 26.0219C36.7755 26.0435 36.6011 26.0165 36.4437 25.9441L31.8357 23.8227C31.6991 23.7595 31.5491 23.7305 31.3988 23.7383C31.2485 23.7461 31.1023 23.7904 30.973 23.8675L26.6136 26.4552C26.465 26.5416 26.2952 26.5851 26.1233 26.5808C25.9514 26.5765 25.784 26.5245 25.6399 26.4307C25.4957 26.3369 25.3804 26.2049 25.3069 26.0495C25.2333 25.894 25.2043 25.7212 25.2232 25.5502L25.8187 20.5122C25.8362 20.3627 25.8172 20.2113 25.7633 20.0708C25.7093 19.9303 25.622 19.8051 25.509 19.7058L21.6948 16.3585C21.5677 16.2435 21.4748 16.0956 21.4265 15.931C21.3782 15.7665 21.3764 15.5919 21.4213 15.4264C21.4661 15.2609 21.5559 15.1111 21.6806 14.9934C21.8054 14.8758 21.9602 14.795 22.1281 14.76Z"
          fill="url(#paint4_linear_7644_3158)"
        />
        <path
          d="M8.64844 31.5393C8.64814 32.1505 8.87005 32.741 9.27283 33.2007C9.6756 33.6604 10.2318 33.958 10.8377 34.038C10.9479 34.0533 11.059 34.0611 11.1703 34.0612H19.5539C19.7365 34.0609 19.9189 34.0459 20.0991 34.0162C20.8742 33.8872 21.5737 33.4748 22.062 32.8592C22.5502 32.2435 22.7924 31.4684 22.7414 30.6843C22.6905 29.9002 22.3501 29.163 21.7863 28.6157C21.2225 28.0684 20.4754 27.75 19.6902 27.7224C19.2847 26.8435 18.6045 26.1203 17.7522 25.6616C16.8998 25.2029 15.9215 25.0336 14.9646 25.1793C14.0077 25.3249 13.1241 25.7776 12.4469 26.4692C11.7696 27.1607 11.3355 28.0536 11.2099 29.0133H11.1703C10.8388 29.0133 10.5105 29.0787 10.2043 29.2057C9.89806 29.3327 9.61987 29.5188 9.38563 29.7534C9.1514 29.988 8.96573 30.2665 8.83923 30.573C8.71274 30.8794 8.6479 31.2078 8.64844 31.5393Z"
          fill="url(#paint5_linear_7644_3158)"
        />
        <path
          d="M46.5975 18.3315C46.4597 17.7906 46.9589 17.3033 47.4963 17.4543C48.0336 17.6053 48.206 18.2814 47.8066 18.6712C47.4071 19.0611 46.7354 18.8723 46.5975 18.3315Z"
          fill="#8680F8"
        />
        <path
          d="M13.101 18.3315C12.9631 17.7906 13.4624 17.3033 13.9997 17.4543C14.537 17.6053 14.7094 18.2814 14.31 18.6712C13.9105 19.0611 13.2389 18.8723 13.101 18.3315Z"
          fill="#8680F8"
        />
        <path
          d="M6.891 12.4753C7.04045 11.8562 7.92106 11.8562 8.07051 12.4753C8.12392 12.6966 8.29666 12.8693 8.5179 12.9227C9.13701 13.0722 9.13701 13.9528 8.5179 14.1022C8.29666 14.1557 8.12392 14.3284 8.07051 14.5496C7.92106 15.1688 7.04045 15.1688 6.891 14.5496C6.83759 14.3284 6.66485 14.1557 6.44361 14.1022C5.8245 13.9528 5.8245 13.0722 6.44361 12.9227C6.66485 12.8693 6.83759 12.6966 6.891 12.4753Z"
          fill="#8680F8"
        />
        <path
          d="M30.9582 38.5786C30.9582 38.4535 31.0595 38.3522 31.1845 38.3522C31.3095 38.3522 31.4109 38.4535 31.4109 38.5786C31.4109 38.7036 31.3095 38.8049 31.1845 38.8049C31.0595 38.8049 30.9582 38.7036 30.9582 38.5786Z"
          stroke="#FFBEBD"
          strokeWidth="2.48287"
        />
        <path
          d="M45.9642 33.0466C46.1137 32.4275 46.9943 32.4275 47.1438 33.0466C47.1972 33.2679 47.3699 33.4406 47.5911 33.494C48.2103 33.6435 48.2103 34.5241 47.5911 34.6735C47.3699 34.7269 47.1972 34.8997 47.1438 35.1209C46.9943 35.74 46.1137 35.74 45.9642 35.1209C45.9108 34.8997 45.7381 34.7269 45.5168 34.6735C44.8977 34.5241 44.8977 33.6435 45.5169 33.494C45.7381 33.4406 45.9108 33.2679 45.9642 33.0466Z"
          fill="#8680F8"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_7644_3158"
          x="-7.69292"
          y="13.6928"
          width="75.9605"
          height="51.0802"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="10.8865"
            result="effect1_foregroundBlur_7644_3158"
          />
        </filter>
        <filter
          id="filter1_f_7644_3158"
          x="1.3568"
          y="3.38707"
          width="59.6526"
          height="45.2967"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="10.3453"
            result="effect1_foregroundBlur_7644_3158"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7644_3158"
          x1="30.6529"
          y1="48.7385"
          x2="30.2412"
          y2="-21.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0CEFF" />
          <stop offset="1" stopColor="#E2E1FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7644_3158"
          x1="13"
          y1="-1"
          x2="47.5"
          y2="47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5D56F6" />
          <stop offset="1" stopColor="#E2E1FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7644_3158"
          x1="50.471"
          y1="18.4946"
          x2="43.438"
          y2="5.66113"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A09AF8" />
          <stop offset="1" stopColor="#FFF6F5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7644_3158"
          x1="18.9467"
          y1="11.9545"
          x2="13.6579"
          y2="2.30714"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6BFF8" />
          <stop offset="1" stopColor="#FFF6F5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7644_3158"
          x1="23.5"
          y1="12.5"
          x2="38"
          y2="29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5149F3" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_7644_3158"
          x1="20.0724"
          y1="37.9137"
          x2="13.0694"
          y2="25.1227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6BFF8" />
          <stop offset="1" stopColor="#FFF6F5" />
        </linearGradient>
        <clipPath id="clip0_7644_3158">
          <rect
            width="60"
            height="43"
            fill="white"
            transform="translate(0.222168)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
