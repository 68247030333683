import React, { FunctionComponent, useState, useCallback, useRef, KeyboardEvent } from "react";
import { Comment } from "/src/domain/studentTasks/StudentTasksData";
import { StudentBaseInfo } from "/src/domain/shared/StudentBaseInfo";
import { FormControl, Autocomplete, TextField, InputAdornment, IconButton, 
    Alert, AlertTitle, SxProps, AutocompleteChangeReason, AutocompleteInputChangeReason } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { trimName } from "/src/domain/shared/trimName";
import produce from "immer"
import { userSearch } from "/src/domain/shared/requests/userSearch";
import { debounce as _debounce, isString as _isString, 
    isObject as _isObject, last as _last, filter as _filter } from "lodash";
import { isMobile } from "is-mobile";

interface CommentInputProps {
    sx?: SxProps;
    draftComment: DraftComment;
    setDraftComment: (value: DraftComment) => void;
    sendComment: () => Promise<boolean>;
    disabled: boolean;
}

export type DraftComment = Pick<Comment, "parentCommentId" | "replyToStudent" | "text">;


const INTERVAL_BETWEEM_REQUESTS = 500;

export const CommentInput: FunctionComponent<CommentInputProps> = ({
    sx,
    draftComment,
    setDraftComment,
    sendComment,
    disabled
}) => {
    const { t } = useTranslation();

    const inputRef = useRef<HTMLInputElement>(null);
    const [options, setOptions] = useState<StudentBaseInfo[]>([]);
    const [openOptions, setOpenOptions] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [optionsLoading, setOptionsLoading] = useState<boolean>(false);
    const [sendingError, setSendingError] = useState<boolean>(false);

    const handleClikCloseReply = () => {
        const newDraftComment = produce(draftComment, draft => {
            delete draft.parentCommentId;
            delete draft.replyToStudent
        })

        setDraftComment(newDraftComment)
    }

    const setUsersState = async (name?: string) => {
        setOptionsLoading(true);

        const users = await userSearch(name);
        const clearedUsers = _filter(users, u => _isObject(u));
        setOptions(clearedUsers);

        setOptionsLoading(false);
    }

    const debounceSearch = useCallback(_debounce(
        (name?: string) => setUsersState(name),

        INTERVAL_BETWEEM_REQUESTS
    ), [])


    const handleAutocompleteChange = (e: React.SyntheticEvent<Element, Event>, value: StudentBaseInfo | string | null, reason: AutocompleteChangeReason) => {
        if (!(_isObject(value) && reason === 'selectOption'))
            return;

        const newDraftComment = produce(draftComment, draft => {
            draft.text = replaceMentionInText(inputRef, draft.text, value)
        })

        setDraftComment(newDraftComment)
    }

    const handleTextChange = (e: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason !== 'input') return;

        const newDraftComment = produce(draftComment, draft => {
                draft.text = value;
        })
        setDraftComment(newDraftComment)
    }

    const handleKeydown = async (e: KeyboardEvent) => {
        if (!openOptions) {
            // for keyboard arrows
            e.stopPropagation();
        }

        if (openOptions) {
            // do not send if options are open
            return;
        }

        if (!isMobile() && !e.shiftKey && e.keyCode == 13) 
            await handleSubmit(e);
    }

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        if (!draftComment.text.length)
            return;

        setLoading(true);
        const isSent = await sendComment();

        if (isSent) {
            setSendingError(false);
        } else {
            setSendingError(true);
        }
        
        inputRef.current?.blur();

        setLoading(false);
    }

    const handleSelect = () => {
        const startOfNameFromText = getStartOfNameFromText(inputRef);

        if (startOfNameFromText.length) {
            setOpenOptions(true);
            debounceSearch(startOfNameFromText);
        } else {
            setOpenOptions(false);
        }
    }
    
    return <FormControl fullWidth component="form" onSubmit={handleSubmit}>
        {draftComment?.replyToStudent && <Alert 
            severity="info" 
            icon={false}
            sx={{ 
                mb: "0.3rem",
            }}
            action={
                <IconButton onClick={handleClikCloseReply}>
                    <CloseIcon fontSize="inherit"/>
                </IconButton>
            }
        >
            <AlertTitle 
                sx={{ 
                    p: "0.25rem",
                }}
            >
                { t('replyTo') } { trimName(draftComment.replyToStudent.firstName, draftComment.replyToStudent.lastName) }
            </AlertTitle>
        </Alert>}
        <Autocomplete
            sx={ sx }
            inputValue={draftComment.text}
            onChange={(e, value, reason) => handleAutocompleteChange(e, value, reason)}
            onInputChange={(e, value, reason) => handleTextChange(e, value, reason)}
            open={openOptions}
            size="small"
            loading={optionsLoading}
            disabled={loading || disabled}
            loadingText={ t('loading') }
            freeSolo
            options={options}
            getOptionLabel={getOptionLabel}
            noOptionsText={ t('noResults') }
            disableClearable
            filterOptions={(options) => options}
            renderInput={(params) => <TextField
                {...params}
                onKeyDown={handleKeydown}
                inputRef={inputRef}
                onSelect={handleSelect}
                variant="standard"
                placeholder={ t('addComment') }
                autoComplete="off"
                multiline
                InputProps={{
                    ...params.InputProps,
                    inputProps: {
                        ...params.inputProps,
                        autoCapitalize: undefined
                    },
                    endAdornment: (
                    <InputAdornment position="start">
                        <IconButton 
                            sx={{ position: "relative", bottom: "0.25rem" }}
                            disabled={!draftComment.text.length || loading}
                            type="submit"
                        >
                            <SendIcon />
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
                helperText={ sendingError && t('serverError') }
                error={sendingError}
            />}
        />
    </FormControl>
}


const getOptionLabel = (value: StudentBaseInfo | string) => {
    if (_isString(value)) {
        return value;
    }
    return `@${value.id} (${trimName(value.firstName, value.lastName)})`
}

const replaceMentionInText = (inputRef: React.RefObject<HTMLInputElement>, text: string, studentInfo: StudentBaseInfo) => {
    const cursorIndex = inputRef.current?.selectionStart || 0;

    const startOfString = text.slice(0, cursorIndex);

    const startOfNameFromText = getStartOfNameFromText(inputRef);

    let startOfStringWithoutMention = startOfString.slice(0, startOfString.length - startOfNameFromText.length);
    startOfStringWithoutMention = startOfStringWithoutMention.endsWith('[@') ? startOfStringWithoutMention.slice(0, -2) : startOfStringWithoutMention.slice(0, -1);

    const endOfString = text.slice(cursorIndex);
    const oldNameMatch = endOfString.match(/\|[^\]]+\]/);
    
    let endOfStringWithNewMention = "";
    if (oldNameMatch) {
        endOfStringWithNewMention = endOfString.slice(oldNameMatch.index || 0 + oldNameMatch[0].length)
    }

    endOfStringWithNewMention = getMentionText(studentInfo) + endOfStringWithNewMention;

    return startOfStringWithoutMention + endOfStringWithNewMention;
}

export const getMentionText = (value: StudentBaseInfo) => {
    return `[@${value.id}|${trimName(value.firstName, value.lastName)}] `
}


const getStartOfNameFromText = (inputRef: React.RefObject<HTMLInputElement>) => {
    const value = inputRef.current?.value;
    const cursorIndex = inputRef.current?.selectionStart || 0;

    const startOfString = value?.slice(0, cursorIndex) || "";

    const lastSpaceIndexBeforeMention = getLastSpaceIndexBeforeMention(startOfString);

    const startOfMention = startOfString.slice(lastSpaceIndexBeforeMention + 1);

    if (checkSeparatorInMention(startOfMention)) {
        return "";
    }

    const name = getNameFromMention(startOfMention);
    if (name){
        return name;
    }

    const penultimateSpaceIndexBeforeMention = getLastSpaceIndexBeforeMention(startOfString, true);
    const startOfCompoundMention = startOfString.slice(penultimateSpaceIndexBeforeMention + 1);

    if (checkSeparatorInMention(startOfCompoundMention)) {
        return "";
    }

    const compoundName = getNameFromMention(startOfCompoundMention);
    return compoundName;
}


const checkSeparatorInMention = (startOfMention: string) => {
    return Boolean(startOfMention.match(/\|/));
}

const getLastSpaceIndexBeforeMention = (value: string, isPenultimate?: boolean) => {
    const whitespacesMatch = Array.from(value.matchAll(/\s|^/g));

    const subtrFromLength = isPenultimate ? 2 : 1;

    const lastMatchOfSpace = whitespacesMatch[whitespacesMatch.length - subtrFromLength];

    if (lastMatchOfSpace && lastMatchOfSpace[0] === '') {
        return -1;
    }

    return lastMatchOfSpace?.index || 0;
}

const getNameFromMention = (startOfMention: string) => {
    if (startOfMention.startsWith("@")) {
        const name = startOfMention.slice(1);
        return name;
    } 
    if (startOfMention.startsWith("[@")) {
        const name = startOfMention.slice(2);
        return name;
    }

    return "";
}