export interface SubscriptionItem {
    id: string;
    typ: SubscriptionType;
}

export enum SubscriptionType {
    ONE = "one",
    DBL = "dbl",
    FOLLOWED = "followed"
}

export type SubscriptionValue = SubscriptionType | boolean;