import React, { FunctionComponent } from "react";
import cs from "classnames";
import { makeStyles } from "@mui/styles";

type YoutubeIconProps = {
    className?: string,
};

export const YoutubeIcon: FunctionComponent<YoutubeIconProps> = ({className}) => {
    const classes = useStyles();

    return (
        <svg className={cs(classes.icon, className)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0.5C18.3572 0.5 23.5 5.64281 23.5 12C23.5 18.3572 18.3572 23.5 12 23.5C5.64281 23.5 0.5 18.3572 0.5 12C0.5 5.64281 5.64281 0.5 12 0.5Z" stroke="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.86 9.18006C18.86 9.18006 18.7231 8.20848 18.3035 7.78072C17.7711 7.21897 17.1743 7.21634 16.9009 7.18375C14.9417 7.04102 12.0031 7.04102 12.0031 7.04102H11.9969C11.9969 7.04102 9.05825 7.04102 7.09912 7.18375C6.82525 7.21634 6.22894 7.21897 5.69606 7.78072C5.2765 8.20859 5.14 9.18006 5.14 9.18006C5.14 9.18006 5 10.3213 5 11.462V12.5318C5 13.6729 5.14 14.8136 5.14 14.8136C5.14 14.8136 5.2765 15.7852 5.69606 16.213C6.22894 16.7747 6.9285 16.7571 7.24 16.8158C8.36 16.9241 12 16.9576 12 16.9576C12 16.9576 14.9417 16.9532 16.9009 16.8105C17.1743 16.7775 17.7711 16.7748 18.3035 16.2131C18.7231 15.7852 18.86 14.8138 18.86 14.8138C18.86 14.8138 19 13.673 19 12.5318V11.462C19 10.3213 18.86 9.18006 18.86 9.18006ZM10.5545 13.8281L10.5541 9.86661L14.3367 11.8542L10.5545 13.8281Z" fill="white"/>
        </svg>
    );
}

const useStyles = makeStyles({
    icon: {
        width: "1.5rem",
        height: "1.5rem",
    }
});

