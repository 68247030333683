import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const OtherActivity: FunctionComponent = () => {
    const { t } = useTranslation();

    return <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.64758 4.24121L6.277 8.96895C6.75985 9.46205 7.37446 9.80569 8.0474 9.95882V9.95882C8.30901 10.0183 8.57646 10.0484 8.84476 10.0484H9.51097C10.1362 10.0484 10.7485 10.2261 11.2766 10.5608V10.5608C11.5038 10.7048 11.7125 10.8761 11.8981 11.0708L16.3302 15.7205" stroke="#195190" strokeWidth="1.8" strokeLinecap="round"/>
    <path d="M9.48193 12.7223L10.8214 23.2376C10.9486 24.2364 11.7985 24.9849 12.8053 24.9849H12.8787" stroke="#195190" strokeWidth="1.8" strokeLinecap="round"/>
    <path d="M8.19449 18.2325L6.52262 23.3429C6.26639 24.1261 6.84997 24.9311 7.67402 24.9311V24.9311" stroke="#195190" strokeWidth="1.8" strokeLinecap="round"/>
    <g filter="url(#filter0_d_507_5)">
    <path d="M11.0474 5.05147C11.0474 5.97457 10.285 6.74444 9.31772 6.74444C8.35043 6.74444 7.58799 5.97457 7.58799 5.05147C7.58799 4.12837 8.35043 3.3585 9.31772 3.3585C10.285 3.3585 11.0474 4.12837 11.0474 5.05147Z" stroke="#195190" strokeWidth="1.8" shapeRendering="crispEdges"/>
    </g>
    <path d="M22.0281 1L22.0523 1.09025C22.5568 2.96757 24.0904 4.39103 26.0001 4.75441V4.75441" stroke="#195190" strokeWidth="1.8" strokeLinecap="round"/>
    <path d="M17.4807 2.64758H19.7269" stroke="#195190" strokeWidth="1.8" strokeLinecap="round"/>
    <path d="M19.2655 7.18787L20.8456 5.61366" stroke="#195190" strokeWidth="1.8" strokeLinecap="round"/>
    <path d="M23.7988 9.15698L23.7813 6.94222" stroke="#195190" strokeWidth="1.8" strokeLinecap="round"/>
    <defs>
    <filter id="filter0_d_507_5" x="2.68799" y="2.4585" width="13.2594" height="13.1859" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_507_5"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_507_5" result="shape"/>
    </filter>
    </defs>
    </svg>    
}
