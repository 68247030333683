import React, {FunctionComponent, useContext} from "react";
import { HeaderProps } from "/src/ui/shared/header/HeaderProps";
import { TeamLogo, TeamLogoSize } from "/src/ui/shared/TeamLogo";
import { useTranslation } from "react-i18next";
import { LogoIcon } from "/src/ui/shared/header/Logo";
import { CrownIcon } from "/src/ui/shared/header/CrownIcon";
import { Notifications } from "/src/ui/shared/header/notifications/Notifications";
import makeStyles from '@mui/styles/makeStyles';
import { theme } from "/src/assets/theme";
import { UserRole } from "/src/domain/shared/User";
import { PageContext, PageContextProps } from "/src/contexts/Page";
import { links } from "/src/domain/shared/links";

export const DesktopHeader: FunctionComponent<HeaderProps> = ({currentUser, teamLogo}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const { pageConfig } = useContext(PageContext) as PageContextProps;

    return (
        <header className={classes.header}>
            {!pageConfig?.isWhiteLabel && <div className={classes.logoContainer}>
                <a href={`${links.origin}/`} className={classes.logoLink}>
                    <LogoIcon className={classes.logo} />
                </a>
            </div>}
            <div className={classes.linkContainer}>
                {!pageConfig?.isWhiteLabel && <>
                    {Boolean(currentUser?.currentTrainerId) &&
                        <a href={`${links.origin}/profile?${currentUser?.currentTrainerId}`}
                            className={classes.link}>{t('myTrainer')}</a>}
                    {!Boolean(currentUser?.currentTrainerId) && currentUser?.role === UserRole.STUDENT &&
                        <a href={`${links.origin}/trainerlist`} className={classes.link}>{t('chooseTrainer')}</a>}
                    {Boolean(currentUser) && currentUser?.role === UserRole.STUDENT &&
                        <a href={`${links.origin}/student?${currentUser?.id}&v2=1`} className={classes.link}>{t('workouts')}</a>}
                    {Boolean(currentUser) && currentUser?.role === UserRole.TRAINER &&
                        <a href={`${links.origin}/students`} className={classes.link}>{t('students')}</a>}
                    {!Boolean(currentUser?.isPremium) &&
                        <a href={`${links.origin}/premium`} className={classes.link}><CrownIcon
                            className={classes.icon} />{t('premium')}</a>}
                    <div className={classes.divider} />
                    <a href={`${links.challengesOrigin}/`} className={classes.link}>{t('challenges')}</a>
                    <a href={`${links.challengesOrigin}/rating`} className={classes.link}>{t('rating')}</a>
                    <a href={links.answersLink} className={classes.link}>{t('faq')}</a>
                    <div className={classes.divider} /></>}
                {!Boolean(currentUser) && <a href={pageConfig?.authUrl || `${links.origin}/login`}
                    className={classes.link}>{t('login')}</a>}
                {Boolean(currentUser) && <>
                    {!pageConfig?.isWhiteLabel && <>
                        <a href={`${links.origin}/money`} className={classes.link}>{t('money')}</a>
                        <a href={`${links.origin}/profile?${currentUser?.id}`} className={classes.link}>{t('myProfile')}</a></>}
                    <Notifications/>
                    <a href={`${links.origin}/logout`} className={classes.link}>{t('logout')}</a>
                </>}
                {Boolean(teamLogo?.length) && Boolean(currentUser) &&
                    <TeamLogo teamLogoUrl={teamLogo as string}
                        size={TeamLogoSize.NORMAL}
                        className={classes.teamLogo}
                    />}
            </div>
        </header>
    );
};

const useStyles = makeStyles({
    header: {
        backgroundColor: theme.primaryColor,
        height: "3rem",
        width: "100%",
        zIndex: 100,
        display: "flex",
        justifyContent: "center"
    },
    logo: {
        height: '100%',
        width: '12rem',
        flex: 1,
    },
    logoLink: {
        height: '2rem'
    },
    logoContainer: {
        maxHeight: "2rem",
        width: "12rem",
        padding: "0.65rem 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
    },
    teamLogo: {
        height: "2rem",
        width: "6rem",
        backgroundPosition: "center"
    },
    linkContainer: {
        display: "flex",
        alignItems: "center",
    },
    link: {
        color: theme.WHITE_COLOR,
        textDecoration: "none",
        "&:hover": { textDecoration: "none" },
        display: "flex",
        alignItems: "center",
        padding: "0.75rem 1rem"
    },
    icon: {
        marginRight: "0.5rem"
    },
    divider: {
        height: "8px",
        width: "1px",
        margin: "0 0.75rem"
    }
});