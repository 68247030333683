import React, { FunctionComponent } from 'react';
import { PostData } from '/src/domain/studentTasks/StudentTasksData';
import { Link, SxProps } from '@mui/material';
import { HtmlStyleContainer } from '/src/ui/shared/HtmlStyleContainer';
import {
  getHtmlPreview,
  HtmlData,
} from '/src/domain/studentTasks/getHtmlPreview';
import parse from 'html-react-parser';
import { AudioView } from './AudioView';
import { TagsView } from '/src/ui/studentTasks/studentTasks/TagsView';
import { useTranslation } from 'react-i18next';
import { links } from '/src/domain/shared/links';

export interface PostContentViewProps extends PostData {
  isPreview?: boolean | 'onlyHeader';
  sx?: SxProps;
  addDomainToImageSrc?: boolean;
}

export const PostContentView: FunctionComponent<PostContentViewProps> = (
  props,
) => {
  const { t } = useTranslation();

  const text = props.addDomainToImageSrc
    ? getTextWithDomainInImgSrc(props.txt)
    : props.txt;

  const getPreview = (): HtmlData => {
    if (props.isPreview === 'onlyHeader') {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, 'text/html');
      const firstH1 = doc.querySelector('h1');

      return {
        isPreview: true,
        result: firstH1 ? parse(firstH1.outerHTML) : '',
      };
    }

    return getHtmlPreview(text, undefined, true);
  };
  const preview = props.isPreview ? getPreview() : null;

  return (
    <HtmlStyleContainer
      sx={{
        mt: '0.75rem',
        borderRadius: '5px',
        ...props.sx,
      }}
    >
      {preview ? (
        <>
          {preview.result}
          <Link
            href={`${links.origin}/post/${props.postid}/`}
            sx={{ display: 'block', mt: '0.75rem' }}
          >
            {preview.isPreview ? `${t('readMore')}` : t('openPost')}
          </Link>
        </>
      ) : (
        parse(text)
      )}
      {!!(props.audiosec && props.audiosrc) && (
        <AudioView
          postid={props.postid}
          d={props.d}
          txt={text}
          viewMode={props.viewMode}
          audiosec={props.audiosec}
          audiosrc={props.audiosrc}
        />
      )}
      {props.tags && <TagsView tags={props.tags} />}
    </HtmlStyleContainer>
  );
};

const getTextWithDomainInImgSrc = (text: string) => {
  const tempContainer = document.createElement('div');
  tempContainer.innerHTML = text;
  const images = tempContainer.querySelectorAll('img');

  images.forEach((image) => {
    const src = image.getAttribute('src');
    if (src && !src.startsWith('http')) {
      const absolutePath = links.origin + src;
      image.setAttribute('src', absolutePath);
    }
  });

  const updatedHtmlString = tempContainer.innerHTML;
  return updatedHtmlString;
};
