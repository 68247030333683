import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/lv";
import "dayjs/locale/en";
import "dayjs/locale/uk";

window.__locale = window.navigator.language.slice(0, 2);
dayjs.locale(window.__locale);

const translations = {
    'en': require('./assets/i18n/en.json'),
    'lv': require('./assets/i18n/lv.json'),
    'ru': require('./assets/i18n/ru.json'),
    'uk': require('./assets/i18n/uk.json'),
}
const defaultNS = 'translations';

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: window.__locale,
    defaultNS,
    resources: {},
    interpolation: {
        escapeValue: false,
    }
});

Object.keys(translations).forEach((lang: string) => {
    //@ts-ignore
    i18n.addResourceBundle(lang, defaultNS, translations[lang]);
});

export default i18n;
