import { Difficulty } from "/src/domain/studentTasks/StudentTasksData";
import { theme } from "/src/assets/theme";

export type DifficultyInfoData = {
    [key in Difficulty]: string;
}

export const difficultyText: DifficultyInfoData = {
    [Difficulty.EASУ]: 'easу',
    [Difficulty.FINE]: 'fine',
    [Difficulty.HARD_DONE]: 'hardButDone',
    [Difficulty.HARD_NOT_DONE]: 'hardAndNotDone',
    [Difficulty.INJURY]: 'injury'
}

export const difficultyColors: DifficultyInfoData = {
    [Difficulty.EASУ]: theme.purpleColor,
    [Difficulty.FINE]: theme.lightGreenColor,
    [Difficulty.HARD_DONE]: theme.orangeColor,
    [Difficulty.HARD_NOT_DONE]: theme.secondaryColor,
    [Difficulty.INJURY]: theme.darkColor
}