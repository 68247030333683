import React, { FunctionComponent } from "react";
import { IconsProps } from "./iconsProps";

export const Envelope: FunctionComponent<IconsProps> = ({ className }) => {

    return <svg className={className} viewBox="17 0 45 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_4411_7747)">
    <ellipse cx="40.6586" cy="31.5273" rx="18.4409" ry="3.55753" fill="#1F88F8" fillOpacity="0.35"/>
    </g>
    <path d="M40.8598 38.6288C51.4214 38.6288 59.9833 30.0668 59.9833 19.5051C59.9833 8.94333 51.4214 0.381348 40.8598 0.381348C30.2982 0.381348 21.7363 8.94333 21.7363 19.5051C21.7363 30.0668 30.2982 38.6288 40.8598 38.6288Z" fill="url(#paint0_linear_4411_7747)"/>
    <g filter="url(#filter1_d_4411_7747)">
    <path d="M56.8795 13.3232L40.492 24.7597L24.1045 13.3232L39.106 2.84963C39.5128 2.56608 39.9968 2.41406 40.4927 2.41406C40.9886 2.41406 41.4725 2.56608 41.8794 2.84963L44.8718 4.94162L45.6552 5.4857L53.5443 10.9945L54.3754 11.578L56.8795 13.3232Z" fill="url(#paint1_linear_4411_7747)"/>
    </g>
    <path d="M37.8274 22.9004L24.1045 32.6585V13.3232L37.8274 22.9004Z" fill="url(#paint2_linear_4411_7747)"/>
    <path d="M43.1582 22.9004L56.8811 32.6585V13.3232L43.1582 22.9004Z" fill="url(#paint3_linear_4411_7747)"/>
    <path d="M24.1045 32.6587L39.0883 22.0056C39.4983 21.7136 39.9892 21.5566 40.4927 21.5566C40.9961 21.5566 41.487 21.7136 41.897 22.0056L56.8795 32.6587H24.1045Z" fill="url(#paint4_linear_4411_7747)"/>
    <g filter="url(#filter2_d_4411_7747)">
    <path d="M25.8368 9.81982C23.3245 10.995 20.6248 11.7185 17.8615 11.957C18.2349 12.8249 20.8878 17.1696 28.7632 20.7495C28.9761 20.7823 33.8939 18.4945 36.5928 16.9256C36.5011 17.0566 28.2097 14.0057 25.8368 9.81982Z" fill="url(#paint5_linear_4411_7747)"/>
    </g>
    <path d="M26.0302 11.9798C25.9146 11.8996 25.7682 11.8779 25.6343 11.9212L21.2388 13.3433C21.0813 13.3943 21.0252 13.5882 21.1311 13.7154V13.7154C21.2165 13.8177 21.3561 13.8569 21.4822 13.8137L25.9899 12.2697C26.1171 12.2261 26.1407 12.0564 26.0302 11.9798V11.9798Z" fill="#C9DFFF"/>
    <path d="M25.954 13.7312C25.8426 13.6265 25.6825 13.5916 25.5376 13.6404L22.4946 14.6645C22.3605 14.7097 22.3131 14.8752 22.4031 14.9845V14.9845C22.4766 15.0738 22.5977 15.1083 22.7072 15.071L25.8994 13.9841C26.0072 13.9474 26.037 13.8091 25.954 13.7312V13.7312Z" fill="#C9DFFF"/>
    <path d="M27.5877 14.5984C27.4714 14.5052 27.3149 14.4794 27.1748 14.5303L23.6697 15.8043C23.5321 15.8543 23.4907 16.0288 23.5912 16.1352V16.1352C23.668 16.2167 23.786 16.2445 23.8912 16.2059L27.5441 14.8654C27.6577 14.8237 27.6821 14.674 27.5877 14.5984V14.5984Z" fill="#C9DFFF"/>
    <path d="M28.8127 17.2284C28.705 17.1093 28.5351 17.0686 28.3851 17.1258L26.3819 17.8903C26.2589 17.9372 26.2146 18.0885 26.2928 18.1944V18.1944C26.3661 18.2937 26.4967 18.3316 26.6117 18.2871L28.7588 17.4555C28.8514 17.4196 28.8793 17.3021 28.8127 17.2284V17.2284Z" fill="#C9DFFF"/>
    <g filter="url(#filter3_d_4411_7747)">
    <path d="M54.8782 15.8784C57.9245 17.3034 61.1981 18.1806 64.5487 18.4698C64.096 19.5223 60.8791 24.7905 51.3297 29.1313C51.0716 29.1711 45.1084 26.397 41.8359 24.4946C41.9471 24.6535 52.0009 20.954 54.8782 15.8784Z" fill="url(#paint6_linear_4411_7747)"/>
    </g>
    <path d="M54.6439 18.4978C54.7841 18.4005 54.9616 18.3743 55.124 18.4268L60.4538 20.1512C60.6447 20.2129 60.7128 20.4481 60.5843 20.6023V20.6023C60.4809 20.7264 60.3115 20.7738 60.1586 20.7215L54.6928 18.8493C54.5386 18.7965 54.51 18.5907 54.6439 18.4978V18.4978Z" fill="#C9DFFF"/>
    <path d="M53.15 19.9175C53.2852 19.7906 53.4792 19.7483 53.655 19.8074L57.3447 21.0492C57.5074 21.104 57.5649 21.3047 57.4558 21.4372V21.4372C57.3666 21.5455 57.2198 21.5873 57.087 21.5421L53.2163 20.2241C53.0856 20.1796 53.0494 20.012 53.15 19.9175V19.9175Z" fill="#C9DFFF"/>
    <path d="M51.6166 21.0963C51.7577 20.9833 51.9474 20.9521 52.1173 21.0138L56.3674 22.5585C56.5342 22.6192 56.5845 22.8308 56.4627 22.9599V22.9599C56.3695 23.0587 56.2264 23.0924 56.0989 23.0456L51.6695 21.4201C51.5318 21.3696 51.5021 21.188 51.6166 21.0963V21.0963Z" fill="#C9DFFF"/>
    <path d="M48.1672 23.4534C48.2979 23.309 48.5038 23.2596 48.6857 23.329L51.1147 24.2559C51.2639 24.3128 51.3176 24.4963 51.2228 24.6247V24.6247C51.1339 24.7451 50.9755 24.7911 50.836 24.737L48.2325 23.7287C48.1203 23.6852 48.0864 23.5427 48.1672 23.4534V23.4534Z" fill="#C9DFFF"/>
    <path d="M49.7522 22.1949C49.896 22.1047 50.0734 22.0861 50.2328 22.1443L55.8294 24.1907C56.0083 24.2561 56.0516 24.4893 55.9082 24.6146V24.6146C55.8133 24.6974 55.6808 24.7219 55.5626 24.6784L49.7893 22.5516C49.6336 22.4943 49.6117 22.2831 49.7522 22.1949V22.1949Z" fill="#C9DFFF"/>
    <path d="M30.0254 15.1729C29.9068 15.0985 29.7605 15.0831 29.6291 15.1311L25.0135 16.8187C24.866 16.8727 24.8303 17.065 24.9486 17.1683V17.1683C25.0268 17.2367 25.1361 17.2569 25.2336 17.221L29.9948 15.467C30.1232 15.4197 30.1413 15.2456 30.0254 15.1729V15.1729Z" fill="#C9DFFF"/>
    <path d="M55.6151 4.88265C55.6151 4.72253 55.7449 4.59271 55.9051 4.59271C56.0652 4.59271 56.1951 4.72253 56.1951 4.88265C56.1951 5.04276 56.0652 5.17259 55.9051 5.17259C55.7449 5.17259 55.6151 5.04276 55.6151 4.88265Z" stroke="url(#paint7_linear_4411_7747)" strokeWidth="2.61276"/>
    <path d="M30.0486 6.74864C30.1983 6.12871 31.0801 6.12871 31.2297 6.74864V6.74864C31.2832 6.97018 31.4562 7.14315 31.6777 7.19663V7.19663C32.2976 7.34628 32.2976 8.22807 31.6777 8.37772V8.37772C31.4562 8.4312 31.2832 8.60417 31.2297 8.82571V8.82571C31.0801 9.44565 30.1983 9.44565 30.0486 8.82571V8.82571C29.9952 8.60417 29.8222 8.4312 29.6007 8.37772V8.37772C28.9807 8.22807 28.9807 7.34628 29.6007 7.19663V7.19663C29.8222 7.14315 29.9952 6.97018 30.0486 6.74864V6.74864Z" fill="url(#paint8_linear_4411_7747)"/>
    <path d="M59.1434 32.005C59.293 31.3851 60.1748 31.3851 60.3245 32.005V32.005C60.3779 32.2265 60.5509 32.3995 60.7724 32.453V32.453C61.3924 32.6026 61.3924 33.4844 60.7724 33.6341V33.6341C60.5509 33.6876 60.3779 33.8605 60.3245 34.0821V34.0821C60.1748 34.702 59.293 34.702 59.1434 34.0821V34.0821C59.0899 33.8605 58.9169 33.6876 58.6954 33.6341V33.6341C58.0755 33.4844 58.0755 32.6026 58.6954 32.453V32.453C58.9169 32.3995 59.0899 32.2265 59.1434 32.005V32.005Z" fill="url(#paint9_linear_4411_7747)"/>
    <path d="M21.5325 28.2798C22.0843 28.1915 22.5249 28.7336 22.3255 29.2558V29.2558C22.1261 29.7779 21.4363 29.8883 21.0838 29.4546V29.4546C20.7313 29.0209 20.9806 28.3682 21.5325 28.2798V28.2798Z" fill="url(#paint10_linear_4411_7747)"/>
    <path d="M60.1584 14.5202C60.0203 13.9787 60.5203 13.4907 61.0583 13.6419V13.6419C61.5964 13.7931 61.769 14.4701 61.369 14.8604V14.8604C60.9691 15.2508 60.2965 15.0618 60.1584 14.5202V14.5202Z" fill="url(#paint11_linear_4411_7747)"/>
    <defs>
    <filter id="filter0_f_4411_7747" x="0.444778" y="6.19673" width="80.4278" height="50.6612" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_4411_7747"/>
    </filter>
    <filter id="filter1_d_4411_7747" x="20.6208" y="2.41406" width="39.7427" height="29.3131" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3.48368"/>
    <feGaussianBlur stdDeviation="1.74184"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4411_7747"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4411_7747" result="shape"/>
    </filter>
    <filter id="filter2_d_4411_7747" x="7.41029" y="2.85247" width="39.6335" height="31.8323" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3.48368"/>
    <feGaussianBlur stdDeviation="5.22552"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.0196078 0 0 0 0 0.168627 0 0 0 0 0.396078 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4411_7747"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4411_7747" result="shape"/>
    </filter>
    <filter id="filter3_d_4411_7747" x="21.7501" y="14.1366" width="52.3242" height="42.8647" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-5.28021" dy="13.0638"/>
    <feGaussianBlur stdDeviation="7.40282"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.021441 0 0 0 0 0.168056 0 0 0 0 0.395833 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4411_7747"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4411_7747" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_4411_7747" x1="40.7124" y1="-5.8458" x2="41.1041" y2="61.3138" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CAE0FF" stopOpacity="0"/>
    <stop offset="1" stopColor="#BCD8FF"/>
    </linearGradient>
    <linearGradient id="paint1_linear_4411_7747" x1="40.492" y1="2.41406" x2="40.492" y2="24.7597" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EDF4FF"/>
    <stop offset="1" stopColor="#ABCCFF"/>
    </linearGradient>
    <linearGradient id="paint2_linear_4411_7747" x1="30.9659" y1="13.3232" x2="30.9659" y2="32.6585" gradientUnits="userSpaceOnUse">
    <stop stopColor="#ABCCFF"/>
    <stop offset="1" stopColor="#70A8FF"/>
    </linearGradient>
    <linearGradient id="paint3_linear_4411_7747" x1="50.0196" y1="13.3232" x2="50.0196" y2="32.6585" gradientUnits="userSpaceOnUse">
    <stop stopColor="#ABCCFF"/>
    <stop offset="1" stopColor="#70A8FF"/>
    </linearGradient>
    <linearGradient id="paint4_linear_4411_7747" x1="40.492" y1="21.5566" x2="40.492" y2="32.6587" gradientUnits="userSpaceOnUse">
    <stop stopColor="#70A8FF"/>
    <stop offset="1" stopColor="#5597FD"/>
    </linearGradient>
    <linearGradient id="paint5_linear_4411_7747" x1="19.8943" y1="14.6842" x2="32.1641" y2="12.2884" gradientUnits="userSpaceOnUse">
    <stop stopColor="#C6DDFF"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <linearGradient id="paint6_linear_4411_7747" x1="62.0838" y1="21.7768" x2="47.206" y2="18.8717" gradientUnits="userSpaceOnUse">
    <stop stopColor="#C6DDFF"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <linearGradient id="paint7_linear_4411_7747" x1="54.6557" y1="4.24738" x2="57.8321" y2="7.00019" gradientUnits="userSpaceOnUse">
    <stop stopColor="#94BFFF"/>
    <stop offset="1" stopColor="#4C94FE"/>
    </linearGradient>
    <linearGradient id="paint8_linear_4411_7747" x1="30.6392" y1="4.30225" x2="30.6392" y2="11.2721" gradientUnits="userSpaceOnUse">
    <stop stopColor="#70A8FF"/>
    <stop offset="1" stopColor="#5597FD"/>
    </linearGradient>
    <linearGradient id="paint9_linear_4411_7747" x1="59.7339" y1="29.5586" x2="59.7339" y2="36.5285" gradientUnits="userSpaceOnUse">
    <stop stopColor="#70A8FF"/>
    <stop offset="1" stopColor="#5597FD"/>
    </linearGradient>
    <linearGradient id="paint10_linear_4411_7747" x1="22.7741" y1="28.081" x2="20.5204" y2="29.9124" gradientUnits="userSpaceOnUse">
    <stop stopColor="#70A8FF"/>
    <stop offset="1" stopColor="#5597FD"/>
    </linearGradient>
    <linearGradient id="paint11_linear_4411_7747" x1="59.8477" y1="13.3017" x2="61.8762" y2="15.38" gradientUnits="userSpaceOnUse">
    <stop stopColor="#70A8FF"/>
    <stop offset="1" stopColor="#5597FD"/>
    </linearGradient>
    </defs>
    </svg>    
}
