import { isNaN as _isNaN } from "lodash";

const MAX_VALUE_UNIT_OF_TIME = 59;

export const checkValidTime = (value: string) => {
    let isValid = true;
    const timeArr = value.split(":");
    timeArr.forEach((item) => {
        const itemNumber = Number(item);
        if (itemNumber > MAX_VALUE_UNIT_OF_TIME || _isNaN(itemNumber)) {
            isValid = false;
        }
    })

    return isValid;
}

