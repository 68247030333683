import React, { FunctionComponent } from "react";
import { TrainerInfo } from "/src/domain/dashboard/DashboardData";
import { Box, Typography } from "@mui/material";
import { TrainersView } from "./TrainersView";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

interface TrainersBlockProps {
    trainers: TrainerInfo[];
}

export const TrainersBlock: FunctionComponent<TrainersBlockProps> = ({ trainers }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return <Box className={classes.container}>
        <Typography component="h5" className={classes.header}>
            { t('chooseYourTrainer') }
        </Typography>
        <Typography className={classes.description}>
            { t('trainerAd') }
        </Typography>
        <TrainersView trainers={trainers}/>
    </Box>
}

const useStyles = makeStyles(muiTheme => ({
    container: {
        background: "linear-gradient(180deg, #E0F8FF 0%, #D8FFBA 100%)",
        marginTop: "1.5rem",
        padding: "1.8rem 0 1rem"
    },
    header: {
        fontWeight: 700,
        color: muiTheme.palette.primary.main,
        margin: "0 1.5rem",
        fontSize: "1.25rem"
    },
    description: {
        fontWeight: 400,
        margin: "0.25rem 1.5rem"
    }
}))