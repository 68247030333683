import React, { FunctionComponent, useContext, useEffect } from 'react';
import { StudentBaseInfo } from '/src/domain/shared/StudentBaseInfo';
import {
  CurrentUserContext,
  CurrentUserContextProps,
} from '/src/contexts/shared/CurrentUserContext';
import { PostContext, PostContextProps } from '/src/contexts/PostContext';
import { Box } from '@mui/material';
import { PostView } from '/src/ui/studentTasks/studentTasks/posts/PostView';
import { PostsStub } from '/src/ui/dashboard/PostsStub';
import { useTranslation } from 'react-i18next';

interface RepostedPostsViewProps {
  repostOwner: StudentBaseInfo;
  className?: string;
}

export const RepostedPostsView: FunctionComponent<RepostedPostsViewProps> = ({
  repostOwner,
  className
}) => {
  const { t } = useTranslation();
  const { currentUser, currentDate } = useContext(
    CurrentUserContext,
  ) as CurrentUserContextProps;
  const { postOwners, repostedPosts, setRepostedPostsState } = useContext(
    PostContext,
  ) as PostContextProps;

  useEffect(() => {
    setRepostedPostsState({ userId: repostOwner.id });
  }, []);

  return (
    <Box className={className}>
      {!!repostedPosts?.length ? (
        repostedPosts.map((p) => {
          if (!p.userid) return null;

          return (
            <PostView
              key={p.postid}
              {...p}
              type="post"
              currentDate={currentDate}
              isReadOnly={true}
              currentUserId={currentUser?.id}
              isPreview
              userInfo={postOwners[p.userid]}
              isRepostAvailable={Boolean(
                currentUser &&
                  currentUser?.id === postOwners[p.userid]?.currentTrainerId,
              )}
              isRepostedPost={Boolean(
                currentUser && currentUser.id == repostOwner.id,
              )}
              isRepostPreview
            />
          );
        })
      ) : (
        <PostsStub sx={{ mt: '1rem' }} />
      )}
    </Box>
  );
};
