import React, { FunctionComponent } from "react";
import { TrainerInfo } from "/src/domain/dashboard/DashboardData";
import { Card, Typography, useMediaQuery, useTheme, styled } from "@mui/material";
import { Avatar, AvatarSize } from "/src/ui/shared/Avatar";
import { RatingStars } from "/src/ui/shared/RatingStars";
import { MoreTrainers } from "./icons/MoreTrainers"
import { StarSize } from "/src/ui/shared/Star";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { trimName } from "/src/domain/shared/trimName";

interface TrainerProps {
    trainer?: TrainerInfo
}

export const TrainerEntryView: FunctionComponent<TrainerProps> = ({
    trainer
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const upSmMediaQuery = useMediaQuery(useTheme().breakpoints.up("sm"));

    const handleClick = () => {
        if (!trainer) {
            location.href="/trainerlist"
            return;
        }
        location.href=`/profile?${trainer.id}`
        
    }

    return <Card 
        className={classes.container}
        onClick={handleClick}
    >
        {trainer ? <>
            <Avatar
                avatarUrl={ trainer.avatarUrl }
                size={ upSmMediaQuery ? AvatarSize.NORMAL : AvatarSize.MEDIUM }
            />
            {!trainer.isRatingHidden && <RatingStars
                starSize={upSmMediaQuery ? StarSize.NORMAL : StarSize.SMALL}
                total={5}
                value={trainer.avgRating}
                className={classes.rating}
            />}
            <Label>
                { trimName(trainer.firstName, trainer.lastName) }
            </Label>
        </>
        : <>
            <MoreTrainers className={classes.moreTrainersIcon}/>
            <Label>
                { t('showMoreTrainers') }
            </Label>
        </>
    }
    </Card>
}

const Label = styled(Typography)(({ theme }) => ({
    marginTop: "0.75rem",
    fontWeight: 700,
    fontSize: "0.8rem",
    lineHeight: "0.9rem",
    width: "min-content",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
        fontSize: "1rem",
        lineHeight: "1.2rem"
    }
}))

const useStyles = makeStyles(muiTheme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "5px",
        padding: "0.75rem 1rem",
        overflow: "initial",
        minWidth: "6rem",
        [muiTheme.breakpoints.up("sm")]: {
            minWidth: "8rem"
        },
        margin: "1.5rem",
        "&:not(:first-child)": {
            marginLeft: 0
        },
        "&:hover": {
            transform: "scale(1.1)",
            cursor: "pointer"
        }
    },
    avatarContainer: {
        position: "relative"
    },
    rating: {
        marginTop: "0.5rem"
    },
    moreTrainersIcon: {
        marginTop: "1rem",
        [muiTheme.breakpoints.up("sm")]: {
            height: "5rem",
            width: "5rem"
        }
    }
}))
