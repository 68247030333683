import React, { FunctionComponent, useMemo } from 'react';

import {
  ChallengeFormula,
  instanceOfDsqRecord,
  instanceOfTeamRecord,
  instanceOfSingleRaceRecord,
  Protocol,
  ProtocolRecord,
  SingleRaceRecord,
  TeamRaceRecord,
} from '/src/domain/challenges/ChallengeProtocolData';
import { sec2hms } from '/src/utils/time-utils';
import { formatRecordResult, getValueLabel } from '/src/utils/protocol-utils';
import { trimName } from '/src/domain/shared/trimName';
import { LeaderBoard } from './LeaderBoard';
import { ResultCardProps, BoardType } from './ResultCard';
import { ResultCardRowProps } from './ResultCardRow';
import { isObject as _isObject } from 'lodash';
import { links } from '/src/domain/shared/links';

interface LeaderBoardContainerProps {
  protocols: Protocol[];
  challengeType: ChallengeFormula;
}

export const LeaderBoardContainer: FunctionComponent<
  LeaderBoardContainerProps
> = ({ protocols, challengeType }) => {
  if (!protocols || !protocols.length) return null;
  const boards: ResultCardProps[] = useMemo(() => {
    return getBoards(protocols, challengeType);
  }, [protocols]);

  return <LeaderBoard boards={boards} />;
};

export const getResultLabel = (
  record: ProtocolRecord,
  challengeType: ChallengeFormula,
) => {
  if (instanceOfTeamRecord(record)) {
    return getValueLabel(challengeType);
  }
  if (instanceOfSingleRaceRecord(record)) {
    return record.time ? 'time' : record.parameters[0].label;
  }

  return '';
};

export const getResultValue = (
  record: TeamRaceRecord | SingleRaceRecord,
  challengeType: ChallengeFormula,
) => {
  if (instanceOfTeamRecord(record)) {
    if (!record.result) return null;

    const result = _isObject(record.result)
      ? record.result.value
      : record.result;
    return formatRecordResult(challengeType, result);
  } else if (record.time) {
    return sec2hms(record.time);
  } else {
    return formatRecordResult(challengeType, record.parameters[0].value);
  }
};

const getBoards = (protocols: Protocol[], challengeType: ChallengeFormula) => {
  const boards: ResultCardProps[] = [];
  for (const p of protocols) {
    if (p.records.length == 0 || instanceOfDsqRecord(p.records[0])) continue;

    const board: ResultCardProps = {
      type: instanceOfTeamRecord(p.records[0])
        ? BoardType.TEAM
        : BoardType.SINGLE,
      title: p.title,
      leaderLabel: 'challengeLeaders',
      resultLabel: getResultLabel(p.records[0], challengeType),
      records: getRecords(p.records, challengeType),
    };

    boards.push(board);
  }
  return boards;
};

const NUMBER_RECORDS_ON_BOARD = 3;

const getRecords = (
  records: ProtocolRecord[],
  challengeType: ChallengeFormula,
) => {
  const newRecords: ResultCardRowProps[] = [];
  for (const r of records) {
    if (!instanceOfTeamRecord(r) && !instanceOfSingleRaceRecord(r)) continue;

    const result = getResultValue(r, challengeType);

    if (!result) continue;

    const newRecord: ResultCardRowProps = {
      place: r.place,
      label: instanceOfTeamRecord(r)
        ? r.name
        : trimName(r.participant.firstName, r.participant.lastName),
      value: result,
    };

    if (instanceOfSingleRaceRecord(r)) {
      newRecord.imgUrl = r.participant.avatarUrl;
      newRecord.link = `${links.origin}/profile?${r.participant.id}`;
    }

    newRecords.push(newRecord);

    if (newRecords.length === NUMBER_RECORDS_ON_BOARD) break;
  }

  return newRecords;
};
