import { Like, StudentInfoForLike } from "/src/domain/studentTasks/StudentTasksData";
import { ActivityNotificationType } from "/src/domain/notifications/NotificationsData";
import { links } from "/src/domain/shared/links";
import { convertObjectToFormData } from "/src/domain/shared/convertObjectToFormData";
import axios from "axios";

export const addLike = async (entityId: string, type: ActivityNotificationType): Promise<Like | null> => {
    try {
        const response = await axios.post(links.origin + `/apiweb/social.php`, convertObjectToFormData({
            action: 'userlike_add',
            id: entityId,
            idtyp: type
        }));
    
        return response.data.userlike
    } catch (e) {
        console.log("addLike:", e);
        return null
    }
}

export const deleteLike = async (entityId: string, type: ActivityNotificationType): Promise<Like | null> => {
    try {
        const response = await axios.post(links.origin + `/apiweb/social.php`, convertObjectToFormData({
            action: 'userlike_del',
            id: entityId,
            idtyp: type
        }));
    
        return response.data.userlike;
    } catch (e) {
        console.log("deleteLike:", e);
        return null
    }
}

export const getListOfLikes = async (entityId: string, type: ActivityNotificationType): Promise<StudentInfoForLike[]> => {
    try {
        const response = await axios.get(links.origin + `/apiweb/social.php`, {
            params: {
                action: "userlike",
                id: entityId,
                idtyp: type
            }
        })

        return response.data.userlike;
    } catch (e) {
        console.log("getListOfLikes:", e);
        return [];
    }
}

export const getLike = async (entityId: string, type: ActivityNotificationType): Promise<Like | null> => {
    try {
        const response = await axios.get(links.origin + `/apiweb/social.php`, {
            params: {
                action: "userlike_one",
                id: entityId,
                idtyp: type
            }
        });

        return response.data.userlike;
    } catch (e) {
        console.log("getLike:", e);
        return null;
    }
}