import React, { FunctionComponent, useContext, useEffect } from 'react';
import { User } from '/src/domain/shared/User';
import { CurrentUserProvider } from '/src/contexts/shared/CurrentUserContext';
import {
  PostProvider,
  PostContext,
  PostContextProps,
} from '/src/contexts/PostContext';
import {
  PostBannerTemplate,
  PostBannerTemplateProps,
} from './PostBannerTemplate';

interface PostBannerContainerProps
  extends Omit<PostBannerTemplateProps, 'post'> {
  postId: string;
  currentUser: User;
}

export const PostBannerContainer: FunctionComponent<
  PostBannerContainerProps
> = (props) => {
  return (
    <CurrentUserProvider defaultUser={props.currentUser}>
      <PostProvider>
        <PostBannerContent {...props} />
      </PostProvider>
    </CurrentUserProvider>
  );
};

export const PostBannerContent: FunctionComponent<PostBannerContainerProps> = (
  props,
) => {
  const { postByStatus, requestPostByStatus } = useContext(PostContext) as PostContextProps;

  useEffect(() => {
    requestPostByStatus({
      postId: props.postId,
      currentUserId: null,
      club: null
    });
  }, []);

  if (!postByStatus) return null;

  return <PostBannerTemplate {...props} post={postByStatus} />;
};
