import React, { FunctionComponent } from "react";
import { Box, Button, Grid, Badge } from "@mui/material"
import { IconsProps } from "./icons/iconsProps";
import makeStyles from "@mui/styles/makeStyles";

export interface ControlItemProps {
    label: string;
    color: string;
    Icon: FunctionComponent<IconsProps>;
    link?: string;
    badge?: number;
    xs?: number;
}

export const ControlItem: FunctionComponent<ControlItemProps> = ({
    label,
    color,
    Icon,
    link,
    badge,
    xs=3
}) => {
    const classes = useStyles({color: color});

    return <Grid item xs={xs} container justifyContent="center">
        <Button disabled={!link} href={link} className={classes.container}>
            <Badge badgeContent={badge} color="secondary" sx={{ position: 'initial' }}>
                <Box className={classes.content}>
                    <Icon className={classes.icon}/>
                    <Box className={classes.label}>
                        { label }
                    </Box>
                </Box>
            </Badge>
        </Button>
    </Grid>
}

interface StylesProps {
    color: string;
}

const useStyles = makeStyles((muiTheme) => ({
    container: {
        flexGrow: 1,
        display: "block",
        maxWidth: "8.5rem",
        "&:hover": {
            transform: "scale(1.1)",
            cursor: "pointer"
        },
        "&:after": {
            content: "''",
            display: "block",
            paddingTop: "100%"
        }
    },
    content: (props: StylesProps) => ({
        textTransform: "none",
        lineHeight: "initial",
        color: "black",
        backgroundColor: props.color,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        padding: "0.25rem",
        [muiTheme.breakpoints.up("sm")]: {
            padding: "0.5rem"
        },
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    }),
    icon: {
        maxWidth: "90%",
        height: "50%",
        [muiTheme.breakpoints.up("sm")]: {
            height: "70%"
        }
    },
    label: {
        marginTop: ".25rem",
        fontSize: "0.65rem",
        [muiTheme.breakpoints.up("sm")]: {
            fontSize: "1rem"
        },
        fontWeight: 500,
        textAlign: "center"
    }
}));