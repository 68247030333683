import React, { FunctionComponent } from "react";
import { Box, TextField, IconButton } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from "react-i18next";

interface PostSearchProps {
    className?: string;
}

export const PostSearch: FunctionComponent<PostSearchProps> = ({
    className
}) => {
    const { t } = useTranslation();

    return <Box 
        component={'form'} 
        action="/posts/search"
        sx={{ display: "flex" }}
        className={className}
    >
        <TextField
            label={ t('postSearch') }
            size="small"
            sx={{
                flexGrow: 1
            }}
            name='q'
            autoComplete="off"
        />
        <IconButton sx={{ ml: "0.5rem" }} color="primary" type="submit">
            <CheckIcon/>
        </IconButton>
    </Box>
}
