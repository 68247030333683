export const sec2hms = (totalSeconds: number, short: boolean = false): string => {
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    if (short) {
        return hours > 0
            ? `${hours}:${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`
            : `${minutes}:${addLeadingZero(seconds)}`;
    }

    return hours > 0
        ? `${addLeadingZero(hours)}:${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`
        : `${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`;
}

export const addLeadingZero = (value: number): string => {
    if (value >= 10) return value.toString();
    return `0${value}`;
}

export const hms2sec = (hms: string): number => {
    const hmsArr = hms.split(':'); 
    let multiplier = 1;
    const seconds = hmsArr.reduceRight((sum, current) => {
        sum += Number(current) * multiplier;
        multiplier *= 60;
        return sum;
    }, 0); 

    return seconds;
}

export const dateToTimestampMsk = (date: string) => {
    return new Date(date).getTime() / 1000 - (180 * 60);
}