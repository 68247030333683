import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { Comment } from "/src/domain/studentTasks/StudentTasksData";
import { ActivityNotificationType } from "/src/domain/notifications/NotificationsData";
import { CommentInput, DraftComment, getMentionText } from "./CommentInput";
import { Box, List, ListItem, ListItemAvatar, ListItemText,
    DialogContent, DialogActions, Avatar, 
    Collapse, ButtonBase, Divider, SxProps, Typography
} from "@mui/material";
import { DialogListWrapper } from "./DialogListWrapper";
import { DialogProgress } from "./DialogProgress";
import { theme } from "/src/assets/theme";
import { getListOfComments, addComment, deleteComment } from "/src/domain/shared/requests/commentRequests";
import { trimName } from "/src/domain/shared/trimName";
import { useTranslation } from "react-i18next";
import { findIndex as _findIndex, isString as _isString } from "lodash";
import { getHtmlPreview } from "/src/domain/studentTasks/getHtmlPreview";
import produce from "immer";
import dayjs from "dayjs";
import * as DOMPurify from "dompurify";
import parse from "html-react-parser";


interface CommentsDialogProps {
    entityId: string;
    open: boolean;
    onClose: () => void;
    currentUserId?: string;
    isReadOnly: boolean;
    setActionState: () => void;
    disabled: boolean;
    type: ActivityNotificationType;
}


export const CommentsDialog: FunctionComponent<CommentsDialogProps> = ({
    entityId,
    open,
    onClose,
    currentUserId,
    isReadOnly,
    setActionState,
    disabled,
    type
}) => {
    const { t } = useTranslation();
    const [listOfComments, setListOfComments] = useState<Comment[]>([]);
    const [progress, setProgress] = useState<boolean>(false);
    const [draftComment, setDraftComment] = useState<DraftComment>({ text: "" });
    const dialogContentRef = useRef<HTMLDivElement>(null);

    const setListOfCommentsState = async () => {
        setProgress(true);

        const listOfComments = await getListOfComments(entityId, type);
        setListOfComments(listOfComments);

        setProgress(false);
    }


    useEffect(() => {
        if (!open) return;

        setListOfCommentsState();
    }, [open])

    const sendComment = async (): Promise<boolean> => {
        const newComment = await addComment({
            entityId,
            text: draftComment.text,
            parentCommentId: draftComment.parentCommentId,
            replyToId: draftComment.replyToStudent?.id,
            type
        });

        if (!newComment) {
            return false;
        }

        if (draftComment.parentCommentId) {
            const parentIndex = _findIndex(listOfComments, { id: draftComment.parentCommentId });

            const newListOfComments = produce(listOfComments, draft => {
                draft[parentIndex].childComments = listOfComments[parentIndex].childComments?.length ? [...listOfComments[parentIndex].childComments!, newComment] : [newComment];
            })
            setListOfComments(newListOfComments);
        } else {
            const newListOfComments = produce(listOfComments, draft => {
                draft.push(newComment);
            })
            setListOfComments(newListOfComments);
        }

        if (dialogContentRef?.current && !draftComment.parentCommentId) {
            dialogContentRef.current.scrollTop = dialogContentRef.current.scrollHeight;
        }

        setActionState();
        setDraftComment({ text: "" });

        return true;
    }

    const handleDeleteButtonClick = async (commentId: number, parentCommentId?: number) => {
        if (!confirm(t('confirmCommentDelete'))) {
            return
        }

        const deleted = await deleteComment(entityId, commentId, type);
        
        if (!deleted) {
            return alert(t('serverError'));
        }

        if (parentCommentId) {
            const parentIndex = _findIndex(listOfComments, { id: parentCommentId });

            const newListOfComments = produce(listOfComments, draft => {
                const commentIndex = _findIndex(draft[parentIndex].childComments, { id: commentId });
                draft[parentIndex].childComments?.splice(commentIndex, 1);
            })
            setListOfComments(newListOfComments);
        } else {
            const newListOfComments = produce(listOfComments, draft => {
                const commentIndex = _findIndex(draft, { id: commentId });
                draft.splice(commentIndex, 1);
            })
            setListOfComments(newListOfComments);
        }

        setActionState();
    }

    return <DialogListWrapper
        open={open}
        onClose={onClose}
        fullWidth
    >
        <DialogContent ref={dialogContentRef}>
            {progress ? <DialogProgress />
            : <>
                <CommentsView 
                    comments={listOfComments}
                    setDraftComment={setDraftComment}
                    handleDeleteButtonClick={handleDeleteButtonClick}
                    currentUserId={currentUserId}
                    isReadOnly={isReadOnly}
                    disabled={disabled}
                />
                
            </>}
        </DialogContent>
        {currentUserId && <DialogActions sx={{ display: "block" }}>
            <CommentInput
                sx={{ 
                    pl: "0.25rem",
                    boxSizing: "border-box",
                    mb: {
                        xs: "1rem",
                        sm: 0
                    }
                }}
                draftComment={draftComment}
                setDraftComment={setDraftComment}
                sendComment={sendComment}
                disabled={disabled}
            />
        </DialogActions>}
    </DialogListWrapper>
}

interface CommentsViewProps {
    comments: Comment[];
    setDraftComment: React.Dispatch<React.SetStateAction<DraftComment>>;
    handleDeleteButtonClick: (commentId: number, parentCommentId?: number) => void;
    isChild?: boolean;
    parentCommentIdToReply?: number;
    sx?: SxProps
    currentUserId?: string;
    isReadOnly: boolean;
    disabled: boolean;
}

const CommentsView: FunctionComponent<CommentsViewProps> = ({
    comments,
    setDraftComment,
    handleDeleteButtonClick,
    isChild,
    parentCommentIdToReply,
    sx,
    currentUserId,
    isReadOnly,
    disabled
}) => {


    return <List disablePadding={isChild} 
        sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            minHeight: "100%",
            ...sx
        }}
    >
        {comments.map((c) => <CommentItem 
            key={c.id} 
            comment={c}
            setDraftComment={setDraftComment}
            handleDeleteButtonClick={handleDeleteButtonClick}
            parentCommentIdToReply={parentCommentIdToReply || c.id}
            isChild={isChild}
            currentUserId={currentUserId}
            isReadOnly={isReadOnly}
            disabled={disabled}
        />)}
    </List>
}

interface CommentItemProps {
    comment: Comment;
    setDraftComment: React.Dispatch<React.SetStateAction<DraftComment>>;
    handleDeleteButtonClick: (commentId: number, parentCommentId?: number) => void;
    parentCommentIdToReply: number;
    isChild?: boolean;
    currentUserId?: string;
    isReadOnly: boolean;
    disabled: boolean;
}

const CommentItem: FunctionComponent<CommentItemProps> = ({
    comment,
    setDraftComment,
    handleDeleteButtonClick,
    parentCommentIdToReply,
    isChild,
    currentUserId,
    isReadOnly,
    disabled
}) => {
    const { t } = useTranslation();

    const handleNameClick = () => {
        window.location.href = `/profile?${comment.studentInfo?.id}`
    }

    const allowUserToReply = !disabled && currentUserId;
    const allowToDeleteComment = !isReadOnly || (currentUserId == comment.studentInfo.id);

    const date = dayjs.tz(comment.date, "Europe/Moscow").tz(dayjs.tz.guess());
    const humanizeDate = dayjs.duration(date.diff()).humanize(true);


    return <ListItem sx={{ flexDirection: "column", alignItems: "flex-start" }} disableGutters={!isChild}>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <ListItemAvatar>
                <Avatar
                    onClick={handleNameClick}
                    sx={{ cursor: 'pointer' }}
                    src={comment.studentInfo?.avatarUrl}
                />
            </ListItemAvatar>
            <ListItemText 
                primary={ <Box sx={{ cursor: 'pointer' }} onClick={handleNameClick}>
                    { trimName(comment.studentInfo.firstName, comment.studentInfo.lastName) }
                </Box> }
                secondary={<>
                    { getCommentElement(comment.text) }
                </>}
            />
        </Box>
        <Box 
            sx={{ 
                display: "flex", 
                justifyContent: "space-between",
                width: "100%"
            }}
        >
            <Typography component="span" sx={buttonSxProps}>
                {humanizeDate}
            </Typography>
            <Box>
                {allowUserToReply && <ButtonBase 
                    sx={ buttonSxProps }
                    onClick={() => setDraftComment(state => ({
                        text: getMentionText(comment.studentInfo),
                        parentCommentId: parentCommentIdToReply,
                        replyToStudent: comment.studentInfo
                    }))}
                >
                    { t('reply') }
                </ButtonBase>}
                {allowToDeleteComment && <ButtonBase 
                    sx={ buttonSxProps }
                    onClick={() => handleDeleteButtonClick(comment.id, comment.parentCommentId)}
                >
                    { t('delete') }
                </ButtonBase>}
            </Box>
        </Box>
        <Divider sx={{ width: "100%", mt: "0.25rem" }}/>
        {comment.childComments && <Collapse in={true} sx={{ width: "100%" }}>
            <CommentsView 
                comments={comment.childComments} 
                setDraftComment={setDraftComment}
                handleDeleteButtonClick={handleDeleteButtonClick}
                isChild
                parentCommentIdToReply={parentCommentIdToReply}
                currentUserId={currentUserId}
                isReadOnly={isReadOnly}
                disabled={disabled}
            />
        </Collapse>}
    </ListItem>
}

const PROFILE_REGEX = /https:\/\/s10.run\/profile\?\d+/;

function getCleanedComment(value: string) {
    const sanitizedValue = DOMPurify.sanitize(value, {
        ALLOWED_TAGS: ['a'],
        ALLOWED_ATTR: ['href'],
        ALLOWED_URI_REGEXP: PROFILE_REGEX
    });

    return sanitizedValue;
}


export const getCommentElement = (text: string, isPreview?: boolean) => {
    const cleanedText = getCleanedComment(text);
    const comment = isPreview ? getHtmlPreview(cleanedText).result : parse(cleanedText);
    return <Typography 
        component={"span"}
        sx={{
            fontSize: "inherit",
            whiteSpace: "pre-wrap",
            "& a": {
                color: "primary.main",
                textDecoration: "underline",
                textDecorationColor: theme.onPrimaryColor
            },
            "& a:hover": {
                textDecorationColor: "inherit"
            },
        }}
    >
        { comment }
    </Typography>
}

const buttonSxProps: SxProps = { 
    fontSize: "0.8rem", 
    color: theme.greyTextColor,
    ml: "0.5rem"
}
