import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { Box, Backdrop } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
const audioTrackIcon = require('../../../../assets/icons/audio_track.svg');
import { isMobile } from 'is-mobile';
import { theme } from '/src/assets/theme';

export const SUPPORTED_FILES = ['audio/mpeg'];

interface AuidoEditorProps {
  newAudioFile: File | null;
  setNewAudioFile: (newAudioFile: File | null) => void;
  audiosec?: number;
}

export const AuidoEditor: FunctionComponent<AuidoEditorProps> = ({
  newAudioFile,
  setNewAudioFile,
  audiosec,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setNewAudioFile(null);
    };
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  const [drag, setDrag] = useState(false);
  const isMob = isMobile();

  const isFile = audiosec || newAudioFile;

  const handleFile = (newAudio?: File) => {
    if (newAudio) {
      setNewAudioFile(newAudio);
    } else {
      setNewAudioFile(null);
    }
  };

  const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(false);
  };

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (isMob) return;

    setDrag(true);
  };

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const isSupported = SUPPORTED_FILES.includes(
      e.dataTransfer.files?.[0].type,
    );

    if (isSupported) {
      handleFile(e.dataTransfer.files?.[0]);
    } else {
      alert(
        `${t('fileNotSupported')}\n${t('supporteExtensions')} mp3`,
      );
    }
    setDrag(false);
  };


  return (
    <Box>
      <Box
        onDragLeave={dragLeaveHandler}
        onDragOver={dragOverHandler}
        sx={{
          height: '10rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          border: `1px solid ${theme.greyColor}`,
          borderRadius: '10px',
          marginTop: '1rem',
          cursor: 'pointer',
          textAlign: 'center',
        }}
        onClick={() => inputRef.current?.click()}
      >
        <AudiotrackIcon color={isFile ? 'primary' : 'inherit'} />
        {isFile && <Box
          sx={{
            backgroundImage: `url(${audioTrackIcon})`,
            height: '2rem',
            width: '80%',
            marginRight: '0.5rem',
            backgroundRepeatY: 'no-repeat',
            backgroundSize: 'contain',
          }}
        />}
        {t(isFile ? 'edit' : 'add')}
        {!isMob && (
          <>
            <br /> ({t('justDragFile')}){' '}
          </>
        )}
      </Box>
      <input
        type="file"
        hidden
        accept={SUPPORTED_FILES.join(',')}
        ref={inputRef}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleFile(e.target.files?.[0])
        }
      />
      <Backdrop
        open={drag}
        sx={{ color: 'primary.contrastText', fontSize: '1.25rem' }}
        onDragLeave={dragLeaveHandler}
        onDragOver={dragOverHandler}
        onDrop={onDropHandler}
      >
        {t('justDragFile')}
      </Backdrop>
    </Box>
  );
};
