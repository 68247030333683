import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { NotificationsContext, NotificationsContextProps } from "/src/contexts/NotificationsContext";
import { NotificationItemType, NotificationType, ActivityNotificationType } from "/src/domain/notifications/NotificationsData";
import { Box, Collapse, 
    List, ListItem, ListItemAvatar, 
    ListItemText, Avatar, styled, Paper, CircularProgress,
    Tabs, Tab, DialogContent
} from "@mui/material";
import { DialogListWrapper } from "/src/ui/studentTasks/studentTasks/actions/DialogListWrapper";
import { theme } from "/src/assets/theme";
import { getCommentElement } from "/src/ui/studentTasks/studentTasks/actions/CommentsDialog";
import { useTranslation } from "react-i18next";
import { trimName } from "/src/domain/shared/trimName";
import { links } from "/src/domain/shared/links";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);

interface ListOfNotificationsProps {
    expandedNotifications: boolean;
    expandNotifications: () => void;
    isDialog?: boolean;
}

enum NotificationMode {
    UNREAD,
    LAST
}

export const ListOfNotifications: FunctionComponent<ListOfNotificationsProps> = ({
    expandedNotifications,
    expandNotifications,
    isDialog
}) => {
    const { t } = useTranslation();

    const { getListOfNotifications, listOfNotifications } = useContext(NotificationsContext) as NotificationsContextProps;
    const [mode, setMode] = useState<NotificationMode>(NotificationMode.UNREAD);
    const [loading, setLoading] = useState(false);

    const setNotificationState = async () => {
        if (!expandedNotifications) return;

        setLoading(true);

        const last15 = mode == NotificationMode.LAST || null;

        await getListOfNotifications(last15);
        setLoading(false);
    }

    const handleTabChange = (e: React.SyntheticEvent, value: NotificationMode) => {
        setMode(value);
    }

    useEffect(() => {
        setNotificationState();
    }, [expandedNotifications, mode])

    return <ListOfNotificationsContainer
        expandedNotifications={expandedNotifications}
        expandNotifications={expandNotifications}
        isDialog={isDialog}
    >
        <Tabs value={mode} onChange={handleTabChange} variant="fullWidth">
            <Tab value={NotificationMode.UNREAD} label={t('unread')} />
            <Tab value={NotificationMode.LAST} label={t('latest')} />
        </Tabs>
        <DialogContent sx={{ p: 0 }}>
            {loading && <Box sx={{
                    m: "2rem",
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <CircularProgress/>
                </Box>}
            {!loading && !listOfNotifications.length &&         
                <Box sx={{
                    m: "2rem",
                    textAlign: "center"
                }}>
                    { t('noNotifications') }
                </Box>}
            {Boolean(!loading && listOfNotifications.length) && <List>
                {listOfNotifications.map((n) => {
                    if (!Object.values(NotificationType).includes(n.type)) {
                        return null;
                    }

                    return <NotificationItem
                        notification={n}
                        key={n.date}
                    />
                })}
            </List>}
        </DialogContent>
    </ListOfNotificationsContainer>
}

interface ListOfNotificationsContainerProps {
    isDialog?: boolean;
    expandedNotifications: boolean;
    expandNotifications: () => void;
}

export const ListOfNotificationsContainer: FunctionComponent<ListOfNotificationsContainerProps> = ({ 
    isDialog, 
    expandedNotifications,
    expandNotifications,
    children 
}) => {

    return isDialog ?
        <DialogListWrapper 
            open={expandedNotifications} 
            onClose={expandNotifications}
            fullWidth
        >
            { children }
        </DialogListWrapper>
        : <Collapse
            in={expandedNotifications}
            component={Paper}
            sx={{
                position: "absolute",
                backgroundColor: "primary.contrastText",
                transform: "translate(-12rem, 0.25rem)",
                zIndex: "999",
                width: "20rem",
                mr: "1.5rem",
                overflow: "auto",
                borderRadius: "5px",
                "& .MuiCollapse-wrapperInner": {
                    maxHeight: "90vh",
                    display: "flex",
                    flexDirection: "column"
                }
            }}
        >
            { children }
        </Collapse>
} 

interface NotificationItemProps {
    notification: NotificationItemType;
}

export const NotificationItem: FunctionComponent<NotificationItemProps> = ({
    notification
}) => {
    const { t } = useTranslation();

    const profileLink = `${links.origin}/profile?${notification.studentInfo.id}`;
    const notificationLink = getNotificationLink(notification);

    return <ListItem sx={{
        "&:not(:last-child):after": {
            content: "''",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            height: "0.1rem",
            backgroundColor: theme.darkColor,
            opacity: 0.12
        }
    }}>
        <ListItemAvatar>
            <a href={profileLink}>
                <Avatar
                    src={notification.studentInfo.avatarUrl}
                />
            </a>
        </ListItemAvatar>
        <ListItemText 
            primary={ <HiddenLink href={profileLink}>
                { trimName(notification.studentInfo.firstName, notification.studentInfo.lastName) }
            </HiddenLink> }
            secondary={<>
                <HiddenLink href={notificationLink}/>
                { t(getNotificationText(notification)) } ({ getHumanizeDate(notification.date) }){ getCommentText(notification) }
            </>}
            secondaryTypographyProps={{
                sx: {
                    position: "relative",
                    "& a": {
                        position: "relative",
                    },
                    "&>a": {
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0
                    }
                }
            }}
        />
    </ListItem>
}

const HiddenLink = styled('a')({
    color: "inherit", 
    textDecoration: "none"
})

const getHumanizeDate = (date: string) => {
    const tzDate = dayjs.tz(date, "Europe/Moscow").tz(dayjs.tz.guess());
    const diff = tzDate.diff();
    const resultDiff = diff > 0 ? 0 : diff;

    const humanizeDate = dayjs.duration(resultDiff).humanize(true);
    return humanizeDate;
}

const getCommentText = (notification: NotificationItemType) => {
    if (!("text" in notification)) {
        return null;
    }

    if (!notification.text) return null;

    const comment = <>: { getCommentElement(notification.text) }</>

    return comment;
}

const getNotificationText = (notification: NotificationItemType): string => {
    if (notification.type === NotificationType.LIKE_ACTIVITY && notification.etyp === ActivityNotificationType.BLOG) {
        return "likedYourPost";
    } else if (notification.type === NotificationType.LIKE_ACTIVITY) {
        return "likedYourActivity";
    }

    if (notification.type === NotificationType.ACTIVITY_COMMENT && notification.etyp === ActivityNotificationType.BLOG) {
        return "commentedOnPost";
    } else if (notification.type === NotificationType.ACTIVITY_COMMENT) {
        return "commentedOnActivity";
    }

    if (notification.type === NotificationType.REPLY_TO_ACTIVITY_COMMENT) {
        return "repliedToComment";
    }

    if (notification.type === NotificationType.MENTIONED_IN_ACTIVITY_COMMENT) {
        return "mentionedInComment";
    }

    if (notification.type === NotificationType.COMMENT_ON_FOLLOWED_ACTIVITY && notification.etyp === ActivityNotificationType.BLOG) {
        return "commentOnFollowedPost";
    } else if (notification.type === NotificationType.COMMENT_ON_FOLLOWED_ACTIVITY) {
        return "commentOnFollowedActivity";
    }

    if (notification.type === NotificationType.STARTED_FOLLOWING) {
        return "startedFollowing";
    }

    return "";
}

const getNotificationLink = (notification: NotificationItemType) => {
    if (notification.type === NotificationType.STARTED_FOLLOWING) {
        return `${links.origin}/profile?${notification.studentInfo.id}`;
    }

    if (notification.etyp === ActivityNotificationType.BLOG) {
        return `${links.origin}/post/${notification.eid}/`;
    }

    return `${links.origin}/activity?aid=${notification.activityId}`;
}