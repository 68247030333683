import { PageData } from "/src/domain/shared/Page";
import { User } from "/src/domain/shared/User";
import { ChallengeComponentProps } from "/src/domain/studentTasks/ChallengeData";
import {TrainerBaseInfo} from "/src/domain/shared/TrainerBaseInfo";
import {StudentBaseInfo} from "/src/domain/shared/StudentBaseInfo";
import { Dayjs } from "dayjs";
import { LapsMean } from "/src/domain/personalStatistics/PersonalStatisticsData";

export interface StudentTasksData extends PageData {
    studentInfo: StudentInfo;
    trainerInfo?: TrainerBaseInfo;
    trainingPlan?: TrainingPlan;
    weekParameters?: PeriodParameters;
    monthParameters?: PeriodParameters;
    trainingSchedule?: TrainingSchedule[];
    newTrainingSchedule?: TrainingSchedule[];
    chat?: TrainerChat;
    templateTrainingSchedule?: TrainingSchedule[];
    studentTasksInfo?: StudentTasksInfo;
}

export type StudentInfo = StudentBaseInfo;

export interface TrainingPlan {
    target: number;
    kmCompleted: number;
    percent: number;
    realsum: number;
    presum: number;
    compsum: number;
    maxWeekKm?: number;
    today: TrainingPlanPeriod;
    day30: TrainingPlanPeriod;
    bestPhysicalShape: number;
    load: number;
    physicalShape: number;
}

export interface TrainingPlanPeriod {
    percent: number;
    realsum: number;
    presum: number;
    compsum: number;
}

export interface PeriodParameters {
    fast: WeekZoneParameters;
    slow: WeekZoneParameters;
    total: WeekZoneParameters;
}   

export interface WeekZoneParameters {
    pace: string;
    bpm?: number;
    distance: number;
}

export interface TrainerChat {
    lastFromTrainer?: number
}

export interface TrainingSchedule {
    type: ScheduleType;
    date: string;
}

export enum ScheduleType {
    NOTHING = 0,
    RUN = 1,
    REST = 2,
    LONG = 3,
    CHALLENGE = 4
}

export interface StudentTasksInfo {
    diaryTasks: TrainingTask[];
    diaryTasksisMoreExist: number;
}

export interface TrainingTask {
    studentInfo?: StudentInfo,
    id: string;
    date: string;
    type: TrainingType;
    comment: string;
    target?: number;
    kmCompleted?: number;
    percentageCompleted?: number;
    today?: boolean;
    tracks?: Track[];
    reportParameters?: ReportParameters;
    nearkm?: number;
    category?: FeedItemCat;
}

export interface DiaryTasksReposonseData {
    diaryTasksInfo: TrainingTask[];
    diaryTasksisMoreExist: number;
}

export type FeedItemCat =
    | 'postsByStatus'
    | 'feed'
    | 'byreport'
    | 'byinterval'
    | 'nearby'
    | 'manual'
    | 'teamsTasks';

export interface PostData {
    postid: string;
    d: string;
    txt: string;
    userid?: string;
    views?: number;
    userInfo?: StudentInfo;
    viewMode: PostViewMode;
    audiosrc?: string;
    audiosec?: number;
    audiomarksec?: number;
    tags?: string[];
    status?: string[];
    category?: FeedItemCat;
}

export interface PostProps extends PostData {
    type: 'post';
}

export enum PostViewMode {
    REGULAR,
    DRAFT,
    CLUB,
    FEATURES,
    ADVERTISING,
    ANNOUNCEMENTS,
    APPEAL,
    SUGGEST_CHANGES
}

export type TaskFeedItem = TrainingTask | ChallengeComponentProps | PostProps;
export type TaskFeed = TaskFeedItem[];

export interface Track {
    id: string;
    type: TrackType;
    distance: number;
    start: string;
    pace: string;
    km: number;
    gs: number;
    impulse: number;
    pulse: number;
    time: string;
    absolute: string;
    avg_speed?: number;
    skipforstat: boolean;
    imgUrl?: string;
    views?: number;
    json?: TrackJson;
}

export enum TrackType {
    RUN = "run",
    WALK = "walk",
    SWIM = "swim",
    RIDE = "ride",
    SKI = "ski",
    OTHER = "other",
    AUTO = "auto",
}

export interface TrackJson {
    lapsmean?: LapsMean;
}

export enum TrainingType {
    NONE = 0,
    WELLNESS = 1,
    DEVELOPMENT = 2,
    INTERVAL = 3,
    REPEATED = 4,
    LONG = 5,
    COMPETITION = 6,
    STREINGTH = 7,
}

export enum FilterType {
    ALL = 0,
    WORKS = 100
}

export type FilterTypes = TrainingType | FilterType;

export interface TaskFilterData {
    type?: FilterTypes;
    tags?: string[];
    txt?: string;
    untilDay?: Dayjs | null;
}

export interface FilterParams {
    training_type?: FilterTypes | null;
    tags?: string[];
    txt?: string;
    last_date?: number | null;
}



export interface Report {
    pk: number;
    value: string;
    html: number;
    sem: Difficulty;
    bigreport?: 1;
    t?: string;
    placeage?: number;
    placeabs?:number;
    lr?: NumberBoolean;
    ot?: TrainerEvaluation;
    tags: string;
}
export interface ReportParameters {
    comment: string;
    difficulty: Difficulty;
    html: number;
    ts?: number;
    time?: string;
    placeage?: number;
    placeabs?:number;
    personalBest?: NumberBoolean;
    trainerEvaluation?: TrainerEvaluation;
    tags?: string[];
}

export enum Difficulty {
    EASУ = 1,
    FINE = 2,
    HARD_DONE = 3,
    HARD_NOT_DONE = 4,
    INJURY = 5,
}

export enum TrainerEvaluation {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5
}


export enum PeriodTabIndex {
    WEEK,
    MONTH
}


export type MessageType = string | null;

export type NumberBoolean = 0 | 1;

export enum PageSection {
    TODAY = 'today',
    CHARTS = 'chart',
    PERFORMANCE = 'performance',
    DIARY = 'diary',
    SCHEDULE = 'schedule',
    CHALLENGES = 'challenges'
}

export enum TaskRoute {
    TASKS = '/',
    SEARCH = '/search'
}

export interface Like {
    numberOfLikes: number;
    likeCost: number;
    userLike: boolean;
}

export interface StudentInfoForLike {
    id: string;
    avatarUrl?: string;
    firstName: string;
    lastName: string;
    isPremium: boolean;
    likeCost: number;
}

export interface Comment {
    id: number;
    date: string;
    text: string;
    studentInfo: StudentInfo;
    childComments?: Comment[];
    parentCommentId?: number;
    replyToStudent?: StudentInfo;
}

export interface PreviewCommentsInfo {
    numberOfComments: number;
    comments: Comment[];
}