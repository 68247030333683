import React, { FunctionComponent, useEffect, useState } from 'react';
import { User } from '/src/domain/shared/User';
import { getUserInfo } from '/src/domain/shared/requests/getUserInfo';
import { getTribunalUsers } from '/src/domain/shared/requests/getTribunalUsers';

export interface UserContextProps {
  user?: User;
  tribunalIds?: string[];
  setTribunalIdsState: () => Promise<string[]>;
}

export const UserContext = React.createContext<UserContextProps | undefined>(
  undefined,
);

interface UserProviderProps {
  userId?: string | null;
}

export const UserProvider: FunctionComponent<UserProviderProps> = ({
  userId,
  children,
}) => {
  const [user, setUser] = useState<User>();
  const [tribunalIds, setTribunalIds] = useState<string[]>();

  const setUserState = async () => {
    const receivedUser = await getUserInfo(userId || undefined);

    if (!receivedUser) return;

    setUser(receivedUser);
  };

  useEffect(() => {
    if (userId !== null) {
      setUserState();
    }
  }, []);

  const setTribunalIdsState = async () => {
    const tribunalIds = await getTribunalUsers();
    setTribunalIds(tribunalIds);
    return tribunalIds;
  };

  return (
    <UserContext.Provider
      value={{
        user,
        tribunalIds,
        setTribunalIdsState,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
