import React, { FunctionComponent } from "react";
import { TrackJson } from "/src/domain/studentTasks/StudentTasksData";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Box, styled } from "@mui/material"
import { useTranslation } from "react-i18next";
import { isObject as _isObject } from "lodash";
import { sec2hms } from "/src/utils/time-utils";

interface ActivityMetricsProps {
    json: TrackJson;
}

export const ActivityMetrics: FunctionComponent<ActivityMetricsProps> = ({
    json
}) => {
    const { t } = useTranslation();

    return <>
        {_isObject(json.lapsmean?.fast) && <Box 
            sx={{ 
                display: "flex",
                alignItems: "center",
                color: "primary.main"
            }}
        >
            <MetricContainer>
                <RotateRightIcon/> 
                <MetricValue>
                    { json.lapsmean?.fast.count ?? "–" }
                </MetricValue>
            </MetricContainer>
            <MetricContainer>
                <SwapCallsIcon/> 
                <MetricValue>
                    { json.lapsmean?.fast.averageDistance ?? "–" } { t('km') }
                </MetricValue>
            </MetricContainer>
            <MetricContainer>
                <TimerOutlinedIcon/> 
                <MetricValue>
                    { json.lapsmean?.fast.averagePace ? sec2hms(json.lapsmean.fast.averagePace) : "–" } { t('minKm') }
                </MetricValue>
            </MetricContainer>
        </Box>}
    </>
}

const MetricContainer = styled(Box)({
    display: "flex",
    alignItems: "center"
})

const MetricValue = styled(Box)({
    margin: "0 0.5rem"
})
