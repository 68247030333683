import React, { FunctionComponent, useState, useContext } from "react";
import { SubscriptionItem } from "/src/domain/subscriptionData/SubscriptionData";
import { StudentInfo } from "/src/domain/studentTasks/StudentTasksData";
import { SubscriptionsContext, SubscriptionsContextProps } from "/src/contexts/SubscriptionsContext";
import { Avatar, AvatarSize } from "/src/ui/shared/Avatar";
import { Box, IconButton, styled, 
    Snackbar, Alert, Link, Slide, SlideProps } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { trimName } from '/src/domain/shared/trimName';
import dayjs from "dayjs";
import { links } from "/src/domain/shared/links";
import { useTranslation } from "react-i18next";
import { find as _find } from "lodash";

interface TaskHeaderProps {
    currentDate: string;
    date: string;
    studentInfo?: StudentInfo;
    nearKm?: number;
    currentUserId?: string;
}

export const TaskHeader: FunctionComponent<TaskHeaderProps> = ({
    currentDate,
    date, 
    studentInfo,
    nearKm,
    currentUserId
}) => {
    const taskDateFormat = dayjs(currentDate).get('year') !== dayjs(date).get('year') ?
    dayjs(date).format('dddd, DD.MM.YYYY')
    : dayjs(date).format('dddd, DD.MM');
    
    return <Box sx={{
        fontSize: "1.2rem",
        fontWeight: "bold"
    }}>
        {studentInfo ? <ExternalTaskHeader
            studentInfo={studentInfo}
            taskDateFormat={taskDateFormat}
            nearKm={nearKm}
            currentUserId={currentUserId}
        />
        : <>
            { taskDateFormat }
        </>}
    </Box>
}

interface ExternalTaskHeaderProps {
    studentInfo: StudentInfo;
    taskDateFormat: string;
    nearKm?: number;
    currentUserId?: string;
}

const ALERT_LIFETIME = 5000;

const ExternalTaskHeader: FunctionComponent<ExternalTaskHeaderProps> = ({
    studentInfo,
    taskDateFormat,
    nearKm,
    currentUserId
}) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const { handleSubscribeToggle, listOfSubscriptions, setListOfSubscriptionsState } = useContext(SubscriptionsContext) as SubscriptionsContextProps;

    const handleSubscribeClick = async () => {
        if (!currentUserId) return;
        
        setLoading(true);

        await handleSubscribeToggle(studentInfo.id);
        await setListOfSubscriptionsState(currentUserId);
        setOpenSnackbar(true);
        setLoading(false);
    }

    const profileLink = `${links.origin}/profile?${studentInfo?.id}`;
    const studentName = trimName(studentInfo.firstName, studentInfo.lastName);

    const showSubscribeButton = currentUserId && currentUserId != studentInfo.id && !checkSubscration(listOfSubscriptions, studentInfo.id);

    return <Box
        sx={{
            color: "secondary.main",
            display: "flex",
            justifyContent: "space-between"
        }}
    >
        <Box sx={{ 
            fontSize: "1.3rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        }}>
            <HiddenLink href={profileLink}>
                { studentName }
            </HiddenLink>
            <Box sx={{
                fontSize: "1rem",
                fontWeight: "normal"
            }}>
                {nearKm != null ? 
                <Box
                    component={'b'}
                    sx={{ display: "block" }}
                >
                    { nearKm } { t('kmFromYou') }
                </Box> : null}
                { taskDateFormat }
            </Box>
        </Box>
        <Box sx={{
            display: "flex",
            alignItems: "center"
        }}>
            {showSubscribeButton && <IconButton 
                sx={{
                    width: "2.5rem" ,
                    height: "2.5rem",
                    mr: "0.5rem"
                }}
                color={"success"}
                disabled={loading}
                onClick={handleSubscribeClick}
            >
                <AddIcon/>
            </IconButton>}
            <HiddenLink href={profileLink}>
                <Avatar avatarUrl={studentInfo.avatarUrl || ""} size={AvatarSize.MEDIUM} premium={studentInfo.isPremium}/>
            </HiddenLink>
        </Box>
        <Snackbar
            open={openSnackbar}
            onClose={() => setOpenSnackbar(!openSnackbar)}
            anchorOrigin={{
                horizontal: "center",
                vertical: "top"
            }}
            autoHideDuration={ALERT_LIFETIME}
            TransitionComponent={TransitionDown}
        >
            <Alert severity="success">
                { t('youSubscribedTo') } 
                <Link href={profileLink}>
                    { studentName }
                </Link>
            </Alert>
        </Snackbar>
    </Box>
}

const checkSubscration = (listOfSubscriptions: SubscriptionItem[] | null, id: string) => {
    return Boolean(_find(listOfSubscriptions, i => i.id == id))
} 

type TransitionProps = Omit<SlideProps, 'direction'>;

const TransitionDown: FunctionComponent<TransitionProps> = (props) => {
  return <Slide {...props} direction="down" />;
}

const HiddenLink = styled('a')({
    color: "inherit", 
    textDecoration: "none"
})