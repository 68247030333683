import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { StudentInfo } from '/src/domain/studentTasks/StudentTasksData';
import { Club } from '/src/domain/club/ClubData';
import {
  ClubContext,
  ClubContextProps,
  SearchClubBy,
} from '/src/contexts/ClubContext';
import {
  UserContext,
  UserContextProps,
} from '/src/contexts/shared/UserContext';
import { Typography, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TestUids } from '/src/domain/shared/checkUserForTestee';

export interface NewPostKeys {
  add: string[];
  del: string[];
  newKeys: string[];
}

interface SelectPostStatusProps {
  postKeys: string[];
  newPostKeys: NewPostKeys;
  setNewPostKeys: (newStatus: NewPostKeys) => void;
  userClub: Club | null;
  postOwnerInfo: StudentInfo;
  clubManagement?: Club;
}

export const SelectPostStatus: FunctionComponent<SelectPostStatusProps> = ({
  postKeys,
  newPostKeys,
  setNewPostKeys,
  userClub,
  postOwnerInfo,
  clubManagement,
}) => {
  const { t } = useTranslation();
  const { requestClub, setClub, club } = useContext(
    ClubContext,
  ) as ClubContextProps;
  const { godIds } = useContext(UserContext) as UserContextProps;

  const clubId = useMemo(
    () =>
      getPostClubId({
        postKeys,
        clubManagement,
        userClub,
      }),
    [postKeys, clubManagement, userClub],
  );
  console.log({ clubId });
  console.log(newPostKeys);
  console.log({ clubFromKeys: club });
  console.log('----------------');

  useEffect(() => {
    const clubIdFromKeys = getClubIdFromKeys(newPostKeys.newKeys);
    if (clubIdFromKeys) {
      requestClub(clubIdFromKeys, SearchClubBy.ID);
    } else {
      setClub(null);
    }
  }, [newPostKeys.newKeys]);

  const getStatusKey = (status: PostStatusName) => {
    switch (status) {
      case PostStatusName.DRAFT:
        return `${PostStatusName.DRAFT}${postOwnerInfo.id}`;
      case PostStatusName.SUBSCRIBERS_ONLY:
        return `${PostStatusName.SUBSCRIBERS_ONLY}${postOwnerInfo.id}`;
      case PostStatusName.IN_CLUB:
        if (clubId) {
          return PostStatusName.IN_CLUB;
        } else {
          return PostStatusName.PUBLIC;
        }
      case PostStatusName.PINNED_CLUB:
        if (clubId) {
          return PostStatusName.PINNED_CLUB;
        } else {
          return PostStatusName.PUBLIC;
        }
      case PostStatusName.WELCOME_CLUB:
        if (clubId) {
          return PostStatusName.WELCOME_CLUB;
        } else {
          return PostStatusName.PUBLIC;
        }
      default:
        return status;
    }
  };

  const handleChangePostStatus = (newStatus: PostStatusName) => {
    const currentKeysSet = new Set(postKeys);
    const addKeys: string[] = [];
    const delKeys: string[] = [];

    console.log('handleChangePostStatus', newStatus);

    const postStatus = getPostStatus(postKeys);
    if (newStatus === postStatus) {
      setNewPostKeys({
        add: [],
        del: [],
        newKeys: postKeys,
      });
      return;
    }

    // получаем ключ статуса по выбранному статусу
    const newStatusKey = getStatusKey(newStatus);

    // удаляем другой ключ статуса
    currentKeysSet.forEach((key) => {
      if (
        Object.entries(PostStatusName).some(([, status]) =>
          key.startsWith(status),
        )
      ) {
        delKeys.push(key);
      }
    });

    // добавляем новый ключ статуса
    addKeys.push(newStatusKey);

    // Если статус общий закреп (welcomePublic/pinnedPublic) или черновик (draft), то удалить клуб
    if (
      newStatus === PostStatusName.DRAFT ||
      newStatus === PostStatusName.PINNED_PUBLIC ||
      newStatus === PostStatusName.WELCOME_PUBLIC ||
      newStatus === PostStatusName.FEATURED
    ) {
      currentKeysSet.forEach((key) => {
        if (key.startsWith('club')) {
          delKeys.push(key);
        }
        // Если статус черновик, то удалить автора
        if (newStatus === PostStatusName.DRAFT && key.startsWith('author')) {
          delKeys.push(key);
        }
      });
    // Если статус не из перечисленных, текущий статус не имеет ключа клуба, но клуб есть – то добавить ключ клуба
    } else if (!currentKeysSet.has(`club${clubId}`) && clubId) {
      addKeys.push(`club${clubId}`);
    }

    // Добавляем автора, если его нет и не черновик
    if (
      !currentKeysSet.has(`author${postOwnerInfo.id}`) &&
      newStatus !== PostStatusName.DRAFT
    ) {
      addKeys.push(`author${postOwnerInfo.id}`);
    }

    // Создаём новые список ключей для выставления статусов
    const newKeysSet = new Set(currentKeysSet);
    delKeys.forEach((key) => newKeysSet.delete(key));
    addKeys.forEach((key) => newKeysSet.add(key));
    setNewPostKeys({
      add: addKeys,
      del: delKeys,
      newKeys: Array.from(newKeysSet),
    });
  };

  const isDefaultTrainer =
    clubManagement?.hasMembershipFee &&
    clubManagement.defaultTrainerId === postOwnerInfo.id;
  const isManager = clubManagement?.managerId === postOwnerInfo.id;

  const isPinnedClubSelect =
    isDefaultTrainer || isManager || checkPinnedClubKey(postKeys);
  const isWelcomeClubSelect = isManager || checkWelcomeClubKey(postKeys);

  const isPinnedPublicSelect = checkPinnedPublicKeys(postKeys)
    ? true
    : godIds
    ? godIds.includes(postOwnerInfo.id)
    : false;

  const isFeaturedSelect = postKeys.includes(PostStatusName.FEATURED) || TestUids.customCheck([TestUids.stro, TestUids.nata, TestUids.s10Runner], postOwnerInfo.id);

  return (
    <>
      {club && (
        <Typography
          sx={{
            mt: '0.75rem',
          }}
        >
          {`${t('postClub')}: ${club.name}`}
        </Typography>
      )}
      <Select
        value={getPostStatus(newPostKeys.newKeys)}
        onChange={(e) =>
          handleChangePostStatus(e.target.value as PostStatusName)
        }
        size="small"
        sx={{ mt: '0.75rem' }}
      >
        <MenuItem value={PostStatusName.PUBLIC}>
          { t(postStatusNameTranslation[PostStatusName.PUBLIC]) }
        </MenuItem>
        <MenuItem value={PostStatusName.DRAFT}>
          { t(postStatusNameTranslation[PostStatusName.DRAFT]) }
        </MenuItem>
        <MenuItem value={PostStatusName.SUBSCRIBERS_ONLY}>
          { t(postStatusNameTranslation[PostStatusName.SUBSCRIBERS_ONLY]) }
        </MenuItem>
        {isPinnedPublicSelect && (
          <MenuItem value={PostStatusName.PINNED_PUBLIC}>
            { t(postStatusNameTranslation[PostStatusName.PINNED_PUBLIC]) }
          </MenuItem>
        )}
        {isPinnedPublicSelect && (
          <MenuItem value={PostStatusName.WELCOME_PUBLIC}>
            { t(postStatusNameTranslation[PostStatusName.WELCOME_PUBLIC]) }
          </MenuItem>
        )}
        {!!clubId && (
          <MenuItem value={PostStatusName.IN_CLUB}>
            { t(postStatusNameTranslation[PostStatusName.IN_CLUB]) }
          </MenuItem>
        )}
        {isPinnedClubSelect && (
          <MenuItem value={PostStatusName.PINNED_CLUB}>
            { t(postStatusNameTranslation[PostStatusName.PINNED_CLUB]) }
          </MenuItem>
        )}
        {isWelcomeClubSelect && (
          <MenuItem value={PostStatusName.WELCOME_CLUB}>
            { t(postStatusNameTranslation[PostStatusName.WELCOME_CLUB]) }
          </MenuItem>
        )}
        {isFeaturedSelect && (
          <MenuItem value={PostStatusName.FEATURED}>
            { t(postStatusNameTranslation[PostStatusName.FEATURED]) }
          </MenuItem>
        )}
      </Select>
    </>
  );
};

enum PostStatusName {
  PUBLIC = 'public',
  DRAFT = 'draft',
  SUBSCRIBERS_ONLY = 'subscribersOnly',
  PINNED_PUBLIC = 'pinnedPublic',
  WELCOME_PUBLIC = 'welcomePublic',
  IN_CLUB = 'inClub',
  PINNED_CLUB = 'pinnedClub',
  WELCOME_CLUB = 'welcomeClub',
  FEATURED = 'featured'
}

type PostStatusNameTranslation = {
  [key in PostStatusName]: string;
};

export const postStatusNameTranslation: PostStatusNameTranslation = {
  public: 'all',
  draft: 'draft',
  subscribersOnly: 'subscribersOnly',
  pinnedPublic: 'pinnedPublic',
  welcomePublic: 'welcomePublic',
  inClub: 'club',
  pinnedClub: 'pinnedClub',
  welcomeClub: 'welcomeClub',
  featured: 'featured',
};

export function getPostStatus(keys: string[]): PostStatusName | undefined {
  // Основные статусы без идентификаторов
  const basicStatuses: PostStatusName[] = [
    PostStatusName.PUBLIC,
    PostStatusName.PINNED_PUBLIC,
    PostStatusName.WELCOME_PUBLIC,
    PostStatusName.IN_CLUB,
    PostStatusName.PINNED_CLUB,
    PostStatusName.WELCOME_CLUB,
    PostStatusName.FEATURED,
  ];

  for (const key of keys) {
    if (basicStatuses.includes(key as PostStatusName)) {
      return key as PostStatusName;
    }

    // Проверка для статуса черновика (draft{id пользователя})
    if (key.startsWith('draft')) {
      return PostStatusName.DRAFT;
    }

    // Проверка для статуса только для подписчиков (subscribersOnly{id пользователя})
    if (key.startsWith(PostStatusName.SUBSCRIBERS_ONLY)) {
      return PostStatusName.SUBSCRIBERS_ONLY;
    }
  }

  return undefined; // Если статус не определён
}

const checkPinnedPublicKeys = (postKeys: string[]) => {
  return (
    postKeys.includes(PostStatusName.PINNED_PUBLIC) ||
    postKeys.includes(PostStatusName.WELCOME_PUBLIC)
  );
};

export const checkWelcomeClubKey = (postKeys: string[]) => {
  return postKeys.includes(PostStatusName.WELCOME_CLUB);
};

export const checkPinnedClubKey = (postKeys: string[]) => {
  return postKeys.includes(PostStatusName.PINNED_CLUB);
};

export const checkFeaturedKey = (postKeys: string[]) => {
  return postKeys.includes(PostStatusName.FEATURED);
};
export const getClubIdFromBasicKeys = (postKeys: string[]): string | null => {
  // Ищем существующий ключ клуба или inClub в ключах поста
  const clubId = postKeys.find((key) => /^club(\d+)$/.test(key));

  // Извлекаем ID клуба, если ключ найден
  if (clubId) {
    return clubId.match(/^club(\d+)$/)![1];
  }

  const inClubId = postKeys.find((key) => /^inClub(\d+)$/.test(key));

  if (inClubId) {
    return inClubId.match(/^inClub(\d+)$/)![1];
  }

  return null;
};

const getClubIdFromKeys = (postKeys: string[]): string | null => {
  const clubIdFromBasicKeys = getClubIdFromBasicKeys(postKeys);

  if (clubIdFromBasicKeys) {
    return clubIdFromBasicKeys;
  }

  return null;
};

export const getPostClubId = ({
  postKeys,
  clubManagement,
  userClub,
}: {
  postKeys: string[];
  clubManagement?: Club;
  userClub: Club | null;
}) => {
  const clubIdFromKeys = getClubIdFromKeys(postKeys);

  if (clubIdFromKeys) {
    return clubIdFromKeys;
  }

  if (clubManagement) {
    return clubManagement.id;
  }

  // Если ключей клуба нет, используем клуб пользователя
  if (userClub?.id) {
    return userClub.id;
  }

  return null;
};
