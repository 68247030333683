import {Report} from "/src/domain/studentTasks/StudentTasksData";
import {convertObjectToFormData} from "/src/domain/shared/convertObjectToFormData";
import axios from "axios";
import {links} from "/src/domain/shared/links";

const postParameters = {
    withCredentials: true,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
}

export const createTaskReport = async (report: Report): Promise<Boolean> => {
    try {
        await axios.post(`${links.origin}/a_report`, convertObjectToFormData(report), postParameters);
        
        return true;
    } catch (e) {
        console.log(e);
        return false;            
    }
}