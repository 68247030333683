import React, { FunctionComponent } from 'react';
import { IconsProps } from './iconsProps';

export const Protocols: FunctionComponent<IconsProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>protocol</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="-Shape"
          fill="#FFB39C"
          transform="translate(85.333333, 42.666667)"
        >
          <path d="M341.333333,3.55271368e-14 L341.333333,426.666667 L1.42108547e-14,426.666667 L1.42108547e-14,3.55271368e-14 L341.333333,3.55271368e-14 Z M298.666667,42.6666667 L42.6666667,42.6666667 L42.6666667,384 L298.666667,384 L298.666667,42.6666667 Z M256,277.333333 L256,320 L85.3333333,320 L85.3333333,277.333333 L256,277.333333 Z M256,192 L256,234.666667 L85.3333333,234.666667 L85.3333333,192 L256,192 Z M256,106.666667 L256,149.333333 L85.3333333,149.333333 L85.3333333,106.666667 L256,106.666667 Z"></path>
        </g>
      </g>
    </svg>
  );
};
