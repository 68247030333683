import React, { FunctionComponent } from 'react';
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  SxProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { links } from '/src/domain/shared/links';
import { CustomDialog } from './common/CustomDialog';
import { BannerHead } from './common/BannerHead';
import { CustomDialogTitle } from './common/CustomDialogTitle';

interface PremiumAdBannerProps {
  open: boolean;
  onClose: () => void;
}

export const PremiumAdBanner: FunctionComponent<PremiumAdBannerProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <CustomDialog open={open} onClose={onClose}>
      <BannerHead onClose={onClose} />
      <CustomDialogTitle>{t('subscribeToPremium')}</CustomDialogTitle>
      <DialogContent>
        <DialogContentText>{t('premiumDescription')}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'space-between', p: '0.5rem 1.5rem 1.75rem' }}
      >
        <Button
          size="large"
          sx={buttonSx}
          variant="outlined"
          href={links.premium}
        >
          {t('learnFunctions')}
        </Button>
        <Button
          size="large"
          sx={buttonSx}
          variant="contained"
          href={links.payment}
        >
          {t('subscribe')}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

const buttonSx: SxProps = {
  width: '47%',
  textTransform: 'none',
  textAlign: 'center',
};
