import React, { FunctionComponent } from "react";
import { Box, CircularProgress } from "@mui/material";

export const DialogProgress: FunctionComponent = () => {

    return <Box sx={{ 
        m: "2rem", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center"
    }}>
        <CircularProgress/>
    </Box>
}
