import React, { FunctionComponent } from "react";
import { TimerType } from "/src/ui/studentTasks/studentTasks/TaskView"
import { Timer } from "../icons/Timer";
import { WarningTimer } from "../icons/WarningTimer";
import { ButtonBase } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { sec2hms } from "/src/utils/time-utils";
import { theme } from "/src/assets/theme";
import { isNumber as _isNumber } from "lodash";

interface ReportButtonProps {
    isTimeShowTimer: boolean;
    isTimeLimitWarning: boolean;
    timer: TimerType;
    isReportSent?: boolean;
    onClick?: () => void;
    type?: "button" | "submit" | "reset" | undefined;
    className?: string;
    disabled?: boolean;
}


export const ReportButton: FunctionComponent<ReportButtonProps> = ({ 
    isTimeShowTimer,
    isTimeLimitWarning,
    timer, 
    isReportSent,
    onClick,
    type,
    className,
    disabled
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    
    
    if (!timer) return null
    
    const timerHms = _isNumber(timer) ? sec2hms(timer) : null;

    return (
        <ButtonBase 
            onClick={onClick}
            className={cs(
                classes.btn,
                {[classes.btnWarning]: isTimeLimitWarning},
                {[classes.btnDisabled]: disabled},
                className
            )}
            disabled={disabled}
            type={type}
        >
            { isReportSent ? t('report') : t('sendReport') }
            {isTimeShowTimer &&
                <>
                    <span className={classes.timerIcon}>{isTimeLimitWarning ? <WarningTimer/> : <Timer/>}</span>
                    { timerHms }
                </>
            }
        </ButtonBase>
    )
}

const useStyles = makeStyles(muiTheme => ({
    btn: { 
        fontSize: "1rem",
        color: muiTheme.palette.primary.contrastText,
        backgroundColor: muiTheme.palette.primary.main,
        width: "100%",
        padding: "0.5rem",
        borderRadius: "5px"
    },
    btnWarning: {
        backgroundColor: muiTheme.palette.secondary.main
    },
    timerIcon: {
        display: "flex",
        alignItems: "center",
        marginLeft: "1rem",
        marginRight: "0.25rem"
    },
    btnDisabled: {
        backgroundColor: theme.greyColor
    }
}))
