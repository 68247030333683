import React, { FunctionComponent } from 'react';
import { RatingProvider } from '/src/contexts/RatingContext';
import { ChooseCoachBannerProps, ChooseCoachBanner } from './ChooseCoachBanner';

export const ChooseCoachBannerContainer: FunctionComponent<
  ChooseCoachBannerProps
> = (props) => {
  return (
    <RatingProvider>
      <ChooseCoachBanner {...props} />
    </RatingProvider>
  );
};
