import React, {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
} from 'react';
import { User, UserRole } from '/src/domain/shared/User';
import {
  CurrentUserContext,
  CurrentUserContextProps,
} from '/src/contexts/shared/CurrentUserContext';
import {
  PostData,
  PostProps,
  PostViewMode,
  CurrentAudioPostData,
  PlaylistData,
} from '/src/domain/post/PostData';
import { getUserInfo } from '/src/domain/shared/requests/getUserInfo';
import { getUserPrivacy } from '/src/domain/shared/requests/getUserPrivacy';
import { getTags } from '/src/domain/shared/requests/getTags';
import {
  getPostWithRecommendations,
  PostWithRecommendations,
  savePost,
  deletePost,
  getPostId,
  getPosts,
  GetUserPostsByLimitArgs,
  GetAllPostsArgs,
  PostsArgs,
  getNumberOfPosts,
  GetNumberOfPostsArgs,
  getAudioMark,
  saveAudioMark,
  getPlaylist,
  savePlaylist,
  getRepostedPosts,
  addRepostPost,
  deleteRepostPost,
  checkRepostedPosts,
  getAudioKey,
} from '/src/domain/shared/requests/userPostRequests';
import { useTranslation } from 'react-i18next';

import { isArray as _isArray } from 'lodash';
import axios from 'axios';
import { links } from '/src/domain/shared/links';

export interface PostContextProps {
  getPostWithRecommendations: (
    postId: string,
  ) => Promise<PostWithRecommendations | null>;
  savePost: (
    create: boolean,
    postId: string,
    date: string,
    text: string,
    viewMode: PostViewMode,
    audio: File | null,
    tags: string[],
  ) => Promise<PostProps | null>;
  deletePost: (id: string, audioOnly: boolean) => Promise<boolean>;
  getPostId: () => Promise<string | null>;
  getPosts: (obj: PostsArgs) => Promise<PostData[]>;

  post?: PostData;
  setPostState: (postId?: string) => Promise<void>;
  isReadOnlyPost: boolean;
  postErrorCode: number | null;

  similarPosts?: PostData[];
  setSimilarPostsState: (postId?: string) => Promise<void>;

  postOwners: PostOwners;
  setPostOwnersState: (
    posts: PostData[],
    defaultUsers?: PostOwners,
  ) => Promise<PostOwners>;

  posts?: PostData[];
  setPostsState: (obj: GetUserPostsByLimitArgs) => Promise<void>;
  isReadOnlyPosts: boolean;
  postsErrorCode: number | null;

  repostedPosts?: PostData[] | null;
  setRepostedPostsState: (obj: GetUserPostsByLimitArgs) => Promise<boolean>;
  repostsOffset: number;
  isRepostsEnd: boolean;

  audioPosts?: PostData[] | null;
  setAudioPostsState: (obj: GetAllPostsArgs) => Promise<boolean>;
  audiosOffset: number;
  isAudiosEnd: boolean;
  newAudioPostsCount?: number;
  setNewAudioPostsCountState: () => Promise<boolean>;
  getAudioKey: () => Promise<string | null>;

  addRepostPost: (postid: string) => Promise<boolean>;
  deleteRepostPost: (postid: string) => Promise<boolean>;
  checkRepostedPosts: (postId: string) => Promise<boolean>;
  repostedPostDeletedStateUpdate: (
    userId: string,
    postId: string,
    isRepostPreview?: boolean,
  ) => Promise<boolean>;

  numberOfPosts?: number;
  setNumberOfPostsState: (args: GetNumberOfPostsArgs) => Promise<void>;

  pagination: PaginationData;
  setPagination: (value: PaginationData) => void;

  searchResults?: PostData[];
  setSearchResultsState: (query: string) => Promise<void>;

  removePost: (id: string, audioOnly: boolean) => Promise<boolean>;
  updatePost: (
    postId: string,
    date: string,
    text: string,
    viewMode: PostViewMode,
    file: File | null,
    tags: string[],
  ) => Promise<boolean>;

  currentAudioPost: CurrentAudioPostData | null;
  setCurrentAudioPostState: (
    value?: CurrentAudioPostData | null,
  ) => Promise<void>;
  saveAudioMark: (
    postid: string,
    sec: number,
    isFull: boolean,
  ) => Promise<boolean>;

  totalPlaylistDuration?: number;
  playlist?: PlaylistData | null;
  setPlaylist: React.Dispatch<
    React.SetStateAction<PlaylistData | null | undefined>
  >;
  setPlaylistState: () => Promise<void>;
  updatePlaylistState: (value: string | string[]) => Promise<void>;

  getTags: (studentId?: string, tag?: string) => Promise<string[]>;

  isParticipantInCommercialChallenge: boolean;
}

interface PaginationData {
  page: number;
  from: number;
  limit: number;
}

export const PAGINATION_STEP = 20;

interface PostOwners {
  [key: string]: User;
}

export const PostContext = React.createContext<PostContextProps | undefined>(
  undefined,
);

export const PostProvider: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();

  const [post, setPost] = useState<PostData>();
  const [isReadOnlyPost, setIsReadOnlyPost] = useState<boolean>(true);
  const [postErrorCode, setPostErrorCode] = useState<number | null>(null);

  const [similarPosts, setSimilarPosts] = useState<PostData[]>();

  const [searchResults, setSearchResults] = useState<PostData[]>();

  const [posts, setPosts] = useState<PostData[]>();
  const [isReadOnlyPosts, setIsReadOnlyPosts] = useState<boolean>(true);
  const [postsErrorCode, setPostsErrorCode] = useState<number | null>(null);

  const [repostedPosts, setRepostedPosts] = useState<PostData[] | null>();
  const [repostsOffset, setRepostsOffset] = useState(0);
  const [isRepostsEnd, setIsRepostsEnd] = useState(false);

  const [audioPosts, setAudioPosts] = useState<PostData[] | null>();
  const [audiosOffset, setAudiosOffset] = useState(0);
  const [isAudiosEnd, setIsAudiosEnd] = useState(false);
  const [newAudioPostsCount, setNewAudioPostsCount] = useState<number>();

  const [numberOfPosts, setNumberOfPosts] = useState<number>();

  const [postOwners, setPostOwners] = useState<PostOwners>({});

  const [pagination, setPagination] = useState<PaginationData>({
    page: 1,
    from: 0,
    limit: PAGINATION_STEP,
  });

  const [playlist, setPlaylist] = useState<PlaylistData | null>();
  const [totalPlaylistDuration, setTotalPlaylistDuration] = useState<number>();

  const [currentAudioPost, setCurrentAudioPost] =
    useState<CurrentAudioPostData | null>(null);

  const [
    isParticipantInCommercialChallenge,
    setIsParticipantInCommercialChallenge,
  ] = useState(true);

  const { currentUser, setCurrentUserState } = useContext(
    CurrentUserContext,
  ) as CurrentUserContextProps;

  const setPostState = async (postId?: string) => {
    const id = getIdFromUrl(postId);
    const postResponse = id ? await getPostWithRecommendations(id, true) : null;

    const postUser = postResponse
      ? await getUserInfo(postResponse.userid)
      : null;
    if (!postResponse || !postUser) {
      return setPostErrorCode(404);
    } else {
      setPostErrorCode(null);
    }

    postResponse.userInfo = postUser;

    const curUser = currentUser || (await setCurrentUserState());
    setIsReadOnlyPost(String(curUser?.id) != String(postUser?.id));
    setPost(postResponse);
  };

  const setSimilarPostsState = async (postId?: string) => {
    const id = getIdFromUrl(postId);

    const postResponse = id ? await getPostWithRecommendations(id) : null;

    if (!postResponse?.similar) return;

    const similarsWithoutPrivate: PostData[] | undefined =
      await getPostsWithoutPrivate(postResponse.similar);

    setSimilarPosts(similarsWithoutPrivate);
  };

  const setPostOwnersState = async (
    posts: PostData[],
    defaultUsers?: PostOwners,
  ) => {
    const newPostOwners = { ...postOwners };

    for (let p of posts) {
      if (!p.userid || newPostOwners[p.userid]) {
        continue;
      }

      const userInfo = await getUserInfo(p.userid);

      if (userInfo?.id) {
        newPostOwners[userInfo.id] = userInfo;
      }
    }

    setPostOwners(newPostOwners);
    return newPostOwners;
  };

  const setPostsState = async (obj: GetUserPostsByLimitArgs) => {
    const posts = await getPosts(obj);
    const curUser = currentUser || (await setCurrentUserState());
    setIsReadOnlyPosts(String(curUser?.id) != String(obj.userId));
    if (!Array.isArray(posts) || !posts.length) {
      return setPostsErrorCode(404);
    } else {
      setPostsErrorCode(null);
    }

    setPosts(posts);
  };

  const setAudioPostsState = async (obj: GetAllPostsArgs) => {
    let posts = await getPosts({ ...obj, isAudio: true });
    posts = await getPostsWithoutPrivate(posts);

    if (!audiosOffset && !posts?.length) {
      setAudioPosts(null);
      return false;
    }

    if (!audiosOffset && posts[0].postid) {
      saveAudioMark(posts[0].postid, 0, false);
    }

    if (!posts?.length) {
      setIsAudiosEnd(true);
      return false;
    }

    const newAudioPosts = audioPosts ? [...audioPosts, ...posts] : [...posts];

    const newAudiosOffset = audiosOffset + PAGINATION_STEP;
    setAudiosOffset(newAudiosOffset);
    setIsAudiosEnd(posts.length < PAGINATION_STEP);
    setAudioPosts(newAudioPosts);

    currentUser || (await setCurrentUserState());

    return true;
  };

  const setNewAudioPostsCountState = async () => {
    try {
      const response = await axios.get(
        `${links.origin}/apiweb/post.php?action=total&all=1&audio=1&newest=1`,
      );
      const newAudioPostsCount = response.data.total;
      if (Number.isInteger(newAudioPostsCount)) {
        setNewAudioPostsCount(newAudioPostsCount);
        return true;
      }

      return false;
    } catch (e) {
      console.log('setNewAudioPostsCountState');
      return false;
    }
  };

  const setRepostedPostsState = async (obj: GetUserPostsByLimitArgs) => {
    if (!obj.userId) {
      setRepostedPosts(null);
      return false;
    }

    let posts = await getRepostedPosts(obj);
    posts = posts ? await getPostsWithoutPrivate(posts) : null;

    if (!repostsOffset && !posts?.length) {
      setRepostedPosts(null);
      return false;
    }

    if (!posts?.length) {
      setIsRepostsEnd(true);
      return false;
    }

    const newRepostedPosts = repostedPosts
      ? [...repostedPosts, ...posts]
      : [...posts];

    const newRepostsOffset = repostsOffset + PAGINATION_STEP;
    setRepostsOffset(newRepostsOffset);
    setIsRepostsEnd(posts.length < PAGINATION_STEP);

    setRepostedPosts(newRepostedPosts);

    currentUser || (await setCurrentUserState());

    return true;
  };

  const repostedPostDeletedStateUpdate = async (
    userId: string,
    postId: string,
    isRepostPreview?: boolean,
  ): Promise<boolean> => {
    const isDeleted = await deleteRepostPost(postId);

    if (!isDeleted) {
      return false;
    }

    const limit = isRepostPreview ? undefined : repostsOffset + PAGINATION_STEP;

    const posts = await getRepostedPosts({
      userId,
      from: 0,
      limit,
    });

    const postsWithoutPrivate: PostData[] | null = posts
      ? await getPostsWithoutPrivate(posts)
      : null;

    setRepostedPosts(postsWithoutPrivate);
    return true;
  };

  const setNumberOfPostsState = async (args: GetNumberOfPostsArgs) => {
    const numberOfPosts = await getNumberOfPosts(args);
    setNumberOfPosts(numberOfPosts);
  };

  const setSearchResultsState = async (query: string) => {
    const posts = await getPosts(query);
    currentUser || (await setCurrentUserState());

    const postsWithoutPrivate: PostData[] = await getPostsWithoutPrivate(posts);

    setSearchResults(postsWithoutPrivate);
  };

  useEffect(() => {
    searchResults && setPostOwnersState(searchResults);
    similarPosts && setPostOwnersState(similarPosts);
    repostedPosts && setPostOwnersState(repostedPosts);
    audioPosts && setPostOwnersState(audioPosts);

    const playlistPosts =
      playlist?.posts &&
      Object.keys(playlist.posts)
        .map((key) => playlist.posts[key])
        .filter((p) => p);
    playlistPosts && setPostOwnersState(playlistPosts);
  }, [searchResults, similarPosts, playlist, repostedPosts, audioPosts]);

  const removePost = async (
    id: string,
    audioOnly: boolean,
  ): Promise<boolean> => {
    const isDeleted = await deletePost(id, audioOnly);

    if (!audioOnly && isDeleted) {
      setPostErrorCode(404);
    }

    if (audioOnly && isDeleted) {
      const newPost: PostData | undefined = post
        ? {
            ...post,
            audiosec: undefined,
            audiosrc: undefined,
          }
        : undefined;

      setPost(newPost);
    }

    return isDeleted;
  };

  const updatePost = async (
    postId: string,
    date: string,
    text: string,
    viewMode: PostViewMode,
    audio: File | null,
    tags: string[],
  ): Promise<boolean> => {
    const result = await savePost(
      false,
      postId,
      date,
      text,
      viewMode,
      audio,
      tags,
    );

    if (result) {
      const newPost: PostData = {
        ...post,
        ...result,
      };

      setPost(newPost);
    }

    return Boolean(result);
  };

  const setCurrentAudioPostState = async (
    value?: CurrentAudioPostData | null,
  ) => {
    if (value === null) {
      setCurrentAudioPost(value);
      return;
    }

    if (value?.audiosrc) {
      setCurrentAudioPost(value);
      return;
    }

    const markData = await getAudioMark();

    if (!markData?.blogid) return;

    const post = await getPostWithRecommendations(markData.blogid, true);

    if (!post?.audiosrc || !post.audiosec) return;

    const currentAudioPost: CurrentAudioPostData = {
      ...post,
      audiosec: post.audiosec,
      audiosrc: post.audiosrc,
      isBugged: false,
    };

    setCurrentAudioPost(currentAudioPost);
  };

  const setTotalPlaylistDurationState = () => {
    const duration = playlist?.ids.reduce((sum, id) => {
      const sec = playlist.posts[id]?.audiosec;

      if (sec) {
        return sum + sec;
      }

      return 0;
    }, 0);

    setTotalPlaylistDuration(duration);
  };

  useEffect(() => {
    setTotalPlaylistDurationState();
  }, [playlist]);

  const setPlaylistState = async () => {
    const playlist = await getPlaylist();
    setPlaylist(playlist);
  };

  const updatePlaylistState = async (value: string | string[]) => {
    let newIds: string[] = playlist?.ids ? [...playlist.ids] : [];
    if (_isArray(value)) {
      newIds = value;
    } else if (playlist?.ids.includes(value)) {
      if (!confirm(t('confirmRemovalFromPlaylist'))) {
        return;
      }
      newIds = clearPlaylistFromEmpty(playlist, value);
    } else {
      newIds.push(value);
    }

    const isSent = await savePlaylist(newIds);

    if (!isSent) {
      alert(t('serverError'));
    }

    await setPlaylistState();
  };

  const requestForCheckCommercialChallenge = async (userId: string) => {
    const response = await axios.get(
      `${links.challengesOrigin}/api/challenges/registration/check-active-commercial`,
      {
        params: {
          user_id: userId,
        },
      },
    );

    setIsParticipantInCommercialChallenge(!!response.data);
  };

  useEffect(() => {
    if (
      !currentUser ||
      currentUser.isPremium ||
      currentUser.role !== UserRole.STUDENT
    ) {
      return;
    }

    requestForCheckCommercialChallenge(currentUser.id);
  }, [currentUser]);

  return (
    <PostContext.Provider
      value={{
        getPostWithRecommendations,
        savePost,
        deletePost,
        getPostId,
        getPosts,

        post,
        setPostState,
        isReadOnlyPost,
        postErrorCode,

        similarPosts,
        setSimilarPostsState,

        postOwners,
        setPostOwnersState,

        posts,
        setPostsState,
        isReadOnlyPosts,
        postsErrorCode,

        repostedPosts,
        setRepostedPostsState,
        repostsOffset,
        isRepostsEnd,

        audioPosts,
        setAudioPostsState,
        audiosOffset,
        isAudiosEnd,
        newAudioPostsCount,
        setNewAudioPostsCountState,
        getAudioKey,

        addRepostPost,
        deleteRepostPost,
        checkRepostedPosts,
        repostedPostDeletedStateUpdate,

        numberOfPosts,
        setNumberOfPostsState,

        pagination,
        setPagination,

        searchResults,
        setSearchResultsState,

        removePost,
        updatePost,

        currentAudioPost,
        setCurrentAudioPostState,
        saveAudioMark,

        totalPlaylistDuration,
        playlist,
        setPlaylist,
        setPlaylistState,
        updatePlaylistState,

        getTags,

        isParticipantInCommercialChallenge,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};

export const getIdFromUrl = (id?: string) => {
  return id || window.location.pathname.split('/')[2];
};

const usersPrivacy: {
  [key: string]: boolean;
} = {};

const getPostsWithoutPrivate = async (posts: PostData[]) => {
  const similarWithoutPrivate: PostData[] = [];

  for (let i of posts) {
    const userId = i.userid;

    if (!userId) {
      console.log('private case', 1);
      continue;
    }

    if (typeof usersPrivacy[userId] === 'boolean') {
      !usersPrivacy[userId] && similarWithoutPrivate.push(i);
      console.log('private case', 2, userId, usersPrivacy[userId]);
    } else {
      const isPrivateUser = await getUserPrivacy(userId);
      !isPrivateUser && similarWithoutPrivate.push(i);
      usersPrivacy[userId] = isPrivateUser;
      console.log('private case', 3, userId, usersPrivacy[userId]);
    }
  }

  return similarWithoutPrivate;
};

export const clearPlaylistFromEmpty = (
  playlist: PlaylistData,
  value?: string,
): string[] => {
  return playlist.ids.filter((id) => {
    return playlist.posts[id]?.audiosec && id !== value;
  });
};
