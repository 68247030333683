import React, { FunctionComponent } from "react";
import { IconsProps } from "./iconsProps";

export const House: FunctionComponent<IconsProps> = ({ className }) => {

    return <svg className={className} viewBox="21 14 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_4411_7805)">
    <ellipse cx="44.4613" cy="56.4129" rx="21.8968" ry="2.17168" fill="#C4C1FE"/>
    </g>
    <path d="M61.928 37.8164H35.0189C34.2658 37.8164 33.6553 38.4143 33.6553 39.1519V55.1775C33.6553 55.915 34.2658 56.513 35.0189 56.513H61.928C62.6811 56.513 63.2916 55.915 63.2916 55.1775V39.1519C63.2916 38.4143 62.6811 37.8164 61.928 37.8164Z" fill="url(#paint0_linear_4411_7805)"/>
    <path d="M51.3519 39.6136V55.1772C51.3519 55.5318 51.2081 55.8718 50.9521 56.1226C50.6961 56.3733 50.3488 56.5141 49.9868 56.5141H26.8241C26.462 56.5141 26.1148 56.3733 25.8588 56.1226C25.6028 55.8718 25.459 55.5318 25.459 55.1772V38.4162C25.4587 38.2278 25.4991 38.0415 25.5776 37.8695C25.656 37.6975 25.7706 37.5436 25.914 37.418L36.1386 28.5139C36.3845 28.3001 36.701 28.1804 37.0298 28.1768C37.3587 28.1733 37.6777 28.2861 37.9284 28.4946L51.2927 39.6136H51.3519Z" fill="url(#paint1_linear_4411_7805)"/>
    <path d="M51.3521 39.614V37.8166L38.1318 26.7793H48.5976C48.9293 26.7808 49.2505 26.8935 49.5077 27.0987L64.5101 39.0941C64.5557 39.1334 64.5882 39.1852 64.6034 39.2428C64.6186 39.3003 64.6158 39.3611 64.5954 39.4171C64.5749 39.4731 64.5378 39.5218 64.4888 39.5569C64.4398 39.592 64.3812 39.6119 64.3205 39.614H51.3521Z" fill="url(#paint2_linear_4411_7805)"/>
    <path d="M40.2667 44.9775H36.1504C35.0458 44.9775 34.1504 45.873 34.1504 46.9775V56.5125H42.2667V46.9775C42.2667 45.873 41.3712 44.9775 40.2667 44.9775Z" fill="url(#paint3_linear_4411_7805)"/>
    <path d="M63.2939 39.6133H51.3584V42.3258H63.2939V39.6133Z" fill="url(#paint4_linear_4411_7805)"/>
    <path d="M38.7723 27.3141L38.1565 26.7793L38.1444 26.7912L38.1307 26.7793L37.2646 27.5339L23.5954 39.0897C23.5502 39.1278 23.5178 39.1786 23.5027 39.2352C23.4876 39.2918 23.4905 39.3516 23.5109 39.4065C23.5313 39.4615 23.5683 39.5091 23.617 39.543C23.6657 39.5769 23.7238 39.5954 23.7835 39.5962L25.1911 39.614L38.1565 28.6807L51.2933 39.614H51.3525L53.4759 39.5873L38.7723 27.3141Z" fill="url(#paint5_linear_4411_7805)"/>
    <path d="M38.0905 40.9698C39.5272 40.9698 40.6918 39.8292 40.6918 38.4222C40.6918 37.0151 39.5272 35.8745 38.0905 35.8745C36.6539 35.8745 35.4893 37.0151 35.4893 38.4222C35.4893 39.8292 36.6539 40.9698 38.0905 40.9698Z" fill="url(#paint6_linear_4411_7805)"/>
    <path d="M30.0408 44.9775H29.1416C28.037 44.9775 27.1416 45.873 27.1416 46.9775V50.1941C27.1416 51.2987 28.037 52.1941 29.1416 52.1941H30.0408C31.1454 52.1941 32.0408 51.2987 32.0408 50.1941V46.9775C32.0408 45.873 31.1454 44.9775 30.0408 44.9775Z" fill="url(#paint7_linear_4411_7805)"/>
    <path d="M46.8523 47.3945H45.9531C44.8486 47.3945 43.9531 48.29 43.9531 49.3945V50.1927C43.9531 51.2973 44.8486 52.1927 45.9531 52.1927H46.8523C47.9569 52.1927 48.8523 51.2973 48.8523 50.1927V49.3945C48.8523 48.29 47.9569 47.3945 46.8523 47.3945Z" fill="url(#paint8_linear_4411_7805)"/>
    <g filter="url(#filter1_f_4411_7805)">
    <ellipse cx="52.4987" cy="34.0634" rx="6.46741" ry="11.6728" fill="#4071F0" fillOpacity="0.6"/>
    </g>
    <path d="M48.1526 44.9956H44.6528C44.2664 44.9956 43.9531 45.3089 43.9531 45.6953C43.9531 46.0817 44.2664 46.3949 44.6528 46.3949H48.1526C48.5391 46.3949 48.8523 46.0817 48.8523 45.6953C48.8523 45.3089 48.5391 44.9956 48.1526 44.9956Z" fill="url(#paint9_linear_4411_7805)"/>
    <path d="M63.5067 50.9377C63.3378 50.2523 62.3604 50.2523 62.1914 50.9377V50.9377C62.1318 51.1795 61.9417 51.3685 61.6994 51.4258V51.4258C61.0179 51.5869 61.0179 52.5568 61.6994 52.7179V52.7179C61.9417 52.7752 62.1318 52.9642 62.1914 53.206V53.206C62.3604 53.8914 63.3378 53.8914 63.5067 53.206V53.206C63.5663 52.9642 63.7564 52.7752 63.9988 52.7179V52.7179C64.6803 52.5568 64.6803 51.5869 63.9988 51.4258V51.4258C63.7564 51.3685 63.5663 51.1795 63.5067 50.9377V50.9377Z" fill="url(#paint10_linear_4411_7805)"/>
    <path d="M65.2616 59.3005C65.0927 58.6151 64.1153 58.6151 63.9463 59.3005V59.3005C63.8867 59.5423 63.6966 59.7313 63.4543 59.7886V59.7886C62.7728 59.9497 62.7728 60.9196 63.4543 61.0807V61.0807C63.6966 61.138 63.8867 61.327 63.9463 61.5688V61.5688C64.1153 62.2542 65.0927 62.2542 65.2616 61.5688V61.5688C65.3212 61.327 65.5113 61.138 65.7537 61.0807V61.0807C66.4352 60.9196 66.4352 59.9497 65.7537 59.7886V59.7886C65.5113 59.7313 65.3212 59.5423 65.2616 59.3005V59.3005Z" fill="url(#paint11_linear_4411_7805)"/>
    <path d="M28.3983 26.5061C28.2294 25.8207 27.252 25.8207 27.083 26.5061V26.5061C27.0234 26.7479 26.8333 26.9369 26.591 26.9942V26.9942C25.9095 27.1553 25.9095 28.1251 26.591 28.2863V28.2863C26.8333 28.3435 27.0234 28.5326 27.083 28.7743V28.7743C27.252 29.4597 28.2294 29.4597 28.3983 28.7743V28.7743C28.4579 28.5326 28.648 28.3435 28.8904 28.2863V28.2863C29.5719 28.1251 29.5719 27.1553 28.8904 26.9942V26.9942C28.648 26.9369 28.4579 26.7479 28.3983 26.5061V26.5061Z" fill="url(#paint12_linear_4411_7805)"/>
    <path d="M65.4562 33.1112C65.9617 32.7501 65.8771 31.9744 65.305 31.7261V31.7261C64.7416 31.4816 64.1261 31.9337 64.1926 32.5431V32.5431C64.2592 33.1525 64.9583 33.4668 65.4562 33.1112V33.1112Z" fill="url(#paint13_linear_4411_7805)"/>
    <path d="M56.4645 58.0664C55.8609 57.9165 55.3177 58.463 55.4869 59.0499V59.0499C55.6561 59.6366 56.4103 59.8236 56.8447 59.3866V59.3866C57.2791 58.9496 57.0679 58.2162 56.4645 58.0664V58.0664Z" fill="url(#paint14_linear_4411_7805)"/>
    <path d="M59.7708 22.4757C58.8065 21.5306 57.6615 20.7809 56.4012 20.2694C55.1409 19.7579 53.79 19.4946 52.4258 19.4946C51.0616 19.4946 49.7107 19.7579 48.4505 20.2694C47.1902 20.7809 46.0451 21.5306 45.0808 22.4757C44.151 23.3652 43.4126 24.4282 42.9093 25.6018C42.406 26.7755 42.1479 28.0361 42.1504 29.309C42.1504 31.8909 43.1682 34.5514 45.0808 36.8539L51.0099 44.3825C51.177 44.5946 51.3915 44.7663 51.637 44.8845C51.8824 45.0026 52.1523 45.0641 52.4258 45.0641C52.6993 45.0641 52.9692 45.0026 53.2147 44.8845C53.4601 44.7663 53.6747 44.5946 53.8417 44.3825L59.7708 36.8539C61.6835 34.5514 62.6997 31.8909 62.6997 29.309C62.7025 28.0362 62.4447 26.7756 61.9417 25.602C61.4386 24.4283 60.7004 23.3653 59.7708 22.4757Z" fill="url(#paint15_linear_4411_7805)"/>
    <path d="M52.4248 33.736C54.8039 33.736 56.7325 31.8472 56.7325 29.5172C56.7325 27.1872 54.8039 25.2983 52.4248 25.2983C50.0458 25.2983 48.1172 27.1872 48.1172 29.5172C48.1172 31.8472 50.0458 33.736 52.4248 33.736Z" fill="white"/>
    <defs>
    <filter id="filter0_f_4411_7805" x="0.67864" y="32.3554" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="10.9429" result="effect1_foregroundBlur_4411_7805"/>
    </filter>
    <filter id="filter1_f_4411_7805" x="24.1454" y="0.504812" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="10.9429" result="effect1_foregroundBlur_4411_7805"/>
    </filter>
    <linearGradient id="paint0_linear_4411_7805" x1="45.2918" y1="50.0792" x2="63.9816" y2="75.6865" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F2"/>
    <stop offset="1" stopColor="#F7F7FF"/>
    </linearGradient>
    <linearGradient id="paint1_linear_4411_7805" x1="67.0966" y1="66.5474" x2="31.2381" y2="27.111" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F2"/>
    <stop offset="1" stopColor="#F7F7FF"/>
    </linearGradient>
    <linearGradient id="paint2_linear_4411_7805" x1="47.4174" y1="37.7732" x2="52.7481" y2="23.8287" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F2"/>
    <stop offset="1" stopColor="#F7F7FF"/>
    </linearGradient>
    <linearGradient id="paint3_linear_4411_7805" x1="38.2085" y1="44.9775" x2="38.2085" y2="56.5125" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F3"/>
    <stop offset="1" stopColor="#8B85FF"/>
    </linearGradient>
    <linearGradient id="paint4_linear_4411_7805" x1="52.0372" y1="35.0578" x2="43.0574" y2="39.7241" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F2"/>
    <stop offset="1" stopColor="#F7F7FF"/>
    </linearGradient>
    <linearGradient id="paint5_linear_4411_7805" x1="49.0733" y1="35.7139" x2="44.89" y2="17.0868" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F2"/>
    <stop offset="1" stopColor="#F7F7FF"/>
    </linearGradient>
    <linearGradient id="paint6_linear_4411_7805" x1="33.0963" y1="37.7727" x2="44.1867" y2="44.6698" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F2"/>
    <stop offset="1" stopColor="#F7F7FF"/>
    </linearGradient>
    <linearGradient id="paint7_linear_4411_7805" x1="29.5912" y1="44.9775" x2="29.5912" y2="52.1941" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F3"/>
    <stop offset="1" stopColor="#8B85FF"/>
    </linearGradient>
    <linearGradient id="paint8_linear_4411_7805" x1="46.4027" y1="47.3945" x2="46.4027" y2="52.1927" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F3"/>
    <stop offset="1" stopColor="#8B85FF"/>
    </linearGradient>
    <linearGradient id="paint9_linear_4411_7805" x1="46.4027" y1="44.9956" x2="46.4027" y2="46.3949" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F3"/>
    <stop offset="1" stopColor="#8B85FF"/>
    </linearGradient>
    <linearGradient id="paint10_linear_4411_7805" x1="60.1069" y1="53.5633" x2="64.8195" y2="44.3915" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F2"/>
    <stop offset="1" stopColor="#F7F7FF"/>
    </linearGradient>
    <linearGradient id="paint11_linear_4411_7805" x1="61.8618" y1="61.9261" x2="66.5744" y2="52.7543" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F2"/>
    <stop offset="1" stopColor="#F7F7FF"/>
    </linearGradient>
    <linearGradient id="paint12_linear_4411_7805" x1="24.9985" y1="29.1316" x2="29.7111" y2="19.9599" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F2"/>
    <stop offset="1" stopColor="#F7F7FF"/>
    </linearGradient>
    <linearGradient id="paint13_linear_4411_7805" x1="64.2445" y1="31.4122" x2="68.2827" y2="33.0431" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F2"/>
    <stop offset="1" stopColor="#F7F7FF"/>
    </linearGradient>
    <linearGradient id="paint14_linear_4411_7805" x1="55.9228" y1="60.0694" x2="54.617" y2="55.9871" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5149F2"/>
    <stop offset="1" stopColor="#F7F7FF"/>
    </linearGradient>
    <linearGradient id="paint15_linear_4411_7805" x1="42.1584" y1="19.5558" x2="56.6456" y2="42.3859" gradientUnits="userSpaceOnUse">
    <stop stopColor="#E1E0FF"/>
    <stop offset="1" stopColor="#6C65F9"/>
    </linearGradient>
    </defs>
    </svg>    
}
