import React, { FunctionComponent } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next"

export const ChallengeProtocolPedestal: FunctionComponent = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    return (
        <svg className={classes.root} width="275" height="132" viewBox="0 0 275 132" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M177.729 65.3335H275L231 132H177.729V65.3335Z" fill="url(#paint0_linear)"/>
            <path d="M194 51.3335H232.5L275 65.3335H178L194 51.3335Z" fill="#FBC14E"/>
            <path d="M97.2705 38.5386H-0.000236511L41.5 132H101.5L97.2705 38.5386Z" fill="url(#paint1_linear)"/>
            <path d="M83.9423 24H36L0 38.6667H97L83.9423 24Z" fill="#FBC14E"/>
            <g className={classes.substitution}>
                <rect x="49" y="38.6665" width="105" height="93.3333" fill="url(#paint2_linear)"/>
            </g>
            <g className={classes.substitution}>
                <rect width="87" height="93.3333" transform="matrix(-1 0 0 1 217 38.6665)" fill="url(#paint3_linear)"/>
            </g>
            <path d="M76.8555 12H201.746L181.746 132H96.8555L76.8555 12Z" fill="url(#paint4_linear)"/>
            <path d="M103.827 0H165.173L202 12H77L103.827 0Z" fill="#FBC14E"/>
            <path d="M141.822 64.6665H134.891V42.6001L128.082 44.5894V39.3394L141.187 34.8071H141.822V64.6665Z" fill="#F38239"/>
            <path d="M213.131 91.3911H215.926C218.141 91.3911 219.248 90.3071 219.248 88.1392C219.248 87.2954 218.984 86.6099 218.457 86.0825C217.93 85.5435 217.186 85.2739 216.225 85.2739C215.439 85.2739 214.754 85.5024 214.168 85.9595C213.594 86.4165 213.307 86.9849 213.307 87.6646H207.383C207.383 86.3169 207.758 85.1157 208.508 84.061C209.258 83.0063 210.295 82.186 211.619 81.6001C212.955 81.0024 214.42 80.7036 216.014 80.7036C218.861 80.7036 221.1 81.354 222.729 82.6548C224.357 83.9556 225.172 85.7427 225.172 88.0161C225.172 89.1177 224.832 90.1606 224.152 91.145C223.484 92.1177 222.506 92.9087 221.217 93.5181C222.576 94.0103 223.643 94.7603 224.416 95.7681C225.189 96.7642 225.576 98.0005 225.576 99.4771C225.576 101.762 224.697 103.59 222.939 104.961C221.182 106.333 218.873 107.018 216.014 107.018C214.338 107.018 212.779 106.702 211.338 106.069C209.908 105.424 208.824 104.54 208.086 103.415C207.348 102.278 206.979 100.989 206.979 99.5474H212.938C212.938 100.333 213.254 101.012 213.887 101.586C214.52 102.161 215.299 102.448 216.225 102.448C217.268 102.448 218.1 102.161 218.721 101.586C219.342 101 219.652 100.256 219.652 99.354C219.652 98.0649 219.33 97.1509 218.686 96.6118C218.041 96.0728 217.15 95.8032 216.014 95.8032H213.131V91.3911Z" fill="#F38239"/>
            <path d="M65.5762 89.3335H47.7168V85.4663L55.9434 76.8179C57.9707 74.5093 58.9844 72.6753 58.9844 71.3159C58.9844 70.2144 58.7441 69.3765 58.2637 68.8022C57.7832 68.228 57.0859 67.9409 56.1719 67.9409C55.2695 67.9409 54.5371 68.3276 53.9746 69.1011C53.4121 69.8628 53.1309 70.8179 53.1309 71.9663H47.1895C47.1895 70.396 47.582 68.9487 48.3672 67.6245C49.1523 66.2886 50.2422 65.2456 51.6367 64.4956C53.0312 63.7456 54.5898 63.3706 56.3125 63.3706C59.0781 63.3706 61.2051 64.0093 62.6934 65.2866C64.1934 66.564 64.9434 68.3979 64.9434 70.7886C64.9434 71.7964 64.7559 72.7808 64.3809 73.7417C64.0059 74.6909 63.4199 75.6929 62.623 76.7476C61.8379 77.7905 60.5664 79.1909 58.8086 80.9487L55.5039 84.7632H65.5762V89.3335Z" fill="#F38239"/>
            <defs>
                <linearGradient id="paint0_linear" x1="183" y1="132" x2="281.06" y2="89.9743" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FEB642"/>
                    <stop offset="1" stopColor="#F6D261"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="86.5" y1="132" x2="3.58863" y2="92.5458" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FEB742"/>
                    <stop offset="1" stopColor="#F6D262"/>
                </linearGradient>
                <linearGradient id="paint2_linear" x1="71.1831" y1="89.9998" x2="184.334" y2="111.189" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD4545" stopOpacity="0"/>
                    <stop offset="1" stopColor="#520000"/>
                </linearGradient>
                <linearGradient id="paint3_linear" x1="18.3803" y1="46.6667" x2="104.382" y2="67.1367" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD4545" stopOpacity="0"/>
                    <stop offset="1" stopColor="#520000"/>
                </linearGradient>
                <linearGradient id="paint4_linear" x1="139.301" y1="12" x2="139.301" y2="132" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F5D565"/>
                    <stop offset="1" stopColor="#FFB43F"/>
                </linearGradient>
            </defs>
        </svg>        
    )
}

const useStyles = makeStyles({
    root: {
        transform: 'scale(0.8)'
    },
    substitution: {
        mixBlendMode: 'darken'
    }
})
