import React, { FunctionComponent, useContext, useEffect } from "react";
import { StudentInfo, TrainerBaseInfo } from "/src/domain/dashboard/DashboardData";
import { CurrentUserContext, CurrentUserContextProps } from "/src/contexts/shared/CurrentUserContext";
import { PostContext, PostContextProps } from "/src/contexts/PostContext";
import { PostButton } from "/src/ui/studentTasks/studentTasks/posts/PostButton";
import { PostView } from "/src/ui/studentTasks/studentTasks/posts/PostView";
import { PostsStub } from "./PostsStub";
import { Box, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PostViewMode } from "/src/domain/studentTasks/StudentTasksData";

interface PostsProps {
    className?: string;
    postOwner: StudentInfo | TrainerBaseInfo;
    ownerTrainerId?: string;
    sx?: SxProps;
}

export const Posts: FunctionComponent<PostsProps> = ({
    className,
    postOwner,
    ownerTrainerId,
    sx
}) => {
    const { t } = useTranslation();
    const { currentUser, currentDate } = useContext(CurrentUserContext) as CurrentUserContextProps;

    const { 
        savePost, 
        getPostId,
        setPostsState, 
        posts,
        deletePost,
        isReadOnlyPosts,
        postsErrorCode
    } = useContext(PostContext) as PostContextProps;


    useEffect(() => {
        setPostsState({userId: postOwner.id});
    }, [])

    const updatePost = async (create: boolean, postId: string, date: string, text: string, viewMode: PostViewMode, audio: File | null, tags: string[]): Promise<boolean> => {
        const newPost = await savePost(
            create,
            postId,
            date,
            text,
            viewMode,
            audio,
            tags
        );

        if (!newPost) {
            return false;
        }
        
        await setPostsState({userId: postOwner.id});
        return true;  
    }

    const removePost = async (postId: string, audioOnly: boolean) => {
        const isDeleted = await deletePost(postId, audioOnly);

        if (!isDeleted) {
            return false;
        }

        await setPostsState({userId: postOwner.id});
        return true;
    }
    return <Box className={className} sx={sx}>
        {!isReadOnlyPosts && <PostButton
            getPostId={getPostId}
            createPost={(postId: string, date: string, text: string, viewMode: PostViewMode, audio: File | null, tags: string[]) => updatePost(true, postId, date, text, viewMode, audio, tags)}
            currentDate={currentDate}
            isPremium={!!currentUser?.isPremium}
            currentUserId={currentUser?.id}
        />}
        {postsErrorCode === 404 ? <PostsStub/>
        : posts?.map((post) => 
            <PostView
                key={post.postid}
                updatePost={(postId: string, date: string, text: string, viewMode: PostViewMode, audio: File | null, tags: string[]) => updatePost(false, postId, date, text, viewMode, audio, tags)}
                removePost={removePost}
                currentDate={currentDate}
                isReadOnly={isReadOnlyPosts}
                type={"post"}
                {...post}
                isPreview
                currentUserId={currentUser?.id}
                isRepostAvailable={Boolean(ownerTrainerId && ownerTrainerId === currentUser?.id)}
                isRepostedPost={false}
            />)
        }
    </Box>
}
