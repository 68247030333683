import React, { FunctionComponent } from "react";
import { Box, SxProps } from "@mui/material"
import { useTranslation } from "react-i18next";


export enum CrownIconSize {
    LARGE = "large",
    SMALL = "small"
}

interface CrownIconProps {
    size?: CrownIconSize;
    className?: string;
    sx?: SxProps
}


export const CrownIcon: FunctionComponent<CrownIconProps> = ({ 
    size, 
    className,
    sx
}) => {
    const { t } = useTranslation();

    return <Box 
        component="svg"  
        viewBox="0 0 57 50" 
        fill="#F77774" xmlns="http://www.w3.org/2000/svg"
        className={className}
        sx={{
            width: size === CrownIconSize.LARGE ? "3.5rem" : "1.3rem",
            height: size === CrownIconSize.LARGE ? "3.1rem" : "1.2rem",
            ...sx
        }}
    >
        <path d="M46.9528 43.4867L10.3298 43.462C9.55506 43.4615 8.91869 44.1499 8.91562 44.9918L8.90446 48.0532C8.90139 48.8951 9.53274 49.5844 10.3075 49.5849L46.9305 49.6096C47.7052 49.6102 48.3416 48.9218 48.3447 48.0799L48.3558 45.0184C48.3589 44.1765 47.7275 43.4873 46.9528 43.4867ZM52.6987 12.876C50.3658 12.8744 48.4655 14.9301 48.4563 17.4653C48.4538 18.1446 48.5923 18.7761 48.8367 19.3599L42.4478 23.5077C41.0888 24.3869 39.3387 23.8882 38.5606 22.3953L31.4354 8.7478C32.3805 7.90653 33.0013 6.6441 33.0065 5.20904C33.0158 2.67378 31.1305 0.615583 28.7975 0.614007C26.4646 0.61243 24.5643 2.66807 24.5551 5.20333C24.5498 6.63839 25.1615 7.90166 26.1004 8.74419L18.8757 22.3819C18.0868 23.8739 16.3242 24.3702 14.9805 23.4891L8.6306 19.3327C8.87039 18.7588 9.02239 18.1179 9.02487 17.4387C9.03411 14.9034 7.14883 12.8452 4.81587 12.8436C2.48292 12.8421 0.573836 14.8977 0.564594 17.433C0.555351 19.9682 2.44063 22.0264 4.77359 22.028C5.00248 22.0282 5.23152 21.99 5.45175 21.9519L11.7495 40.4015L45.5554 40.4243L51.9877 21.9834C52.2076 22.0218 52.4364 22.0602 52.6653 22.0604C54.9982 22.0619 56.8985 20.0063 56.9077 17.471C56.917 14.9358 55.0317 12.8776 52.6987 12.876Z" fill="inherit"/>
    </Box>
}
