import React, {FunctionComponent} from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {UncheckedIcon} from "/src/ui/shared/checkbox/UncheckedIcon";
import {CheckedIcon} from "/src/ui/shared/checkbox/CheckedIcon";
import cs from "classnames";

type AppCheckboxProps = {
    label: string,
    name: string,
    checked: boolean,
    onChange: (fieldName: string, isChecked: boolean) => void,
    className?: string;
    primary?: boolean
};

export const AppCheckbox: FunctionComponent<AppCheckboxProps> = ({
    label, 
    name, 
    checked, 
    onChange, 
    className,
    primary
}) => {
    const classes = useStyles()
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(name, event.target.checked);
    };

    return (
        <FormControlLabel
            control={<Checkbox icon={<UncheckedIcon className={cs({[classes.primary]: primary})}/>} checked={checked} onChange={handleChange}
                               checkedIcon={<CheckedIcon className={cs({[classes.primary]: primary})}/>} value={checked}
                               name={name}/>}
            label={label}
            className={cs(className)}
        />
    )
}

const useStyles = makeStyles(muiTheme => ({
    primary: {
        '&>path': {
            stroke: muiTheme.palette.primary.main,
        } 
    }
}))