import React, { FunctionComponent, useState } from 'react';
import { 
    getSubscribe, 
    getListOfSubscribers,
    getListOfSubscriptions, 
    addSubscribe,
    deleteSubscribe
} from "/src/domain/shared/requests/subscriptionRequests";
import { 
    SubscriptionValue, 
    SubscriptionItem,
    SubscriptionType 
} from "/src/domain/subscriptionData/SubscriptionData";

export interface SubscriptionsContextProps {
    setSubscriptionInfoState: (userId: string) => Promise<void>;
    isSubscribed: SubscriptionValue | null;

    setListOfSubscribersState: (userId: string) => Promise<void>;
    listOfSubscribers: SubscriptionItem[] | null;

    setListOfSubscriptionsState: (userId: string) => Promise<void>
    listOfSubscriptions: SubscriptionItem[] | null;

    setSubscription: (value: SubscriptionValue) => void; 

    handleSubscribeToggle: (userId: string) => Promise<void>;
}


export const SubscriptionsContext = React.createContext<SubscriptionsContextProps | undefined>(undefined);

export const SubscriptionsProvider: FunctionComponent = ({ children }) => {
    const [isSubscribed, setSubscription] = useState<SubscriptionValue | null>(null);
    const [listOfSubscribers, setListOfSubscribers] = useState<SubscriptionItem[] | null>(null);
    const [listOfSubscriptions, setListOfSubscriptions] = useState<SubscriptionItem[] | null>(null);


    const setSubscriptionInfoState = async (userId: string) => {
        const isSubscribed = await getSubscribe(userId);
        setSubscription(isSubscribed);
    }

    const setListOfSubscribersState = async (userId: string) => {
        const listOfSubscribers = await getListOfSubscribers(userId);
        setListOfSubscribers(listOfSubscribers);
    }

    const setListOfSubscriptionsState = async (userId: string) => {
        const listOfSubscriptions = await getListOfSubscriptions(userId);
        setListOfSubscriptions(listOfSubscriptions);
    }


    const handleSubscribeToggle = async (userId: string) => {
        if (!isSubscribed || isSubscribed === SubscriptionType.FOLLOWED) {
            const isSubscribed = await addSubscribe(userId);
            setSubscription(isSubscribed);
        } else {
            const isSubscribed = await deleteSubscribe(userId);
            setSubscription(isSubscribed);
        }
        setListOfSubscribersState(userId)
    }

    return (
        <SubscriptionsContext.Provider 
            value={{
                setSubscriptionInfoState,
                isSubscribed,

                setListOfSubscribersState,
                listOfSubscribers,

                setListOfSubscriptionsState,
                listOfSubscriptions,

                setSubscription,

                handleSubscribeToggle
            }}>{children}</SubscriptionsContext.Provider>
    )
}
