import axios from "axios";
import { links } from "/src/domain/shared/links";


export const getTribunalUsers = async (): Promise<string[]> => {
    try {
        const response = await axios.get(`${links.challengesOrigin}/api/challenges/tribunal`);
        const tribunalIds: string[] = response.data;
        
        return tribunalIds;
    } catch (e) {
        console.log("Tribunal error: ", e);
        return [];
    }
}