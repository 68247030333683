export interface User {
    readonly id: string;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly currentTrainerId?: string;
    readonly avatarUrl?: string;
    readonly role: UserRole;
    readonly isPremium: boolean;
    readonly trainingsRequests: TrainingsRequest[];
    readonly lang?: Lang;
    readonly nowmsk?: number;
    readonly haveTelegram?: boolean;
    readonly speedup?: number;
}

export enum UserRole {
    TRAINER = "trainer",
    STUDENT = "student",
}

export enum Lang {
    EN = 'en',
    RU = 'ru',
    UK = 'uk',
    LV = 'lv'
}

export interface TrainingsRequest {
    requestTimestamp: number;
    trainerId: string;
}

export interface TelegramConnectInfo {
    tlgrmpin: number;
    tlgrmvalid: string;
    linked: boolean;
}