import React, {FunctionComponent} from "react";
import makeStyles from '@mui/styles/makeStyles';
import cs from "classnames";

type IconProps = {
    className?: string
}

export const CheckedIcon: FunctionComponent<IconProps> = ({className}) => {
    const classes = useStyles();

    return (
        <svg className={cs(classes.icon, className)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 11V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H14" stroke="black"
                  strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M21.1998 4.6001L11.1998 15.2001L6.7998 10.8001" stroke="black" strokeWidth="2"
                  strokeMiterlimit="10"/>
        </svg>
    );
}

const useStyles = makeStyles({
    icon: {
        width: "1.5rem",
        height: "1.5rem"
    }
});