import React, { FunctionComponent, useContext } from 'react';
import { PageContext, PageContextProps } from '/src/contexts/Page';
import { BannerTemplate } from './common/BannerTemplate';
import { links } from '/src/domain/shared/links';
import { useTranslation } from 'react-i18next';

interface RegistrationBannerProps {
  open: boolean;
  onClose: () => void;
}

export const RegistrationBanner: FunctionComponent<RegistrationBannerProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { pageConfig } = useContext(PageContext) as PageContextProps;

  return (
    <BannerTemplate
      open={open}
      onClose={onClose}
      title={t('register')}
      text={t('registrationAdDescription')}
      link={{
        content: t('login'),
        href: pageConfig?.authUrl || `${links.origin}/login`,
      }}
    />
  );
};
