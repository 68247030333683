import React, {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
} from 'react';
import {
  CurrentUserContext,
  CurrentUserContextProps,
} from '/src/contexts/shared/CurrentUserContext';
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  Box,
  CircularProgress,
} from '@mui/material';
import { CustomDialog } from './common/CustomDialog';
import { BannerHead } from './common/BannerHead';
import { CustomDialogTitle } from './common/CustomDialogTitle';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { theme } from '/src/assets/theme';

interface TelegramConnectionBannerProps {
  open: boolean;
  onClose: () => void;
}

export const TelegramConnectionBanner: FunctionComponent<
  TelegramConnectionBannerProps
> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { telegramConnectInfo, requestTelegramConnectInfo } = useContext(
    CurrentUserContext,
  ) as CurrentUserContextProps;

  useEffect(() => {
    requestTelegramConnectInfo();
  }, []);

  return (
    <CustomDialog open={open} onClose={onClose} fullWidth>
      <BannerHead onClose={onClose} />
      <CustomDialogTitle>{t('connectTelegramBot')}</CustomDialogTitle>
      <DialogContent>
        <DialogContentText>
          {telegramConnectInfo?.tlgrmpin ? (
            <>
              {t('telegramBotAdDescription')}
              {!telegramConnectInfo.linked &&
                !!telegramConnectInfo.tlgrmpin && (
                  <>
                    <Box>
                      {`Pin ${t('toConnect')}`}{' '}
                      <Box
                        component={'b'}
                        sx={{ color: theme.charcoalGreyColor }}
                      >
                        {telegramConnectInfo.tlgrmpin}
                      </Box>{' '}
                      {telegramConnectInfo.tlgrmvalid &&
                        `(${t('validUntil')} ${dayjs(
                          telegramConnectInfo.tlgrmvalid,
                        ).format('DD.MM.YYYY HH:mm')}) `}
                    </Box>
                    <Box>
                      {`(${t('orEnterCommand')} /pin ${
                        telegramConnectInfo.tlgrmpin
                      } ${t('inChatWithBot')})`}
                    </Box>
                  </>
                )}
            </>
          ) : (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', p: '2rem 0' }}
            >
              <CircularProgress />
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          p: '2.25rem 1.5rem 1.75rem',
        }}
      >
        <Button
          size="large"
          fullWidth
          sx={{
            textTransform: 'capitalize',
          }}
          variant="contained"
          disabled={!telegramConnectInfo?.tlgrmpin}
          href={`https://telegram.me/spbrunBot?start=${telegramConnectInfo?.tlgrmpin}`}
        >
          {t('enable')}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};
