import React, { FunctionComponent } from "react";
import makeStyles from '@mui/styles/makeStyles';
const defaultAvatar = require('../../../assets/icons/defaultAvatar.svg')

interface AvatarProps {
    url?: string
}


export const Avatar: FunctionComponent<AvatarProps> = ({ url }) => {
    const classes = useStyles({ url: url ? url : defaultAvatar })

    return (
        <div className={classes.gradient}>
            <div className={classes.avatar}></div>
        </div>
    )
}

const useStyles = makeStyles({
    gradient: {
        width: '6.5rem',
        height: '6.5rem',
        background: 'radial-gradient(50% 50% at 50% 50%, #F9625A 43.75%, rgba(255, 181, 140, 0) 100%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    avatar: {
        width: '5rem',
        height: '5rem',
        borderRadius: '50%',
        backgroundImage: ({ url }: AvatarProps) => `url('${url}')`,
        backgroundSize: 'cover'
    }
});