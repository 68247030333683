import React, {FunctionComponent} from "react";

import {PageProvider} from "/src/contexts/Page";
import {PageConfig, PageData} from "/src/domain/shared/Page";
import {User} from "/src/domain/shared/User";

export interface AppProps {
    pageData: PageData,
    currentUser?: User,
    pageConfig: PageConfig,
    children: any,
}

const App: FunctionComponent<AppProps> = (props) => {
    const {pageData, currentUser, pageConfig, children} = props;

    return (
        <PageProvider initialPageData={pageData} initialCurrentUser={currentUser} initialPageConfig={pageConfig}>
            {children}
        </PageProvider>
    );
}

export default App;