import React, { FunctionComponent, useContext } from "react";
import { NotificationsContext, NotificationsContextProps } from "/src/contexts/NotificationsContext";
import { IconButton, Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

interface NotificationButtonProps {
    expandNotifications: () => void;
}

const MAX_COUNT_TO_SHOW_BADGE = 99;

export const NotificationButton: FunctionComponent<NotificationButtonProps> = ({
    expandNotifications
}) => {
    const { numberOfUnreadNotifications } = useContext(NotificationsContext) as NotificationsContextProps;


    return <IconButton aria-label="notifications" onClick={expandNotifications}>
        <Badge badgeContent={numberOfUnreadNotifications} max={MAX_COUNT_TO_SHOW_BADGE} sx={{ color: "primary.contrastText" }}>
            <NotificationsIcon/>
        </Badge>    
    </IconButton>
}
