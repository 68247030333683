import React, { FunctionComponent } from "react";
import { Link as LinkData, UpcomingRaceData } from "/src/domain/challenges/UpcomingRace";
import { Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { get as _get } from "lodash";
import { theme } from "/src/assets/theme";


type UpcomingRaceViewProps = {
    raceData: UpcomingRaceData,
    className?: string
}

export const UpcomingRaceView: FunctionComponent<UpcomingRaceViewProps> = ({ raceData, className }) => {
    const classes = useStyles({ cardBackgroundUrl: raceData.imgUrl });

    const link1 = _get(raceData.links, '0') as LinkData | undefined;
    const link2 = _get(raceData.links, '1') as LinkData | undefined;
    const link3 = _get(raceData.links, '2') as LinkData | undefined;

    return (
        <div className={classes.container}>
            <div className={classes.imgContainer}>
                <div className={classes.dates}>
                    {raceData.dates}
                </div>
                {raceData.distances.length > 0 &&
                    <div className={classes.distances}>
                        {raceData.distances.join(', ')}
                    </div>}
            </div>
            <div className={classes.descriptionContainer}>
                <div className={classes.challengeName}>{raceData.name}</div>
                {raceData.description && <div className={classes.challengeDescription}>{raceData.description}</div>}
                <div className={classes.linkContainer}>
                    {link1 &&
                        <Button className={classes.link} variant={"contained"}
                            color={"secondary"} href={link1?.url}>{link1?.label}</Button>
                    }
                    {link2 &&
                        <Button className={classes.link} variant={"outlined"}
                            color={"primary"} href={link2?.url}>{link2?.label}</Button>
                    }
                    {link3 &&
                        <Button className={classes.link} variant={"contained"}
                            color={"primary"} href={link3?.url}>{link3?.label}</Button>
                    }
                </div>
            </div>
        </div>
    );
}

type UpcomingRaceViewStyleProps = {
    cardBackgroundUrl: string,
}

const useStyles = makeStyles((muiTheme) => ({
    container: {
        [muiTheme.breakpoints.up("sm")]: {
            width: "15rem",
            flex: 1
        },
        width: "70vw",
        flexShrink: 0,
        display: "flex",
        textDecoration: "none",
        color: "inherit",
        "&:hover,&:visited,&:active": { textDecoration: "none", color: "inherit" },
        overflow: "hidden",
        borderRadius: "5px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        cursor: "pointer",
        flexDirection: "column"
    },
    imgContainer: (props: UpcomingRaceViewStyleProps) => ({
        width: "100%",
        height: "13rem",
        position: "relative",
        background: `url('${props.cardBackgroundUrl}') no-repeat`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        flexShrink: 0
    }),
    descriptionContainer: {
        width: "100%",
        padding: "1rem",
        minHeight: "8rem",
        boxSizing: "border-box",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    dates: {
        position: "absolute",
        top: 0,
        left: 0,
        color: theme.WHITE_COLOR,
        padding: "0.25rem 3rem 0.25rem 1rem",
        fontSize: "0.9rem",
        fontWeight: 500,
        background: `url(${require('../../../assets/icons/secondary_rect.svg')})`,
        display: "inline-block",
        backgroundPositionX: "right",
        backgroundSize: "cover"
    },
    distances: {
        position: "absolute",
        bottom: 0,
        right: 0,
        color: theme.WHITE_COLOR,
        padding: "0.25rem 1rem 0.25rem 3rem",
        fontSize: "0.9rem",
        fontWeight: 500,
        background: `url(${require('../../../assets/icons/primary_rect_flipped.svg')})`,
        display: "inline-block",
        backgroundPositionX: "left",
        backgroundSize: "cover"
    },
    challengeName: {
        fontSize: "1.15rem",
        fontWeight: 500,
        marginBottom: "0.5rem",
        minHeight: "2.6rem"
    },
    challengeDescription: {
        flexGrow: 1
    },
    linkContainer: {
        width: "100%",
        textAlign: "center",
        paddingTop: "1rem"
    },
    link: {
        width: "100%",
        "&:not(:last-child)": {
            marginBottom: "1rem"
        }
    }
}));