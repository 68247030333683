import React, {FunctionComponent} from "react";
import {Grid} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useTranslation} from "react-i18next";
import {ResultCardRow, ResultCardRowProps} from "./ResultCardRow";
import {theme} from "/src/assets/theme";

export interface ResultCardProps {
    type: BoardType;
    title: string;
    leaderLabel: string;
    resultLabel: string;
    records: ResultCardRowProps[];
}

export enum BoardType {
    SINGLE = "single",
    TEAM = "team",
    CLUB = "club"
}


export const ResultCard: FunctionComponent<ResultCardProps> = ({
    title,
    leaderLabel,
    resultLabel,
    records
}) => {
    const {t} = useTranslation()
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Grid container
                  className={classes.headerContainer}
                  justifyContent="center"
                  alignItems="center"
            >
                <Grid item className={classes.header}>
                    {title}
                </Grid>
            </Grid>
            <Grid container className={classes.resultsHeader}>
                <Grid item xs={7}
                      container
                      alignItems="center"
                      className={classes.resultsHeaderLeader}>{t(leaderLabel)}</Grid>
                <Grid item xs={5}
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                      className={classes.resultsHeaderParameter}>{t(resultLabel)}</Grid>
            </Grid>
            <div className={classes.results}>
                {
                    records.map(record => (
                        <ResultCardRow
                            key={`${record.place}${record.label}`}
                            {...record}
                        />
                    ))
                }
            </div>
        </div>
    );
}


const useStyles = makeStyles(muiTheme => ({
    container: {
        color: muiTheme.palette.primary.contrastText,
        backgroundColor: muiTheme.palette.primary.main,
        borderRadius: '10px',
        fontWeight: 500,
        margin: '0 1.5rem',
    },
    headerContainer: {
        minHeight: '4rem'
    },
    header: {
        fontSize: '1.05rem',
        textAlign: 'center',
        padding: '0 1.5rem',
        fontWeight: 600,
    },
    resultsHeader: {
        backgroundColor: theme.primaryDarkColor,
        fontSize: '0.9rem',
        fontWeight: 600,
    },
    resultsHeaderLeader: {
        padding: '0.5rem 0 0.5rem 1.5rem',
        overflow: 'hidden',
    },
    resultsHeaderParameter: {
        background: `url(${require('../../../assets/icons/challengeProtocolLeaderBoardCardRect.svg')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '0.5rem 1.5rem',
    },
    results: {
        padding: '0.5rem 1.5rem',
        fontSize: '0.9rem',
        lineHeight: '1.2rem',
        height: '8.4rem'
    }
}))
