import React, {FunctionComponent} from "react";
import {parameter} from "/src/domain/challenges/ChallengeProtocolData"
import CurrentUserResultRow from "/src/ui/challengeProtocol/currentUserResults/CurrentUserResultRow"
import makeStyles from '@mui/styles/makeStyles';
import {theme} from "/src/assets/theme";
import {useTranslation} from "react-i18next"

interface CurrentUserResultsData {
    userResults: UserResultData[]
}

export interface UserResultData {
    title: string;
    place?: number;
    label: string;
    result: parameter;
    resultLabel: string;
}

export const CurrentUserResults: FunctionComponent<CurrentUserResultsData> = ({ userResults }) => {
    const {t} = useTranslation()
    const classes = useStyles()


    if (!userResults.length) return null;

    return (
        <div className={classes.container}>
            <div className={classes.containerCard}>
                <h5 className={classes.header}>{t('yourResult')}</h5>
                {
                    userResults.map(userResult => (
                        <CurrentUserResultRow
                            key={userResult.title}
                            userResult={userResult}
                        />
                    ))
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles(muiTheme => ({
    container: {
        color: muiTheme.palette.secondary.main,
        margin: '0 auto 1rem',
        maxWidth: '30rem',
        fontWeight: 500,
    },
    containerCard: {
        margin: '0 1.5rem 0.5rem',
        backgroundColor: theme.lightSecondaryColor,
        borderRadius: '10px',
    },
    header: {
        fontSize: '1.25rem',
        textAlign: 'center',
        padding: '1.5rem',
        fontWeight: 500,
    }
}));