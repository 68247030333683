import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import { User } from "/src/domain/shared/User";
import { Club } from "/src/domain/club/ClubData";
import { PremiumFeaturesBanner } from "/src/ui/shared/premiumBanners/PremiumFeaturesBanner";
import { PostViewMode } from "/src/domain/studentTasks/StudentTasksData";
import { UpdatePostParams } from "/src/contexts/PostContext";
import { Box, Dialog } from "@mui/material";
import { theme } from "/src/assets/theme";
import { NewPostKeys } from './SelectPostStatus';
import { getPostClubId } from './SelectPostStatus'
import { PostEditor } from "./PostEditor";
import { Editor as TinyMCEEditor } from 'tinymce';
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

interface PostButtonProps {
    getPostId: () => Promise<string | null>;
    createPost: (args: UpdatePostParams) => Promise<boolean>;
    currentDate: string;
    isPremium: boolean;
    userClub: Club | null;
    currentUser: User;
    clubManagement?: Club;
}

const getDefaultKeys = ({
    userClub,
    currentUser,
    clubManagement,
} : {
    userClub: Club | null;
    currentUser: User;
    clubManagement?: Club
}) => {
    const keys: string[] = ['public', `author${currentUser.id}`];
    const clubId = getPostClubId({
        postKeys: [],
        userClub,
        clubManagement,
    });
    if (clubId) {
        keys.push(`club${clubId}`)
    }

    return keys;
}

export const PostButton: FunctionComponent<PostButtonProps> = ({
    getPostId,
    createPost,
    currentDate,
    isPremium,
    userClub,
    currentUser,
    clubManagement,
}) => {
    const { t } = useTranslation();

    const editorRef = useRef<TinyMCEEditor | null>(null);

    const [openEditor, setOpenEditor] = useState(false);
    const [postId, setPostId] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [openPremiumBanner, setOpenPremiumBanner] = useState(false);
    const [viewMode, setViewMode] = React.useState(PostViewMode.REGULAR);
    const [newPostKeys, setNewPostKeys] = useState<NewPostKeys>(() => ({
        newKeys: getDefaultKeys({
            userClub,
            currentUser,
            clubManagement,
        }),
        add: [],
        del: [],
    }));
    const [tags, setTags] = useState<string[]>([]);

    const [newAudioFile, setNewAudioFile] = useState<File | null>(null);

    const setComponentStates = async () => {
        const postId = await getPostId();
        setPostId(postId);
        
        if (postId)
            setLoading(false);
    }

    useEffect(() => {
        if (!openEditor) return;
        setComponentStates();

        return () => {
            setPostId(null);
        }
    }, [openEditor])

    const createNewPost = async () => {
        const text = editorRef.current?.getContent();
        if (!postId || !text) {
            alert(t('postRequirement'));
            return false;
        }
        setLoading(true);


        const postDate = dayjs(currentDate).format("YYYY-MM-DD");

        const isSent = await createPost({
            postId,
            date: postDate,
            text,
            viewMode,
            audio: newAudioFile,
            audiosec: undefined,
            tags,
            keys: newPostKeys.newKeys,
            addKeys: newPostKeys.newKeys,
            delKeys: [],
        });

        setLoading(false);

        if (!isSent) {
            alert(t('serverError'));
            return false;
        }
        
        setOpenEditor(false);
        setPostId(null);

        return true;        
    }   

    const handlePostButtonClick = () => {
        if (isPremium) {
            setOpenEditor(!openEditor);
        } else {
            setOpenPremiumBanner(!openPremiumBanner);
        }
    }

    return <>
        <Box
            onClick={handlePostButtonClick}
            sx={{
                m: "1rem 0",
                p: "0.6rem 0.875rem",
                cursor: "pointer",
                borderRadius: "4px",
                color: theme.darkColor,
                opacity: 0.8,
                border: `1px solid ${theme.greyTextColor}`,
                "&:hover": {
                    borderColor: theme.darkColor
                }
            }}
        >
            { t('postRequirement') }
        </Box>
        <Dialog PaperComponent={React.Fragment} open={openPremiumBanner} onClose={() => setOpenPremiumBanner(!openPremiumBanner)}>
            <PremiumFeaturesBanner/>
        </Dialog>
        <PostEditor
            open={openEditor}
            onClose={() => setOpenEditor(!openEditor)}
            postId={postId}
            editorRef={editorRef}
            savePost={createNewPost}
            loading={loading}
            viewMode={viewMode}
            setViewMode={setViewMode}
            currentUserId={currentUser?.id}
            newAudioFile={newAudioFile}
            setNewAudioFile={setNewAudioFile}
            tags={tags}
            setTags={setTags}
            postOwnerInfo={currentUser ? {
                id: currentUser.id,
                firstName: currentUser.firstName || "",
                lastName: currentUser.lastName || "",
                isPremium: currentUser.isPremium,
                avatarUrl: currentUser.avatarUrl,
                currentTrainerId: currentUser.currentTrainerId,
            } : undefined}
            newPostKeys={newPostKeys}
            setNewPostKeys={setNewPostKeys}
            userClub={userClub}
            clubManagement={clubManagement}
        />
    </>
}
