import React, { FunctionComponent } from 'react';
import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TagsViewProps {
  tags: string[];
}

export const TagsView: FunctionComponent<TagsViewProps> = ({ tags }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: '0.25rem' }}>
      {tags.map((tag) => (
        <Link
          key={tag}
          underline="hover"
          href={`/tag?q=${encodeURIComponent(tag)}`}
          sx={{
            display: 'inline-block',
            mr: '0.1rem 0.1rem 0 0',
            cursor: 'pointer',
            '&::before': {
              content: "'#'",
            },
          }}
        >
          {tag}
        </Link>
      ))}
    </Box>
  );
};
