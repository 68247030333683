import { TrainingType } from "/src/domain/studentTasks/StudentTasksData"
import { theme } from "/src/assets/theme";

export type TrainingInfoData = {
    [key in TrainingType]: string;
}

export const trainingText: TrainingInfoData = {
    [TrainingType.NONE]: '',
    [TrainingType.WELLNESS]: 'recoveryTraining',
    [TrainingType.DEVELOPMENT]: 'developmentTraining',
    [TrainingType.LONG]: 'longTraining',
    [TrainingType.INTERVAL]: 'intervalTraining',
    [TrainingType.REPEATED]: 'repeatedTraining',
    [TrainingType.COMPETITION]: 'competition',
    [TrainingType.STREINGTH]: 'strengthTraining',
}

export const trainingColors: TrainingInfoData = {
    [TrainingType.NONE]: 'inherit',
    [TrainingType.WELLNESS]: theme.lightGreenColor,
    [TrainingType.DEVELOPMENT]: theme.blueColor,
    [TrainingType.LONG]: theme.secondaryColor,
    [TrainingType.INTERVAL]: theme.orangeColor,
    [TrainingType.REPEATED]: '#FDE910',
    [TrainingType.COMPETITION]: theme.darkColor,
    [TrainingType.STREINGTH]: '#FF0099',
}

