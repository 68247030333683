import React, {FunctionComponent} from "react";
import {makeStyles} from "@mui/styles";
import cs from 'classnames';
import {theme} from "/src/assets/theme";

type StarProps = {
    isActive: boolean,
    size?: StarSize,
    className?: string,
}

export enum StarSize {
    SMALL = "small",
    NORMAL = "normal",
}

export const Star: FunctionComponent<StarProps> = ({isActive, size = StarSize.NORMAL, className}) => {
    const classes = useStyles({size});
    const fillColor = isActive ? theme.secondaryColor : theme.lightSecondaryColor;

    return (<svg className={cs(classes.star, className)} viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.1897 0.310547L16.1509 9.42437H25.7338L17.9811 15.057L20.9423 24.1709L13.1897 18.5382L5.43697 24.1709L8.39823 15.057L0.645548 9.42437H10.2284L13.1897 0.310547Z"
            fill={fillColor}/>
    </svg>);
};

interface StarStyleProps {
    size: StarSize
}

const useStyles = makeStyles({
    star: (props: StarStyleProps) => ({
        height: props.size === StarSize.NORMAL ? "1rem" : ".75rem",
        width: props.size === StarSize.NORMAL ? "1rem" : ".75rem",
    })
});