import {QuestTasksInfo, LootBoxData} from "/src/domain/quest/QuestData";
import axios from "axios";
import { isString as _isString, findKey as _findKey } from "lodash";
import {links} from "/src/domain/shared/links";

const API_URL = `${links.origin}/challenge2210.php`;

export const getQuestTasks = async (userId: string): Promise<QuestTasksInfo | null> => {
    try {
        if (!userId) return null;

        const response = await axios.get(`${API_URL}?id=${userId}`);
        const questTasksInfo = response.data;
        
        return questTasksInfo;
    } catch (e) {
        console.log("getQuestTasks error: ", e);
        return null;
    }
}

export const checkParticipationInQuest = async (userId?: string): Promise<boolean | null> => {
    try {
        if (!userId) return null;

        const response = await axios.get(`${API_URL}?what=check&id=${userId}`);
        const isParticipationInQuest = response.data.inquest;
        
        return isParticipationInQuest;
    } catch (e) {
        console.log("checkParticipationInQuest: ", e);

        return null;
    }
}

export const getLootbox = async (boxId: number, userId: string, clickedKey: number): Promise<LootBoxData | null> => {
    try {
        const response = await axios.get(`${API_URL}?what=boxget&boxid=${boxId}&stravauser=${userId}`);

        const lootbox = response.data;

        if (_isString(lootbox)) return null;

        const selectedKey = _findKey(lootbox, {'selected': true});
        if (!selectedKey) return null;

        if (Number(selectedKey) === clickedKey) {
            return lootbox;
        }

        const selectedPrize = lootbox[selectedKey];
        lootbox[selectedKey] = lootbox[clickedKey];
        lootbox[clickedKey] = selectedPrize;

        return lootbox;
    } catch (e) {
        console.log("getLootBox: ", e);
        return null;
    }
}