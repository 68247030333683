import { instanceOfSingleRaceRecord } from "/src/domain/challenges/ChallengeProtocolData";
import { ProtocolData } from "/src/domain/shared/requests/getProtocol";
import { find as _find } from "lodash";


export const checkUserInProtocol = (protocol: ProtocolData | null, userId?: string) => {
    if (!protocol?.protocols.length || !userId) return false;

    const isUserInProtocol = Boolean(_find(protocol.protocols, p => _find(p.records, r => {
        if (instanceOfSingleRaceRecord(r))
            return r.participant.id == userId;
    })))

    return isUserInProtocol;
}