import React, { FunctionComponent } from "react";
import makeStyles from '@mui/styles/makeStyles';

interface LogoProps {
    url?: string
}

export const Logo: FunctionComponent<LogoProps> = ({
    url
}) => {
    const classes = useStyles({ url: url })

    return (
        <div className={classes.back}>
            <div className={classes.logo}></div>
        </div>
    )
    }
    
const useStyles = makeStyles(muiTheme => ({
    back: {
        backgroundColor: muiTheme.palette.primary.main,
        width: '5rem',
        height: '5rem',
        padding: "1rem",
        borderRadius: '50%'
    },
    logo: {
        width: "100%",
        height: '100%',
        backgroundImage: ({ url }: LogoProps) => `url('${url}')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain"
    }
}));