import { links } from '/src/domain/shared/links';
import {
  PostData,
  PostProps,
  PostViewMode,
  AudioMarkData,
  PlaylistData,
} from '/src/domain/post/PostData';
import i18n from 'i18next';

import axios from 'axios';
import { isArray as _isArray, isNumber as _isNumber } from 'lodash';

export const getPostId = async () => {
  try {
    const response = await axios.get(
      links.origin + '/apiweb/post.php?action=postid',
    );

    return response.data.postid as string;
  } catch (e) {
    console.log(e);
    return null;
  }
};

interface GetPostsMainArgs {
  isAudio?: boolean;
}

export interface GetUserPostsByDateArgs extends GetPostsMainArgs {
  userId: string;
  firstDate?: string;
  lastDate?: string;
}

export interface GetUserPostsByLimitArgs extends GetPostsMainArgs {
  userId: string;
  from?: number;
  limit?: number;
}

export interface GetAllPostsArgs extends GetPostsMainArgs {
  from?: number;
  limit?: number;
}

export type GetPosts = {
  (val: PostsArgs): Promise<PostData[]>;
};

export type PostsArgs =
  | GetAllPostsArgs
  | GetUserPostsByDateArgs
  | GetUserPostsByLimitArgs
  | string;

export const getPosts: GetPosts = async (
  val: PostsArgs,
): Promise<PostData[]> => {
  try {
    let response;

    if (typeof val === 'string') {
      response = await axios.get(
        links.origin + '/apiweb/post.php?action=search',
        {
          params: {
            q: val,
          },
        },
      );
    } else if ('firstDate' in val || 'lastDate' in val) {
      response = await axios.get(
        links.origin + '/apiweb/post.php?action=list',
        {
          params: {
            uid: val.userId,
            first_date: val.firstDate,
            last_date: val.lastDate,
            audio: getAudioPostParam(val.isAudio),
          },
        },
      );
    } else if ('userId' in val) {
      response = await axios.get(
        links.origin + '/apiweb/post.php?action=list2',
        {
          params: {
            uid: val.userId,
            from: (val as GetUserPostsByLimitArgs).from,
            limit: (val as GetUserPostsByLimitArgs).limit,
            audio: getAudioPostParam(val.isAudio),
          },
        },
      );
    } else {
      response = await axios.get(
        links.origin + '/apiweb/post.php?action=list2',
        {
          params: {
            all: 1,
            from: (val as GetUserPostsByLimitArgs).from,
            limit: (val as GetUserPostsByLimitArgs).limit,
            audio: getAudioPostParam(val.isAudio),
          },
        },
      );
    }

    return response.data.list || [];
  } catch (e) {
    console.log('getPosts:', e);
    return [];
  }
};

const getAudioPostParam = (isAuido?: boolean) => {
  return isAuido ? 1 : undefined;
};

type GetPostWithRecommendations = {
  (
    postId: string,
    isWithoutRecommend?: false,
  ): Promise<PostWithRecommendations | null>;
  (postId: string, isWithoutRecommend: true): Promise<PostData | null>;
};

export interface PostWithRecommendations {
  post: PostData;
  similar?: PostData[];
}

export const getPostWithRecommendations: GetPostWithRecommendations = async (
  postId: string,
  isWithoutRecommend?: boolean,
) => {
  try {
    const response = await axios.get(
      links.origin + '/apiweb/post.php?action=one',
      {
        params: {
          postid: postId,
          nosimilar: isWithoutRecommend ? 1 : undefined,
        },
      },
    );

    if (isWithoutRecommend) {
      return response.data.post || null;
    }

    return response.data.post ? response.data : null;
  } catch (e) {
    console.log('getPostWithRecommendations:', e);
    return null;
  }
};

export interface SavePostParams {
  create: boolean;
  postId: string;
  date: string;
  text: string;
  viewMode: PostViewMode;
  audio: File | null;
  audiosec: number | undefined;
  tags: string[];
  keys: string[];
  addKeys: string[];
  delKeys: string[];
}

export const savePost = async ({
  create,
  postId,
  date,
  text,
  viewMode,
  audio,
  audiosec,
  tags,
  keys,
  addKeys,
  delKeys,
}: SavePostParams): Promise<PostProps | null> => {
  try {
    const saveMode = create ? 'add' : 'save';

    const formData = new FormData();
    formData.append('d', date);
    formData.append('txt', text);
    formData.append('tags', JSON.stringify(tags));
    audio && formData.append('audio', audio);
    const response = await axios.post(
      links.origin + '/apiweb/post.php',
      formData,
      {
        params: {
          action: saveMode,
          postid: postId,
          viewMode,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    if (response.data.audioerror) {
      alert(`${i18n.t('serverError')}: ${response.data.audioerror}`);
    }

    if (!response.data.ok) {
      return null;
    }

    const newPost: PostProps = {
      type: 'post',
      postid: response.data.idpost || postId,
      d: date,
      txt: text,
      viewMode,
    };

    if (audio && response.data.audiosec) {
      newPost.audiosec = response.data.audiosec;
      newPost.audiosrc = response.data.audiosrc;
    }

    // Добавляем ключи, которые стали известны наверняка
    addKeys.push(`post${newPost.postid}`);
    // если загружено аудио
    if (response.data.audiosec) {
      addKeys.push(`audio`);
    // если загружено с ошибкой и аудио до этого не было
    } else if (!audiosec && response.data.audioerror) {
      delKeys.push(`audio`);
    }

    const isAddKeysSaved = addKeys.length
      ? await updatePostStatus('add', newPost.postid, addKeys)
      : true;
    const isDelKeysSaved = delKeys.length
      ? await updatePostStatus('del', newPost.postid, delKeys)
      : true;
    if (isAddKeysSaved && isDelKeysSaved) {
      newPost.status = keys;
    } else {
      alert(`${i18n.t('serverError')}: status saving error`);
    }

    return newPost;
  } catch (e) {
    console.log('savePost:', e);
    return null;
  }
};

export const updatePostStatus = async (
  type: 'set' | 'add' | 'del',
  postId: string,
  keys: string[],
): Promise<boolean> => {
  try {
    const formData = new FormData();
    formData.append('action', 'status' + type);
    formData.append('postid', postId);
    formData.append('key', JSON.stringify(keys));
    const response = await axios.post(
      `${links.origin}/apiweb/post.php`,
      formData,
    );

    return response.data.ok;
  } catch (e) {
    console.log('updatePostStatus:', e);
    return false;
  }
};

export type GetPostsByStatusParams = {
  keys: string[][];
  total?: false;
  // использовать вес постов при запросе
  byWeight?: boolean;
} & (
  | {
      from?: number;
      limit?: number;
      firstDate?: never;
      lastDate?: never;
    }
  | {
      firstDate?: string;
      lastDate?: string;
      from?: never;
      limit?: never;
    }
);

export type GetTotalPostsByStatusParams = Omit<
  GetPostsByStatusParams,
  'total'
> & {
  total: true;
};

export async function getPostsByStatus(
  props: GetPostsByStatusParams,
): Promise<PostData[] | null>;
export async function getPostsByStatus(
  props: GetTotalPostsByStatusParams,
): Promise<number | null>;
export async function getPostsByStatus(
  props: GetPostsByStatusParams | GetTotalPostsByStatusParams,
): Promise<PostData[] | number | null> {
  try {
    if (!props.firstDate && !props.lastDate && !props.limit) {
      props.limit = 3;
    }
    const response = await axios.get(`${links.origin}/apiweb/post.php`, {
      params: {
        action: 'statussearch',
        q: JSON.stringify(props.keys),
        from: props.from,
        limit: props.limit,
        first_date: props.firstDate,
        last_date: props.lastDate,
        total: props.total ? 1 : undefined,
        byweight: props.byWeight ? 1 : undefined,
      },
    });

    if (props.total) {
      return _isNumber(response.data.total) ? response.data.total : null;
    }

    return response.data.liststatus || null;
  } catch (e) {
    console.log('getPostsByStatus:', e);
    return null;
  }
}

export const deletePost = async (
  id: string,
  audioOnly: boolean,
): Promise<boolean> => {
  try {
    const action = audioOnly ? 'deleteaudio' : 'delete';
    const response = await axios.get(`${links.origin}/apiweb/post.php`, {
      params: {
        action,
        postid: id,
      },
    });

    // В слаучае удачнаго удаления аудио - удалить ключ audio
    const isDelAudioKeySaved =
      response.data.ok && audioOnly
        ? await updatePostStatus('del', id, ['audio'])
        : true;
    if (!isDelAudioKeySaved) {
      alert(i18n.t('serverError'));
    }

    if (response.data.ok) {
      return true;
    }

    return false;
  } catch (e) {
    console.log('deletePost:', e);
    return false;
  }
};

export interface GetNumberOfPostsArgs {
  userId?: string;
  all?: boolean;
}

export const getNumberOfPosts = async ({
  userId,
  all,
}: GetNumberOfPostsArgs) => {
  try {
    const response = await axios.get(
      `${links.origin}/apiweb/post.php?action=total`,
      {
        params: {
          uid: userId,
          all: all ? 1 : undefined,
        },
      },
    );

    return response.data.total || 0;
  } catch (e) {
    console.log('getNumberOfPosts:', e);
    return 0;
  }
};

export const getAudioMark = async (
  postid?: boolean,
): Promise<AudioMarkData | null> => {
  try {
    const response = await axios.get(`${links.origin}/apiweb/post.php`, {
      params: {
        action: 'getmark',
        postid: postid || undefined,
        last: postid ? undefined : 1,
      },
    });

    return response.data.mark;
  } catch (e) {
    console.log('getAudioMark:', e);
    return null;
  }
};

export const saveAudioMark = async (
  postid: string,
  sec: number,
  isFull: boolean,
): Promise<boolean> => {
  try {
    const response = await axios.post(
      `${links.origin}/apiweb/post.php`,
      {},
      {
        params: {
          action: 'setmark',
          postid: postid,
          sec: Math.round(sec),
          full: +isFull,
        },
      },
    );

    return Boolean(response.data.ok);
  } catch (e) {
    console.log('saveAudioMark:', e);
    return false;
  }
};

export const getPlaylist = async (): Promise<PlaylistData | null> => {
  try {
    const response = await axios.get(`${links.origin}/apiweb/playlist.php`, {
      params: { action: 'get' },
    });

    if (response.data.ok) {
      return response.data;
    }

    return null;
  } catch (e) {
    console.log('getPlaylist:', e);
    return null;
  }
};

export const savePlaylist = async (
  value: string[] | string,
): Promise<boolean> => {
  try {
    const formData = new FormData();

    const isSaveMode = _isArray(value);

    if (isSaveMode) {
      formData.append('ids', JSON.stringify(value));
    } else {
      formData.append('id', String(value));
    }
    const response = await axios.post(
      `${links.origin}/apiweb/playlist.php`,
      formData,
      {
        params: {
          action: isSaveMode ? 'set' : 'add',
        },
      },
    );

    return Boolean(response.data.ok);
  } catch (e) {
    console.log('saveAudioMark:', e);
    return false;
  }
};

export const getRepostedPosts = async (
  obj: GetUserPostsByLimitArgs,
): Promise<PostData[] | null> => {
  try {
    const response = await axios.get(`${links.origin}/apiweb/post.php`, {
      params: {
        action: 'listrepost',
        uid: obj.userId,
        from: obj.from,
        limit: obj.limit,
      },
    });

    if (response.data.list) {
      return response.data.list;
    }

    return null;
  } catch (e) {
    console.log('getRepostedPosts', e);
    return null;
  }
};

export const addRepostPost = async (postid: string): Promise<boolean> => {
  try {
    const response = await axios.post(
      `${links.origin}/apiweb/post.php`,
      {},
      {
        params: {
          action: 'addrepost',
          postid,
        },
      },
    );

    return Boolean(response.data.ok);
  } catch (e) {
    console.log('addRepost', e);
    return false;
  }
};

export const deleteRepostPost = async (postid: string): Promise<boolean> => {
  try {
    const response = await axios.post(
      `${links.origin}/apiweb/post.php`,
      {},
      {
        params: {
          action: 'delrepost',
          postid,
        },
      },
    );

    return Boolean(response.data.ok);
  } catch (e) {
    console.log('deleteRepost', e);
    return false;
  }
};

export const checkRepostedPosts = async (postId: string): Promise<boolean> => {
  try {
    const response = await axios.get(`${links.origin}/apiweb/post.php`, {
      params: {
        action: 'checkrepost',
        postid: postId,
      },
    });

    return response.data.repost === postId;
  } catch (e) {
    console.log('checkRepostedPosts', e);
    return false;
  }
};

export const getAudioKey = async (): Promise<string | null> => {
  try {
    const response = await axios.get(
      `${links.origin}/apiweb/post.php?action=getaudiokey`,
    );

    return response.data.audiokey || null;
  } catch (e) {
    console.log('getAudioKey', e);
    return null;
  }
};
